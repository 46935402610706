import React from 'react';
import _ from 'underscore';

export class LazyTableData {
    constructor(cellWidths, id = null) {
        const cellSum = Math.round(
            _.reduce(
                cellWidths,
                function (x, y) {
                    return x + y;
                },
                0
            )
        );
        if (cellSum !== 100) {
            console.error('Sum must be 100(%). Current sum is: ' + cellSum);
        }
        this.config = {
            id: id,
            cellWidths: cellWidths,
            headerRow: null,
            headerHeight: 30,
            footerRow: null,
            footerHeight: 30,
            rows: [],
            rowHeight: 23,
            noDataMessage: 'No data found',
            pagination: {
                enabled: false,
                perPage: 0,
                height: 0,
            },
            sorting: {
                enabled: false,
                defaultColumn: null,
                defaultDirection: 'asc',
                columns: {
                    0: {
                        sort: (a, b, direction) => {
                            // to be overwritten if needed
                            // here is to show the structure of the config
                        },
                    },
                },
            },
        };
    }
    setPaginationEnabled(enabled) {
        this.config.pagination.enabled = enabled;
        this.config.pagination.perPage = 100;
        this.config.pagination.height = 30;
    }
    setPaginationConfig(pagination) {
        this.config.pagination = pagination;
    }
    setHeaderRow(row) {
        this.config.headerHeight = row.height;
        this.config.headerRow = row;
    }
    setFooterRow(row) {
        if (row == null) {
            this.config.footerHeight = 0;
            this.config.footerRow = null;
        } else {
            this.config.footerHeight = row.height;
            this.config.footerRow = row;
        }
    }
    addRow(row) {
        this.config.rows.push(row);
    }
    getConfig() {
        const config = _.clone(this.config);
        config.numberOfPages = this._getNumberOfPages(config);
        return config;
    }

    _getNumberOfPages(config) {
        const visibleRowCount = _.filter(config.rows, row => !row.hidden)
                .length,
            perPage = config.pagination.perPage;

        if (visibleRowCount === 0 || perPage === 0) {
            return 1;
        }
        return Math.ceil(visibleRowCount / perPage);
    }

    getSorting() {
        if (!this.config.sorting.enabled) {
            return null;
        }
        const sorting = {
            defaultColumn: this.config.sorting.defaultColumn,
            defaultDirection: this.config.sorting.defaultDirection,
        };
        Object.keys(this.config.sorting.columns).forEach(columnKey => {
            const columnData = this.config.sorting.columns[columnKey];
            let sort = defaultSortColumn;
            if (_.isFunction(columnData.sort)) {
                sort = columnData.sort;
            }
            sorting[columnKey] = Object.assign({}, columnData, { sort });
        });
        return sorting;
    }

    _containsText(content, text) {
        if (!content) {
            return false;
        }
        if (React.isValidElement(content)) {
            if (_.isArray(content.props.children)) {
                for (const child of content.props.children) {
                    if (this._containsText(child, text)) {
                        return true;
                    }
                }
            }
            return false;
        } else if (_.isObject(content)) {
            for (const key in content) {
                if (
                    content.hasOwnProperty(key) &&
                    this._containsText(content[key], text)
                ) {
                    return true;
                }
            }
            return false;
        }
        return content.toString().toLowerCase().includes(text);
    }
}

export class Row {
    constructor(
        cells,
        classes = [],
        onClick = false,
        hidden = false,
        height = 23,
        additionalInfoRow = false
    ) {
        this.cells = cells;
        this.classes = classes;
        this.onClick = onClick;
        this.hidden = hidden;
        this.height = height;
        this.metaData = {};
        this.additionalInfoRow = additionalInfoRow;
    }
}

export class Cell {
    constructor(
        content,
        classes = [],
        sortingValue = null,
        tooltip = '',
        colspan = null
    ) {
        this.content = content;
        this.sortingValue = sortingValue;
        this.tooltip = tooltip;
        this.colspan = colspan;
        if (sortingValue == null) {
            if (_.isString(content)) {
                this.sortingValue = content;
            } else {
                this.sortingValue = '';
            }
        }
        if (!_.isArray(classes)) {
            this.classes = [classes];
        } else {
            this.classes = classes;
        }
    }
}

const defaultSortColumn = (a, b, direction) => {
    const aUppercase = a.toUpperCase(),
        bUppercase = b.toUpperCase();

    if (aUppercase < bUppercase) {
        return direction;
    }
    if (aUppercase > bUppercase) {
        return -direction;
    }

    return 0;
};
