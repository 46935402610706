import React from 'react';
import PropTypes from 'prop-types';
import './HighlightedContent.scss';

function HighlightedContent(props) {
    if (props.search.length === 0) {
        return props.content;
    }

    let found = false;
    let wordLength = 0;

    props.search.forEach(searchTerm => {
        const index = props.content
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase());

        if (index !== -1) {
            found = index;
            wordLength = searchTerm.length;
        }
    });

    if (found !== false) {
        return (
            <>
                {props.content.substr(0, found)}
                <b className="event-highlighted-content">
                    {props.content.substr(found, wordLength)}
                </b>
                {props.content.substr(found + wordLength)}
            </>
        );
    }

    return props.content;
}

HighlightedContent.propTypes = {
    search: PropTypes.array.isRequired,
    content: PropTypes.string.isRequired,
};

export default HighlightedContent;
