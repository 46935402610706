import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import PropTypes from 'prop-types';
import Calendar from '../Calendar/Calendar';
import './splash.scss';

class Splash extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.path !== this.props.path ||
            nextProps.xhrError !== this.props.xhrError ||
            nextProps.sports !== this.props.sports
        );
    }

    render() {
        if (_.isEmpty(this.props.sports)) {
            if (this.props.xhrError) {
                return (
                    <div className="auth-message-container error">
                        <strong>
                            Server error: {this.props.xhrError.debug.code}
                        </strong>
                        <br />
                        {this.props.xhrError.message}
                        <br />
                        If the error persists, please contact Sportradar support
                        for more details.
                    </div>
                );
            }

            return (
                <div className="auth-message-container">Fetching sports...</div>
            );
        }

        return <Calendar />;
    }
}

Splash.propTypes = {
    path: PropTypes.string.isRequired,
    sports: PropTypes.object.isRequired,
    xhrError: PropTypes.object,
};

const mapStateToProps = state => ({
    selectedDayTimestamp: state.calendar.selectedDayTimestamp,
    sports: state.calendar.sports,
    xhrError: state.calendar.xhrError,
});

export default connect(mapStateToProps)(Splash);
