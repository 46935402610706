import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Group from '../Group/Group';
import moment from '../../../../../../utils/CalendarMoment';
import FlagIcon from '../../../../../icons/FlagIcon/FlagIcon';
import './masterInfo.scss';
import { buildAvailability, buildLiveChannelGroup } from '../ContentEventInfo';

function MasterInfo(props) {
    const startOfEventMoment = moment.fromTimestamp(props.event.startDate);
    const endOfEventMoment = moment.fromTimestamp(props.event.endDate);

    function getStadiumDesc() {
        const stadiumInfo = props.event.me_stadium;
        const parent = stadiumInfo.stadiumname_parent || '';
        const stadium = stadiumInfo.stadiumname;
        const city = stadiumInfo.cityname;
        return `${parent}${stadium}, ${city}`;
    }

    function buildTournaments() {
        const rows = [];
        Object.values(props.event.me_tournaments).forEach((name, index) => {
            rows.push(
                <div key={`tournament-${index}`} className="tournament-name">
                    {name}
                </div>
            );
        });
        return rows;
    }

    const time = moment.now() > startOfEventMoment ? 'past' : 'future';

    return (
        <div className="event-info">
            <Group className="master-header">
                <h4>{props.event.me_description}</h4>
                <h5>{getStadiumDesc()}</h5>
            </Group>
            <Group className="datetime">
                <span className="time-livestatus">
                    From {startOfEventMoment.toFormat('DDDD T')}
                </span>
                <span className="time-livestatus">
                    To {endOfEventMoment.toFormat('DDDD T')}
                </span>
                <span className={`relativeTime ${time}`}>
                    {startOfEventMoment.toRelative()}
                </span>
            </Group>
            <br />
            <Group>
                <FlagIcon countryCode={props.event.countryCode} />
                <br />
                {buildTournaments()}
            </Group>
            {buildLiveChannelGroup(props.event, props.bookmakerInfo)}
            {buildAvailability(props.event)}
        </div>
    );
}

MasterInfo.propTypes = {
    event: PropTypes.object.isRequired,
    bookmakerInfo: PropTypes.object.isRequired,
    access: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        bookmakerInfo: state.calendar.auth.userInfo.bookmakerInfo,
        access: state.calendar.access,
    };
};

export default connect(mapStateToProps)(MasterInfo);
