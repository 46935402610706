import types from './constants';

const initialState = {
    selectedTabs: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.INITIALIZE:
            return {
                ...state,
                selectedTabs: action.payload.defaults,
            };
        case types.SET_TAB:
            return {
                ...state,
                selectedTabs: {
                    ...state.selectedTabs,
                    [action.payload.viewId]: action.payload.id,
                },
            };
        default:
            return state || initialState;
    }
};
