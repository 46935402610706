import Xhr from '../../utils/Xhr';
import actionConstants from './constants';
import Alert from '../../components/Alert/Alert';
import validatorFunctions from '../../utils/ValidatorFunctions';
import { create as createNotification } from '../notification/actions';
import loggerConstants from '../logger/constants';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const addTextBanner = banner => (dispatch, getState) => {
    const addTextBannerReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  '/text-banner/save',
                  validatorFunctions.saveTextBanner,
                  'POST',
                  banner
              )
            : Xhr.backendRequest(
                  '/text-banner/save',
                  validatorFunctions.saveTextBanner,
                  'POST',
                  banner
              );
    addTextBannerReq
        .then(response => {
            const actualResponse = response.response || response;
            if (!actualResponse || !actualResponse.id) {
                throw new Error(
                    'Something went wrong while saving the text banner'
                );
            }

            dispatch({
                type: actionConstants.ADD,
                payload: {
                    banner: actualResponse,
                },
            });
            dispatch({
                type: loggerConstants.FETCH_LOGS_SUCCESS,
                payload: {
                    debug: actualResponse.debug,
                },
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const deleteTextBanner = id => (dispatch, getState) => {
    const deleteTextBannerReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  `/text-banner/delete/${id}`,
                  null,
                  'DELETE'
              )
            : Xhr.backendRequest(`/text-banner/delete/${id}`, null, 'DELETE');
    deleteTextBannerReq
        .then(response => {
            if (response.rowsAffected < 1) {
                throw new Error(
                    'Something went wrong while deleting the text banner'
                );
            }

            dispatch({
                type: actionConstants.DELETE,
                payload: {
                    id,
                },
            });
            dispatch({
                type: loggerConstants.FETCH_LOGS_SUCCESS,
                payload: {
                    debug: response.debug,
                },
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const updateTextBanner = banner => (dispatch, getState) => {
    const updateTextBannerReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  '/text-banner/update',
                  null,
                  'POST',
                  banner
              )
            : Xhr.backendRequest('/text-banner/update', null, 'POST', banner);
    updateTextBannerReq
        .then(response => {
            if (response.rowsAffected < 1) {
                throw new Error(
                    'Something went wrong while updating the text banner'
                );
            }

            dispatch({
                type: actionConstants.UPDATE,
                payload: {
                    banner,
                },
            });
            dispatch({
                type: loggerConstants.FETCH_LOGS_SUCCESS,
                payload: {
                    debug: response.debug,
                },
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const fetchAllTextBanners = () => (dispatch, getState) => {
    const fetchAllTextBannersReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  '/text-banner/all',
                  validatorFunctions.allBanners
              )
            : Xhr.backendRequest(
                  '/text-banner/all',
                  validatorFunctions.allBanners
              );
    fetchAllTextBannersReq.then(response => {
        dispatch(setTextBanners(response.response || response));
    });
};

export const setTextBanners = textBanners => ({
    type: actionConstants.SET,
    payload: {
        textBanners,
    },
});

export const confirmTextBanner = textBannerId => (dispatch, getState) => {
    getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
    isMicrofrontend
        ? Xhr.backendRequestLbcQuarkus('/text-banner/confirm', null, 'POST', {
              textBannerId,
          })
        : Xhr.backendRequest('/text-banner/confirm', null, 'POST', {
              textBannerId,
          });

    dispatch({
        type: actionConstants.CONFIRM,
        payload: {
            textBannerId,
        },
    });
};
