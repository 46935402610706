import _ from 'underscore';

const isValidBooking = function (booking) {
    return (
        _.isObject(booking) &&
        isPositiveNumber(booking.id) &&
        isPositiveNumber(booking.status) &&
        typeof booking.message === 'string'
    );
};

const isPositiveNumber = function (n) {
    return isNumeric(n) && n >= 0;
};

const isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};
const validateBanner = banner => {
    return (
        _.isObject(banner) &&
        banner.title !== undefined &&
        banner.id !== undefined &&
        !isNaN(parseFloat(banner.id)) &&
        banner.type !== undefined &&
        !isNaN(parseFloat(banner.type)) &&
        banner.validFrom !== undefined &&
        (banner.validFrom === false || !isNaN(parseFloat(banner.validFrom))) &&
        banner.validTo !== undefined &&
        (banner.validTo === false || !isNaN(parseFloat(banner.validTo)))
    );
};

const ValidatorFunctions = {
    bookingHistory: function (response) {
        return _.isObject(response) && response.response !== undefined;
    },
    bookingHistoryDetail: function (response) {
        return _.isObject(response) && response.response !== undefined;
    },
    packagesOverview: function (response) {
        return _.isObject(response) && response.packages !== undefined;
    },
    filter: function (response) {
        return _.isObject(response) && response.response !== undefined;
    },
    feed: function (response) {
        return _.isObject(response) && _.isArray(response.matches);
    },
    textBanner: function (response) {
        return _.isObject(response) && _.isArray(response.textBanners);
    },
    saveTextBanner: function (response) {
        if (!_.isObject(response)) {
            return false;
        }
        return validateBanner(response.response || response);
    },
    allBanners: function (response) {
        if (
            !_.isObject(response) ||
            !_.isObject(response.response || response)
        ) {
            return false;
        }

        response = response.response || response;
        for (const key in response) {
            if (response.hasOwnProperty(key)) {
                if (!validateBanner(response[key])) {
                    return false;
                }
            }
        }
        return true;
    },
    booking: function (response, matchIds) {
        if (
            !(
                _.isObject(response) &&
                _.isObject(response.prices) &&
                isPositiveNumber(response.status)
            )
        ) {
            console.error('Book response is not valid');
            return false;
        }

        for (const matchId in response.prices) {
            if (response.prices.hasOwnProperty(matchId)) {
                const booking = response.prices[matchId];
                if (
                    !isValidBooking(booking) ||
                    matchIds.indexOf(parseInt(matchId, 10)) === -1
                ) {
                    console.error('Price in book response is not valid:');
                    console.error(booking);
                    return false;
                }
            }
        }
        return true;
    },
};

export default ValidatorFunctions;
