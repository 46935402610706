import { adapterConstants } from '../../constants/shoppingBasket';

export default shoppingBasketStoreState => {
    const adapter = shoppingBasketStoreState.adapters.find(
        adapt => adapt.id === shoppingBasketStoreState.selectedAdapterId
    );

    if (adapter) {
        return {
            ...adapter,
            ...adapterConstants[adapter.id],
        };
    } else {
        return {};
    }
};
