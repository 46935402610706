import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import Button from '../Button/Button';
import FontIcon from '../../icons/FontIcon/FontIcon.jsx';
import './buttonDropdown.scss';

class ButtonDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.dropdownRef = createRef();
        this._handleClickOutside = this._handleClickOutside.bind(this);
        this._toggle = this._toggle.bind(this);
        this._mainButtonClick = this._mainButtonClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this._handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._handleClickOutside);
    }

    _handleClickOutside(event) {
        if (
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target) &&
            this.state.expanded
        ) {
            this._toggle();
        }
    }

    _getDropdownContent(content) {
        return content.map((button, index) => {
            return (
                <Button
                    key={`button_dropdown_${index}`}
                    className="dropdown-list-button"
                    type={button.type}
                    onClick={() => {
                        this._toggle();
                        button.onClick();
                    }}
                    fixedWidth={false}
                >
                    {button.text}
                </Button>
            );
        });
    }

    _toggle() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    _mainButtonClick() {
        if (this.props.mainButtonClick !== null) {
            this.props.mainButtonClick();
        } else {
            this._toggle();
        }
    }

    render() {
        const { data, style, type } = this.props;
        const { expanded } = this.state;

        return (
            <div
                className={classnames('dropdown-container', {
                    open: expanded,
                })}
                ref={this.dropdownRef}
                style={style}
            >
                <Button type={type} iconPosition="right" fixedWidth={false}>
                    <div
                        className={'dropdown-text'}
                        onClick={this._mainButtonClick}
                    >
                        {data.mainButtonText}
                    </div>
                    <div className="dropdown-toggle" onClick={this._toggle}>
                        <FontIcon icon="" />
                    </div>
                </Button>
                <div className="button-dropdown-content">
                    {this._getDropdownContent(data.buttons)}
                </div>
            </div>
        );
    }
}

ButtonDropdown.propTypes = {
    data: PropTypes.object.isRequired,
    style: PropTypes.object,
    type: PropTypes.oneOf(Object.values(Button.types)).isRequired,
    mainButtonClick: PropTypes.func,
};

ButtonDropdown.defaultProps = {
    mainButtonClick: null,
};

export default ButtonDropdown;
