export default class FilterSelection {
    constructor(title = '', uid = null) {
        this.uid = uid;
        this.title = title;
        this.searchPhrase = [];
        this.selectedCheckboxes = [];
        this.selectedProducts = [];
        this.ncaaFilter = {};
    }

    setSelectedCheckbox(groupId, value) {
        const count = this.selectedCheckboxes.filter(checkbox => {
            return checkbox.groupId === groupId && checkbox.value === value;
        }).length;
        if (!count) {
            this.selectedCheckboxes.push({ groupId, value });
        }
    }

    setSearchPhrase(phrase) {
        this.searchPhrase = phrase;
    }

    setSelectedProducts(selectedProducts) {
        this.selectedProducts = selectedProducts;
    }

    setNcaaFilter(ncaaFilter) {
        this.ncaaFilter = ncaaFilter;
    }
}
