import { createSelector } from 'reselect';
import { isVisible } from '../../utils/FilterUtils';
import _ from 'underscore';
import FilterConstants from '../../constants/FilterConstants';

export default createSelector(
    state => state.calendar.selectedCheckboxes,
    state => state.calendar.events,
    state => state.calendar.selectedProducts,
    state => state.calendar.ncaaFilter,
    state => state.calendar.recommenderSettings,
    (
        selectedCheckboxes,
        events,
        selectedProducts,
        ncaaFilter,
        recommenderSettings
    ) => {
        const checkboxesByGroup = Object.values(
            _.groupBy(selectedCheckboxes, 'groupId')
        );

        const filterOnUnavailable = selectedCheckboxes.some(
            selected =>
                selected.value ===
                FilterConstants.availabilities.UNAVAILABLE_MATCHES
        );
        const filterOnRecommended = selectedCheckboxes.some(
            selected =>
                selected.value ===
                FilterConstants.availabilities.RECOMMENDED_MATCHES
        );

        if (selectedProducts.length && !filterOnUnavailable) {
            // If booking status is not selected, products will be filtered here
            events = events.filter(event => {
                let isVisible = false;
                for (const prod of selectedProducts) {
                    const product = prod.toLowerCase();
                    if (event.products[product]) {
                        isVisible = true;
                        break;
                    }
                }
                return isVisible;
            });
        }

        // Checkboxes
        events = events.filter(event =>
            checkboxesByGroup.every(groupedCheckboxes => {
                return groupedCheckboxes.some(checkbox =>
                    isVisible(
                        checkbox,
                        event,
                        selectedProducts,
                        recommenderSettings
                    )
                );
            })
        );

        if (ncaaFilter.divisions && ncaaFilter.divisions.length) {
            events = events.filter(event => {
                if (!event.isNcaa) return false;

                return (
                    _.pluck(ncaaFilter.divisions, 'value').indexOf(
                        event.teamAway.division === null ||
                            event.teamHome.division === null
                            ? 0
                            : // _max finds the lowest division if different divisions
                              _.max([
                                  event.teamHome.division || 0,
                                  event.teamAway.division || 0,
                              ])
                    ) !== -1
                );
            });
        } else if (ncaaFilter.teamStates && ncaaFilter.teamStates.length) {
            events = events.filter(event => {
                if (!event.isNcaa) return false;

                // Set some defaults if missing for easier code
                if (event.teamHome.venue === null)
                    event.teamHome.venue = { state: 'XX' };
                if (event.teamAway.venue === null)
                    event.teamAway.venue = { state: 'XX' };

                return ncaaFilter.teamStatesOnly
                    ? // both teams' state must be in list of selected states
                      _.intersection(
                          [
                              event.teamHome.venue.state,
                              event.teamAway.venue.state,
                          ],
                          _.pluck(ncaaFilter.teamStates, 'value')
                      ).length === 2
                    : // at least team state must be in list of selected states
                      _.intersection(
                          [
                              event.teamHome.venue.state,
                              event.teamAway.venue.state,
                          ],
                          _.pluck(ncaaFilter.teamStates, 'value')
                      ).length;
            });
        } else if (ncaaFilter.venueStates && ncaaFilter.venueStates.length) {
            events = events.filter(event => {
                if (!event.isNcaa) return false;

                return (
                    event.venue &&
                    ncaaFilter.venueStates.filter(obj => {
                        return obj.value === event.venue.state;
                    }).length
                );
            });
        }
        return events;
    }
);
