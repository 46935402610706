import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHoveredEvent } from '../../stores/eventInfo/actions';

// it may occur that during the drag select the mouse enters on the selection box,
// thus triggering the onMouseLeave of the EventMatchViewTrigger. To avoid such
// behavior we are identifying this case and preventing the normal onMouseLeave execution.
function isSelectionInterferingOnMouseOver(event) {
    const inSelectionMode =
        document.querySelector('.selectable-selectbox') !== null;
    return (
        inSelectionMode &&
        event.nativeEvent.relatedTarget &&
        event.nativeEvent.relatedTarget.className === 'selectable-selectbox'
    );
}

function EventMatchViewTrigger(props) {
    return (
        <div
            onMouseEnter={() => props.setHoveredEvent(props.uri)}
            onMouseLeave={event => {
                if (!isSelectionInterferingOnMouseOver(event)) {
                    props.setHoveredEvent(null);
                }
            }}
        >
            {props.children}
        </div>
    );
}

EventMatchViewTrigger.propTypes = {
    uri: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    setHoveredEvent: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setHoveredEvent,
};

export default connect(null, mapDispatchToProps)(EventMatchViewTrigger);
