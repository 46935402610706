import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notification from './Notification';

import {
    close,
    onMouseLeave,
    onMouseEnter,
} from '../../../../stores/notification/actions';

const NotificationContainer = props => {
    return (
        <Notification
            notifications={props.notifications}
            onClose={props.close}
            onMouseLeave={props.onMouseLeave}
            onMouseEnter={props.onMouseEnter}
        />
    );
};

NotificationContainer.propTypes = {
    notifications: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    notifications: state.notification.notifications,
});

const mapDispatchToProps = {
    close,
    onMouseLeave,
    onMouseEnter,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationContainer);
