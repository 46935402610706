import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import getGroupedEvents from '../../../../../selectors/getGroupedEvents';
import {
    recalculateFilteredGroups,
    sortRecomendations,
} from '../../../../../stores/calendar/actions';
import { saveScrollPosition } from '../../../../../stores/viewport/actions.js';
import moment from '../../../../../utils/CalendarMoment';
import StorageLocal from '../../../../../utils/StorageLocal.js';
import EventContextMenu from '../../../../Event/EventContextMenu';
import EventMatchViewTrigger from '../../../../Event/EventMatchViewTrigger';
import EventSelectable from '../../../../Event/EventSelectable';
import EventSelector from '../../../../Event/EventSelector';
import FilterEventMessage from '../../../../FilterEventMessage/FilterEventMessage.jsx';
import { productText } from '../../../../formInput/ProductPicker/ProductPicker';
import './ListCondensed.scss';
import SortIcon from './SortIcon.jsx';

let scrollTimer = null,
    previousScrollTop = 0;

class ListCondensed extends React.Component {
    constructor(props) {
        super(props);

        this.viewPortRef = React.createRef();
        this.listRef = React.createRef();
        this.outerListRef = React.createRef();
        this.prevMatchesCount = this.props.amounts.matchesFiltered || 0;

        this.state = {
            scrollPosition: 0,
            isSortEnabled: false,
            sortingOrder: null,
            timeSortingOrder: null,
            height: 0,
        };
    }

    componentDidMount() {
        let mmNow = moment.now().startOf('hour');
        let isToday = moment
            .fromTimestamp(this.props.momentSelectedDay)
            .hasSame(mmNow, 'day');
        const storageLocal = new StorageLocal();
        storageLocal.setItem(
            'isStreamingQuarkusEnabled',
            this.props.isStreamingQuarkusEnabled
        );
        storageLocal.setItem(
            'isMatchInfoEnabledForQuarkus',
            this.props.isMatchInfoEnabledForQuarkus
        );

        if (isToday && this.props.scrollPosition === 0) {
            this.scrollToCurrentTimeRow();
        } else {
            this.listRef.current.scrollTo(this.props.scrollPosition);
        }
        if (this.viewPortRef.current) {
            this.setState({
                height: this.viewPortRef.current.clientHeight,
            });
        }

        const handleResize = () => {
            if (this.viewPortRef.current) {
                this.setState({
                    height: this.viewPortRef.current.clientHeight,
                });
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }

    componentWillUnmount() {
        this._clearScrollTimer();
    }

    scrollToCurrentTimeRow = () => {
        const events = this.generateContentArray();

        const mmNow = moment.now().startOf('hour').toFormat('HH:mm');
        const currentTime = moment.now().startOf('hour').toFormat('HH:mm');

        let previousTimeIndex = -1;
        let currentTimeIndex = -1;

        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event.type === 'timeHeader') {
                const eventTime = event.data;
                const isCurrentHour = eventTime === currentTime;
                const isAfterCurrentHour = eventTime > mmNow;
                if (isCurrentHour) {
                    currentTimeIndex = i;
                    break;
                }
                if (isAfterCurrentHour) {
                    break;
                }
                previousTimeIndex = i;
            }
        }
        const targetIndex =
            currentTimeIndex !== -1 ? currentTimeIndex : previousTimeIndex;
        if (targetIndex !== -1 && this.listRef.current) {
            this.listRef.current.scrollToItem(targetIndex, 'centre');
        }
    };

    shouldSortRecommendations(prevProps, selectedPriority, isSortEnabled) {
        return prevProps.selectedPriority !== selectedPriority && isSortEnabled;
    }

    componentDidUpdate(prevProps) {
        const { selectedPriority, amounts } = this.props;
        const { isSortEnabled, sortingOrder, scrollPosition } = this.state;

        if (
            amounts.matchesFiltered !== prevProps.amounts.matchesFiltered &&
            this.props.freeTextSearchPhrase.length <= 1
        ) {
            this.listRef.current.scrollTo(scrollPosition);
        }

        if (
            this.shouldSortRecommendations(
                prevProps,
                selectedPriority,
                isSortEnabled
            )
        ) {
            this.sortRecommendations(sortingOrder);
        }
    }

    _clearScrollTimer = () => {
        if (scrollTimer) {
            clearTimeout(scrollTimer);
        }
    };

    sortRecommendations = sortingOrder => {
        this.setState(() => ({
            isSortEnabled: true,
            sortingOrder,
        }));
        this.props.sortRecomendations(sortingOrder);
    };

    sortEventsBasedOnTime = sortingOrder => {
        this.setState(() => ({
            isSortEnabled: false,
            timeSortingOrder: sortingOrder,
        }));
        this.props.recalculateFilteredGroups(
            this.props.currentView,
            sortingOrder
        );
    };

    _onScroll = () => {
        this._clearScrollTimer();

        const nextScrollTop = this.outerListRef?.current?.scrollTop;
        if (nextScrollTop !== previousScrollTop) {
            previousScrollTop = nextScrollTop;
        }

        if (
            this.props.amounts.matchesFiltered !== this.prevMatchesCount &&
            this.props.freeTextSearchPhrase.length <= 1
        ) {
            this.prevMatchesCount = this.props.amounts.matchesFiltered;
            this.setState({ scrollPosition: this.props.scrollPosition });
        }

        scrollTimer = setTimeout(() => {
            this.props.saveScrollPosition(nextScrollTop);
        }, 100);
    };

    // Helper method to generate the entire content array (headers and event rows)
    /* istanbul ignore next */
    generateContentArray = () => {
        const { events, groups } = this.props;
        const content = [];
        const mmNow = moment.now().startOf('hour');

        if (this.state.isSortEnabled) {
            for (const i in events) {
                if (events.hasOwnProperty(i)) {
                    content.push({
                        type: 'event',
                        data: events[i],
                    });
                }
            }
        } else {
            const sortedGroups =
                this.state.timeSortingOrder === 'desc'
                    ? Object.entries(groups).reverse()
                    : Object.entries(groups);
            for (const [k, value] of sortedGroups) {
                const group = value;
                const tsGroup = k;
                const mmGroup = moment.fromTimestamp(tsGroup);
                const timeheaderText = mmGroup.toFormat('HH:mm');
                let tabClassName = 'tab';

                const isToday = moment
                    .fromTimestamp(this.props.momentSelectedDay)
                    .hasSame(mmNow, 'day');

                if (isToday && mmGroup.hasSame(mmNow, 'hour')) {
                    tabClassName += ' highlight';
                }

                content.push({
                    type: 'timeHeader',
                    data: timeheaderText,
                    className: tabClassName,
                });

                for (const i in group.events) {
                    if (group.events.hasOwnProperty(i)) {
                        content.push({
                            type: 'event',
                            data: group.events[i],
                        });
                    }
                }
            }
        }

        return content;
    };

    renderEventRow = ({ index, style }) => {
        const contentArray = this.generateContentArray();
        const item = contentArray[index];

        if (item.type === 'timeHeader') {
            return (
                <div
                    className={`event-condensed-row time-row ${item.className}`}
                    style={style}
                >
                    <div className="time textline-500">{item.data}</div>
                </div>
            );
        }

        if (item.type === 'event') {
            const event = item.data;
            return (
                <div style={style} key={event.uri}>
                    <EventMatchViewTrigger uri={event.uri}>
                        <EventSelectable
                            event={event}
                            isCondensed
                            width="100%"
                        />
                    </EventMatchViewTrigger>
                </div>
            );
        }

        return null;
    };

    render() {
        const headers = this.props.isNewProductsUIEnabled
            ? ['Sport', 'Time', 'Status', 'Fixture', 'Details', 'Products']
            : [
                  'Sport',
                  'Time',
                  'Status',
                  'Fixture',
                  'Details',
                  'LO',
                  'LD',
                  'BP',
                  'LCO',
                  'LCR',
                  'LCT',
              ];

        const contentArray = this.generateContentArray();
        const itemSize = 32;
        const headerHeight = 36;
        return (
            <div className="viewport-list" ref={this.viewPortRef}>
                <EventSelector
                    scrollContainer=".viewport-list"
                    eventsAreCondensed
                >
                    <div
                        className={`event-condensed-row top-row ${
                            this.props.isNewProductsUIEnabled
                                ? 'products-row'
                                : ''
                        }`}
                        key="top-row"
                    >
                        {headers.map(header => (
                            <div
                                className={`header ${header.toLowerCase()} table-400`}
                                title={productText[header]}
                                key={`header_${header}`}
                            >
                                {header}
                                {header === 'Time' && (
                                    <SortIcon
                                        sortingOrder={
                                            this.state.timeSortingOrder
                                        }
                                        onSortClick={sortOrder =>
                                            this.sortEventsBasedOnTime(
                                                sortOrder
                                            )
                                        }
                                    />
                                )}
                            </div>
                        ))}
                        {this.props.showRecommended && (
                            <div className="options">
                                <SortIcon
                                    sortingOrder={this.state.sortingOrder}
                                    onSortClick={sortOrder =>
                                        this.sortRecommendations(sortOrder)
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <List
                        height={
                            contentArray.length
                                ? this.state.height - headerHeight
                                : 0
                        }
                        itemCount={contentArray.length}
                        itemSize={itemSize}
                        width="100%"
                        outerRef={this.outerListRef}
                        ref={this.listRef}
                        onScroll={this._onScroll}
                    >
                        {this.renderEventRow}
                    </List>
                    <EventContextMenu />
                    <FilterEventMessage visibleEvents={this.props.events} />
                </EventSelector>
            </div>
        );
    }
}

ListCondensed.propTypes = {
    currentView: PropTypes.string.isRequired,
    momentSelectedDay: PropTypes.any.isRequired,
    groups: PropTypes.object.isRequired,
    isNewProductsUIEnabled: PropTypes.bool,
    sortRecomendations: PropTypes.func,
    events: PropTypes.array.isRequired,
    recalculateFilteredGroups: PropTypes.func.isRequired,
    showRecommended: PropTypes.bool,
    selectedPriority: PropTypes.string,
    isStreamingQuarkusEnabled: PropTypes.bool.isRequired,
    isMatchInfoEnabledForQuarkus: PropTypes.bool.isRequired,
    scrollPosition: PropTypes.number,
    saveScrollPosition: PropTypes.func,
    freeTextSearchPhrase: PropTypes.array,
    amounts: PropTypes.object,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    momentSelectedDay: state.calendar.selectedDayTimestamp,
    freeTextSearchPhrase: state.calendar.freeTextSearchPhrase,
    groups: getGroupedEvents(state),
    events: state.calendar.events,
    scrollPosition: state.viewport.scrollPosition,
    isNewProductsUIEnabled:
        state.viewport.selectedVersion === 'new-version' ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
    showRecommended: !!state.calendar.recommenderSettings.showRecommended,
    selectedPriority: state.calendar.recommenderSettings.selectedPriority,
    isStreamingQuarkusEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_fullstream,
    isMatchInfoEnabledForQuarkus:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_match_info,
    amounts: state.calendar.amounts,
});
const mapDispatchToProps = {
    sortRecomendations,
    recalculateFilteredGroups,
    saveScrollPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCondensed);
