import React from 'react';
import PropTypes from 'prop-types';
import moment from '../../../utils/CalendarMoment';

function MatchEventTime(props) {
    const eventStartMoment = moment.fromTimestamp(props.startDateTimestamp);

    return (
        <span className="event-time">
            <time>{eventStartMoment.toFormat('HH:mm')}</time>
        </span>
    );
}

MatchEventTime.propTypes = {
    startDateTimestamp: PropTypes.number.isRequired,
};

export default MatchEventTime;
