import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import FontIcon from '../../../icons/FontIcon/FontIcon.jsx';
import CustomSelect from '../../../formInput/CustomSelect/CustomSelect';
import Xhr from '../../../../utils/Xhr';
import userSwitcherBookmakers from '../../../../selectors/userSwitcherBookmakers';
import _ from 'underscore';
import './userSwitcherDialog.scss';
import { DateTime } from 'luxon';
import { zones } from 'tzdata';

class UserSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputBookmakerId: '',
            inputTimezone: {
                value: props.currentUser.timezone,
                text: props.currentUser.timezone,
            },
        };

        this._onResetClick = this._onResetClick.bind(this);
        this._onInputKeyUp = this._onInputKeyUp.bind(this);
        this._onInputChange = this._onInputChange.bind(this);
        this._onSelectChange = this._onSelectChange.bind(this);
    }

    _switchUser(uri) {
        Xhr.backendRequest(uri).then(
            () => window.location.reload(),
            () => window.location.reload()
        );
    }

    _onResetClick() {
        this._switchUser('/user-switcher/reset');
    }

    _onInputKeyUp(e) {
        switch (e.which) {
            case 13: // ENTER
                this._switchUserFromInput(
                    this.state.inputBookmakerId,
                    this.state.inputTimezone
                );
                break;
            case 27: // ESC
                this.setState({ inputBookmakerId: '' });
                break;
            default:
            // Nothing to do
        }
    }

    _onInputChange(e) {
        const bmId = e.target.value.replace(/\D/, '');
        bmId !== e.target.value && (e.target.value = bmId);
        this.setState({ inputBookmakerId: bmId });
    }

    _onSelectChange(selected) {
        if (_.isObject(selected) && !_.isArray(selected)) {
            this.setState({
                inputTimezone: selected,
            });
        }
    }

    _switchUserFromInput(bookmakerId, timezone) {
        if (isNaN(parseFloat(bookmakerId))) {
            console.error(`Invalid bookmakerId: ${bookmakerId}`);
            return;
        }

        const userId = this.props.currentUser.id;
        const userName = this.props.currentUser.name;
        this._switchUser(
            `/user-switcher/set/${bookmakerId}/${userId}/unknown/${userName}/${timezone.value}`
        );
    }

    _buildInput() {
        // find a good list of timezones I guess
        const timezones = Object.entries(zones)
            .filter(([zoneName, v]) => Array.isArray(v))
            .map(([zoneName, v]) => zoneName)
            .filter(tz => DateTime.local().setZone(tz).isValid);

        const options = timezones.map(timezone => {
            const diff = DateTime.local().setZone(timezone).offset,
                prefix = diff < 0 ? '-' : '+',
                hour = this._formatTime(Math.floor(diff / 60)),
                minute = this._formatTime(diff % 60);

            const nbsp = String.fromCharCode(160).repeat(3);
            return {
                value: timezone,
                label: `${timezone} ${nbsp}(${prefix}${hour}:${minute})`,
            };
        });

        return (
            <div>
                <h3>Manual input</h3>
                <div className="input bookmaker-input">
                    <label htmlFor="bookmakerId">Bookmaker ID:</label>
                    <input
                        name="bookmakerId"
                        id="bookmakerId"
                        type="text"
                        onKeyUp={this._onInputKeyUp}
                        onChange={this._onInputChange}
                        value={this.state.inputBookmakerId}
                        autoFocus
                    />
                </div>
                <div className="timezone-input">
                    <label>Timezone:</label>
                    <CustomSelect
                        id={'timezone-input-select'}
                        onChange={this._onSelectChange}
                        value={this.state.inputTimezone}
                        placeholder="Timezone"
                        clearable={false}
                        searchable={true}
                        optionHeight={20}
                        options={options}
                    />
                </div>
                <br />
                <div className="input">
                    <button
                        onClick={() =>
                            this._switchUserFromInput(
                                this.state.inputBookmakerId,
                                this.state.inputTimezone
                            )
                        }
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }

    _formatTime(number) {
        number = number.toString(10);
        if (number.substr(0, 1) === '-') {
            number = number.substr(1);
        }
        return number.length > 1 ? number : `0${number}`;
    }

    _buildBookmakersList(bookmakers) {
        return bookmakers.map((bookmaker, i) => {
            const switchId = bookmaker.userId || this.props.currentUser.id;
            const switchName =
                bookmaker.userName || this.props.currentUser.name;
            const onRowClick = () =>
                this._switchUser(
                    `/user-switcher/set/${bookmaker.bookmakerId}/${switchId}/${bookmaker.bookmakerName}/${switchName}/${bookmaker.timezone}`
                );

            return (
                <div
                    className={classnames('row', {
                        selected: bookmaker.selected,
                    })}
                    onClick={onRowClick}
                    key={`user_switcher_row_${i}`}
                >
                    <div>
                        <FontIcon icon="" />
                        {bookmaker.bookmakerName} ({bookmaker.bookmakerId})
                    </div>
                    <div className="green">
                        {switchName} ({switchId})
                    </div>
                    <div className="green">{bookmaker.timezone}</div>
                </div>
            );
        });
    }

    render() {
        const reset = (
            <div className="reset" onClick={this._onResetClick}>
                <FontIcon icon="" />
                RESET
            </div>
        );

        const user = this.props.currentUser,
            currentUser = (
                <div className="current-user">
                    <h3>Current bookmaker</h3>
                    <div className="row">
                        <div>
                            <FontIcon icon="" />
                            {user.bookmakerName} ({user.bookmakerId})
                        </div>
                        <div className="green">
                            {user.name} ({user.id})
                        </div>
                        <div className="green">{user.timezone}</div>
                    </div>
                </div>
            );

        return (
            <div className="user-switcher-dialog">
                {currentUser}
                {this._buildInput()}
                <div className="user-list">
                    {this._buildBookmakersList(this.props.bookmakers)}
                </div>
                {reset}
            </div>
        );
    }
}

UserSwitcher.propTypes = {
    bookmakers: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    currentUser: state.calendar.auth.userInfo,
    bookmakers: userSwitcherBookmakers(state),
});

export default connect(mapStateToProps, {})(UserSwitcher);
