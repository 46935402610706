import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentBox from '../../../../ContentBox/ContentBox';
import ContentBoxTitle from '../../../../ContentBoxTitle/ContentBoxTitle';
import ContentFilters from '../ContentFilters/ContentFilters';
import './BookingRecommender.scss';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import {
    recalculateFilteredGroups,
    setRecommenderSettings,
} from '../../../../../stores/calendar/actions';
import Button from '../../../../../components/formInput/Button/Button';
import { recommenderSettingsFn } from '../../../../../utils/RecommenderSettings';
import FilterConstants from '../../../../../constants/FilterConstants';
import { PdsTooltip } from '@podium-design-system/react-components';
const selectableNumbers = [10, 20, 50, 100];

const BookingRecommender = ({
    recommenderSettings,
    setRecommenderSettings,
    currentView,
    recalculateFilteredGroups,
    events,
    selectedCheckboxes,
}) => {
    const [isSelectedPriority, setIsSelectedPriority] = useState(true);
    const [selectedNumber, setSelectedNumber] = useState(100);

    const isRecommendedFilterSelected = useCallback(() => {
        return selectedCheckboxes.find(
            checkbox =>
                checkbox.value ===
                FilterConstants.availabilities.RECOMMENDED_MATCHES
        );
    }, [selectedCheckboxes]);

    const settings = useMemo(() => {
        return recommenderSettingsFn(
            events,
            selectedNumber,
            isSelectedPriority,
            recommenderSettings.showRecommended === null
                ? true
                : recommenderSettings.showRecommended,
            !!isRecommendedFilterSelected()
        );
    }, [
        events,
        isSelectedPriority,
        selectedNumber,
        recommenderSettings.showRecommended,
        isRecommendedFilterSelected,
    ]);

    useEffect(() => {
        setRecommenderSettings(settings);
        recalculateFilteredGroups(currentView);
    }, [
        settings,
        currentView,
        setRecommenderSettings,
        recalculateFilteredGroups,
    ]);

    const options = selectableNumbers.map(value => {
        return (
            <option value={value} key={'banner_type_' + value}>
                {value.toString()}
            </option>
        );
    });

    return (
        <div>
            <ContentBox>
                <ContentBoxTitle>Recommender</ContentBoxTitle>
                <div className="recommender">
                    <div className="group ">
                        <div className="item recommendation-type">
                            Recommendation type
                            <div className="switch-background">
                                <PdsTooltip
                                    key="Forecasted LO/LD Bookings"
                                    size="md"
                                    arrow={true}
                                    preferredPosition="bottom"
                                    fixedPosition="bottom"
                                    intensity="low"
                                    surface="on-light"
                                    maxWidth="175px"
                                >
                                    <div
                                        slot="text"
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            display: 'flex',
                                        }}
                                    >
                                        Probability of booking LO/LD or Expected
                                        Turnover for MTS customers
                                    </div>
                                    <Button
                                        onClick={() => {
                                            setIsSelectedPriority(true);
                                        }}
                                        type={
                                            isSelectedPriority
                                                ? Button.types.BLUE_BORDER
                                                : Button.types
                                                      .BLUE_BORDER_FILLED
                                        }
                                        fixedWidth={true}
                                        width={69}
                                    >
                                        Forecasted LO/LD Bookings
                                    </Button>
                                </PdsTooltip>
                                <PdsTooltip
                                    key="Number of LCO Viewers"
                                    arrow={true}
                                    preferredPosition="bottom"
                                    fixedPosition="bottom"
                                    intensity="low"
                                    surface="on-light"
                                    maxWidth="175px"
                                >
                                    <div
                                        slot="text"
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        Expected number of viewers of LCO stream
                                    </div>
                                    <Button
                                        onClick={() => {
                                            setIsSelectedPriority(false);
                                        }}
                                        type={
                                            !isSelectedPriority
                                                ? Button.types.BLUE_BORDER
                                                : Button.types
                                                      .BLUE_BORDER_FILLED
                                        }
                                        fixedWidth={true}
                                        width={93}
                                    >
                                        Number of LCO Viewers
                                    </Button>
                                </PdsTooltip>
                            </div>
                        </div>
                        <div className="item">
                            <div>
                                <FontIcon className="filter-icon" icon="" />
                                Number of events
                            </div>
                            <select
                                className="number-selector"
                                value={selectedNumber}
                                onChange={e =>
                                    setSelectedNumber(+e.target.value)
                                }
                            >
                                {options}
                            </select>
                        </div>
                        <div className="checkbox-item ui-400">
                            <div
                                className="checkbox"
                                onClick={() => {
                                    setRecommenderSettings({
                                        ...recommenderSettings,
                                        showRecommended:
                                            !recommenderSettings.showRecommended,
                                    });
                                }}
                            >
                                <FontIcon
                                    className={`filter-icon ${
                                        recommenderSettings.showRecommended
                                            ? 'text-blue-br-700'
                                            : 'text-gray-1100'
                                    }`}
                                    icon={
                                        recommenderSettings.showRecommended
                                            ? ''
                                            : ''
                                    }
                                />
                                Show recommended matches
                                <div className="counter">
                                    {recommenderSettings
                                        ? recommenderSettings.count
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentBox>
            <ContentFilters />
        </div>
    );
};

BookingRecommender.propTypes = {
    recommenderSettings: PropTypes.object,
    setRecommenderSettings: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    recalculateFilteredGroups: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    selectedCheckboxes: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    recommenderSettings: state.calendar.recommenderSettings,
    currentView: state.viewport.currentView,
    events: state.calendar.events,
    selectedCheckboxes: state.calendar.selectedCheckboxes,
});

const mapDispatchToProps = {
    setRecommenderSettings,
    recalculateFilteredGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingRecommender);
