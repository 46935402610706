import { addPrefix } from '../../utils/storeUtils';

export default addPrefix('autobooking', {
    LOAD_AUTOBOOKINGS: 'LOAD_AUTOBOOKINGS',
    LOAD_AUTOBOOKINGS_SUCCESS: 'LOAD_AUTOBOOKINGS_SUCCESS',
    LOAD_AUTOBOOKINGS_FAIL: 'LOAD_AUTOBOOKINGS_FAIL',

    AUTOBOOKING_SAVED: 'AUTOBOOKING_SAVED',
    SAVE_FAILED: 'SAVE_FAILED',

    AUTOBOOKING_DELETED: 'AUTOBOOKING_DELETED',
    DELETE_FAILED: 'DELETE_FAILED',

    UPDATE_FILTER: 'UPDATE_FILTER',

    SET_FILTERS: 'SET_FILTERS',

    LOAD_FILTER_SET: 'LOAD_FILTER_SET',

    TOGGLE_FILTER_SELECTION: 'TOGGLE_FILTER_SELECTION',
    ADD_FILTERS_SELECTED: 'ADD_FILTERS_SELECTED',
    SET_FILTERS_SELECTED_TO_RELEVANT: 'SET_FILTERS_SELECTED_TO_RELEVANT',
    SET_FILTER_ORDER: 'SET_FILTER_ORDER',

    TOGGLE_FILTER_DISABLED: 'TOGGLE_FILTER_DISABLED',

    RESET_FILTERS: 'RESET_FILTERS',
    RESET_ACTIVE_FILTERS: 'RESET_ACTIVE_FILTERS',
    ACTIVATE_FILTERS: 'ACTIVATE_FILTERS',

    SET_EDIT_ID: 'SET_EDIT_ID',

    LOAD_OPTIONS: 'LOAD_OPTIONS',
    LOAD_OPTIONS_SUCCESS: 'LOAD_OPTIONS_SUCCESS',
    LOAD_OPTIONS_FAIL: 'LOAD_OPTIONS_FAIL',

    SET_AUTOBOOKING_TYPE: 'SET_AUTOBOOKING_TYPE',

    SET_INITIAL_DATES: 'SET_INITIAL_DATES',

    AUTOBOOKING_RULES_IMPORT: 'AUTOBOOKING_RULES_IMPORT',
    AUTOBOOKING_RULES_IMPORT_SUCCESS: 'AUTOBOOKING_RULES_IMPORT_SUCCESS',
});

export const LEFT_COLUMN_FORM = 'LEFT_COLUMN_FORM';
export const RIGHT_COLUMN_FORM = 'RIGHT_COLUMN_FORM';
