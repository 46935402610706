import Xhr from '../../utils/Xhr';
import featureConstants from './constants';
import Alert from '../../components/Alert/Alert';
import { create as createNotification } from '../notification/actions';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const fetchFeatures = () => (dispatch, getState) => {
    const fetchFeaturesReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .enable_features_quarkus && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus('/feature/all')
            : Xhr.backendRequest('/feature/all');
    fetchFeaturesReq
        .then(response =>
            dispatch({
                type: featureConstants.FETCH,
                payload: response.response || response,
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const createFeature = feature => (dispatch, getState) => {
    dispatch({
        type: featureConstants.START_CREATION,
    });
    const createFeatureReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .enable_features_quarkus && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  '/feature/new',
                  null,
                  'POST',
                  feature
              )
            : Xhr.backendRequest('/feature/new', null, 'POST', feature);
    createFeatureReq
        .then(response =>
            dispatch({
                type: featureConstants.CREATE,
                payload: { ...response, ...feature },
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const updateFeature = feature => (dispatch, getState) => {
    dispatch({
        type: featureConstants.START_UPDATE,
        payload: feature.id,
    });
    const updateFeatureReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .enable_features_quarkus && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  '/feature/update',
                  null,
                  'POST',
                  feature
              )
            : Xhr.backendRequest('/feature/update', null, 'POST', feature);
    updateFeatureReq
        .then(response => {
            if (!response.success) {
                throw new Error('An error occured while updating feature.');
            }

            dispatch({
                type: featureConstants.UPDATE,
                payload: feature,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const deleteFeature = id => (dispatch, getState) => {
    const deleteFeatureReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .enable_features_quarkus && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  `/feature/delete/${id}`,
                  null,
                  'DELETE'
              )
            : Xhr.backendRequest(`/feature/delete/${id}`, null, 'DELETE');
    deleteFeatureReq
        .then(response => {
            if (!response.success) {
                throw new Error('An error occured while deleting feature.');
            }

            dispatch({
                type: featureConstants.DELETE,
                payload: id,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};
