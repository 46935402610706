import constants from '../../constants/Constants';
import views from '../../constants/views';
import FilterUtils from '../../utils/FilterUtils';
import Scroller from '../../utils/Scroller';
import StorageLocal from '../../utils/StorageLocal';
import _ from 'underscore';
import moment from '../../utils/CalendarMoment';
import types from './constants';

const storageLocal = new StorageLocal();

const _initLeftColumnProps = storage => {
    let status = Number(storage.getItem(constants.LOCAL_STORAGE.LEFT_COLUMN));

    if (!status) {
        status = constants.SIDE_COLUMN_STATUS.EXPANDED;
    }

    return status;
};

const sortEventsBasedOnRanking = (payload, state) => {
    const recommendationType =
        state.recommenderSettings.selectedPriority === 'mts'
            ? 'lo-mts'
            : state.recommenderSettings.selectedPriority;

    payload.events.sort((a, b) => {
        const aHasRecommendations =
            !!a.recommendations && Object.keys(a.recommendations).length > 0;
        const bHasRecommendations =
            !!b.recommendations && Object.keys(b.recommendations).length > 0;

        if (aHasRecommendations && bHasRecommendations) {
            const aRank =
                a.recommendations[recommendationType]?.rank || Infinity;
            const bRank =
                b.recommendations[recommendationType]?.rank || Infinity;
            return payload.sortOrder === 'asc' ? aRank - bRank : bRank - aRank;
        } else if (aHasRecommendations && !bHasRecommendations) {
            return payload.sortOrder === 'asc' ? -1 : 1; // a comes first, b without recommendations goes later
        } else if (!aHasRecommendations && bHasRecommendations) {
            return payload.sortOrder === 'asc' ? 1 : -1; // b comes first, a without recommendations goes later
        } else {
            return 0; // no recommendation, maintain order
        }
    });

    return payload.events;
};
const _initRightColumnProps = storage => {
    let status = Number(storage.getItem(constants.LOCAL_STORAGE.RIGHT_COLUMN));

    if (!status) {
        status = constants.SIDE_COLUMN_STATUS.EXPANDED;
    }

    return status;
};

const _initShowBookedCountsType = storage => {
    let showBookedCountType = storage.getItem(
        constants.LOCAL_STORAGE.SHOW_BOOKED_COUNT_TYPE
    );
    if (
        !showBookedCountType ||
        ![
            constants.SHOW_BOOKED_COUNT_TYPES.HIDDEN,
            constants.SHOW_BOOKED_COUNT_TYPES.BADGE,
            constants.SHOW_BOOKED_COUNT_TYPES.TEXTUAL,
        ].includes(showBookedCountType)
    ) {
        showBookedCountType = constants.SHOW_BOOKED_COUNT_TYPES.HIDDEN; // = default
    }
    return showBookedCountType;
};

const _initShowAsBooked = (storage, itemId, defaultBookedProduct) => {
    let showAsBooked = storage.getItem(itemId);
    if (
        !showAsBooked ||
        ![
            constants.SHOW_AS_BOOKED_PRODUCT.ANY,
            constants.SHOW_AS_BOOKED_PRODUCT.LD,
            constants.SHOW_AS_BOOKED_PRODUCT.LO,
            constants.SHOW_AS_BOOKED_PRODUCT.LCO,
            constants.SHOW_AS_BOOKED_PRODUCT.LCT,
            constants.SHOW_AS_BOOKED_PRODUCT.LCR,
            constants.SHOW_AS_BOOKED_PRODUCT.BP,
            constants.SHOW_AS_BOOKED_PRODUCT.BOTH,
        ].includes(showAsBooked)
    ) {
        showAsBooked = defaultBookedProduct; // = default
    }
    return showAsBooked;
};

const _initSelectedLOSportConfigSetting = storage => {
    let selectedLOSportConfigSetting = storage.getItem(
        constants.LOCAL_STORAGE.SELECTED_LO_SPORT_CONFIG_SETTING
    );
    if (!selectedLOSportConfigSetting) {
        selectedLOSportConfigSetting =
            constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_SHOWN; // = default
    }
    return selectedLOSportConfigSetting;
};

const _initSelectedDay = () => {
    const dateMatch = window.location.href.match(
        /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    );
    if (dateMatch) {
        const dateString = dateMatch[1];
        const date = moment.fromISO(dateString);
        const isToday = moment.now().hasSame(date, 'day');
        if (date.isValid && !isToday) {
            return date.toFormat('X');
        }
    }
    return moment.now().toFormat('X');
};

const initialState = {
    size: {
        leftColumn: _initLeftColumnProps(storageLocal),
        rightColumn: _initRightColumnProps(storageLocal),
        viewSwitcherWidth: {
            medium: 0,
            large: 0,
        },
    },
    auth: {
        userCredentialsDidChange: false,
        isAuthenticating: true,
        isAuthenticated: false,
        userSwitcher: {},
        userInfo: {},
        enableQuarkusInPortal: false,
    },
    access: {},
    showBookedCountsType: _initShowBookedCountsType(storageLocal),
    selectedLOSportConfigSetting:
        _initSelectedLOSportConfigSetting(storageLocal),
    showAsBooked: _initShowAsBooked(
        storageLocal,
        constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT,
        constants.SHOW_AS_BOOKED_PRODUCT.ANY
    ),
    showAsBookedPrimary: _initShowAsBooked(
        storageLocal,
        constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT_PRIMARY,
        constants.SHOW_AS_BOOKED_PRODUCT.BOTH
    ),
    showAsBookedSecondary: _initShowAsBooked(
        storageLocal,
        constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT_SECONDARY,
        constants.SHOW_AS_BOOKED_PRODUCT.ANY
    ),
    isFetching: false,
    selectedDayTimestamp: Number(moment.now().toFormat('X')), // today = default
    events: [],
    groupsTimeline: [],
    groupsListCondensed: {},
    sports: {},
    countries: {},
    amounts: {
        matchesUnfiltered: 0, // total amount of matches for the current selected day (before filters are applied)
        matchesFiltered: 0, // total amount of matches that are visible on screen, for the user (after filters are applied)
    },
    checkboxes: [],
    selectedCheckboxes: [],
    selectedProducts: [],
    ncaaFilter: {
        divisions: [],
        teamStates: [],
        teamStatesOnly: false,
        venueStates: [],
    },
    rowsHeightTotal: 0, // updated after we've fetched events
    freeTextSearchPhrase: [],
    xhrError: null,
    backendSettings: null,
    helpBalloon: {
        visible: false,
        position: { x: 0, y: 0 },
        type: null,
    },
    showHiddenPackages: false, // see LO-4724,
    contextMenuOpen: false,
    deletedFilterSets: [],
    defaultFilterId: 0,
    recommenderSettings: {
        selectedPriority: 'mts',
        selectedNumber: 100,
        showRecommended: true,
        count: 0,
        matchIds: [],
    },
};

const setXhrError = (state, data) => {
    return {
        ...state,
        xhrError: data,
    };
};

const canCheckboxBeChecked = (state, groupId, value) => {
    if (groupId !== constants.FILTER_GROUP_SPORTS) {
        return true;
    }
    if (
        state.selectedLOSportConfigSetting !==
        constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_HIDDEN
    ) {
        return true;
    }

    const foundDisabled = Object.values(state.sports).find(
        sport => sport.disabledInLOConfig && sport.id === value
    );
    return !foundDisabled;
};

const onCheckboxFilterToggle = (state, payload) => {
    let selectedCheckboxes = state.selectedCheckboxes;

    const isChecked = state.selectedCheckboxes.some(
        checkbox =>
            checkbox.groupId === payload.groupId &&
            checkbox.value === payload.value
    );

    if (isChecked) {
        selectedCheckboxes = selectedCheckboxes.filter(checkbox => {
            return !(
                checkbox.groupId === payload.groupId &&
                checkbox.value === payload.value
            );
        });
    } else if (canCheckboxBeChecked(state, payload.groupId, payload.value)) {
        selectedCheckboxes = [
            ...selectedCheckboxes,
            {
                groupId: payload.groupId,
                subGroupId: FilterUtils.getSubGroupId(payload.value),
                value: payload.value,
            },
        ];
    }

    return {
        ...state,
        selectedCheckboxes,
    };
};

const onProductFilterChange = (state, payload) => {
    return {
        ...state,
        selectedProducts: [...payload.products],
    };
};

const onNcaaFilterChange = (state, payload) => {
    return {
        ...state,
        ncaaFilter: { ...state.ncaaFilter, ...payload },
    };
};

const onRecommenderSettingsChange = (state, payload) => {
    return {
        ...state,
        recommenderSettings: {
            ...payload.settings,
        },
    };
};

const onFreeTextSearchPhraseChange = (state, payload) => {
    let nextSelectedCheckboxes = state.selectedCheckboxes.filter(item => {
        return item.groupId !== constants.FILTER_GROUP_FREETEXT;
    });

    if (payload.phrase.length > 0) {
        nextSelectedCheckboxes = nextSelectedCheckboxes.concat([
            { groupId: constants.FILTER_GROUP_FREETEXT, value: payload.phrase },
        ]);
    }

    return {
        ...state,
        freeTextSearchPhrase: payload.phrase,
        selectedCheckboxes: nextSelectedCheckboxes,
    };
};

export default (state, action) => {
    let newState = {};
    switch (action.type) {
        case types.GET_AUTH_STATUS_START:
            return setXhrError(state, null);

        case types.GET_AUTH_STATUS_SUCCESS:
            newState = {
                ...state,
                auth: {
                    ...state.auth,
                    isAuthenticating: false,
                    consumerRoute: action.payload.consumerRoute || '/',
                    enableQuarkusInPortal: action.payload.enableQuarkusInPortal,
                },
            };

            if (action.payload.authenticated) {
                newState = {
                    ...newState,
                    auth: {
                        ...newState.auth,
                        userInfo: action.payload.response.auth.user,
                        isAuthenticated: true,
                        userSwitcher: action.payload.response.auth.userSwitcher,
                    },
                    access: {},
                    selectedDayTimestamp: _initSelectedDay(),
                    sports: action.payload.response.misc.sports,
                    countries: action.payload.response.misc.countries,
                    isFetching: false,
                    backendSettings: action.payload.response.backendSettings,
                };

                for (const role of action.payload.response.auth.user.access) {
                    newState.access[
                        role
                            .split('-')
                            .map((item, index) =>
                                index
                                    ? item[0].toUpperCase() + item.slice(1)
                                    : item
                            )
                            .join('')
                    ] = true;
                }

                Scroller.setCurrentScrollHour(
                    moment
                        .fromTimestamp(newState.selectedDayTimestamp)
                        .toFormat('H')
                );
            }
            return newState;

        case types.GET_AUTH_STATUS_ERROR:
            console.error('Authenticate request failed.', action.payload.error);
            return setXhrError(
                {
                    ...state,
                    auth: {
                        ...state.auth,
                        isAuthenticating: false,
                    },
                },
                action.payload.error
            );

        case types.FETCH_EVENTS_FOR_DAY_ERROR:
            return setXhrError(
                {
                    ...state,
                    isFetching: false,
                },
                action.payload.error
            );

        case types.FETCH_EVENTS_FOR_DAY_START:
            return setXhrError(
                {
                    ...state,
                    isFetching: true,
                    selectedDayTimestamp: Number(
                        action.payload.mm.toFormat('X')
                    ),
                },
                null
            );

        case types.FETCH_EVENTS_FOR_DAY_SUCCESS:
            return {
                ...state,
                events: action.payload.matches,
                amounts: {
                    ...state.amounts,
                    matchesUnfiltered: action.payload.matches.length,
                },
            };

        case types.FETCH_EVENTS_FOR_DAY_GROUP_SUCCESS:
            let name = 'groupsListCondensed';
            if (
                action.payload.currentView ===
                views.VIEWPORT_TYPE_TIMELINE_GADGET
            ) {
                name = 'groupsTimeline';
            }
            return {
                ...state,
                rowsHeightTotal: action.payload.data.rowsHeightTotal,
                isFetching: false,
                [name]: action.payload.data.groups,
                amounts: {
                    ...state.amounts,
                    matchesFiltered: action.payload.data.visibleMatchCount,
                },
            };

        case types.CHECKBOX_FILTER_TOGGLE:
            return onCheckboxFilterToggle(state, action.payload);

        case types.SWITCH_TO_VIEW:
            if (action.payload.error) {
                return setXhrError(state, action.payload.error);
            }

            return setXhrError(state, null);

        case types.CLEAR_XHR_ERROR:
            return setXhrError(state, null);

        case types.FREETEXT_SEARCH_PHRASE_CHANGE:
            return onFreeTextSearchPhraseChange(state, action.payload);

        case types.PRODUCTS_CHANGE:
            return onProductFilterChange(state, action.payload);

        case types.NCAA_FILTER_CHANGE:
            return onNcaaFilterChange(state, action.payload);

        case types.RECOMMENDER_SETTINGS_CHANGE:
            return onRecommenderSettingsChange(state, action.payload);

        case types.SET_DEFAULT_FILTER_ID:
            return { ...state, defaultFilterId: action.payload.selectionId };

        case types.TOGGLE_HELP_BALLOON:
            return {
                ...state,
                helpBalloon: {
                    visible: action.payload.visible || false,
                    position: action.payload.position || null,
                    type: action.payload.type || null,
                    area: action.payload.area || null,
                },
            };

        case types.SET_FILTER_SELECTION:
            // reset filters
            newState = {
                ...state,
                selectedCheckboxes: [],
                checkboxes: FilterUtils.getNewCheckboxList(
                    state.sports,
                    state.auth.userInfo.bookmakerInfo.isReseller,
                    (state.auth.userInfo.bookmakerInfo.segment.segmentId ||
                        1) === 1
                ),
                freeTextSearchPhrase: [],
                selectedproducts: [],
                ncaaFilter: {},
            };

            // free text search?
            if (action.payload.selection.searchPhrase !== '') {
                newState = onFreeTextSearchPhraseChange(newState, {
                    phrase: action.payload.selection.searchPhrase,
                    emit: false,
                });
            }

            // select checkboxes
            _.each(action.payload.selection.selectedCheckboxes, item => {
                newState = onCheckboxFilterToggle(newState, item);
            });

            if (action.payload.selection.selectedProducts) {
                newState = onProductFilterChange(newState, {
                    products: action.payload.selection.selectedProducts,
                });
            }

            if (
                action.payload.selection.ncaaFilter &&
                FilterUtils.anyNcaaFilterSet(
                    action.payload.selection.ncaaFilter
                )
            ) {
                newState = onNcaaFilterChange(
                    newState,
                    action.payload.selection.ncaaFilter
                );
            }

            return newState;

        case types.SET_LEFT_COLUMN_STATUS:
            if (action.payload.writeToLocalStorage) {
                storageLocal.setItem(
                    constants.LOCAL_STORAGE.LEFT_COLUMN,
                    action.payload.status
                );
            }
            return {
                ...state,
                size: {
                    ...state.size,
                    leftColumn: action.payload.status,
                },
            };

        case types.SET_RIGHT_COLUMN_STATUS:
            if (action.payload.writeToLocalStorage) {
                storageLocal.setItem(
                    constants.LOCAL_STORAGE.RIGHT_COLUMN,
                    action.payload.status
                );
            }
            return {
                ...state,
                size: {
                    ...state.size,
                    rightColumn: action.payload.status,
                },
            };

        case types.TOGGLE_HIDDEN_PACKAGES:
            return {
                ...state,
                showHiddenPackages: !state.showHiddenPackages,
            };

        case types.SET_SHOW_BOOKED_COUNTS_TYPE:
            storageLocal.setItem(
                constants.LOCAL_STORAGE.SHOW_BOOKED_COUNT_TYPE,
                action.payload.uid
            );
            return {
                ...state,
                showBookedCountsType: action.payload.uid,
            };

        case types.SET_SHOW_AS_BOOKED_PRODUCT:
            storageLocal.setItem(
                constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT,
                action.payload.uid
            );
            return {
                ...state,
                showAsBooked: action.payload.uid,
            };
        case types.SET_SHOW_AS_BOOKED_PRODUCT_PRIMARY:
            storageLocal.setItem(
                constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT_PRIMARY,
                action.payload.uid
            );
            return {
                ...state,
                showAsBookedPrimary: action.payload.uid,
            };

        case types.SET_SHOW_AS_BOOKED_PRODUCT_SECONDARY:
            storageLocal.setItem(
                constants.LOCAL_STORAGE.SHOW_AS_BOOKED_PRODUCT_SECONDARY,
                action.payload.uid
            );
            return {
                ...state,
                showAsBookedSecondary: action.payload.uid,
            };

        case types.SET_SELECTED_LO_SPORT_CONFIG_SETTING:
            storageLocal.setItem(
                constants.LOCAL_STORAGE.SELECTED_LO_SPORT_CONFIG_SETTING,
                action.payload.uid
            );

            newState = {
                ...state,
                selectedLOSportConfigSetting: action.payload.uid,
            };

            if (
                action.payload.uid ===
                constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_HIDDEN
            ) {
                const selectedSports = state.selectedCheckboxes
                    .filter(
                        checkbox =>
                            checkbox.groupId === constants.FILTER_GROUP_SPORTS
                    )
                    .map(checkbox => checkbox.value);

                Object.values(state.sports).forEach(sport => {
                    if (
                        sport.disabledInLOConfig &&
                        selectedSports.indexOf(sport.id) !== -1
                    ) {
                        newState = onCheckboxFilterToggle(newState, {
                            groupId: constants.FILTER_GROUP_SPORTS,
                            value: sport.id,
                        });
                    }
                });
            }

            return newState;

        case types.SET_CONTEXT_MENU_OPEN:
            return {
                ...state,
                contextMenuOpen: action.payload.isOpen,
            };

        case types.HASH_MISMATCH:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    userCredentialsDidChange: true,
                },
            };

        case types.DELETE_FILTER_SET:
            return {
                ...state,
                deletedFilterSets: state.deletedFilterSets.concat([action.id]),
            };
        case types.SORT_RECOMMENDATIONS:
            return {
                ...state,
                events: sortEventsBasedOnRanking(action.payload, state),
            };

        default:
            return state || initialState;
    }
};
