import { createSelector } from 'reselect';
import { getFilterName } from '../../utils/autobookingUtils';

export default createSelector(
    (state, ownProps) =>
        ownProps.attributeSelection
            ? ownProps.attributeSelection.selectedFilters
            : state.autobooking.selectedFilters,
    (state, ownProps) =>
        ownProps.attributeSelection
            ? ownProps.attributeSelection.filterOrder
            : state.autobooking.filterOrder,
    (state, ownProps) =>
        ownProps.attributeSelection
            ? ownProps.attributeSelection.disabledFilters
            : state.autobooking.disabledFilters,
    (selectedFilters, filterOrder, disabledFilters) => {
        const parts = [];
        for (const filter of filterOrder) {
            if (selectedFilters[filter]) {
                let part = getFilterName(filter);
                if (disabledFilters[filter]) {
                    part += ' (inactive)';
                }
                parts.push(part);
            }
        }
        return parts;
    }
);
