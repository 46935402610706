export default shoppingBasketState => {
    return Object.values(shoppingBasketState.items)
        .sort((a, b) => {
            // date compare
            if (a.addedAtTimestamp < b.addedAtTimestamp) {
                return -1;
            }
            if (b.addedAtTimestamp < a.addedAtTimestamp) {
                return 1;
            }

            // date is equal ( = matches were added to basket in a batch)
            // simply compare id, to maintain a certain order for them
            if (a.id > b.id) {
                return -1;
            }
            return 1;
        })
        .map(item => item.event);
};
