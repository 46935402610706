import views from '../../constants/views';
import types from './constants';

const initialState = {
    currentView: views.VIEWPORT_TYPE_LIST_CONDENSED,
    viewHistory: [],
    selectedVersion: undefined,
    scrollPosition: 0,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_CURRENT_VIEW:
            const history = [...state.viewHistory];
            if (history.length > 50) {
                history.shift();
            }
            history.push(state.currentView);

            return {
                ...state,
                currentView: action.payload.view,
                viewHistory: history,
            };
        case types.SET_SELECTED_VERSION:
            return {
                ...state,
                selectedVersion: action.payload.selectedVersion,
            };
        case types.SAVE_SCROLL_POSITION:
            return {
                ...state,
                scrollPosition: action.payload.scrollPosition,
            };
        default:
            return state || initialState;
    }
};
