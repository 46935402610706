export const simpleStatus = {
    NOT_STARTED: 1,
    LIVE: 2,
    STOPPED: 3,
    ENDED: 4,
};

export const matchStatus = {
    UNSET: -1,
    NOT_STARTED: 0,
    POSTPONED: 60,
    START_DELAYED: 61,
    CANCELLED: 70,
    INTERRUPTED: 80,
    SUSPENDED: 81,
    ABANDONED: 90,
    WALKOVER: 91,
    RETIRED: 92,
    WALKOVER_1: 93,
    WALKOVER_2: 94,
    RETIRED_1: 95,
    RETIRED_2: 96,
    DEFAULTED_1: 97,
    DEFAULTED_2: 98,
    ENDED: 100,
    AET: 110,
    AFTER_OT: 111,
    AFTER_PEN: 120,
    SESSION_BREAK: 445,
    STUMPS: 513,
    AFTER_SD: 444,
    AFTER_SUPER_OVER: 508,
    AFTER_GOLDEN_SET: 130,
};

export const notStarted = [
    matchStatus.UNSET,
    matchStatus.NOT_STARTED,
    matchStatus.POSTPONED,
    matchStatus.START_DELAYED,
];

export const stopped = [
    matchStatus.INTERRUPTED,
    matchStatus.SUSPENDED,
    matchStatus.SESSION_BREAK,
    matchStatus.STUMPS,
];

export const ended = [
    matchStatus.CANCELLED,
    matchStatus.ABANDONED,
    matchStatus.WALKOVER,
    matchStatus.RETIRED,
    matchStatus.WALKOVER_1,
    matchStatus.WALKOVER_2,
    matchStatus.RETIRED_1,
    matchStatus.RETIRED_2,
    matchStatus.DEFAULTED_1,
    matchStatus.DEFAULTED_2,
    matchStatus.ENDED,
    matchStatus.AET,
    matchStatus.AFTER_OT,
    matchStatus.AFTER_PEN,
    matchStatus.AFTER_SD,
    matchStatus.AFTER_SUPER_OVER,
    matchStatus.AFTER_GOLDEN_SET,
];

export const statusDescription = {
    [simpleStatus.NOT_STARTED]: 'Not started',
    [simpleStatus.LIVE]: 'Live',
    [simpleStatus.STOPPED]: 'Paused',
    [simpleStatus.ENDED]: 'Finished',
};

export const simplifyStatus = status => {
    if (notStarted.includes(status)) {
        return simpleStatus.NOT_STARTED;
    }
    if (stopped.includes(status)) {
        return simpleStatus.STOPPED;
    }
    if (ended.includes(status)) {
        return simpleStatus.ENDED;
    }
    return simpleStatus.LIVE;
};
