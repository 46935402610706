import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from '../../../../../../../../constants/Constants';
import { isVisible } from '../../../../../../../../utils/FilterUtils';
import './count.scss';
import Badge from '../../../../../../../Badge/Badge';
import FilterConstants from '../../../../../../../../constants/FilterConstants';

function Count(props) {
    const counts = useMemo(() => {
        return getBookedCounts(
            props.events,
            props.checkbox,
            props.selectedProducts
        );
    }, [props.events, props.checkbox, props.selectedProducts]);

    const countElements = [];
    if (
        counts.matches &&
        counts.bookedMatches &&
        props.showBookedCounts &&
        props.type !== constants.SHOW_BOOKED_COUNT_TYPES.HIDDEN
    ) {
        countElements.push(buildBookedCount(props.type, counts.bookedMatches));
        if (props.type === constants.SHOW_BOOKED_COUNT_TYPES.TEXTUAL) {
            const separator = '/';
            countElements.push(separator);
        }
    }
    countElements.push(
        <span title="Number of matches for filter" key="totalCount">
            {props.checkbox.value ===
            FilterConstants.availabilities.RECOMMENDED_MATCHES
                ? props.recommendedSettings.count
                : counts.matches}
        </span>
    );

    return <div className="count-elements textline-100">{countElements}</div>;
}

Count.propTypes = {
    checkbox: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    showBookedCounts: PropTypes.bool.isRequired,
    events: PropTypes.array.isRequired,
    selectedProducts: PropTypes.array.isRequired,
    recommendedSettings: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    events: state.calendar.events,
    type: state.calendar.showBookedCountsType,
    recommendedSettings: state.calendar.recommenderSettings,
});

export default connect(mapStateToProps)(Count);

function getBookedCounts(events, checkbox, selectedProducts) {
    return events.reduce(
        (counts, event) => {
            if (isVisible(checkbox, event, selectedProducts)) {
                counts['matches'] += 1;
                if (event.products.lo && event.products.lo.isBooked) {
                    counts['bookedMatches'] += 1;
                }
            }

            return counts;
        },
        { matches: 0, bookedMatches: 0 }
    );
}

function buildBookedCount(type, bookedMatches) {
    const title = 'Number of booked matches for filter';
    if (type === 'booked-count-badge') {
        return (
            <Badge
                color="green"
                content={bookedMatches}
                key="bookedCount"
                title={title}
            />
        );
    }
    return (
        <span className={type} title={title} key="bookedCount">
            {bookedMatches}
        </span>
    );
}
