import moment from './CalendarMoment';
import xhr from './Xhr';

const getEventIds = events => {
    const eventIds = [];
    events.forEach(event => {
        eventIds.push(event.uri);
    });
    return eventIds;
};

const sortEvents = events => {
    return events.sort((a, b) => {
        const aDate = moment.fromTimestamp(a.startDate),
            bDate = moment.fromTimestamp(b.startDate);
        if (aDate < bDate) {
            return -1;
        }
        if (aDate > bDate) {
            return 1;
        }
        return 0;
    });
};

export const exportCsv = (headers, rows, separator = ',') => {
    return new Promise((resolve, reject) => {
        resolve(
            [headers, ...rows]
                .map(cells => {
                    return cells
                        .map(cell => {
                            return `"${cell}"`;
                        })
                        .join(separator);
                })
                .join('\n')
        );
    });
};

export const exportXml = (headers, rows) => {
    return new Promise((resolve, reject) => {
        const doc = document.implementation.createDocument(null, 'list', null),
            root = doc.createElement('list');

        rows.forEach(row => {
            const rowElement = doc.createElement('row');
            row.forEach((cell, index) => {
                const attributeName = headers[index]
                    .toLowerCase()
                    .split(' ')
                    .join('_');

                rowElement.setAttribute(attributeName, cell);
            });
            root.appendChild(rowElement);
        });

        resolve(root.outerHTML);
    });
};

export const exportPdf = (
    headers,
    headersWidth,
    body,
    portrait = true,
    headersText = [],
    numberedRows = true
) => {
    const tableHeaders = headers.map((header, index) => {
        return {
            width: headersWidth[index],
            name: header,
        };
    });

    return new Promise((resolve, reject) => {
        xhr.fileRequest('/export/generic-pdf', {
            headers: tableHeaders,
            body,
            portrait,
            headersText,
            numberedRows,
        }).then(
            success => {
                resolve(success);
            },
            failure => {
                reject(failure);
            }
        );
    });
};

export const downloadFile = (filename, fileData, type) => {
    let tempLink;

    const exportData = new Blob(['\ufeff', fileData], { type });
    const url = window.URL.createObjectURL(exportData);

    if (detectIE()) {
        window.navigator.msSaveBlob(exportData, filename);
    } else {
        tempLink = document.createElement('a');
        tempLink.style = 'display: none';
        tempLink.href = url;
        tempLink.download = filename;

        document.body.appendChild(tempLink);

        tempLink.click();

        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        }, 100);
    }
};

const detectIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        return true;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        return true;
    }

    const edge = ua.indexOf('Edge/');
    return edge > 0;
};

const ExportUtils = {
    sortEvents,
    getEventIds,
};

export default ExportUtils;
