import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import './dateStepRange.scss';
import moment from '../../../../../utils/CalendarMoment';

class DateStepRange extends Component {
    _updateDates(from, to, max) {
        let error = null;
        const timeDiff = to.getTime() - from.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        if (daysDiff > max) {
            error = `You cannot export more than ${max} days`;
        } else if (to < from) {
            error = 'Invalid date entered';
        }
        this.props.onDateRangeChange(
            moment.fromTimestamp(from.getTime() / 1000),
            moment.fromTimestamp(to.getTime() / 1000)
        );
        this.props.onError(error);
    }

    render() {
        const props = this.props;
        const toTp = props.to.toFormat('X');
        const fromTp = props.from.toFormat('X');
        const toDate = new Date(toTp * 1000);
        const fromDate = new Date(fromTp * 1000);

        return (
            <div className="date-step-range">
                <div className="group">
                    <div className="label">From</div>
                    <DatePicker
                        title="From date"
                        placeholderText="Select from date"
                        selected={fromDate}
                        onChange={date =>
                            this._updateDates(date, toDate, props.limit)
                        }
                        className="date-picker-input"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
                <div className="group">
                    <div className="label">To</div>
                    <DatePicker
                        title="To date"
                        placeholderText="Select to date"
                        selected={toDate}
                        onChange={date =>
                            this._updateDates(fromDate, date, props.limit)
                        }
                        className="date-picker-input"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
            </div>
        );
    }
}

DateStepRange.propTypes = {
    onDateRangeChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
};

export default DateStepRange;
