import { adapterConstants } from '../../constants/shoppingBasket';
import availableAdapterIds from './availableAdapterIds';

export default calendarStoreState => {
    return availableAdapterIds(calendarStoreState).map(id => {
        return {
            id,
            products: adapterConstants[id].products,
            name: adapterConstants[id].name,
        };
    });
};
