import actionConstants from './constants';

export const setTab = (viewId, id) => ({
    type: actionConstants.SET_TAB,
    payload: {
        viewId,
        id,
    },
});

export const initialize = defaults => ({
    type: actionConstants.INITIALIZE,
    payload: {
        defaults,
    },
});
