import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import Alert from '../../../Alert/Alert';
import defaultSettings from '../../../../utils/DefaultSettings';
import FilterUtils from '../../../../utils/FilterUtils';
import FilterSelectionBox from '../../../FilterSelectionBox/FilterSelectionBox';
import Button from '../../../formInput/Button/Button';
import TextInput from '../../../formInput/TextInput/TextInput';
import DropDown from '../../../formInput/Dropdown/DropDown';
import { addDialog } from '../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../stores/dialog/constants';
import { saveFilterSet } from '../../../../stores/calendar/actions';
import './saveFilterSelectionDialog.scss';

class SaveFilterSelectionDialog extends Component {
    constructor(props) {
        super(props);
        this.defaultDropdownOption = '- select -';
        this.state = {
            userInputString: '',
            selectedDropdownOption: this.defaultDropdownOption,
        };
    }

    _filterNameChange(value) {
        this.setState({
            userInputString: value,
            selectedDropdownOption: this.defaultDropdownOption,
        });
    }

    _onSelectChange(value) {
        this.setState({
            userInputString: '',
            selectedDropdownOption: value,
        });
    }

    _submitForm() {
        const {
                selectedCheckboxes,
                searchString,
                remoteData,
                selectedProducts,
                ncaaFilter,
            } = this.props,
            { userInputString } = this.state;
        let uri = defaultSettings.saveFilterUri;
        const currentFilterName = this._getCurrentFilterName(),
            existingFilterSetWithSameName = _.find(
                remoteData.response || remoteData,
                filterSet => filterSet.name === currentFilterName
            ),
            postData = {
                filterSettings: FilterUtils.buildFilterSaveJson(
                    selectedCheckboxes,
                    searchString,
                    selectedProducts,
                    ncaaFilter
                ),
                name: this._getCurrentFilterName(),
            };

        const validationStatus = this._validateName(currentFilterName);
        if (!validationStatus.isValid) {
            alert(validationStatus.message);
            return;
        }

        if (userInputString.length) {
            if (_.isObject(existingFilterSetWithSameName)) {
                this.props.addDialog(dialogTypes.OVERWRITE_SAVED_FILTER_SET, {
                    existingFilterSetWithSameNameId:
                        existingFilterSetWithSameName.id,
                    postData,
                });
            } else {
                this._saveFilterSet(uri, postData);
            }
        } else if (_.isObject(existingFilterSetWithSameName)) {
            uri = defaultSettings.updateFilterUri.replace(
                '{id}',
                existingFilterSetWithSameName.id
            );
            this._saveFilterSet(uri, postData);
        }
    }

    _getCurrentFilterName() {
        const { userInputString, selectedDropdownOption } = this.state;
        return !selectedDropdownOption ||
            selectedDropdownOption === this.defaultDropdownOption
            ? userInputString
            : selectedDropdownOption;
    }

    _saveFilterSet(url, postData) {
        this.props.saveFilterSet(url, postData);
        this.props.onClose();
    }

    _onKeyDown(e) {
        if (e.keyCode === 13) {
            // ENTER
            this._submitForm();
        }
    }

    _close() {
        this.props.onClose();
    }

    _hasValidRemoteData() {
        const { remoteData } = this.props;
        return (
            (remoteData &&
                remoteData.response &&
                remoteData.response.length > 0) ||
            (remoteData && remoteData.length > 0)
        );
    }

    _buildSelect() {
        if (!this._hasValidRemoteData()) {
            return '';
        }

        const options = _.map(
            this.props.remoteData.response || this.props.remoteData,
            (filterSet, i) => ({
                value: filterSet.name,
                text: filterSet.name,
            })
        );

        options.unshift({
            value: null,
            text: this.defaultDropdownOption,
        });

        return (
            <div className="flex">
                <DropDown
                    onChange={this._onSelectChange.bind(this)}
                    value={this.state.selectedDropdownOption}
                    options={options}
                    label={'Update existing set:'}
                />
                <span>or</span>
            </div>
        );
    }

    _validateName(name) {
        const response = {
            isValid: false,
            message: '',
        };
        if (!name.length) {
            response.message = 'Filter name cannot be empty';
            return response;
        }
        if (name.length > 50) {
            response.message = 'Filter name cannot be more than 50 characters';
            return response;
        }
        if (name === defaultSettings.defaultFilterSetName) {
            response.message = `Filter name cannot be ${name}`;
            return response;
        }
        response.isValid = true;
        return response;
    }

    render() {
        const showError = this.state.userInputString.length,
            validationStatus = this._validateName(this._getCurrentFilterName()),
            filterSelection = {
                selectedCheckboxes: this.props.selectedCheckboxes,
                searchPhrase: this.props.searchString,
                selectedProducts: this.props.selectedProducts,
                ncaaFilter: this.props.ncaaFilter,
            };

        return (
            <div className="dialog-content-wrapper save-filter-selection">
                <div className="sub-header">
                    <Alert type={Alert.types.EXPLANATORY} icon="" float={false}>
                        <p>
                            By saving and loading your filters you can easily
                            switch between different selections.
                        </p>
                        <p>
                            After saving below, open the{' '}
                            <strong>Load Filters</strong> dialog to manage and
                            load your saved sets.
                        </p>
                    </Alert>
                </div>
                <div className="content">
                    <FilterSelectionBox
                        title={this._getCurrentFilterName()}
                        filterSelection={filterSelection}
                    />
                    <div className="inputs">
                        {this._buildSelect()}
                        <div className="flex">
                            <label htmlFor="filterName" className="form-label">
                                Create new set:
                            </label>
                            <TextInput
                                onChange={this._filterNameChange.bind(this)}
                                value={this.state.userInputString}
                                disabled={
                                    this.state.selectedDropdownOption !==
                                    this.defaultDropdownOption
                                }
                                invalid={Boolean(
                                    showError && !validationStatus.isValid
                                )}
                                onKeyDown={this._onKeyDown.bind(this)}
                                autoFocus={true}
                            />
                            {showError ? (
                                <div className="error-feedback">
                                    {validationStatus.message}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <Button
                        onClick={this._close.bind(this)}
                        type={Button.types.LARGE_GREY_SHADOW}
                        icon={''}
                        fixedWidth={false}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this._submitForm.bind(this)}
                        type={Button.types.LARGE_BLUE_SHADOW}
                        icon={''}
                        disabled={!validationStatus.isValid}
                        fixedWidth={false}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

SaveFilterSelectionDialog.propTypes = {
    selectedCheckboxes: PropTypes.array.isRequired,
    searchString: PropTypes.array.isRequired,
    selectedProducts: PropTypes.array.isRequired,
    ncaaFilter: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    remoteData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
        .isRequired,
    saveFilterSet: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    searchString: state.calendar.freeTextSearchPhrase,
    selectedCheckboxes: state.calendar.selectedCheckboxes,
    selectedProducts: state.calendar.selectedProducts,
    ncaaFilter: state.calendar.ncaaFilter,
});

const mapDispatchToProps = {
    saveFilterSet,
    addDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveFilterSelectionDialog);
