import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './betpalCheckoutDialog.scss';

const BetpalCheckoutDialog = props => {
    const baseUrl = `${props.betpalUri}events/import/guth-matches-noframe/reset/true/guthmatchids`;
    const ids = props.matchIds.join(',');
    const src = `${baseUrl}/${ids}/uniquename/importNewMatchesTable`;

    return (
        <div className="dialog-content-wrapper betpal-checkout">
            <iframe
                title="betpal"
                src={src}
                scrolling="no"
                seamless="seamless"
                id="iframe-betpal"
            />
        </div>
    );
};

BetpalCheckoutDialog.propTypes = {
    betpalUri: PropTypes.string.isRequired,
    matchIds: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    betpalUri: state.calendar.backendSettings.betpal.uri,
});

export default connect(mapStateToProps, {})(BetpalCheckoutDialog);
