import moment from './CalendarMoment';

const Cache = function (maxItems = 14) {
    this.items = [];
    this.maxItems = maxItems;
};

Cache.prototype.addItem = function (key, value, expireSeconds = 60) {
    const ms = expireSeconds * 1000;

    this.items[key] = {
        value: value,
        expireTime: new moment.now().plus({ seconds: expireSeconds }),
    };

    setTimeout(() => {
        this.evaluateItems();
    }, ms + 10);
};

Cache.prototype.getItem = function (key) {
    if (this.items.hasOwnProperty(key)) {
        return this.items[key].value;
    }
    return false;
};

Cache.prototype.getExpireTime = function (key) {
    if (this.items.hasOwnProperty(key)) {
        return this.items[key].expireTime;
    }
    return 0;
};

Cache.prototype.hasItem = function (key) {
    return this.items.hasOwnProperty(key);
};

Cache.prototype.countItems = function () {
    let count = 0;
    for (const key in this.items) {
        if (this.items.hasOwnProperty(key)) {
            count++;
        }
    }
    return count;
};

Cache.prototype.evaluateItems = function (forceDelete) {
    forceDelete = forceDelete || this.countItems() > this.maxItems;

    for (const key in this.items) {
        if (!this.items.hasOwnProperty(key)) {
            continue;
        }
        const item = this.items[key];
        if (forceDelete || item.expireTime <= new moment.now()) {
            this.deleteItem(key);
        }
    }
};

Cache.prototype.deleteItem = function (key) {
    delete this.items[key];
};

Cache.prototype.deleteAll = function () {
    this.items = [];
};

export default Cache;
