import views from '../constants/views';
import _ from 'underscore';
import moment from './CalendarMoment';

const selectors = {
    LIST: '.viewport-list',
    TIME_HEADER: '.time-row',
    TIME: '.time',
};

class Scroller {
    constructor() {
        this.listScrollHour = 0;
    }

    setCurrentScrollHour(hour) {
        this.listScrollHour = hour;
    }

    resetScrollHour(mm) {
        if (mm.hasSame(moment.now(), 'day')) {
            this.setCurrentScrollHour(moment.now().toFormat('H'));
        } else {
            this.setCurrentScrollHour(0);
        }
    }

    updateScrollPos(selectedView, hour) {
        // Let DOM render component before getting scroll pos
        window.requestAnimationFrame(() => {
            if (selectedView === views.VIEWPORT_TYPE_LIST_CONDENSED) {
                if (hour) {
                    this.listScrollHour = hour;
                } else {
                    this.listScrollHour = this._listGetScrolledToHour();
                }
            } else {
                console.error('Invalid view: ' + selectedView);
            }
        });
    }

    scrollToSavedPos(selectedView) {
        // Let DOM render component before scrolling
        window.requestAnimationFrame(() => {
            if (selectedView === views.VIEWPORT_TYPE_LIST_CONDENSED) {
                this._listScrollToSavedHour();
            }
        });
    }

    _listGetScrolledToHour() {
        const list = document.querySelector(selectors.LIST);
        if (list) {
            const timeHeaders = list.querySelectorAll(selectors.TIME_HEADER);
            let previousHour = 0,
                tmpPreviousHour,
                timeHeader;

            const offset = list.scrollTop;

            for (timeHeader of timeHeaders) {
                if (timeHeader.offsetTop > offset) {
                    return previousHour;
                }

                tmpPreviousHour = parseInt(
                    timeHeader.querySelector(selectors.TIME).innerHTML,
                    10
                );
                previousHour = _.isNaN(tmpPreviousHour)
                    ? previousHour
                    : tmpPreviousHour;
            }
            return 0;
        }
        return 0;
    }

    _listScrollToSavedHour() {
        let timeHeaderPos = 0,
            previousTimeHeaderPos = 0;
        const list = document.querySelector(selectors.LIST);
        if (!list) {
            return;
        }
        const timeHeaders = list.querySelectorAll(selectors.TIME_HEADER);
        let timeHeader, hour;

        for (timeHeader of timeHeaders) {
            hour = parseInt(
                timeHeader.querySelector(selectors.TIME).innerHTML,
                10
            );
            timeHeaderPos = timeHeader.offsetTop;

            if (hour === this.listScrollHour) {
                break;
            } else if (hour !== 23 && hour > this.listScrollHour) {
                // Scroll to previous hour if current hour does not exist
                timeHeaderPos = previousTimeHeaderPos;
                break;
            }
            previousTimeHeaderPos = timeHeaderPos;
        }

        list.scrollTop = timeHeaderPos;
    }
}
const scroller = new Scroller();
export default scroller;
