import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = props => {
    /* Annoying to create all these CustomComponents just to add classes/targeting-selectors.
       Hope there is an easier way that I haven't found */

    class CustomOption extends React.Component {
        render() {
            let { children, ...props } = this.props;
            return (
                <div
                    className={'wrap-option'}
                    data-cy={String(props.data.value).toLowerCase()}
                >
                    <components.Option {...props}>{children}</components.Option>
                </div>
            );
        }
    }
    CustomOption.propTypes = { children: PropTypes.any };

    class CustomClearIndicator extends React.Component {
        render() {
            let { children, ...props } = this.props;
            return (
                <div className={'wrap-clear'} data-cy={'clear'}>
                    <components.ClearIndicator {...props}>
                        {children}
                    </components.ClearIndicator>
                </div>
            );
        }
    }
    CustomClearIndicator.propTypes = { children: PropTypes.any };

    class CustomOpenCloseIndicator extends React.Component {
        render() {
            let { children, ...props } = this.props;
            return (
                <div className={'wrap-toggle'} data-cy={'toggle'}>
                    <components.DropdownIndicator {...props}>
                        {children}
                    </components.DropdownIndicator>
                </div>
            );
        }
    }
    CustomOpenCloseIndicator.propTypes = { children: PropTypes.any };

    const targetHeight = 28;
    const styles = {
        control: base => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: base => ({
            ...base,
            minHeight: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: base => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
            cursor: 'pointer',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
            cursor: 'pointer',
        }),
        option: base => ({
            ...base,
            cursor: 'pointer',
        }),
    };

    const newProps = {
        ...props,
        components: {
            ...props.components,
            Option: CustomOption,
            ClearIndicator: CustomClearIndicator,
            DropdownIndicator: CustomOpenCloseIndicator,
        },
    };

    return (
        <div className="lbc-select">
            <Select {...newProps} styles={styles} />
        </div>
    );
};

CustomSelect.propTypes = {
    ...Select.propTypes,
    innerProps: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default CustomSelect;
