import PropTypes from 'prop-types';
import React from 'react';
import './eventInfoDialog.scss';
import MatchQuickInfo from '../MatchQuickInfo/MatchQuickInfo';
import StageQuickInfo from '../StageQuickInfo/StageQuickInfo';

const EventInfoDialog = props => {
    const quickInfo = props.event.uri.includes('match') ? (
        <MatchQuickInfo {...props} />
    ) : (
        <StageQuickInfo {...props} />
    );
    return (
        <div className="dialog-content-wrapper event-info-dialog">
            <div className="content">{quickInfo}</div>
        </div>
    );
};

EventInfoDialog.propTypes = {
    event: PropTypes.object.isRequired,
};

export default EventInfoDialog;
