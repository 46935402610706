import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import Constants from '../../../../../../constants/Constants';
import selectedCheckboxes from '../../../../../../selectors/filter/selectedCheckboxes';
import FilterUtils, {
    getIdentifier,
} from '../../../../../../utils/FilterUtils';
import StorageLocal from '../../../../../../utils/StorageLocal';
import Badge from '../../../../../Badge/Badge';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import Checkbox from './Checkbox/Checkbox';
import './filterCheckboxList.scss';

const storageLocal = new StorageLocal();

function FilterCheckboxList(props) {
    const {
        collapsedByDefault = true,
        title = 'Undefined',
        showBookedCounts = true,
        groupId = null,
        showSportIcons = false,
        showNCheckboxesWhileCollapsed = 0,
    } = props;
    const filterCheckboxes = () => {
        let filteredCheckboxes = props.checkboxes;
        if (!props.is4sightEnabled) {
            filteredCheckboxes = filteredCheckboxes.filter(
                cb => cb.subGroupId !== 'Live Channel Variant'
            );
        }
        if (!props.isPcsCoverageEnabled) {
            filteredCheckboxes = filteredCheckboxes.filter(
                cb => cb.subGroupId !== Constants.FILTER_SUBGROUP_LIVEODDS_FEED
            );
        }
        if (!props.isLcpEnabled) {
            filteredCheckboxes = filteredCheckboxes.filter(
                cb => cb.name !== 'Promotion'
            );
            filteredCheckboxes = filteredCheckboxes.filter(
                cb =>
                    cb.subGroupId !==
                    Constants.FILTER_SUBGROUP_LIVECHANNEL_PROMOTION
            );
        }

        if (!props.isHighQualityEnabled) {
            filteredCheckboxes = filteredCheckboxes.filter(
                cb =>
                    cb.subGroupId !==
                    Constants.FILTER_SUBGROUP_LIVECHANNEL_QUALITY
            );
        }

        if (!props.recommenderSettings.showRecommended) {
            filteredCheckboxes = filteredCheckboxes.filter(
                cb => cb.name !== 'Recommended Matches'
            );
        }

        return filteredCheckboxes;
    };

    const checkboxesInGroup = FilterUtils.getInGroup(
        filterCheckboxes(),
        groupId
    );

    const isCollapsible =
        showNCheckboxesWhileCollapsed !== -1 &&
        checkboxesInGroup.length > showNCheckboxesWhileCollapsed;

    const localStorageKey = `${groupId}_collapsed`;
    const [isCollapsed, setIsCollapsed] = useState(
        isCollapsible &&
            ((!storageLocal.hasItem(localStorageKey) && collapsedByDefault) ||
                storageLocal.getItem(localStorageKey) === 'true')
    );
    useEffect(() => {
        if (isCollapsible) {
            storageLocal.setItem(localStorageKey, isCollapsed);
        }
        // eslint-disable-next-line
    }, [isCollapsed]);

    let checkboxesWhenCollapsed = checkboxesInGroup;
    let headerTitle = '';
    if (isCollapsible) {
        const action = isCollapsed ? 'expand' : 'collapse';
        headerTitle = `Click to ${action}`;
        if (isCollapsed) {
            checkboxesWhenCollapsed = checkboxesInGroup.slice(
                0,
                showNCheckboxesWhileCollapsed
            );
        }
    }

    return (
        <div className="filter-checkbox-list">
            <div
                className={classnames('header', {
                    collapsible: isCollapsible,
                })}
                onClick={() => isCollapsible && setIsCollapsed(!isCollapsed)}
                title={headerTitle}
            >
                <div className="title textline-400 text-semibold">{title}</div>
                <div className="indicators">
                    {isCollapsed &&
                        buildSelectedCount(
                            checkboxesInGroup,
                            props.selectedCheckboxes
                        )}
                    {isCollapsible && buildChevron(isCollapsed)}
                </div>
            </div>
            <div className="list">
                {buildCheckboxList(
                    checkboxesWhenCollapsed,
                    showSportIcons,
                    showBookedCounts
                )}
            </div>
        </div>
    );
}

FilterCheckboxList.propTypes = {
    checkboxes: PropTypes.array.isRequired,
    selectedCheckboxes: PropTypes.object.isRequired,
    collapsedByDefault: PropTypes.bool,
    showSportIcons: PropTypes.bool,
    showBookedCounts: PropTypes.bool,
    groupId: PropTypes.string,
    title: PropTypes.string,
    showNCheckboxesWhileCollapsed: PropTypes.number,
    is4sightEnabled: PropTypes.bool.isRequired,
    isHighQualityEnabled: PropTypes.bool.isRequired,
    recommenderSettings: PropTypes.object,
    isPcsCoverageEnabled: PropTypes.bool.isRequired,
    isLcpEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    checkboxes: state.calendar.checkboxes,
    selectedCheckboxes: selectedCheckboxes(
        state,
        state.calendar.selectedCheckboxes
    ),
    is4sightEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_4Sight,
    isHighQualityEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_av_high_quality,
    recommenderSettings: state.calendar.recommenderSettings,
    isPcsCoverageEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_pcs_coverage,
    isLcpEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_lcp,
});

export default connect(mapStateToProps)(FilterCheckboxList);

function buildCheckboxList(checkboxes, showSportIcons, showBookedCounts) {
    const list = [];
    const checkboxesBySubGroup = _.groupBy(checkboxes, 'subGroupId');
    for (const subGroupId in checkboxesBySubGroup) {
        if (!checkboxesBySubGroup.hasOwnProperty(subGroupId)) {
            continue;
        }
        const subGroupHeader = FilterUtils.getSubGroupHeaderText(subGroupId);
        if (subGroupHeader) {
            list.push(
                <div className="header" key={`header_${subGroupId}`}>
                    <div className="title textline-300">{subGroupHeader}</div>
                </div>
            );
        }

        checkboxesBySubGroup[subGroupId].forEach(checkbox => {
            list.push(
                <Checkbox
                    key={getIdentifier(checkbox)}
                    checkbox={checkbox}
                    showSportIcons={showSportIcons}
                    showBookedCounts={showBookedCounts}
                />
            );
        });

        list.push(
            <br key={`clearElement_${subGroupId}`} style={{ clear: 'both' }} />
        );
    }

    return list;
}

function buildSelectedCount(checkboxes, selectedCheckboxesList) {
    const count = checkboxes.filter(checkbox =>
        selectedCheckboxesList.hasOwnProperty(getIdentifier(checkbox))
    ).length;

    return (
        count > 0 && (
            <Badge
                color="gray-light"
                className="textline-100 text-semibold"
                content={count}
            />
        )
    );
}

function buildChevron(collapsed) {
    return collapsed ? <FontIcon icon="" /> : <FontIcon icon="" />;
}
