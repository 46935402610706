import { AV_PRODUCT } from '../constants/audioVisual';

export const isSubProductOptionalContent = subProduct => {
    if (
        !subProduct ||
        subProduct.clientPackageType === undefined ||
        subProduct.devices === undefined ||
        subProduct.devices.length === 0
    ) {
        return false;
    }

    return subProduct.clientPackageType === AV_PRODUCT.PACKAGE_TYPE.OPTIONAL;
};
