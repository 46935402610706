import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Xhr from '../../utils/Xhr';
import moment from '../../utils/CalendarMoment';
import Splash from './Splash/Splash';
import { Router, Redirect } from '@reach/router';
import { hashMismatch, xhrError } from '../../stores/calendar/actions';
import AuthenticationGuard from './AuthenticationGuard/AuthenticationGuard';
import FullStreamPlayer from './FullStreamPlayer/FullStreamPlayer';

import './App.scss';
import '../../css/dynamic.scss';

const defaultRoute = moment.now().toISODate();
// console.log(moment.now().toFormat());

function App(props) {
    const { isPiwikInitialized = false } = props;
    const isMicrofrontend =
        window.location.href.includes('portal') ||
        window.location.href.includes('9090');

    let consumerRoute = window.location.pathname;

    if (/\d{2,4}-\d{1,2}-\d{1,2}/.test(consumerRoute)) {
        consumerRoute = consumerRoute.substring(
            0,
            consumerRoute.lastIndexOf('/')
        );
    }
    useEffect(() => {
        Xhr.setOnXhrErrorListener(props.xhrError);
        Xhr.setOnHashMismatchListener(props.hashMismatch);
    }, [props.xhrError, props.hashMismatch]);
    return (
        <Router>
            <AuthenticationGuard
                path="/"
                consumerRoute={consumerRoute}
                isPiwikInitialized={isPiwikInitialized}
            >
                <Splash path={`/${consumerRoute}/:date`} />
                <Redirect
                    from={`/${consumerRoute}`}
                    to={`/${consumerRoute}/${defaultRoute}`}
                    noThrow
                />
            </AuthenticationGuard>
            <FullStreamPlayer
                path={
                    isMicrofrontend
                        ? `/live-booking-calendar/stream/:eventUri`
                        : '/stream/:eventUri'
                }
            />
        </Router>
    );
}

App.propTypes = {
    xhrError: PropTypes.func.isRequired,
    hashMismatch: PropTypes.func.isRequired,
    consumerRoute: PropTypes.string,
    isPiwikInitialized: PropTypes.bool,
};

const mapDispatchToProps = {
    hashMismatch,
    xhrError,
};

export default connect(null, mapDispatchToProps)(App);
