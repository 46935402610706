import React from 'react';
import PropTypes from 'prop-types';
import ContentBox from '../../../ContentBox/ContentBox';
import Legend from '../../../pages/Calendar/LeftColumn/ContentLegend/Legend/Legend';
import Button from '../../../formInput/Button/Button';
import './LegendDialog.scss';

const LegendDialog = props => {
    return (
        <div className="legend-dialog">
            <ContentBox>
                <Legend />
            </ContentBox>
            <div className="dialog-footer">
                <Button
                    onClick={props.onClose}
                    type={Button.types.LARGE_GREY_SHADOW}
                    icon={''}
                    fixedWidth={false}
                >
                    Close
                </Button>
            </div>
        </div>
    );
};

LegendDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LegendDialog;
