import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import settings from '../../../../../utils/DefaultSettings';
import Alert from '../../../../Alert/Alert';
import { colors } from '../../../../../selectors/scheduleOverview';
import './hoverInfo.scss';

class HoverInfo extends Component {
    _buildSportInfo(sportsData, sportFilterState, sportsSorted) {
        let selectedSports = [];
        const otherRow = {
            booked: 0,
            all: 0,
        };

        if (sportFilterState.length === 0) {
            selectedSports = Object.keys(sportsData);
        } else {
            selectedSports = sportFilterState.map(sfs => sfs.value);
        }

        const rows = selectedSports.map(sportId => {
            let isOthers = true;
            sportsSorted.forEach(sport => {
                if (sport.sportId === parseInt(sportId, 10)) {
                    if (sport.isOthers) {
                        otherRow.all += sportsData[sportId].all;
                        otherRow.booked += sportsData[sportId].booked;
                    } else {
                        isOthers = false;
                    }
                }
            });

            const sportName = this.props.sports[sportId]
                ? this.props.sports[sportId].name
                : 'Undefined';

            return this._buildRow(
                sportName,
                sportsData[sportId],
                sportId,
                isOthers ? null : colors[sportId]
            );
        });

        return {
            rows,
            others: otherRow,
        };
    }

    _buildRow(text, values, sportId, color = null) {
        const style = {};
        if (color !== null) {
            style.backgroundColor = color;
        }
        return (
            <tr
                className={classnames({
                    faded: values.all === 0,
                })}
                key={sportId}
            >
                <td className="sport-color" style={style}>
                    &nbsp;
                </td>
                <td className="schedule-overview-sport">{text}</td>
                <td className="schedule-overview-total schedule-overview-booked">
                    {values.booked}
                </td>
                <td className="schedule-overview-total">{values.all}</td>
            </tr>
        );
    }

    render() {
        if (!this.props.info || Object.keys(this.props.info).length === 0) {
            return <div />;
        }

        const sportInfo = this._buildSportInfo(
            this.props.info.column.sports,
            this.props.selectedSports,
            this.props.sportsSorted
        );

        return (
            <div className="hover-info">
                <div className="title">Schedule Overview</div>
                <div className="time-info">
                    {this.props.info.time.from} - {this.props.info.time.to}
                </div>
                <table className="schedule-overview-hover-info">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="schedule-overview-sport">Sport</th>
                            <th className="schedule-overview-booked schedule-overview-total">
                                Booked
                            </th>
                            <th className="schedule-overview-total">All</th>
                        </tr>
                    </thead>
                    <tbody>{sportInfo.rows}</tbody>
                    <tfoot>
                        <tr className="totals">
                            <th>&nbsp;</th>
                            <th className="schedule-overview-sport">Totals</th>
                            <th className="schedule-overview-total schedule-overview-booked">
                                {this.props.info.column.totals.booked}
                            </th>
                            <th className="schedule-overview-total">
                                {this.props.info.column.totals.all}
                            </th>
                        </tr>
                        {this._buildRow(
                            'Others',
                            sportInfo.others,
                            0,
                            settings.timeLine.scheduleOverview.defaultColor
                        )}
                    </tfoot>
                </table>
                <div className="schedule-overview-info">
                    <Alert
                        type={Alert.types.EXPLANATORY}
                        minWidth={false}
                        float={false}
                        icon="info-circle"
                    >
                        The color coding in the schedule overview represents the
                        top {settings.timeLine.scheduleOverview.maxColorCount}{' '}
                        covered sports for this day. All other sports are
                        grouped together under “others” and are always
                        represented by the red bar.
                    </Alert>
                </div>
            </div>
        );
    }
}

HoverInfo.propTypes = {
    info: PropTypes.object.isRequired,
    sportsSorted: PropTypes.array.isRequired,
    selectedSports: PropTypes.array.isRequired,
    sports: PropTypes.object.isRequired,
};

export default HoverInfo;
