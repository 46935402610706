import { adapterIds } from '../../constants/shoppingBasket';

export default shoppingBasketStoreState => {
    if (
        shoppingBasketStoreState.selectedAdapterId === adapterIds.LIVEODDS &&
        shoppingBasketStoreState.canBookLiveodds
    ) {
        return true;
    }

    if (
        shoppingBasketStoreState.canBookLiveChannel &&
        [
            adapterIds.LCO,
            adapterIds.LCR,
            adapterIds.LCT,
            adapterIds.LCP,
        ].indexOf(shoppingBasketStoreState.selectedAdapterId) !== -1
    ) {
        return true;
    }

    if (
        shoppingBasketStoreState.selectedAdapterId === adapterIds.BETPAL &&
        shoppingBasketStoreState.canBookBetpal
    ) {
        return true;
    }

    return false;
};
