import moment from './CalendarMoment';
import StorageLocal from './StorageLocal';

const storageLocal = new StorageLocal();

export const timeStringToTimestamp = timeString => {
    const [hour, minute] = timeString.split(':');
    return moment.now().set({ hour: hour, minute: minute }).toFormat('X');
};

export const getFilterName = filter => {
    switch (filter) {
        case 'matches':
            return 'Matches from teams';
        case 'tiers':
            return 'LO/LD Type';
        case 'dates':
            return 'Date Range';
        case 'times':
            return 'Time Range';
        case 'weekdays':
            return 'Only on day(s)';
        case 'agegroups':
            return 'Age Group(s)';
        case 'loconfig':
            return 'LO Config';
        case 'single':
            return 'Single Match Booking';

        // to add a new autobooking filter/column, edit here and every other place this comment exists
        // if custom name is needed

        default:
            return filter.charAt(0).toUpperCase() + filter.slice(1);
    }
};

export const filterMapping = {
    // also is the default order for the filters
    sport: ['sportId'],
    category: ['realCategoryId'],
    tournament: ['tournamentId'],
    matches: ['homeTeamId', 'awayTeamId'],
    products: ['products'],
    types: ['types'],
    tiers: ['tierContent'],
    dates: ['startDate', 'endDate'],
    times: ['startTime', 'endTime'],
    weekdays: ['daysOfWeek'],
    agegroups: ['ageGroups'],
    surcharge: ['withoutSurchargeOnly'],
    loconfig: ['isMatchActiveInLiveoddsConfig'],
    single: ['bookableWithSinglePackage'],
    // to add a new autobooking filter/column, edit here and every other place this comment exists
};

export const defaultAutobooking = {
    sportId: 0,
    realCategoryId: 0,
    tournamentId: 0,
    tournamentStart: null,
    tournamentEnd: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    daysOfWeek: [],
    ageGroups: [],
    homeTeamId: 0,
    awayTeamId: 0,
    products: ['lo'],
    types: ['real', 'simulated'],
    tierContent: [],
    bookableWithSinglePackage: 0,
    isMatchActiveInLiveoddsConfig: true,
    withoutSurchargeOnly: 0,
};

const arrayEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (const item of arr1) {
        if (!arr2.includes(item)) {
            return false;
        }
    }
    return true;
};

const isDefaultValue = (autobooking, id) => {
    if (Array.isArray(defaultAutobooking[id])) {
        return arrayEqual(defaultAutobooking[id], autobooking[id]);
    }
    return !!defaultAutobooking[id] === !!autobooking[id]; // cast to boolean
};

export const getNonDefaultFilters = autobooking => {
    return Object.keys(filterMapping).filter(filter => {
        for (const id of filterMapping[filter]) {
            if (!isDefaultValue(autobooking, id)) {
                return true;
            }
        }
        return false;
    });
};

export const getAllRelevantFilters = autobookings => {
    return Object.keys(filterMapping).filter(filter => {
        for (const id of filterMapping[filter]) {
            for (const autobooking of autobookings) {
                if (!isDefaultValue(autobooking, id)) {
                    return true;
                }
            }
        }
        return false;
    });
};

export const getSavedAttributeSets = () => {
    return JSON.parse(storageLocal.getItem('autobooking_attribute_sets')) || {};
};

export const saveAttributeSets = data => {
    storageLocal.setItem('autobooking_attribute_sets', JSON.stringify(data));
};

export const getDefaultAttributeSet = () => {
    if (!storageLocal.getItem('autobooking_attribute_sets')) {
        return {};
    }
    const savedSets = JSON.parse(
        storageLocal.getItem('autobooking_attribute_sets')
    );
    const defaultSetName = storageLocal.getItem('autobooking_default_set');
    return savedSets[defaultSetName] || {};
};

export const getDefaultAttributeSetName = () => {
    return storageLocal.getItem('autobooking_default_set');
};

export const setDefaultAttributeSet = name => {
    storageLocal.setItem('autobooking_default_set', name);
};

export const removeSavedAttributeSet = name => {
    const attributeSets = getSavedAttributeSets();

    delete attributeSets[name];

    saveAttributeSets(attributeSets);

    return attributeSets;
};

export const saveAttributeSet = (name, data) => {
    const attributeSets = getSavedAttributeSets();

    attributeSets[name] = data;

    saveAttributeSets(attributeSets);

    return attributeSets;
};
