import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import ContentBox from '../../../../ContentBox/ContentBox';
import ContentBoxTitle from '../../../../ContentBoxTitle/ContentBoxTitle';
import './contentEventInfo.scss';
import MatchInfo from './MatchInfo/MatchInfo';
import MasterInfo from './MasterInfo/MasterInfo';
import StageInfo from './StageInfo/StageInfo';
import Group from './Group/Group';
import FilterConstants from '../../../../../constants/FilterConstants';
import FontIcon from '../../../../icons/FontIcon/FontIcon';

const getComponent = uri => {
    if (uri.includes('match')) {
        return MatchInfo;
    }

    if (uri.includes('master')) {
        return MasterInfo;
    }

    if (uri.includes('stage')) {
        return StageInfo;
    }

    throw new Error(`Invalid uri: ${uri}`);
};

class ContentEventInfo extends React.Component {
    render() {
        let content = '';
        if (!this.props.event) {
            content = (
                <Group>
                    <div className="icon-label ui-400 text-blue-br-700">
                        <FontIcon icon="" />
                        Hover over an event to show detailed information.
                    </div>
                </Group>
            );
        } else {
            const event = this.props.event;
            const Component = getComponent(event.uri);

            content = (
                <div className="event-view">
                    <Component event={event} />
                </div>
            );
        }

        return (
            <ContentBox className="content-event-info">
                <div className="content">{content}</div>
            </ContentBox>
        );
    }
}

export const buildAvailability = match => {
    const { availabilityReason } = FilterConstants;
    const unavailableReasons = {
        [availabilityReason.SPORT_TOURNAMENT_PACKAGE]:
            'Missing sport tournament package',
        [availabilityReason.EXCLUSIVE]: 'Missing exclusivity',
        [availabilityReason.RTS_MATCH]: 'Missing RTS package',
        [availabilityReason.TEST_MATCH]: 'Test match',
        [availabilityReason.LIVEODDS_SCOUT_PACKAGE]:
            'Missing Liveodds Scout package',
        [availabilityReason.LIVEODDS_TV_PACKAGE]: 'Missing Liveodds TV package',
        [availabilityReason.LIVEODDS_PACKAGE]: 'Missing Liveodds package',
        [availabilityReason.LIVEDATA_PACKAGE]: 'Missing Livedata package',
        [availabilityReason.INACTIVE]: 'Match is marked as inactive',
        [availabilityReason.UNKNOWN]: 'Match is unavailable for booking',
        [availabilityReason.STREAMING_ONLY]:
            'Match is only available for streaming',
        [availabilityReason.STREAMING_ONLY_NO_PRODUCT]:
            'Streaming only, but no applicable product',
        [availabilityReason.UNIFIED_FEED_ONLY]: 'Missing Unified Feed package',
        [availabilityReason.MISSING_PACKAGE]: 'Missing LO/LD package',
        [availabilityReason.NOT_OFFERED]:
            'Not offered within our live betting services',
        [availabilityReason.EVENT_ENDED]: 'The event has ended',
        [availabilityReason.FLEX_FULL]: 'The flex package is full',
    };

    if (!match.availabilityReasons.length) {
        return '';
    }
    const availabilityRows = match.availabilityReasons.map((reason, index) => {
        return (
            <div key={`availability_reason_${index}`}>
                {unavailableReasons[reason]}
            </div>
        );
    });
    return (
        <Group
            title={
                match.availabilityReasons.indexOf(
                    availabilityReason.STREAMING_ONLY
                ) < 0
                    ? 'Match not bookable for Liveodds'
                    : null
            }
            className="pink"
        >
            {availabilityRows}
        </Group>
    );
};

export const buildLiveChannelGroup = (match, bookmakerInfo) => {
    const subProductsCount =
        (match.products.lco ? 1 : 0) +
        (match.products.lcr ? 1 : 0) +
        (match.products.lct ? 1 : 0);

    if (subProductsCount === 0) {
        return '';
    }
    const avProducts = ['lco', 'lcr', 'lct'];

    const avRestrictions = avProducts.map((prod, index) => {
        const avProduct = Object.entries(match.products).filter(
            ([key]) => key === prod
        )[0];

        return avProduct ? (
            <div className="av-products" key={index}>
                <div
                    className={`icon-label ui-400 icon-${
                        avProduct[1].countriesNotAvailable.count > 0
                            ? 'red'
                            : 'gray'
                    }`}
                >
                    <FontIcon icon="" />
                </div>
                <div className="icon-label ui-400 lc-info">
                    {` ${prod.toUpperCase()}`}
                </div>
            </div>
        ) : (
            <div key={index}></div>
        );
    });

    return (
        <>
            <Group className="live-channel">
                <div className="group-header ui-700">{`Live Channel (${subProductsCount})`}</div>
                <div className="av-products-container">
                    {avRestrictions.map(ar => ar)}
                </div>
                <div className="icon-label ui-400 lc-info">
                    <FontIcon
                        icon=""
                        title="LC Match information in context menu"
                    />
                    LC Match information in context menu
                </div>
            </Group>
        </>
    );
};

ContentEventInfo.propTypes = {
    event: PropTypes.object,
    sports: PropTypes.object,
};

ContentEventInfo.defaultProps = {
    event: null,
    sports: {},
};

const mapStateToProps = state => ({
    sports: state.calendar.sports,
    event: state.eventInfo.hoveredEventUri
        ? _.find(
              state.calendar.events,
              event => event.uri === state.eventInfo.hoveredEventUri
          )
        : null,
});

export default connect(mapStateToProps)(ContentEventInfo);
