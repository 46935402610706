import { createSelector } from 'reselect';

export default createSelector(
    (state, url) => url,
    state => state.remoteData.meta,
    state => state.remoteData.data,
    (url, allMeta, allData) => {
        let meta = allMeta[url];
        let data = allData[url];
        if (!meta) {
            meta = {
                loading: false,
                loadedAt: null,
                error: false,
            };
        }
        if (!data) {
            data = null;
        }
        return {
            meta,
            data,
        };
    }
);
