import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import constants from '../../constants/Constants';
import insideMaintenanceWindow from '../../selectors/insideMaintenanceWindow';
import { isInBasket } from '../../selectors/shoppingBasket';
import { openContextMenu } from '../../stores/eventContextMenu/actions';
import './Event.scss';
import MatchEvent from './MatchEvent';
import StageEvent from './StageEvent/StageEvent';

const isBooked = props => {
    let products = [];
    switch (props.showAsBooked) {
        case constants.SHOW_AS_BOOKED_PRODUCT.LO:
            products = ['lo'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LD:
            products = ['ld'];
            break;
        default:
            products = [
                'lo',
                'ld',
                'bp',
                'lco',
                'lcr',
                'lct',
                'lco-4sight',
                'lco-as',
                'lcp',
            ];
    }

    for (const product of products) {
        if (
            props.event.products[product] &&
            props.event.products[product].isBooked
        ) {
            return true;
        }
    }
    return false;
};
const isBookedPrimary = props => {
    let products = [];

    switch (props.showAsBookedPrimary) {
        case constants.SHOW_AS_BOOKED_PRODUCT.LO:
            products = ['lo'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LD:
            products = ['ld'];
            break;
        default:
            products = ['lo', 'ld'];
    }

    for (const product of products) {
        if (
            props.event.products[product] &&
            props.event.products[product].isBooked
        ) {
            return true;
        }
    }
    return false;
};
const isBookedSecondary = props => {
    let products = [];

    switch (props.showAsBookedSecondary) {
        case constants.SHOW_AS_BOOKED_PRODUCT.LO:
            products = ['lo'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LD:
            products = ['ld'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.BP:
            products = ['bp'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LCO:
            products = ['lco', 'lco-4sight', 'lco-as'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LCT:
            products = ['lct'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LCR:
            products = ['lcr'];
            break;
        case constants.SHOW_AS_BOOKED_PRODUCT.LCP:
            products = ['lcp'];
            break;
        default:
            products = [''];
            break;
    }

    for (const product of products) {
        if (
            props.event.products[product] &&
            props.event.products[product].isBooked &&
            !props.event.products[props.showAsBookedPrimary]?.isBooked
        ) {
            return true;
        }
    }
    return false;
};

const isRecommended = props => {
    if (!props.recommenderSettings.showRecommended) return false;
    if (props.recommenderSettings.matchIds.includes(props.event.uri)) {
        return true;
    }
};

function Event(props) {
    const {
        isCondensed: condensed = false,
        isSelectedStore: selected,
        width = 'auto',
    } = props;
    const indented = props.event.indented;
    const classNames = ['event', `event-${props.event.uri.split(':')[1]}`];

    if (!props.isNewProductsUIEnabled && isBooked(props)) {
        classNames.push('booked');
    }
    if (props.isNewProductsUIEnabled && isBookedPrimary(props)) {
        classNames.push('booked-primary');
    }
    if (props.isNewProductsUIEnabled && isBookedSecondary(props)) {
        classNames.push('booked-secondary');
    } else {
        if (isRecommended(props) && !selected) {
            classNames.push(['recommended', 'piwikTrackContent']);
        } else if (isRecommended(props) && selected) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trigger_click_recommended',
                id: props.event.uri,
            });
        }
    }

    let EventTypeContent = null;

    if (props.event.uri.includes('match')) {
        EventTypeContent = MatchEvent;
    } else if (props.event.uri.includes('stage')) {
        EventTypeContent = StageEvent;
    }

    if (EventTypeContent === null) {
        console.warn(
            'An unsupported event was found. Please check the event object ' +
                'and make sure the Event.jsx is able to handle it.',
            props.event
        );
    }

    return (
        <div
            ref={props.selectableRef}
            style={{ width }}
            className={cn(classNames, { condensed, selected, indented })}
            data-content-piece={isRecommended(props) ? 'recommended' : ''}
        >
            <div className="side-border" />
            <EventTypeContent isRecommended={isRecommended(props)} {...props} />
        </div>
    );
}

Event.propTypes = {
    width: PropTypes.any,
    event: PropTypes.object.isRequired,
    isSelectedStore: PropTypes.bool.isRequired,
    isCondensed: PropTypes.bool,
    bookmakerInfo: PropTypes.object.isRequired,
    insideMaintenanceWindow: PropTypes.bool.isRequired,
    freeTextSearchPhrase: PropTypes.array.isRequired,
    selectedDayTimestamp: PropTypes.number.isRequired,
    access: PropTypes.object.isRequired,
    selectableRef: PropTypes.any,
    selecting: PropTypes.bool,
    openContextMenu: PropTypes.func.isRequired,
    recommenderSettings: PropTypes.object,
    showAsBooked: PropTypes.string,
    showAsBookedPrimary: PropTypes.string,
    showAsBookedSecondary: PropTypes.string,
    isNewProductsUIEnabled: PropTypes.bool,
    isLcpEnabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    isSelectedStore: isInBasket(state, ownProps),
    bookmakerInfo: state.calendar.auth.userInfo.bookmakerInfo,
    insideMaintenanceWindow: insideMaintenanceWindow(state, ownProps),
    freeTextSearchPhrase: state.calendar.freeTextSearchPhrase,
    selectedDayTimestamp: state.calendar.selectedDayTimestamp,
    access: state.calendar.access,
    recommenderSettings: state.calendar.recommenderSettings,
    showAsBooked: state.calendar.showAsBooked,
    showAsBookedPrimary: state.calendar.showAsBookedPrimary,
    showAsBookedSecondary: state.calendar.showAsBookedSecondary,
    isNewProductsUIEnabled:
        state.viewport.selectedVersion === 'new-version' ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
    isLcpEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_lcp,
});

const mapDispatchToProps = {
    openContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
