import { createSelector } from 'reselect';
import expiredRuleIds from './expiredRuleIds';
import FilterConstants from '../../constants/FilterConstants';

export default createSelector(
    state => state.autobooking.autobookings,
    state => state.autobooking.selectedAutobookingType,
    state => expiredRuleIds(state),
    (autobookings, type, expiredRuleIds) => {
        if (type === FilterConstants.autobookingTypes.EXPIRED) {
            return Object.fromEntries(
                expiredRuleIds.map(id => [id, autobookings[id]])
            );
        } else if (type === FilterConstants.autobookingTypes.ACTIVE) {
            return Object.fromEntries(
                Object.keys(autobookings)
                    .filter(id => !expiredRuleIds.includes(parseInt(id)))
                    .map(id => [id, autobookings[id]])
            );
        }
        return autobookings;
    }
);
