import views from '../constants/views';
import Hashmap from 'es6-map';
import Utils from './Utils';
import FilterConstants from '../constants/FilterConstants';
import moment from '../utils/CalendarMoment';
import { BOOKING_STATUS } from '../constants/shoppingBasket';

const SportRow = function (y, height) {
    this.y = y;
    this.height = height;
    this.events = [];
};

SportRow.prototype.addEvent = function (event) {
    this.events.push(event);
};

let momCalDayStart, momCalDayEnd;

const sortEvents = function (a, b, sortOrder) {
    if (a.sportId < b.sportId) {
        return -1;
    }
    if (a.sportId > b.sportId) {
        return 1;
    }
    if (sortOrder === 'asc') {
        if (a.startDate < b.startDate) {
            return -1;
        }
        if (a.startDate > b.startDate) {
            return 1;
        }
    } else if (sortOrder === 'desc') {
        if (a.startDate < b.startDate) {
            return 1;
        }
        if (a.startDate > b.startDate) {
            return -1;
        }
    }
    if (a.tournamentName < b.tournamentName) {
        return -1;
    }
    if (a.tournamentName > b.tournamentName) {
        return 1;
    }
    if (a.homeTeam < b.homeTeam) {
        return -1;
    }
    if (a.homeTeam > b.homeTeam) {
        return 1;
    }
    return 0;
};

const EventUtils = {
    groupEvents: function (
        events,
        viewType,
        sports,
        momCalendar,
        sortOrder = 'asc'
    ) {
        // Start of calendar day
        momCalDayStart = momCalendar.startOf('day');

        // End of calendar day
        momCalDayEnd = momCalendar.endOf('day');

        // Remove matches that do not belong to the feed (ME-matches from the day before or after)
        events = events.filter(event => {
            return !event.parentOnly;
        });

        if (viewType === views.VIEWPORT_TYPE_TIMELINE_GADGET) {
            return this._groupForTimelineView(events, sports);
        }

        return this._groupForListCondensed(events, sports, sortOrder);
    },

    _getSportRowKey(event, sportRows) {
        let sportRowKey = null;

        sportRows.some((sportRow, key) => {
            const eventTsStart = this.getEventTodayStart(event.startDate),
                eventTsEnd = eventTsStart + event.durationSeconds;

            const collision = sportRow.events.some(rowEvent => {
                const rowEventTsStart = this.getEventTodayStart(
                        rowEvent.startDate
                    ),
                    rowEventTsEnd = rowEventTsStart + rowEvent.durationSeconds,
                    a = eventTsStart < rowEventTsEnd,
                    b = eventTsEnd > rowEventTsStart;
                return a && b;
            });

            if (!collision) {
                sportRowKey = key;
                return true;
            }
            return false;
        });

        return sportRowKey;
    },

    _groupForTimelineView: function (events, sports) {
        return new Promise(resolve => {
            // settings
            const rowHeight = 56,
                sportHeaderHeight = 20; // see settings.timeLine.sportHeaderHeight

            // sort events by sport, date
            events.sort(sortEvents);

            // sort the events into groups
            const sportGroups = new Hashmap(),
                pxPerSecond = 60 / 3600; // todo dynamic
            let prevSportId = null,
                sportGroup,
                sportId,
                sportRowKey,
                curY = 0,
                sportRow,
                sportRowHeight,
                tsStart;

            events.forEach(event => {
                this.setDuration(event, sports);

                sportId = event.sportId;

                tsStart = this.getEventTodayStart(event.startDate);

                // calculate where the event should be positioned (horizontal)
                const secondsIntoDay = tsStart - momCalDayStart.toFormat('X'),
                    left = Math.round(secondsIntoDay * pxPerSecond);

                event.dimensions = {
                    left: left,
                    width: Math.round(pxPerSecond * event.durationSeconds) - 1,
                };

                // create new sport group
                if (prevSportId === null || prevSportId !== sportId) {
                    sportGroups.set(sportId, {
                        id: sportId,
                        rows: [],
                    });
                }

                // select sport group
                sportGroup = sportGroups.get(sportId);

                // iterate over sport rows. Check if event fits for a row.
                // If so, foundSportRowKey EQ true, and row key is stored inside sportRowKey
                sportRowKey = this._getSportRowKey(event, sportGroup.rows);

                // sport row was found? Then add event to that row
                if (sportRowKey !== null) {
                    sportGroup.rows[sportRowKey].addEvent(event);
                } else {
                    // sport row was not found. We have to add a new one
                    sportRowHeight =
                        sportGroup.rows.length === 0 // first row in group? add extra space for sport-header
                            ? rowHeight + sportHeaderHeight
                            : rowHeight;
                    sportRow = new SportRow(curY, sportRowHeight);
                    curY += sportRowHeight;
                    sportRow.addEvent(event);
                    sportGroup.rows.push(sportRow);
                }

                // store sport id for next iteration
                prevSportId = sportId;
            });

            resolve({
                groups: Utils.MapToArray(sportGroups),
                rowsHeightTotal: curY,
                visibleMatchCount: events.length,
            });
        });
    },

    _groupForListCondensed: function (events, sports, sortOrder) {
        return new Promise(resolve => {
            // sort the events into (time-) groups
            const groups = {},
                timegroupMinutes = 60; // todo make dynamic
            let tsStart, tsGroup;

            events.forEach(event => {
                this.setDuration(event, sports);

                tsStart = this.getEventTodayStart(event.startDate);
                tsGroup = tsStart - (tsStart % (timegroupMinutes * 60));

                if (!groups.hasOwnProperty(tsGroup)) {
                    groups[tsGroup] = {
                        events: [],
                        tsGroup: tsGroup,
                    };
                }

                groups[tsGroup]['events'].push(event);
            });

            // sort events (inside group) by sport, date
            let group, k;
            for (k in groups) {
                if (!groups.hasOwnProperty(k)) {
                    continue;
                }
                group = groups[k];
                group.events.sort((a, b) => sortEvents(a, b, sortOrder));
                groups[k] = group;
            }

            resolve({
                groups: groups,
                rowsHeightTotal: 0, // not used for list view, we set it to zero
                visibleMatchCount: events.length,
            });
        });
    },

    setDuration(event, sports) {
        const momEventStart = moment.fromTimestamp(event.startDate),
            momEventEnd = event.endDate
                ? moment.fromTimestamp(event.endDate)
                : momEventStart,
            sportId = event.sportId;

        const tsStart = this.getEventTodayStart(event.startDate);

        if (event.uri.includes('master')) {
            // Use event duration within this day
            event.durationSeconds = Math.abs(
                moment
                    .fromTimestamp(tsStart)
                    .diff(
                        momEventEnd > momCalDayEnd ? momCalDayEnd : momEventEnd,
                        'seconds'
                    )
                    .toObject().seconds
            );
        } else {
            // Use sportsduration
            event.durationSeconds =
                sports[sportId] && sports[sportId].duration
                    ? sports[sportId].duration * 60
                    : 90 * 60;
        }
    },
    getEventTodayStart(startDate) {
        if (moment.fromTimestamp(startDate) < momCalDayStart) {
            // If event starts before this day (master event / stage), use start of day
            return momCalDayStart.toFormat('X');
        }
        return startDate;
    },
};

export const eventCanBeAddedToBasket = event => {
    return (
        Object.values(event.products).some(function (product) {
            return product.bookingStatusId === BOOKING_STATUS.BOOKABLE;
        }) &&
        event.availability !== FilterConstants.availabilityReason.MATCH_INACTIVE
    );
};

export default EventUtils;
