import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import defaultSettings from '../../../../utils/DefaultSettings';
import moment from '../../../../utils/CalendarMoment';
import { saveAutobooking } from '../../../../stores/autobooking/actions';
import Confirmation from '../../Confirmation/Confirmation';
import {
    filterMapping,
    defaultAutobooking,
} from '../../../../utils/autobookingUtils';

class SaveAutobookingDialog extends React.Component {
    onConfirm = () => {
        const { postData, autobooking } = this.buildPostData();

        this.props.saveAutobooking(autobooking, postData);
        this.props.onClose();
    };

    addDropdownValue = (key, selected, postData) => {
        if (selected && selected > -1) {
            postData[key] = selected;
        }
    };

    buildPostData = () => {
        const { formFilters, selectedFilters, disabledFilters } = this.props;
        let autobooking = {};

        this.props.editId && (autobooking.id = this.props.editId);

        const addItemToAutobooking = function (condition, item) {
            if (
                selectedFilters[condition] &&
                !disabledFilters[condition] &&
                formFilters[item] !== -1
            ) {
                autobooking[item] = formFilters[item];
            }
        };

        for (const [filter, ids] of Object.entries(filterMapping)) {
            for (const id of ids) {
                addItemToAutobooking(filter, id);
            }
        }

        if (!autobooking.sportId) {
            autobooking.sportId = defaultAutobooking.sportId;
        }

        const setArrayDefault = id => {
            if (!autobooking[id] || !autobooking[id].length) {
                autobooking[id] = defaultAutobooking[id];
            }
        };
        setArrayDefault('products');
        setArrayDefault('types');

        const postData = { ...autobooking };

        // add more fields to the autobooking that we don't need to send to the backend
        // because they are derived from the other fields in the backend normally
        addItemToAutobooking('sport', 'sportName');
        addItemToAutobooking('category', 'realCategoryName');
        addItemToAutobooking('tournament', 'tournamentName');
        addItemToAutobooking('matches', 'homeTeamName');
        addItemToAutobooking('matches', 'awayTeamName');

        setArrayDefault('ageGroups');
        setArrayDefault('tierContent');
        setArrayDefault('daysOfWeek');

        autobooking = JSON.parse(JSON.stringify(autobooking));

        return { postData, autobooking };
    };

    render() {
        const textOutputs = defaultSettings.autobooking.textOutputs;
        const dateMessage = !this.props.selectedFilters.dates
            ? textOutputs.noPeriodFilterSelected
            : textOutputs.periodFilterSelected.replace(
                  '{date_end}',
                  moment
                      .fromTimestamp(this.props.formFilters.endDate)
                      .toFormat('DDD')
              );

        return (
            <Confirmation
                onConfirm={this.onConfirm}
                onReject={this.props.onClose}
            >
                {dateMessage}
                <br />
                Continue?
            </Confirmation>
        );
    }
}

SaveAutobookingDialog.propTypes = {
    editId: PropTypes.number,
    formFilters: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    disabledFilters: PropTypes.object.isRequired,
    saveAutobooking: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    bookmakerFeatures: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    formFilters: state.autobooking.formFilters,
    selectedFilters: state.autobooking.selectedFilters,
    disabledFilters: state.autobooking.disabledFilters,
    editId: state.autobooking.editId,
    bookmakerFeatures: state.calendar.auth.userInfo.bookmakerInfo.features,
});

const mapDispatchToProps = {
    saveAutobooking,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveAutobookingDialog);
