import JsonCache from './JsonCache';
import settings from './DefaultSettings';
import validatorFunctions from './ValidatorFunctions';
import Xhr from './Xhr';
import StorageLocal from './StorageLocal';

const storageLocal = new StorageLocal();
const EventFetcher = {
    fetch: function (uri, mm, isQuarkusEnabled) {
        // todo - it seems the uri parameter here is never used
        // instead the uri defined below based on settings is
        // investigate whether this is intentional or not
        return new Promise((resolve, reject) => {
            // in cache? resolve
            const hash = this.mmToCacheHash(mm);
            if (JsonCache.hasItem(hash)) {
                resolve(JsonCache.getItem(hash));
            } else {
                // not in cache - make request

                let start = mm.startOf('day').toFormat('X'),
                    end = mm.endOf('day').toFormat('X'),
                    uri = String(settings.feedUri)
                        .replace('{timestampStart}', start)
                        .replace('{timestampEnd}', end);
                if (storageLocal.getItem('ttlMinutes') > 0) {
                    uri = String(settings.feedUriCache)
                        .replace('{timestampStart}', start)
                        .replace('{timestampEnd}', end)
                        .replace(
                            '{ttlMinutes}',
                            storageLocal.getItem('ttlMinutes')
                        );
                }
                const feedReq = isQuarkusEnabled
                    ? Xhr.backendRequestLbcQuarkus(uri, validatorFunctions.feed)
                    : Xhr.backendRequest(uri, validatorFunctions.feed);
                feedReq.then(
                    response => {
                        JsonCache.addItem(hash, response);
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },

    mmToCacheHash: function (mm) {
        return String(mm.startOf('day').toFormat('X'));
    },

    clearCache: function () {
        JsonCache.deleteAll();
    },
};

export default EventFetcher;
