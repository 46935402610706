import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetchTemplates,
    assignTemplate,
    removeTemplate,
    switchTemplate,
    copyTemplate,
} from '../../../../stores/template/actions';
import LazyTable from '../../../LazyTable/LazyTable.jsx';
import constants from '../../../../constants/Constants';
import {
    LazyTableData as LazyTableModel,
    Row as LazyTableModelRow,
    Cell as LazyTableModelCell,
} from '../../../../models/LazyTableData';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import './matchConfigDialog.scss';
import MatchQuickInfo from '../MatchQuickInfo/MatchQuickInfo';
import Button from '../../../formInput/Button/Button';

class ChangelogDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateId: null,
        };
    }

    componentDidMount() {
        this.props.fetchTemplates(this.props.eventUri);
    }

    onApply() {
        this.setState({ selectedTemplateId: null });

        this.props.effectiveTemplateId && this.props.effectiveType === 'MATCH'
            ? this.props.switchTemplate(
                  this.props.eventUri,
                  this.state.selectedTemplateId
              )
            : this.props.assignTemplate(
                  this.props.eventUri,
                  this.state.selectedTemplateId
              );
    }

    onCopy() {
        // Todo : create proper popup with text input?

        let isValidTemplateName = name => {
            return name === null || name.length > 5;
        };

        let templateName;
        do {
            if (templateName !== null && templateName !== undefined) {
                alert(
                    'Template name is not valid. Please enter at least 5 characters'
                );
            }
            templateName = prompt(
                'Please enter a name of the new template you want to create:',
                `Copy of [${
                    this.props.availableTemplates[
                        this.props.effectiveTemplateId
                    ]
                }] - (sr:match:${this.props.event.id} - ${
                    this.props.event.homeTeam
                } vs ${this.props.event.awayTeam})`
            );
        } while (!isValidTemplateName(templateName));

        if (!templateName) {
            return;
        }

        this.setState({ selectedTemplateId: null });

        this.props.copyTemplate(
            this.props.eventUri,
            this.props.effectiveTemplateId,
            templateName
        );
    }

    onChange(template) {
        this.setState({ selectedTemplateId: template.id });
    }

    onRemove() {
        this.props.removeTemplate(
            this.props.eventUri,
            this.props.effectiveTemplateId
        );
    }

    createTemplateOption(template) {
        return (
            <div className="template-option" key={`template${template.id}`}>
                <input
                    type="radio"
                    id={`template${template.id}`}
                    checked={template.id === this.state.selectedTemplateId}
                    onChange={this.onChange.bind(this, template)}
                />
                <label htmlFor={`template${template.id}`}>
                    {template.name}
                </label>
            </div>
        );
    }

    render() {
        const matchInfo = (
            <MatchQuickInfo
                event={this.props.event}
                includeGeneralInfo={false}
                includeLinks={false}
                optionalKeyValue={{
                    key: 'Category',
                    value: this.props.event.realCategoryName,
                }}
            />
        );

        if (this.props.loading) {
            return (
                <div className="match-config-dialog">
                    {matchInfo}
                    <BusySpinner />
                </div>
            );
        }

        let assignDisabled = false;
        let assignTitle = '';
        if (!this.state.selectedTemplateId) {
            assignDisabled = true;
            assignTitle = 'No template selected';
        } else if (
            this.props.effectiveType === 'MATCH' &&
            parseInt(this.state.selectedTemplateId) ===
                this.props.effectiveTemplateId
        ) {
            assignDisabled = true;
            assignTitle = 'Selected template is already assigned';
        }

        return (
            <div className="match-config-dialog">
                {matchInfo}
                {this.buildAssignedTemplates()}
                <div>
                    <Button
                        onClick={this.onRemove.bind(this)}
                        type={Button.types.LARGE_RED_SOLID}
                        icon=""
                        fixedWidth={'300px'}
                        disabled={this.props.effectiveType !== 'MATCH'}
                        style={{ float: 'right' }}
                    >
                        Remove current match assignment
                    </Button>
                </div>

                <h2>Change MATCH level assignment</h2>
                <div className="template-options">
                    {Object.entries(this.props.availableTemplates).map(item => {
                        return this.createTemplateOption({
                            id: item[0],
                            name: item[1],
                        });
                    })}
                </div>

                <div>
                    <Button
                        onClick={this.onApply.bind(this)}
                        type={Button.types.LARGE_GREEN_3D}
                        icon={''}
                        fixedWidth={'300px'}
                        disabled={assignDisabled}
                        title={assignTitle}
                        style={{ float: 'left' }}
                    >
                        {this.props.effectiveType === 'MATCH'
                            ? 'Change'
                            : 'Apply'}{' '}
                        match assignment
                    </Button>
                    <Button
                        onClick={this.onCopy.bind(this)}
                        type={Button.types.LARGE_GREEN_3D}
                        icon={''}
                        fixedWidth={'300px'}
                        disabled={assignDisabled}
                        style={{ float: 'right' }}
                    >
                        Copy template and assign to match
                    </Button>
                </div>
            </div>
        );
    }

    buildAssignedTemplates() {
        const typeName = {
            SPORT: this.props.sports[this.props.event.sportId]
                ? this.props.sports[this.props.event.sportId].name
                : 'Undefined',
            CATEGORY: this.props.event.realCategoryName,
            TOURNAMENT: this.props.event.tournamentName,
            MATCH: '',
        };

        const table = new LazyTableModel([20, 30, 30, 20]);

        const headerCells = [
            new LazyTableModelCell('Type'),
            new LazyTableModelCell('Name'),
            new LazyTableModelCell('Template Name'),
            new LazyTableModelCell('Ctrl Link'),
        ];
        const header = new LazyTableModelRow(headerCells);
        table.setHeaderRow(header);

        table.config.rows = this.props.assignedTemplates.map(template => {
            const cells = [
                new LazyTableModelCell(template.type),
                new LazyTableModelCell(typeName[template.type]),
                new LazyTableModelCell(
                    (template.templateId &&
                        this.props.availableTemplates[template.templateId]) ||
                        'No template assigned'
                ),
                new LazyTableModelCell(
                    template.templateId ? (
                        <a
                            href={`${this.props.backendSettings.betradar.coconut}/templates/edit/${template.templateId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Edit template in <em>Ctrl</em>
                        </a>
                    ) : (
                        ''
                    )
                ),
            ];
            return new LazyTableModelRow(cells);
        });
        return (
            <div>
                <h2>Current assignment tree</h2>
                <LazyTable
                    lazyTableData={table}
                    id={constants.LAZY_TABLE_MATCH_CONFIG}
                />
            </div>
        );
    }
}

ChangelogDialog.propTypes = {
    eventUri: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
    availableTemplates: PropTypes.object,
    effectiveTemplateId: PropTypes.number,
    effectiveType: PropTypes.string,
    assignedTemplates: PropTypes.object,
    fetchTemplates: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    assignTemplate: PropTypes.func.isRequired,
    removeTemplate: PropTypes.func.isRequired,
    copyTemplate: PropTypes.func.isRequired,
    switchTemplate: PropTypes.func.isRequired,
    sports: PropTypes.array,
    backendSettings: PropTypes.object,
};

const mapStateToProps = state => ({
    availableTemplates: state.template.availableTemplates,
    assignedTemplates: state.template.assignedTemplates,
    effectiveTemplateId: state.template.effectiveTemplateId,
    effectiveType: state.template.effectiveType,
    loading: state.template.loading,
    sports: state.calendar.sports,
    backendSettings: state.calendar.backendSettings,
});

const mapDispatchToProps = {
    fetchTemplates,
    assignTemplate,
    removeTemplate,
    copyTemplate,
    switchTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangelogDialog);
