import Xhr from '../../utils/Xhr';
import changelogConstants from './constants';
import Alert from '../../components/Alert/Alert';
import { create as createNotification } from '../notification/actions';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const isQuarkusEnabled = getState =>
    getState().calendar.auth.userInfo.bookmakerInfo.features
        .enable_lbc_quarkus_changelog;

export const fetchChangelog = () => (dispatch, getState) => {
    let fetchChangeLogRequest =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus('/changelog/all')
            : Xhr.backendRequest('/changelog/all');

    fetchChangeLogRequest
        .then(response =>
            dispatch({
                type: changelogConstants.FETCH,
                payload: response.response,
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const createChangelogEntry = changelogEntry => (dispatch, getState) => {
    dispatch({
        type: changelogConstants.START_CREATION,
    });

    const createChangelogEntryRequest =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus(
                  '/changelog/new',
                  null,
                  'POST',
                  changelogEntry
              )
            : Xhr.backendRequest(
                  '/changelog/new',
                  null,
                  'POST',
                  changelogEntry
              );
    createChangelogEntryRequest
        .then(response =>
            dispatch({
                type: changelogConstants.CREATE,
                payload: { ...response, ...changelogEntry },
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const updateChangelogEntry = changelogEntry => (dispatch, getState) => {
    dispatch({
        type: changelogConstants.START_UPDATE,
        payload: changelogEntry.id,
    });

    let updateChangelogRequest =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus(
                  '/changelog/update',
                  null,
                  'POST',
                  changelogEntry
              )
            : Xhr.backendRequest(
                  '/changelog/update',
                  null,
                  'POST',
                  changelogEntry
              );
    updateChangelogRequest
        .then(response => {
            if (!response.success) {
                throw new Error(
                    'An error occured while updating changelog entry.'
                );
            }

            dispatch({
                type: changelogConstants.UPDATE,
                payload: changelogEntry,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const deleteChangelogEntry = id => (dispatch, getState) => {
    let deleteChangelogRequest =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus(
                  `/changelog/delete/${id}`,
                  null,
                  'DELETE'
              )
            : Xhr.backendRequest(`/changelog/delete/${id}`, null, 'DELETE');
    deleteChangelogRequest
        .then(response => {
            if (!response.success) {
                throw new Error(
                    'An error occured while deleting changelog entry.'
                );
            }

            dispatch({
                type: changelogConstants.DELETE,
                payload: id,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};
