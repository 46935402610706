import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import _ from 'underscore';
import StorageLocal from '../../../../../../utils/StorageLocal';
import Button from '../../../../../formInput/Button/Button';
import CustomSelect from '../../../../../formInput/CustomSelect/CustomSelect';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import './filterNcaaFilters.scss';
const storageLocal = new StorageLocal();
const localStorageKey = 'NCAA_FILTER_collapsed';

class FilterNcaaFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: '',
        };
    }

    componentDidMount() {
        this.setState({ isCollapsed: this.props.isCollapsed });
    }

    setIsCollapsed(isCollapsed) {
        this.setState({ isCollapsed: isCollapsed });
        storageLocal.setItem(localStorageKey, isCollapsed);
    }

    render() {
        const Menu = props => {
            return (
                <components.Menu {...props}>
                    <div className="bothButton">
                        <Button
                            cssId={'filter-ncaa-team-state-only'}
                            type={
                                this.props.ncaaFilter.teamStatesOnly
                                    ? Button.types.SMALL_BLUE_BORDER_FILLED
                                    : Button.types.SMALL_BLUE_BORDER
                            }
                            title={
                                'Show only matches where BOTH teams are from selected state'
                            }
                            onClick={this._onChangeTeamStateMode.bind(this)}
                            fixedWidth={150}
                        >
                            Both teams
                        </Button>
                    </div>
                    <div className="bothButtonText">
                        {this.props.ncaaFilter.teamStatesOnly
                            ? 'Both teams are from the selected state(s)'
                            : 'One team from the selected state(s)'}
                    </div>
                    {props.children}
                </components.Menu>
            );
        };

        return (
            <div className="filter-checkbox-list filter-ncaa">
                <div className={'header collapsible'} title={this.props.title}>
                    <div className="title textline-400 text-semibold">NCAA</div>
                    <div
                        className="indicators"
                        onClick={() =>
                            this.setIsCollapsed(!this.state.isCollapsed)
                        }
                    >
                        {buildChevron(this.state.isCollapsed)}
                    </div>
                </div>
                {this.state.isCollapsed || (
                    <div className="list">
                        <CustomSelect
                            id={'filter-ncaa-division'}
                            placeholder="MATCH DIVISION(S)"
                            onChange={this._onChangeDivision.bind(this)}
                            isMulti={true}
                            options={this._getDivisions()}
                            value={this.props.ncaaFilter.divisions}
                            closeMenuOnSelect={false}
                            menuPosition="fixed"
                        />
                        <CustomSelect
                            id={'filter-ncaa-team-state'}
                            placeholder="TEAM STATE(S)"
                            onChange={this._onChangeTeamState.bind(this)}
                            isMulti={true}
                            options={this._getTeamStates()}
                            value={this.props.ncaaFilter.teamStates}
                            closeMenuOnSelect={false}
                            clearable={true}
                            searchable={true}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            components={{ Menu }}
                            maxMenuHeight={150}
                        />
                        <CustomSelect
                            id={'filter-ncaa-venue-state'}
                            placeholder="VENUE STATE(S)"
                            onChange={this._onChangeVenueState.bind(this)}
                            isMulti={true}
                            options={this._getVenueStates()}
                            value={this.props.ncaaFilter.venueStates}
                            closeMenuOnSelect={false}
                            clearable={true}
                            searchable={true}
                            menuPosition="fixed"
                        />
                        <br clear="all" />
                    </div>
                )}
            </div>
        );
    }

    _onChangeTeamStateMode() {
        this.props.onChange({
            teamStatesOnly: !this.props.ncaaFilter.teamStatesOnly,
        });
    }

    _onChangeTeamState(val) {
        this.props.onChange({
            divisions: [],
            venueStates: [],
            teamStates: val,
        });
    }

    _onChangeVenueState(val) {
        this.props.onChange({
            divisions: [],
            venueStates: val,
            teamStates: [],
        });
    }

    _onChangeDivision(val) {
        this.props.onChange({
            divisions: val,
            venueStates: [],
            teamStates: [],
        });
    }

    _getDivisions() {
        return [
            { label: 'Division 1', value: 1 },
            { label: 'Division 2', value: 2 },
            { label: 'Division 3', value: 3 },
            { label: 'Not available', value: 0 },
        ];
    }

    _getTeamStates() {
        let states = [];
        let NA = false;
        this.props.events.forEach(event => {
            if (event.isNcaa) {
                if (event.teamHome.venue) {
                    let state = event.teamHome.venue.state;
                    let sKey = 'US-' + state;
                    states[state] = {
                        value: state,
                        label: this.props.countries.hasOwnProperty(sKey)
                            ? this.props.countries[sKey]
                            : state,
                    };
                } else {
                    NA = true;
                }
                if (event.teamAway.venue) {
                    let state = event.teamAway.venue.state;
                    let sKey = 'US-' + state;
                    states[state] = {
                        value: state,
                        label: this.props.countries.hasOwnProperty(sKey)
                            ? this.props.countries[sKey]
                            : state,
                    };
                } else {
                    NA = true;
                }
            }
        });
        states = _.sortBy(Object.values(states), 'label');
        if (NA) {
            states['XX'] = { value: 'XX', label: 'Not Available' };
        }
        return states;
    }

    _getVenueStates() {
        let states = [];
        let NA = false;
        this.props.events.forEach(event => {
            if (event.isNcaa && event.venue) {
                let state = event.venue.state;
                let sKey = 'US-' + state;
                states[state] = {
                    value: state,
                    label: this.props.countries.hasOwnProperty(sKey)
                        ? this.props.countries[sKey]
                        : state,
                };
            } else {
                NA = true;
            }
        });
        states = _.sortBy(Object.values(states), 'label');
        if (NA) {
            states['XX'] = { value: 'XX', label: 'Not Available' };
        }
        return states;
    }
}

FilterNcaaFilters.propTypes = {
    isCollapsed: PropTypes.bool,
    title: PropTypes.string,
    events: PropTypes.array.isRequired,
    countries: PropTypes.object.isRequired,
    ncaaFilter: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

FilterNcaaFilters.defaultProps = {
    isCollapsed: storageLocal.getItem(localStorageKey) === 'true',
    title: 'Undefined',
};

const mapStateToProps = state => ({
    events: state.calendar.events,
    countries: state.calendar.countries,
});

export default connect(mapStateToProps)(FilterNcaaFilters);

function buildChevron(collapsed) {
    return collapsed ? <FontIcon icon="" /> : <FontIcon icon="" />;
}
