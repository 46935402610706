import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventSelector from '../../../../Event/EventSelector';
import FilterEventMessage from '../../../../FilterEventMessage/FilterEventMessage.jsx';
import settings from '../../../../../utils/DefaultSettings';
import ScheduleOverview from './ScheduleOverview/ScheduleOverview';
import SportIcon from '../../../../icons/SportIcon/SportIcon';
import moment from '../../../../../utils/CalendarMoment';
import { setLeftOffset } from '../../../../../stores/scheduleOverview/actions';
import './TimeLine.scss';
import Event from '../../../../Event/Event';
import EventExpansionToggle from '../../../../Event/EventExpansionToggle';
import EventMatchViewTrigger from '../../../../Event/EventMatchViewTrigger';
import EventContextMenu from '../../../../Event/EventContextMenu';

let scrollTimer = null,
    previousScrollTop = 0,
    previousScrollLeft = 0;

class TimeLine extends React.Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();

        this.state = {
            scrollPosition: 0,
            leftScroll: 0,
        };
    }

    componentWillUnmount() {
        this._clearScrollTimer();
    }

    componentDidMount() {
        const contentContainer = this.contentRef.current;
        if (!contentContainer) {
            return;
        }
        this.props.setLeftOffset(contentContainer.scrollLeft || 0);
    }

    _clearScrollTimer() {
        if (scrollTimer) {
            clearTimeout(scrollTimer);
        }
    }

    _onScroll = e => {
        this._clearScrollTimer();

        const nextScrollTop = this.contentRef.current.scrollTop,
            nextScrollLeft = this.contentRef.current.scrollLeft;

        if (nextScrollTop !== previousScrollTop) {
            previousScrollTop = nextScrollTop;
        }

        if (nextScrollLeft !== previousScrollLeft) {
            previousScrollLeft = nextScrollLeft;
        }

        scrollTimer = setTimeout(() => {
            this.setState({
                scrollPosition: nextScrollTop,
            });
        }, 100);

        // also scroll the time block headers
        const headers = document.querySelector(
            '.viewport-time-line .sticky-row'
        );
        headers.style.left = -nextScrollLeft + 'px';

        // handle scroll for schedule overview
        this.props.setLeftOffset(nextScrollLeft);
    };

    _getTimelineWidth() {
        const hoursInDay = 24;
        return settings.timeLine.blockWidth * hoursInDay;
    }

    render() {
        const leftOffset = settings.timeLine.sportPillar.width; // offset, before we start rendering time horizontal

        // init time calculations (horizontal)
        const momentOffset = moment
                .fromTimestamp(this.props.momentSelectedDay)
                .startOf('day'),
            isToday = momentOffset.hasSame(moment.now(), 'day'),
            pxPerSecond = 60 / 3600; // todo dynamic (60 = blockWidth)
        let content;

        // prepare time headers + time columns
        const timeHeaders = [],
            timeColumns = [];
        let i, mm, nowLine, nowHeader, isNowHour;
        for (i = 0; i <= 23; i++) {
            mm = momentOffset.plus({ hours: i });
            isNowHour = isToday && moment.now().hasSame(mm, 'hour');

            // now line + time header
            nowLine = '';
            if (isNowHour) {
                nowLine = (
                    <div
                        className="now-line"
                        style={{
                            left: Math.round(
                                Number(moment.now().toFormat('m')) *
                                    60 *
                                    pxPerSecond
                            ),
                        }}
                    />
                );
                const nowHeaderWidth = 38;
                nowHeader = (
                    <div
                        key={'now-header'}
                        className={
                            'now-header textline-100 text-white text-center'
                        }
                        style={{
                            width: `${nowHeaderWidth}px`,
                            left:
                                Math.round(
                                    Number(moment.now().toFormat('m')) *
                                        60 *
                                        pxPerSecond
                                ) +
                                leftOffset +
                                i * settings.timeLine.blockWidth -
                                nowHeaderWidth / 2,
                        }}
                    >
                        <span className="hour">
                            {moment.now().toFormat('HH')}
                        </span>
                        <span className="minutes">
                            :{moment.now().toFormat('mm')}
                        </span>
                    </div>
                );
            }

            timeHeaders.push(
                <div
                    key={'timeHeader_' + i}
                    className={
                        'time-block-header textline-100 text-gray-1100 text-center'
                    }
                    style={{
                        width: settings.timeLine.blockWidth,
                        left:
                            leftOffset +
                            i * settings.timeLine.blockWidth -
                            settings.timeLine.blockWidth / 2,
                    }}
                >
                    <span className="hour">{mm.toFormat('HH')}</span>
                    <span className="minutes">:00</span>
                </div>
            );

            timeColumns.push(
                <div
                    key={'timeColumn_' + i}
                    className="time-column"
                    style={{
                        width:
                            settings.timeLine.blockWidth +
                            (i === 0 ? leftOffset : 0),
                        left:
                            i * settings.timeLine.blockWidth +
                            (i > 0 ? leftOffset : 0),
                        height: this.props.rowsHeightTotal,
                    }}
                >
                    {nowLine}
                </div>
            );
        }
        timeHeaders.push(nowHeader);

        // prepare rows with events
        const eventRows = [],
            sportPillars = [],
            visibleEvents = [];
        let uid = 0,
            _y = 0,
            pillarTop = 0;

        this.props.groups.forEach(sportGroup => {
            sportGroup.rows.forEach(sportRow => {
                eventRows.push(
                    <div
                        key={'sportGroupRow_' + uid}
                        className="row"
                        style={{
                            height: sportRow.height,
                            top: sportRow.y,
                            width: this._getTimelineWidth(),
                        }}
                    >
                        {sportRow.events.map(event => {
                            visibleEvents.push(event.uri);

                            const offsetLeft =
                                event.dimensions.left + leftOffset;

                            let baseEventComponent = (
                                <Event event={event} inTimeLine={true} />
                            );

                            // only master events don't need to expand/compress
                            if (!event.uri.includes('master')) {
                                baseEventComponent = (
                                    <EventExpansionToggle
                                        event={event}
                                        width={event.dimensions.width}
                                        containerWidth={this._getTimelineWidth()}
                                        offsetLeft={offsetLeft}
                                    >
                                        {baseEventComponent}
                                    </EventExpansionToggle>
                                );
                            }

                            return (
                                <EventMatchViewTrigger
                                    key={event.uri}
                                    uri={event.uri}
                                >
                                    {baseEventComponent}
                                </EventMatchViewTrigger>
                            );
                        })}
                    </div>
                );

                uid++;

                // store current y (used for positioning pillars)
                _y = sportRow.height + sportRow.y;
            });

            const sportName = this.props.sports[sportGroup.id]
                ? this.props.sports[sportGroup.id].name
                : 'Undefined';

            // add sport pillar
            sportPillars.push(
                <div
                    key={'sportPillar_' + sportGroup.id}
                    className="sport-pillar"
                    style={{
                        top: pillarTop,
                        height: _y - pillarTop,
                        width: this._getTimelineWidth() + leftOffset,
                    }}
                >
                    <div
                        className="header"
                        style={{
                            height: settings.timeLine.sportHeaderHeight,
                        }}
                    >
                        <SportIcon sportId={sportGroup.id} />
                        {sportName}
                    </div>
                </div>
            );

            pillarTop = _y; // store as top, for next pillar
        });

        if (!sportPillars.length) {
            // no events
            content = (
                <div className="viewport-time-line">
                    <FilterEventMessage visibleEvents={visibleEvents} />
                </div>
            );
        } else {
            content = (
                <Fragment>
                    <ScheduleOverview
                        top={0}
                        leftOffset={leftOffset}
                        isVisible={this.props.gadgetEnabled}
                    />
                    <div
                        className="sticky-row"
                        style={{
                            width: this._getTimelineWidth() + 500, // 500 = fill for the right side
                        }}
                    >
                        {timeHeaders}
                    </div>
                    <div
                        className="content"
                        ref={this.contentRef}
                        onScroll={this._onScroll}
                    >
                        {timeColumns}
                        {sportPillars}
                        <EventSelector scrollContainer=".viewport-time-line .content">
                            {eventRows}
                        </EventSelector>
                    </div>
                </Fragment>
            );
        }

        return (
            <div className="viewport-time-line">
                {content}
                <EventContextMenu />
                <FilterEventMessage
                    visibleEvents={visibleEvents}
                    position={pillarTop + 50}
                />
            </div>
        );
    }
}

TimeLine.propTypes = {
    momentSelectedDay: PropTypes.number.isRequired,
    rowsHeightTotal: PropTypes.number.isRequired,
    groups: PropTypes.array.isRequired,
    sports: PropTypes.object.isRequired,
    setLeftOffset: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    gadgetEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    textBannerState: state.textBanner,
    currentView: state.viewport.currentView,
    viewportState: state.viewport,

    momentSelectedDay: state.calendar.selectedDayTimestamp,
    rowsHeightTotal: state.calendar.rowsHeightTotal,
    groups: state.calendar.groupsTimeline,
    sports: state.calendar.sports,

    gadgetEnabled: state.scheduleOverview.gadgetEnabled,
});

const mapDispatchToProps = {
    setLeftOffset,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeLine);
