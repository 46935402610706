export default class StorageLocal {
    getInstance() {
        try {
            if (window && window.localStorage) {
                return window.localStorage;
            }

            return false;
        } catch (e) {
            // localStorage available, but disabled
            return false;
        }
    }

    removeItem(key) {
        const instance = this.getInstance();
        if (!instance) {
            return false;
        }
        instance.removeItem(key);
        return true;
    }

    setItem(key, value) {
        const instance = this.getInstance();
        if (!instance) {
            return false;
        }

        instance.setItem(key, value);
        return true;
    }

    getItem(key) {
        const instance = this.getInstance();
        if (!instance) {
            return false;
        }

        return instance.getItem(key);
    }

    hasItem(key) {
        const instance = this.getInstance();
        if (!instance) {
            return false;
        }

        return instance.getItem(key) !== null;
    }
}
