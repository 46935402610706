import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PackagesTable from './PackagesTable/PackagesTable.jsx';
import RemoteData from '../../../../RemoteData/RemoteData.jsx';
import ValidatorFunction from '../../../../../utils/ValidatorFunctions';
import { hasRecentlyBookedMatches } from '../../../../../selectors/shoppingBasket';
import settings from '../../../../../utils/DefaultSettings';

class ContentBookingOverview extends React.Component {
    state = {
        hasInvalidatedData: false,
    };

    componentDidUpdate(prevProps) {
        if (
            !prevProps.hasRecentlyBookedMatches &&
            this.props.hasRecentlyBookedMatches
        ) {
            this.setState({ hasInvalidatedData: true });
        } else if (this.state.hasInvalidatedData) {
            this.setState({ hasInvalidatedData: false });
        }
    }

    render() {
        const dataIsInvalid =
            this.props.hasRecentlyBookedMatches &&
            this.state.hasInvalidatedData;

        // init
        const tables = [];

        // Table: liveodds/scout packages
        tables.push(
            <RemoteData
                key="loPackagesTable"
                uri={settings.remoteData.packagesOverview.uri}
                caching={settings.remoteData.packagesOverview.caching}
                validatorFunction={ValidatorFunction.packagesOverview}
                autoErrorHandling={
                    settings.remoteData.packagesOverview.autoErrorHandling
                }
                dataIsInvalid={dataIsInvalid}
            >
                <PackagesTable
                    className="package-table-lo"
                    title="Products"
                    titleSuffix={
                        this.props.showHiddenPackages ? ' (showing hidden)' : ''
                    }
                />
            </RemoteData>
        );

        // result
        return <div>{tables}</div>;
    }
}

ContentBookingOverview.propTypes = {
    hasRecentlyBookedMatches: PropTypes.bool.isRequired,
    showHiddenPackages: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    hasRecentlyBookedMatches: hasRecentlyBookedMatches(state),
    showHiddenPackages: state.calendar.showHiddenPackages,
});

export default connect(mapStateToProps)(ContentBookingOverview);
