import settings from '../../utils/DefaultSettings';
import Xhr from '../../utils/Xhr';
import types, { type as historyTypes } from './constants';
import moment from '../../utils/CalendarMoment';

const getPayload = (type, payload = {}) => {
    return {
        ...payload,
        type,
    };
};
const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');
const bookingHistoryReq = (getState, type, search, url) => {
    return getState().calendar.auth.userInfo.bookmakerInfo.features
        .enable_lbc_history_overview &&
        isMicrofrontend &&
        type === historyTypes.LO
        ? Xhr.backendRequestLbcQuarkus(
              settings.remoteData.bookingHistory.uri,
              null,
              'GET',
              search
          )
        : getState().calendar.auth.userInfo.bookmakerInfo.features
              .enable_lbc_av_history_overview &&
          isMicrofrontend &&
          type !== historyTypes.LO
        ? Xhr.backendRequestLbcQuarkus(
              settings.remoteData.streamingBookingHistory.uri,
              null,
              'GET',
              search
          )
        : Xhr.backendRequest(url, null, 'GET', search);
};

export const loadBookingHistory =
    (from, to, type, subProductId) => (dispatch, getState) => {
        dispatch({
            type: types.LOAD_OVERVIEW_START,
            payload: getPayload(type, { loadMoreLabel: 'Loading' }),
        });

        const url =
            type === historyTypes.LO
                ? settings.remoteData.bookingHistory.uri
                : settings.remoteData.streamingBookingHistory.uri;

        const search = {
            from: from,
            to: to,
        };

        if (subProductId !== undefined) {
            search.subProductId = subProductId;
        }

        bookingHistoryReq(getState, type, search, url).then(
            response => {
                dispatch({
                    type: types.LOAD_OVERVIEW_SUCCESS,
                    payload: getPayload(type, {
                        data: response.response,
                        loadedAt: moment.now().toFormat('X'),
                    }),
                });
            },
            () => {
                dispatch({
                    type: types.LOAD_OVERVIEW_FAIL,
                    payload: getPayload(type),
                });
            }
        );
    };

export const loadMoreBookingHistory =
    (from, to, type, subProductId) => (dispatch, getState) => {
        const loadMoreStartTime = moment.now().toFormat('X');
        dispatch({
            type: types.LOAD_MORE_START,
            payload: getPayload(type, { loadMoreLabel: 'Loading' }),
        });

        const url =
            type === historyTypes.LO
                ? settings.remoteData.bookingHistory.uri
                : settings.remoteData.streamingBookingHistory.uri;

        const search = {
            from: from,
            to: to,
        };

        if (subProductId !== undefined) {
            search.subProductId = subProductId;
        }

        bookingHistoryReq(getState, type, search, url).then(
            response => {
                dispatch({
                    type: types.LOAD_MORE_SUCCESS,
                    payload: getPayload(type, {
                        data: response.response,
                        loadedAt: moment.now().toFormat('X'),
                    }),
                });
            },
            () => {
                dispatch({
                    type: types.LOAD_MORE_FAIL,
                    payload: getPayload(type),
                });
            }
        );
    };

export const setIsDisplayTimezoneMyTimeZone = isDisplayTimezoneMyTimeZone => {
    return dispatch => {
        dispatch({
            type: types.SET_IS_DISPLAY_MY_TIME_ZONE,
            payload: isDisplayTimezoneMyTimeZone,
        });
        localStorage.setItem(
            'detail_view_timezone',
            isDisplayTimezoneMyTimeZone ? 1 : 0
        );
    };
};
