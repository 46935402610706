import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../../../Alert/Alert';
import './notification.scss';

const Notification = props => {
    return (
        <div className="notification">
            {props.notifications.map((notification, index) => {
                return (
                    <Alert
                        key={index}
                        type={notification.type}
                        icon={notification.icon}
                        onClose={() => {
                            props.onClose(notification.id);
                        }}
                        onMouseLeave={() => props.onMouseLeave(notification.id)}
                        onMouseEnter={() => props.onMouseEnter(notification.id)}
                        minWidth={false}
                    >
                        {notification.message}
                    </Alert>
                );
            })}
        </div>
    );
};

Notification.propTypes = {
    notifications: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
};

export default Notification;
