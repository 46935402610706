import constants from '../../constants/Constants';
import views from '../../constants/views';

export default (currentView, status) => {
    if (
        currentView === views.VIEWPORT_TYPE_LIST_CONDENSED ||
        currentView === views.VIEWPORT_TYPE_TIMELINE_GADGET
    ) {
        return status === constants.SIDE_COLUMN_STATUS.EXPANDED;
    }

    // other views don't have a right column
    return false;
};
