import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import './hoverTooltip.scss';

class HoverTooltip extends React.Component {
    _buildContent = () => {
        const hoverInfo = this.props.hoveredElement.hoverInfo;

        if (_.isObject(hoverInfo)) {
            return _.map(hoverInfo, (text, title) => {
                return (
                    <div
                        className="hover-tooltip-paragraph"
                        key={`hover-tooltip-paragraph_${title}`}
                    >
                        <span>{title}: </span>
                        <span>{text}</span>
                    </div>
                );
            });
        } else if (_.isString(hoverInfo)) {
            return (
                <p
                    className="hover-tooltip-paragraph"
                    style={{ height: this.props.paragraphHeight }}
                >
                    {hoverInfo}
                </p>
            );
        }

        return '';
    };

    _buildStyle = height => {
        const position = this.props.position,
            hoveredElement = this.props.hoveredElement,
            offset = hoveredElement.offset;

        if (position === 'right' || position === 'left') {
            const offsetX = 10;
            return {
                left:
                    position === 'right'
                        ? offset.left + hoveredElement.width + offsetX
                        : offset.left - offsetX,
                top: offset.top - height / 2 + hoveredElement.height / 2,
            };
        } else if (position === 'above' || position === 'below') {
            const offsetY = 15;
            return {
                left: this.props.horizontalOffset,
                top:
                    position === 'above'
                        ? offset.top - (height + offsetY)
                        : offset.top + hoveredElement.height + offsetY,
            };
        }

        return {};
    };

    _buildTriangle = height => {
        const triangleStyle = {},
            triangleSize = this.props.triangleSize,
            triangleColor = '#68BEE1';

        switch (this.props.position) {
            case 'above':
                triangleStyle['top'] = height;
                triangleStyle['left'] = 20; // Offset triangle by 20px
                triangleStyle[
                    'borderLeft'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderRight'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderTop'
                ] = `${triangleSize}px solid ${triangleColor}`;

                break;
            case 'below':
                triangleStyle['top'] = -triangleSize;
                triangleStyle['left'] = 20; // Offset triangle by 20px
                triangleStyle[
                    'borderLeft'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderRight'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderBottom'
                ] = `${triangleSize}px solid ${triangleColor}`;
                break;
            case 'left':
                triangleStyle['top'] = height / 2 - triangleSize;
                triangleStyle['right'] = -triangleSize;
                triangleStyle[
                    'borderTop'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderBottom'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderLeft'
                ] = `${triangleSize}px solid ${triangleColor}`;
                break;
            case 'right':
                triangleStyle['top'] = height / 2 - triangleSize;
                triangleStyle['left'] = -triangleSize;
                triangleStyle[
                    'borderTop'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderBottom'
                ] = `${triangleSize}px solid transparent`;
                triangleStyle[
                    'borderRight'
                ] = `${triangleSize}px solid ${triangleColor}`;
                break;
            default:
                console.error(`Invalid position: ${this.props.position}`);
        }

        return <div className="hover-tooltip-triangle" style={triangleStyle} />;
    };

    render() {
        const content = this._buildContent(),
            contentLength = content.length ? content.length : 1,
            height = this.props.paragraphHeight * contentLength + 12, // add 12px to account for 2px of border and 10px of padding
            style = this._buildStyle(height),
            triangle = this._buildTriangle(height);

        return (
            <div className="hover-tooltip" style={style}>
                {content}
                {triangle}
            </div>
        );
    }
}

HoverTooltip.defaultProps = {
    horizontalOffset: 140,
    paragraphHeight: 15,
    triangleSize: 7,
    position: 'right',
    content: '',
    style: {},
    hoveredElement: {},
};

HoverTooltip.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'above', 'below']),
    hoveredElement: PropTypes.object.isRequired,
    paragraphHeight: PropTypes.number,
    horizontalOffset: PropTypes.number,
    triangleSize: PropTypes.number,
};

export default HoverTooltip;
