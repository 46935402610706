import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import constants from '../../../../../constants/Constants';
import {
    setSelectedLOSportConfigSetting,
    setShowAsBooked,
    setShowAsBookedPrimary,
    setShowAsBookedSecondary,
    setShowBookedCountsType,
    toggleHelpBalloon,
} from '../../../../../stores/calendar/actions';
import ContentBox from '../../../../ContentBox/ContentBox';
import ContentBoxTitle from '../../../../ContentBoxTitle/ContentBoxTitle';
import MatchColorPicker from '../../../../formInput/MatchColorPicker/MatchColorPicker';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import './ContentSettings.scss';

class ContentSettings extends React.Component {
    constructor(props) {
        super(props);
        this.dynamicStyles = props.dynamicStyles;
    }
    loadColorSet = (type, name) => {
        if (
            type === 'booked-secondary' &&
            this.dynamicStyles.getDynamicColor('booked-primary') === name
        )
            return;

        this.dynamicStyles.setDynamicColors(type, name);
    };

    renderColors = () => {
        const optionsList = this.props.isNewProductsUIEnabled
            ? this.dynamicStyles.newUiColors
            : this.dynamicStyles.colors;
        return (
            <div>
                {Object.entries(optionsList).map(([attributeName, options]) => (
                    <div key={attributeName} className="match-color-picker-row">
                        <div className="description">{attributeName}</div>
                        <MatchColorPicker
                            options={options}
                            attribute={attributeName}
                            onChange={this.loadColorSet}
                            isNewProductsUIEnabled={
                                this.props.isNewProductsUIEnabled
                            }
                        />
                    </div>
                ))}
            </div>
        );
    };

    renderBookedOptions = () => {
        const choices = [
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LO,
                    text: 'LO',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LD,
                    text: 'LD',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.ANY,
                    text: 'Any',
                },
            ],
            { showAsBooked: selectedUid } = this.props;

        return (
            <div>
                {_.map(choices, choice => {
                    const isSelected = choice.uid === selectedUid,
                        clickHandler = isSelected
                            ? () => {}
                            : this.createShowAsBookedHandler(choice.uid).bind(
                                  this
                              );

                    return (
                        <div
                            className={classnames('settings-option', {
                                disabled: !isSelected,
                            })}
                            key={`showAsBookedChoice${choice.uid}`}
                            onClick={clickHandler}
                        >
                            <FontIcon icon={isSelected ? '' : ''} />
                            <div className="item-row">{choice.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderBookedPrimaryOptions = () => {
        const choices = [
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LO,
                    text: 'LO',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LD,
                    text: 'LD',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.BOTH,
                    text: 'Both',
                },
            ],
            { showAsBookedPrimary: selectedUid } = this.props;

        return (
            <div>
                {_.map(choices, choice => {
                    const isSelected = choice.uid === selectedUid,
                        clickHandler = isSelected
                            ? () => {}
                            : this.createShowAsBookedHandlerForPrimary(
                                  choice.uid
                              ).bind(this);

                    return (
                        <div
                            className={classnames('settings-option', {
                                disabled: !isSelected,
                            })}
                            key={`showAsBookedChoice${choice.uid}`}
                            onClick={clickHandler}
                        >
                            <FontIcon icon={isSelected ? '' : ''} />
                            <div className="item-row">{choice.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    };
    renderBookedSecondaryOptions = () => {
        const choices = [
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LO,
                    text: 'LO',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LD,
                    text: 'LD',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.BP,
                    text: 'BP',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LCO,
                    text: 'LCO',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LCR,
                    text: 'LCR',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LCT,
                    text: 'LCT',
                },
                {
                    uid: constants.SHOW_AS_BOOKED_PRODUCT.LCP,
                    text: 'LCP',
                },
            ],
            { showAsBookedSecondary: selectedUid } = this.props;

        return (
            <div>
                {_.map(choices, choice => {
                    const isSelected = choice.uid === selectedUid,
                        clickHandler =
                            isSelected ||
                            choice.uid === this.props.showAsBookedPrimary
                                ? () => {}
                                : this.createShowAsBookedHandlerForSecondary(
                                      choice.uid
                                  ).bind(this);
                    return (
                        <div
                            className={classnames('settings-option', {
                                disabled:
                                    !isSelected ||
                                    this.props.showAsBookedPrimary ===
                                        choice.uid,
                            })}
                            key={`showAsBookedSecondaryChoice${choice.uid}`}
                            onClick={clickHandler}
                        >
                            <FontIcon icon={isSelected ? '' : ''} />
                            <div className="item-row">{choice.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderBookedCountTypes = () => {
        const choices = [
                {
                    uid: constants.SHOW_BOOKED_COUNT_TYPES.HIDDEN,
                    text: "Don't show",
                },
                {
                    uid: constants.SHOW_BOOKED_COUNT_TYPES.BADGE,
                    text: 'Show (as labels)',
                },
                {
                    uid: constants.SHOW_BOOKED_COUNT_TYPES.TEXTUAL,
                    text: 'Show (textual)',
                },
            ],
            { showBookedCountsType: selectedUid } = this.props;

        return (
            <div>
                {_.map(choices, choice => {
                    const isSelected = choice.uid === selectedUid,
                        clickHandler = isSelected
                            ? () => {}
                            : this.createBookedCountTypeHandler(
                                  choice.uid
                              ).bind(this);

                    return (
                        <div
                            className={classnames('settings-option', {
                                disabled: !isSelected,
                            })}
                            key={`bookedCountChoice${choice.uid}`}
                            onClick={clickHandler}
                        >
                            <FontIcon icon={isSelected ? '' : ''} />
                            <div className="item-row">{choice.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderSportConfigSettings = () => {
        const choices = [
            {
                uid: constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_SHOWN,
                text: 'Show matches outside my configuration (default)',
            },
            {
                uid: constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_HIDDEN,
                text: 'Hide matches outside my configuration',
            },
        ];

        return (
            <div>
                {_.map(choices, choice => {
                    const isSelected =
                        choice.uid === this.props.selectedLOSportConfigSetting;

                    return (
                        <div
                            className={classnames('settings-option', {
                                disabled: !isSelected,
                            })}
                            key={`loSportConfigChoice${choice.uid}`}
                            onClick={() => {
                                if (!isSelected) {
                                    this.props.setSelectedLOSportConfigSetting(
                                        choice.uid,
                                        this.props.currentView
                                    );
                                }
                            }}
                        >
                            <FontIcon icon={isSelected ? '' : ''} />
                            <div className="item-row">{choice.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    openHelpBalloon(event) {
        this.props.toggleHelpBalloon(
            true,
            constants.BALLOON_TYPE_LO_SPORT_CONFIG_HELP,
            { x: event.clientX, y: event.clientY }
        );
    }

    createBookedCountTypeHandler(uid) {
        return () => {
            this.props.setShowBookedCountsType(uid);
        };
    }

    createShowAsBookedHandler(uid) {
        return () => {
            this.props.setShowAsBooked(uid);
        };
    }
    createShowAsBookedHandlerForPrimary(uid) {
        return () => {
            this.props.setShowAsBookedPrimary(uid);
        };
    }
    createShowAsBookedHandlerForSecondary(uid) {
        if (uid === this.props.showAsBookedPrimary) return;
        return () => {
            this.props.setShowAsBookedSecondary(uid);
        };
    }

    render() {
        return (
            <div className="content-settings">
                <ContentBox>
                    <ContentBoxTitle>Filter settings</ContentBoxTitle>
                    <div className="list">
                        <div className="sub-header">Colors</div>
                        {this.renderColors()}
                    </div>
                    {!this.props.isNewProductsUIEnabled && (
                        <div className="list">
                            <div className="sub-header">
                                Product to show as booked
                            </div>
                            {this.renderBookedOptions()}
                        </div>
                    )}
                    {this.props.isNewProductsUIEnabled && (
                        <>
                            <div className="list">
                                <div className="sub-header">
                                    Product to show as booked (primary)
                                </div>
                                {this.renderBookedPrimaryOptions()}
                            </div>
                            <div className="list">
                                <div className="sub-header">
                                    Product to show as booked (secondary)
                                </div>
                                {this.renderBookedSecondaryOptions()}
                            </div>
                        </>
                    )}
                    <div className="list">
                        <div className="sub-header">
                            Booked Counts rendering
                        </div>
                        {this.renderBookedCountTypes()}
                    </div>
                    <div className="list">
                        <div className="sub-header">
                            Live Odds Sport Config (
                            <span
                                className="dummy-link"
                                onClick={this.openHelpBalloon.bind(this)}
                            >
                                what is this?
                            </span>
                            )
                        </div>
                        {this.renderSportConfigSettings()}
                    </div>
                </ContentBox>
            </div>
        );
    }
}

ContentSettings.propTypes = {
    showBookedCountsType: PropTypes.string.isRequired,
    selectedLOSportConfigSetting: PropTypes.string.isRequired,
    currentView: PropTypes.string.isRequired,
    toggleHelpBalloon: PropTypes.func.isRequired,
    setSelectedLOSportConfigSetting: PropTypes.func.isRequired,
    setShowBookedCountsType: PropTypes.func.isRequired,
    setShowAsBooked: PropTypes.func.isRequired,
    showAsBooked: PropTypes.string.isRequired,
    showAsBookedPrimary: PropTypes.string.isRequired,
    showAsBookedSecondary: PropTypes.string.isRequired,
    setShowAsBookedPrimary: PropTypes.func.isRequired,
    setShowAsBookedSecondary: PropTypes.func.isRequired,
    isNewProductsUIEnabled: PropTypes.bool,
    dynamicStyles: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    toggleHelpBalloon,
    setSelectedLOSportConfigSetting,
    setShowBookedCountsType,
    setShowAsBooked,
    setShowAsBookedPrimary,
    setShowAsBookedSecondary,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentSettings);
