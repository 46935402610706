import actionConstants from './constants';

export const openContextMenu = (event, x, y) => ({
    type: actionConstants.OPEN_CONTEXT_MENU,
    payload: {
        event: event,
        positionX: x,
        positionY: y,
        showContextMenu: true,
    },
});

export const closeContextMenu = () => ({
    type: actionConstants.CLOSE_CONTEXT_MENU,
    payload: {
        showContextMenu: false,
    },
});
