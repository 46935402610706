import actionConstants from './constants';

export const setCurrentPage = currentPage => ({
    type: actionConstants.SET_CURRENT_PAGE,
    payload: { currentPage },
});

export const setTotalItems = totalItems => ({
    type: actionConstants.SET_TOTAL_ITEMS,
    payload: { totalItems },
});
