import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import moment from '../../../../utils/CalendarMoment';
import { fetchChangelog } from '../../../../stores/changelog/actions';
import './changelogDialog.scss';

class ChangelogDialog extends Component {
    componentDidMount() {
        this.props.fetchChangelog();
    }

    getChangelogContent() {
        const now = moment.now().toFormat('X');

        const visibleChangelogs = this.props.changelog.filter(
            changelog =>
                changelog.featureId === 0 ||
                this.props.enabledFeatures.includes(changelog.featureId)
        );

        if (visibleChangelogs.length === 0) {
            return null;
        }

        visibleChangelogs.sort((changelog1, changelog2) => {
            return changelog2.releaseDate - changelog1.releaseDate;
        });

        return (
            <table>
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Changelog</th>
                    </tr>
                    {visibleChangelogs.map(
                        changelog =>
                            changelog.releaseDate <= now && (
                                <tr key={changelog.id}>
                                    <td className="date">
                                        {moment
                                            .fromTimestamp(
                                                changelog.releaseDate
                                            )
                                            .toFormat('dd/MM/yyyy')}
                                    </td>
                                    <td>{changelog.description}</td>
                                </tr>
                            )
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <Confirmation
                onConfirm={this.props.onClose}
                confirmText={'Close'}
                showCancelButton={false}
            >
                <div className="changelogs">{this.getChangelogContent()}</div>
            </Confirmation>
        );
    }
}

ChangelogDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    changelog: PropTypes.array.isRequired,
    enabledFeatures: PropTypes.array.isRequired,
    fetchChangelog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    changelog: state.changelog.entries,
    enabledFeatures: Object.values(
        state.calendar.auth.userInfo.bookmakerInfo.features
    ),
});

const mapDispatchToProps = {
    fetchChangelog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangelogDialog);
