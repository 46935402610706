import { addPrefix } from '../../utils/storeUtils';

export default addPrefix('booking_history', {
    LOAD_OVERVIEW_START: 'LOAD_OVERVIEW_START',
    LOAD_OVERVIEW_SUCCESS: 'LOAD_OVERVIEW_SUCCESS',
    LOAD_OVERVIEW_FAIL: 'LOAD_OVERVIEW_FAIL',
    LOAD_MORE_START: 'LOAD_MORE_START',
    LOAD_MORE_SUCCESS: 'LOAD_MORE_SUCCESS',
    LOAD_MORE_FAIL: 'LOAD_MORE_FAIL',
    SET_IS_DISPLAY_MY_TIME_ZONE: 'SET_IS_DISPLAY_MY_TIME_ZONE',
});

export const type = {
    LCO: 'LCO',
    LCT: 'LCT',
    LCR: 'LCR',
    LO: 'LO',
    LCP: 'LCP',
};
