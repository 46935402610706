import { addPrefix } from '../../utils/storeUtils';

export default addPrefix('changelog', {
    FETCH: 'FETCH',
    START_CREATION: 'START_CREATION',
    CREATE: 'CREATE',
    START_UPDATE: 'START_UPDATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
});
