import PropTypes from 'prop-types';
import React from 'react';
import CheckBoxIcon from '../icons/CheckBoxIcon/CheckBoxIcon';
import './productBookingStatus.scss';

function ProductBookingStatus(props) {
    const products = ['lo', 'ld', 'bp', 'lco', 'lcr', 'lct'];

    function getProductBookingStatus(prod) {
        const product = props.event.products[prod];
        if (!product) return;

        const bookingStatusId = product.bookingStatusId;
        const variant = Object.values(props.event.products).filter(
            product =>
                product.type.includes(`${prod}-4sight`) ||
                product.type.includes(`${prod}-as`)
        );

        let isRecommended = false;
        if (
            props.recommenderSettings?.showRecommended &&
            props.recommenderSettings.matchIds.includes(props.event.uri)
        ) {
            isRecommended = true;
        }

        return getCheckBox(product, bookingStatusId, isRecommended, variant);
    }

    function getCheckBox(product, bookingStatusId, isRecommended, variant) {
        let checkBoxColor = '';
        let checkBoxFilled = false;
        let className = '';
        let showStar = false;
        let hasVariantIcon = false;
        let title;
        if (props.inTimeLine) {
            return (
                <div
                    icon=""
                    className={`prod-box ${
                        bookingStatusId === 2 ? 'green' : ''
                    }`}
                ></div>
            );
        }
        if (product.clientPackageType === 3 && product.type === 'lco') {
            className =
                bookingStatusId === 1 ? 'bookable_optional' : 'booked_optional';
            title =
                bookingStatusId === 1
                    ? 'Bookable optional content'
                    : 'Booked optional content';
            return (
                <div
                    className={`optional-content ${className}`}
                    title={title}
                />
            );
        }
        if (isRecommended) {
            showStar = setShowStar(product, showStar);
        }
        if (variant.length > 0) {
            // for reference:
            // Both booked = 22
            // Both bookable = 11
            // Both buyable = 33
            // Booked Bookable = 12
            // Booked Buyable = 23
            // Bookable Buyable = 13

            const bookingStatus = [bookingStatusId, variant[0].bookingStatusId]
                .sort()
                .join('');
            // const bookingStatus = bookingStatusSorted.join('')
            // `${bookingStatusId}${variant[0].bookingStatusId}`

            switch (bookingStatus) {
                case '22':
                    checkBoxColor = 'color-green-700';
                    checkBoxFilled = true;
                    className = 'booked';
                    title = 'Both feeds booked';
                    break;
                case '11':
                    checkBoxColor = 'color-green-700';
                    checkBoxFilled = false;
                    className = 'bookable';
                    title = 'Both feeds Bookable';
                    break;
                case '33':
                    checkBoxColor = 'color-orange-700';
                    checkBoxFilled = false;
                    className = 'buyable';
                    title = 'Both feeds Buyable';
                    break;
                case '12':
                    hasVariantIcon = true;
                    className = 'booked-bookable';
                    title = 'Booked Bookable';
                    break;
                case '23':
                    hasVariantIcon = true;
                    className = 'booked-buyable';
                    title = 'Booked Buyable';
                    break;
                case '13':
                    hasVariantIcon = true;
                    className = 'bookable-buyable';
                    title = 'Bookable Buyable';
                    break;
                default:
                    return '';
            }
        } else {
            switch (bookingStatusId) {
                case 1: //Bookable
                    checkBoxColor = 'color-green-700';
                    checkBoxFilled = false;
                    className = 'bookable';
                    title = 'Bookable';
                    break;
                case 2: //Booked
                    checkBoxColor = 'color-green-700';
                    checkBoxFilled = true;
                    className = 'booked';
                    title = 'Booked';
                    break;
                case 3: //Buyable
                    checkBoxColor = 'color-orange-700';
                    checkBoxFilled = false;
                    className = 'buyable';
                    title = 'Buyable';
                    break;
                default:
                    return '';
            }
        }
        return (
            <CheckBoxIcon
                filled={checkBoxFilled}
                color={showStar ? 'color-orange-recommender' : checkBoxColor}
                className={className}
                title={title}
                isRecommended={showStar}
                hasVariantIcon={hasVariantIcon}
            />
        );
    }

    function setShowStar(product, showStar) {
        if (
            props.recommenderSettings.selectedPriority === 'mts' &&
            ['lo', 'ld'].includes(product.type)
        ) {
            showStar = true;
        }
        if (
            props.recommenderSettings.selectedPriority === 'lco' &&
            ['lo', 'ld', 'lco'].includes(product.type)
        ) {
            showStar = true;
        }
        return showStar;
    }

    function getNumberProductsBooked() {
        let productsBooked = 0;
        products.forEach(prod => {
            if (
                props.event.products[prod] &&
                props.event.products[prod].bookingStatusId === 2
            ) {
                productsBooked++;
            }
        });
        return productsBooked;
    }

    function renderProductCheckBoxes() {
        if (!props.inTimeLine) {
            return products.map((prod, index) => (
                <div className={prod} key={index}>
                    {getProductBookingStatus(prod)}
                </div>
            ));
        } else if (props.isExtraSmall && !props.isExpanded) {
            return (
                <div className="product-booking-status">
                    <div className="product-number">
                        {getNumberProductsBooked()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="product-booking-status">
                    {products.map((prod, index) => (
                        <div className={prod} key={index}>
                            {getProductBookingStatus(prod)}
                        </div>
                    ))}
                </div>
            );
        }
    }

    return renderProductCheckBoxes();
}

ProductBookingStatus.propTypes = {
    recommenderSettings: PropTypes.object,
    event: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    isExtraSmall: PropTypes.bool,
    isExtraExtraSmall: PropTypes.bool,
    isExpanded: PropTypes.bool,
    inTimeLine: PropTypes.bool,
};

export default ProductBookingStatus;
