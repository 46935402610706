import _ from 'underscore';
import constants from '../constants/Constants';
import FilterConstants from '../constants/FilterConstants';
import { AV_PRODUCT } from '../constants/audioVisual';
import { BOOKING_STATUS } from '../constants/shoppingBasket';
import { simulatedCategories } from '../constants/sports';
import FilterSelection from '../models/FilterSelection';
import defaultSettings from './DefaultSettings';

export const isVisible = function (
    checkbox,
    event,
    selectedProducts,
    recommenderSettings
) {
    if (!selectedProducts.length) {
        // if no product is selected, treat it as all products selected
        selectedProducts = ['lo', 'ld', 'bp', 'lco', 'lcr', 'lct', 'lcp'];
    }
    if (selectedProducts.includes('lco')) {
        selectedProducts = [...selectedProducts, 'lco-4sight', 'lco-as'];
    }
    let eventIsVisible = false;
    switch (checkbox.groupId) {
        case constants.FILTER_GROUP_SPORTS:
            eventIsVisible = event.sportId === checkbox.value;
            break;
        case constants.FILTER_GROUP_MATCHES:
            for (const prod of selectedProducts) {
                const product = prod.toLowerCase();
                const value =
                    FilterConstants.availabilityStatusMap[checkbox.value];

                if (
                    checkbox.value ===
                    FilterConstants.availabilities.RECOMMENDED_MATCHES
                ) {
                    eventIsVisible = recommenderSettings?.matchIds.includes(
                        event.uri
                    );
                    break;
                } else if (
                    event.products[product]
                        ? event.products[product].bookingStatusId === value
                        : value === BOOKING_STATUS.NOT_AVAILABLE
                ) {
                    eventIsVisible = true;
                    break;
                }
            }
            break;
        case constants.FILTER_GROUP_SEGMENT_TYPE:
            eventIsVisible =
                (event.products.lo &&
                    event.products.lo.vbpType === Number(checkbox.value)) ||
                (event.products.ld &&
                    event.products.ld.vbpType === Number(checkbox.value)) ||
                (Number(checkbox.value) === 0 &&
                    ((event.products.lo && event.products.lo.vbpType === 0) ||
                        (event.products.ld &&
                            event.products.ld.vbpType === 0)));

            break;
        case constants.FILTER_GROUP_COVERED_FROM:
            eventIsVisible =
                (event.products.lo || event.products.ld) &&
                event.coveredFrom === Number(checkbox.value);
            break;
        case constants.FILTER_GROUP_COVERED_WITH:
            switch (checkbox.subGroupId) {
                case constants.FILTER_SUBGROUP_LIVEDATA:
                    eventIsVisible =
                        event.products.ld &&
                        event.products.ld.coveredWith.indexOf(checkbox.value) >=
                            0;
                    break;
                case constants.FILTER_SUBGROUP_LIVEODDS:
                    eventIsVisible =
                        event.products.lo &&
                        event.products.lo.coveredWith.indexOf(checkbox.value) >=
                            0;
                    break;
                case constants.FILTER_SUBGROUP_LIVECHANNEL:
                    if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_ONLINE
                    ) {
                        eventIsVisible =
                            typeof event.products.lco !== 'undefined';
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_RETAIL
                    ) {
                        eventIsVisible =
                            typeof event.products.lcr !== 'undefined';
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_TRADING
                    ) {
                        eventIsVisible =
                            typeof event.products.lct !== 'undefined';
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_PROMOTION
                    ) {
                        eventIsVisible =
                            typeof event.products.lcp !== 'undefined';
                    } else {
                        console.warn(
                            'coverage not identified.',
                            checkbox.value
                        );
                    }
                    break;
                case constants.FILTER_SUBGROUP_LIVECHANNEL_VARIANT:
                    if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_STANDARD
                    ) {
                        eventIsVisible = Object.keys(event.products).some(
                            product => ['lco', 'lct', 'lcr'].includes(product)
                        );
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_MIXED
                    ) {
                        eventIsVisible = Object.keys(event.products).some(
                            product =>
                                product.includes('as') ||
                                product.includes('4sight')
                        );
                    } else {
                        console.warn(
                            'coverage not identified.',
                            checkbox.value
                        );
                    }
                    break;
                case constants.FILTER_SUBGROUP_LIVECHANNEL_QUALITY:
                    if (
                        checkbox.value ===
                        FilterConstants.coveredWith
                            .LIVE_CHANNEL_STANDARD_QUALITY
                    ) {
                        eventIsVisible = event.products['lco']?.addOns?.find(
                            addOn =>
                                addOn.name === 'HIGH_QUALITY' &&
                                addOn.value === 'false'
                        );
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.LIVE_CHANNEL_HIGH_QUALITY
                    ) {
                        eventIsVisible = event.products['lco']?.addOns?.find(
                            addOn =>
                                addOn.name === 'HIGH_QUALITY' &&
                                addOn.value === 'true'
                        );
                    } else {
                        console.warn(
                            'coverage not identified.',
                            checkbox.value
                        );
                    }
                    break;
                case constants.FILTER_SUBGROUP_LIVEODDS_FEED:
                    eventIsVisible =
                        event.products.lo &&
                        event.products.lo?.coverageProvider?.includes(
                            checkbox.value
                        );

                    break;
                case constants.FILTER_SUBGROUP_LIVECHANNEL_PROMOTION:
                    if (checkbox.value === FilterConstants.coveredWith.CLIPS) {
                        eventIsVisible = event.products[
                            'lcp'
                        ]?.preBookingEventContentTypes?.find(
                            contentType =>
                                contentType.name ===
                                FilterConstants.coveredWith.CLIPS
                        );
                    } else if (
                        checkbox.value ===
                        FilterConstants.coveredWith.HIGHLIGHTS
                    ) {
                        eventIsVisible = event.products[
                            'lcp'
                        ]?.preBookingEventContentTypes?.find(
                            contentType =>
                                contentType.name ===
                                FilterConstants.coveredWith.HIGHLIGHTS
                        );
                    } else {
                        console.warn(
                            'coverage not identified.',
                            checkbox.value
                        );
                    }
                    break;
                default:
                    console.warn(
                        'sub group id not identified.',
                        checkbox.subGroupId
                    );
            }
            break;
        case constants.FILTER_GROUP_AGE_GROUPS:
            eventIsVisible = checkbox.value
                .toLowerCase()
                .includes(event.ageGroup.match.toLowerCase());
            break;
        case constants.FILTER_GROUP_FREETEXT:
            if (Array.isArray(checkbox.value)) {
                eventIsVisible = [
                    event.uri,
                    event.homeTeam,
                    event.awayTeam,
                    event.realCategoryName,
                    event.tournamentName,
                ].some(possibleValue => {
                    return checkbox.value.some(userInputValue => {
                        return (
                            String(possibleValue)
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .toLowerCase()
                                .indexOf(
                                    String(userInputValue)
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .toLowerCase()
                                ) >= 0
                        );
                    });
                });
            }
            break;
        case constants.FILTER_GROUP_SPORT_TYPE:
            if (simulatedCategories.includes(event.realCategoryId)) {
                eventIsVisible =
                    checkbox.value === FilterConstants.sportType.simulated;
            } else {
                eventIsVisible =
                    checkbox.value === FilterConstants.sportType.real;
            }
            break;
        default:
            eventIsVisible = true;
            console.error(`Invalid group id: ${checkbox.groupId}`);
    }

    return eventIsVisible;
};

export const getIdentifier = function (checkbox) {
    return `${checkbox.groupId}_${checkbox.value}`;
};

class FilterUtils {
    getNewCheckboxList(sports, isReseller = false, isDefaultSegment) {
        const collection = this.getSportCheckboxesOrdered(sports);

        const addNewCheckboxGroup = (groupId, subGroupId, checkBoxList) => {
            checkBoxList.forEach(item => {
                collection.push({
                    groupId,
                    subGroupId,
                    name: item.name,
                    value: item.key,
                    disabled: false,
                    title: item.title,
                });
            });
        };

        addNewCheckboxGroup(
            constants.FILTER_GROUP_MATCHES,
            constants.FILTER_SUBGROUP_MATCHES,
            [
                {
                    key: FilterConstants.availabilities.MY_MATCHES,
                    name: 'My Matches',
                },
                {
                    key: FilterConstants.availabilities.AVAILABLE_MATCHES,
                    name: 'Available Matches',
                },
                {
                    key: FilterConstants.availabilities.BUYABLE_MATCHES,
                    name: 'Buyable Matches',
                },
                {
                    key: FilterConstants.availabilities.UNAVAILABLE_MATCHES,
                    name: 'Unavailable Matches',
                },
                {
                    key: FilterConstants.availabilities.RECOMMENDED_MATCHES,
                    name: 'Recommended Matches',
                },
            ]
        );

        // LCO, LCR and LCT groups
        for (const productId in AV_PRODUCT.SHORTNAME) {
            if (!AV_PRODUCT.SHORTNAME.hasOwnProperty(productId)) {
                continue;
            }

            const productAbbr = AV_PRODUCT.SHORTNAME[productId];

            let liveChannelCheckboxes = [
                {
                    key: FilterConstants.lcBookingStatus[productAbbr + '_MY'],
                    name: 'My matches',
                },
                {
                    key: FilterConstants.lcBookingStatus[
                        productAbbr + '_AVAILABLE'
                    ],
                    name: 'Available matches',
                },
            ];

            if (!isReseller) {
                liveChannelCheckboxes = liveChannelCheckboxes.concat([
                    {
                        key: FilterConstants.lcBookingStatus[
                            productAbbr + '_BUYABLE'
                        ],
                        name: 'Buyable matches',
                    },
                    {
                        key: FilterConstants.lcBookingStatus[
                            productAbbr + '_UNAVAILABLE'
                        ],
                        name: 'Unavailable matches',
                    },
                    {
                        key: FilterConstants.lcBookingStatus[
                            productAbbr + '_RECOMMENDED'
                        ],
                        name: 'Recommended matches',
                    },
                ]);
            }

            addNewCheckboxGroup(
                constants[`FILTER_GROUP_${productAbbr}_MATCHES`],
                constants[`FILTER_SUBGROUP_${productAbbr}_MATCHES`],
                liveChannelCheckboxes
            );
        }

        // Package type
        if (!isDefaultSegment) {
            addNewCheckboxGroup(
                constants.FILTER_GROUP_SEGMENT_TYPE,
                constants.FILTER_SUBGROUP_SEGMENT_TYPE,
                [
                    { key: FilterConstants.vbpType.MVP, name: 'MVP' },
                    { key: FilterConstants.vbpType.STAR, name: 'STAR' },
                    {
                        key: FilterConstants.vbpType.Other,
                        name: 'Professional/Other',
                    },
                ]
            );
        }

        // covered from
        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_FROM,
            constants.FILTER_SUBGROUP_COVERED_FROM,
            [
                { key: FilterConstants.coveredFrom.TV, name: 'Tv' },
                { key: FilterConstants.coveredFrom.VENUE, name: 'Venue' },
            ]
        );

        // covered with
        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVEODDS,
            [
                { key: FilterConstants.coveredWith.LIVEODDS, name: 'Regular' },
                {
                    key: FilterConstants.coveredWith.LIVEODDS_EXTENDED,
                    name: 'Extended markets',
                },
            ]
        );

        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVEDATA,
            [
                {
                    key: FilterConstants.coveredWith.SCOUT_BASIC,
                    name: 'Basic coverage',
                },
                {
                    key: FilterConstants.coveredWith.SCOUT_DEEPER,
                    name: 'Deeper coverage',
                },
                {
                    key: FilterConstants.coveredWith.RTS,
                    name: 'RTS',
                },
            ]
        );

        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVECHANNEL,
            [
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_ONLINE,
                    name: 'Online',
                },
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_RETAIL,
                    name: 'Retail',
                },
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_TRADING,
                    name: 'Trading',
                },
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_PROMOTION,
                    name: 'Promotion',
                },
            ]
        );

        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVECHANNEL_VARIANT,
            [
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_STANDARD,
                    name: 'Standard feed',
                },
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_MIXED,
                    name: constants.AV_4SIGHT + ' feed',
                },
            ]
        );
        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVECHANNEL_QUALITY,
            [
                {
                    key: FilterConstants.coveredWith
                        .LIVE_CHANNEL_STANDARD_QUALITY,
                    name: 'Standard Quality',
                },
                {
                    key: FilterConstants.coveredWith.LIVE_CHANNEL_HIGH_QUALITY,
                    name: 'High Quality',
                },
            ]
        );
        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVEODDS_FEED,
            [
                {
                    key: FilterConstants.coveredWith.PREMIUM_FEED,
                    name: 'Premium Feed',
                },
                {
                    key: FilterConstants.coveredWith.STANDARD_FEED,
                    name: 'Standard Feed',
                },
            ]
        );
        addNewCheckboxGroup(
            constants.FILTER_GROUP_COVERED_WITH,
            constants.FILTER_SUBGROUP_LIVECHANNEL_PROMOTION,
            [
                {
                    key: FilterConstants.coveredWith.CLIPS,
                    name: 'Clips',
                },
                {
                    key: FilterConstants.coveredWith.HIGHLIGHTS,
                    name: 'Highlights',
                },
            ]
        );

        addNewCheckboxGroup(
            constants.FILTER_GROUP_AGE_GROUPS,
            constants.FILTER_SUBGROUP_AGE_GROUPS,
            [
                {
                    key: FilterConstants.ageGroups.Senior,
                    name: 'Senior',
                },
                {
                    key: FilterConstants.ageGroups.U23,
                    name: 'U23',
                },
                {
                    key: FilterConstants.ageGroups.U22,
                    name: 'U22', // Also includes "Youth"
                },
                {
                    key: FilterConstants.ageGroups.U21,
                    name: 'U21',
                },
                {
                    key: FilterConstants.ageGroups.U20,
                    name: 'U20',
                },
                {
                    key: FilterConstants.ageGroups.U19,
                    name: 'U19',
                },
                {
                    key: FilterConstants.ageGroups.U18,
                    name: 'U18 and below', // Also includes "Junior"
                },
                {
                    key: FilterConstants.ageGroups.Unconfirmed,
                    name: 'Unconfirmed',
                    title: 'Unconfirmed is used for fixtures with no age group applicable',
                },
            ]
        );

        addNewCheckboxGroup(
            constants.FILTER_GROUP_SPORT_TYPE,
            constants.FILTER_SUBGROUP_SPORT_TYPE,
            [
                {
                    key: FilterConstants.sportType.real,
                    name: 'Real',
                },
                {
                    key: FilterConstants.sportType.simulated,
                    name: 'Simulated',
                },
            ]
        );

        return collection;
    }

    getSportCheckboxesOrdered(sports) {
        const checkboxes = [];
        for (const sportId in sports) {
            if (!sports.hasOwnProperty(sportId)) {
                continue;
            }

            const sport = sports[sportId];
            let subGroupId;
            if (sport['isEsport']) {
                subGroupId = constants.FILTER_SUBGROUP_SPORTS_ELECTRONIC;
            } else if (sport['isEleague']) {
                subGroupId =
                    constants.FILTER_SUBGROUP_SPORTS_ELECTRONIC_LEAGUES;
            } else {
                subGroupId = constants.FILTER_SUBGROUP_SPORTS_REGULAR;
            }

            checkboxes.push({
                groupId: constants.FILTER_GROUP_SPORTS,
                subGroupId,
                name: sport.name,
                value: sport.id,
                order: sport.order,
                disabled: false,
            });
        }

        return checkboxes.sort((a, b) => {
            const aSportIdIndex = a.order;
            const bSportIdIndex = b.order;

            if (aSportIdIndex < bSportIdIndex) {
                return -1;
            } else if (bSportIdIndex < aSportIdIndex) {
                return 1;
            }

            return 0;
        });
    }

    getString(selectedCheckboxes) {
        return selectedCheckboxes
            .map(checkbox => {
                if (
                    typeof FilterConstants.map[checkbox.value] === 'undefined'
                ) {
                    return '';
                }

                return FilterConstants.map[checkbox.value].char;
            })
            .sort()
            .join('');
    }

    getInGroup(checkboxList, groupId) {
        return checkboxList.filter(checkbox => {
            return checkbox.groupId === groupId;
        });
    }

    buildFilterSaveJson(
        selectedCheckboxes,
        searchPhrase,
        selectedProducts,
        ncaaFilter
    ) {
        const filter = {};

        if (searchPhrase.length) {
            filter.searchPhrase = searchPhrase;
        }

        if (selectedCheckboxes.length) {
            filter.selectedCheckbox = selectedCheckboxes.map(checkbox => {
                return {
                    groupId: checkbox.groupId,
                    id: checkbox.value,
                };
            });
        }

        if (selectedProducts.length) {
            filter.selectedProducts = selectedProducts;
        }

        if (this.anyNcaaFilterSet(ncaaFilter)) {
            filter.ncaaFilter = ncaaFilter;
        }

        return JSON.stringify(filter);
    }

    getDefaultFilterSelection(isReseller) {
        const selection = new FilterSelection(
            defaultSettings.defaultFilterSetName
        );

        selection.setSelectedCheckbox(
            constants.FILTER_GROUP_MATCHES,
            FilterConstants.availabilities.MY_MATCHES
        );
        if (!isReseller) {
            selection.setSelectedCheckbox(
                constants.FILTER_GROUP_MATCHES,
                FilterConstants.availabilities.AVAILABLE_MATCHES
            );
        }

        return selection;
    }

    getFilterSelectionFromResponse(filter) {
        const { name, id, filterSettings: filterJson } = filter,
            selection = new FilterSelection(name, id);
        let filterSettings;

        try {
            filterSettings = JSON.parse(filterJson);
        } catch (e) {
            console.error('invalid json');
            return selection;
        }

        if (
            filterSettings.searchPhrase &&
            filterSettings.searchPhrase.length > 0
        ) {
            if (Array.isArray(filterSettings.searchPhrase)) {
                selection.setSearchPhrase(filterSettings.searchPhrase);
            } else {
                selection.setSearchPhrase([filterSettings.searchPhrase]);
            }
        }

        if (
            filterSettings.selectedCheckbox &&
            filterSettings.selectedCheckbox.length
        ) {
            filterSettings.selectedCheckbox.forEach(selected => {
                selection.setSelectedCheckbox(selected.groupId, selected.id);
            });
        }

        if (
            filterSettings.selectedProducts &&
            filterSettings.selectedProducts.length
        ) {
            selection.setSelectedProducts(filterSettings.selectedProducts);
        }

        if (
            filterSettings.ncaaFilter &&
            this.anyNcaaFilterSet(filterSettings.ncaaFilter)
        ) {
            selection.setNcaaFilter(filterSettings.ncaaFilter);
        }

        return selection;
    }

    getSubGroupHeaderText(subGroupId) {
        const map = {
            [constants.FILTER_SUBGROUP_SPORTS_ELECTRONIC_LEAGUES]: 'E-leagues',
            [constants.FILTER_SUBGROUP_SPORTS_ELECTRONIC]: 'E-sports',
            [constants.FILTER_SUBGROUP_LIVEODDS]: 'Live Odds',
            [constants.FILTER_SUBGROUP_LIVEDATA]: 'Live Data',
            [constants.FILTER_SUBGROUP_LIVECHANNEL]: 'Live Channel',
            [constants.FILTER_SUBGROUP_LIVECHANNEL_VARIANT]:
                'Live Channel Variant',
            [constants.FILTER_SUBGROUP_LIVECHANNEL_QUALITY]:
                'Live Channel Quality',
            [constants.FILTER_SUBGROUP_LIVEODDS_FEED]: 'Live Odds Feed',
            [constants.FILTER_SUBGROUP_LIVECHANNEL_PROMOTION]:
                'Live Channel Promotion',
        };

        return map.hasOwnProperty(subGroupId) ? map[subGroupId] : false;
    }

    getSubGroupId(filter) {
        if (
            [
                FilterConstants.coveredWith.LIVEODDS,
                FilterConstants.coveredWith.LIVEODDS_EXTENDED,
            ].includes(filter)
        ) {
            return 'Live Odds';
        } else if (
            [
                FilterConstants.coveredWith.SCOUT_BASIC,
                FilterConstants.coveredWith.SCOUT_DEEPER,
                FilterConstants.coveredWith.RTS,
            ].includes(filter)
        ) {
            return 'Live Data';
        } else if (
            [
                FilterConstants.coveredWith.LIVE_CHANNEL_ONLINE,
                FilterConstants.coveredWith.LIVE_CHANNEL_RETAIL,
                FilterConstants.coveredWith.LIVE_CHANNEL_TRADING,
                FilterConstants.coveredWith.LIVE_CHANNEL_PROMOTION,
            ].includes(filter)
        ) {
            return 'Live Channel';
        } else if (
            [
                FilterConstants.coveredWith.LIVE_CHANNEL_STANDARD,
                FilterConstants.coveredWith.LIVE_CHANNEL_MIXED,
            ].includes(filter)
        ) {
            return 'Live Channel Variant';
        } else if (
            [
                FilterConstants.coveredWith.LIVE_CHANNEL_STANDARD_QUALITY,
                FilterConstants.coveredWith.LIVE_CHANNEL_HIGH_QUALITY,
            ].includes(filter)
        ) {
            return 'Live Channel Quality';
        } else if (
            [
                FilterConstants.coveredWith.PREMIUM_FEED,
                FilterConstants.coveredWith.STANDARD_FEED,
            ].includes(filter)
        ) {
            return 'Live Odds Feed';
        } else if (
            [
                FilterConstants.coveredWith.CLIPS,
                FilterConstants.coveredWith.HIGHLIGHTS,
            ].includes(filter)
        ) {
            return 'Live Channel Promotion';
        }

        return '';
    }

    anyNcaaFilterSet(ncaaFilter) {
        return (
            (_.keys(ncaaFilter).length &&
                ncaaFilter.divisions &&
                ncaaFilter.divisions.length) ||
            (ncaaFilter.venueStates && ncaaFilter.venueStates.length) ||
            (ncaaFilter.teamStates && ncaaFilter.teamStates.length)
        );
    }
}

const filterUtils = new FilterUtils();
export default filterUtils;
