import actionConstants from './constants';

export const saveAttributeSet = (name, attributeSet) => ({
    type: actionConstants.SAVE_ATTRIBUTE_SET,
    payload: { name, attributeSet },
});

export const removeAttributeSet = name => ({
    type: actionConstants.REMOVE_ATTRIBUTE_SET,
    payload: { name },
});

export const setDefaultAttributeSet = name => ({
    type: actionConstants.SET_DEFAULT_ATTRIBUTE_SET,
    payload: { name },
});
