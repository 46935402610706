import types from './constants';

const initialState = {
    notifications: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.CLOSE:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.id !== action.payload.id
                ),
            };
        case types.CREATE:
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        default:
            return state || initialState;
    }
};
