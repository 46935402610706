import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';
import Button from '../../formInput/Button/Button';
import './confirmation.scss';

const Confirmation = props => {
    const {
        onConfirm = () => null,
        onReject = () => null,
        confirmText = 'Confirm',
        rejectText = 'Cancel',
        showCancelButton = true,
        confirmButtonDisabled = false,
        footerProp = null,
        width = 550,
    } = props;

    let footer = footerProp;
    if (footer === null) {
        footer = (
            <Footer>
                <Button
                    onClick={onConfirm}
                    type={Button.types.LARGE_BLUE_SHADOW}
                    icon={''}
                    fixedWidth={false}
                    disabled={confirmButtonDisabled}
                >
                    {confirmText}
                </Button>
                {showCancelButton ? (
                    <Button
                        onClick={onReject}
                        type={Button.types.LARGE_GREY_SHADOW}
                        icon={''}
                        fixedWidth={false}
                    >
                        {rejectText}
                    </Button>
                ) : (
                    ''
                )}
            </Footer>
        );
    }
    return (
        <div className="confirm-dialog" style={{ width: width }}>
            <div className="content">{props.children}</div>
            {footer}
        </div>
    );
};

Confirmation.propTypes = {
    children: PropTypes.node.isRequired,
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
    confirmText: PropTypes.string,
    rejectText: PropTypes.string,
    showCancelButton: PropTypes.bool,
    confirmButtonDisabled: PropTypes.bool,
    footerProp: PropTypes.node,
    width: PropTypes.number,
};

export default Confirmation;
