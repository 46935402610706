import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import constants from '../../../../constants/Constants';
import views from '../../../../constants/views';
import canLiveBook from '../../../../selectors/canLiveBook';
import { setLeftColumnStatus } from '../../../../stores/calendar/actions';
import { initialize, setTab } from '../../../../stores/leftColumn/actions';
import { tabs } from '../../../../stores/leftColumn/constants';
import DynamicStyles from '../../../../utils/DynamicStyles.js';
import Tabs from '../../../Tabs/Tabs.jsx';
import BookingRecommender from './BookingRecommender/BookingRecommender';
import ContentBookingOverview from './ContentBookingOverview/ContentBookingOverview.jsx';
import ContentFilters from './ContentFilters/ContentFilters';
import ContentSettings from './ContentSettings/ContentSettings.jsx';
import './LeftColumn.scss';

const { EXPANDED, COLLAPSED } = constants.SIDE_COLUMN_STATUS;

class LeftColumn extends React.Component {
    constructor(props) {
        super(props);
        this.dynamicStyles = new DynamicStyles(props.isNewProductsUIEnabled);
    }
    componentDidMount() {
        const defaults = {};
        Object.keys(this._getTabs()).forEach(viewId => {
            const viewData = this._getTabs()[viewId];
            let memory = viewId,
                tabValue = viewData.defaultTab;

            if (viewData.sharedMemory) {
                memory = viewData.sharedMemory;
            }

            if (!tabValue && viewData.tabs.length > 0) {
                tabValue = viewData.tabs[0].value;
            }

            if (memory && tabValue) {
                defaults[memory] = tabValue;
            }
        });
        this.props.initialize(defaults);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isNewProductsUIEnabled !==
            this.props.isNewProductsUIEnabled
        ) {
            this.dynamicStyles = new DynamicStyles(
                this.props.isNewProductsUIEnabled
            );
        }
    }

    _getTabs() {
        return {
            [views.VIEWPORT_TYPE_LIST_CONDENSED]: {
                tabs: this._getEventViewTabs(),
                sharedMemory: 'event_views',
                defaultTab: tabs.FILTERS,
            },
            [views.VIEWPORT_TYPE_TIMELINE_GADGET]: {
                tabs: this._getEventViewTabs(),
                sharedMemory: 'event_views',
            },
        };
    }

    _getEventViewTabs() {
        return [
            {
                text: 'filters',
                icon: '',
                value: tabs.FILTERS,
                component: ContentFilters,
            },
            {
                text: 'packages',
                icon: '',
                value: tabs.PACKAGES,
                component: ContentBookingOverview,
            },
            {
                text: 'recommender',
                icon: '',
                value: tabs.RECOMMENDER,
                component: BookingRecommender,
                dynamicStyles: this.dynamicStyles,
            },
            {
                text: '',
                icon: '',
                value: tabs.SETTINGS,
                component: ContentSettings,
                componentProperties: {
                    showBookedCountsType: this.props.showBookedCountsType,
                    selectedLOSportConfigSetting:
                        this.props.selectedLOSportConfigSetting,
                    currentView: this.props.currentView,
                    showAsBooked: this.props.showAsBooked,
                    showAsBookedPrimary: this.props.showAsBookedPrimary,
                    showAsBookedSecondary: this.props.showAsBookedSecondary,
                    isNewProductsUIEnabled: this.props.isNewProductsUIEnabled,
                    dynamicStyles: this.dynamicStyles,
                },
            },
        ];
    }

    _getSelectedTab() {
        const viewMemory = this._getCurrentViewMemory(),
            selectedTab = this.props.selectedTabs[viewMemory];

        if (!selectedTab) {
            return null;
        }

        return selectedTab;
    }

    _getCurrentViewMemory() {
        const viewTabsData = this._getCurrentViewTabData();

        if (!viewTabsData.sharedMemory) {
            return this.props.currentView;
        }

        return viewTabsData.sharedMemory;
    }

    _getCurrentViewTabData() {
        const viewTabsData = this._getTabs()[this.props.currentView];

        if (!viewTabsData || !viewTabsData.tabs) {
            return {
                tabs: [],
            };
        }

        return viewTabsData;
    }

    _getCurrentViewTabs() {
        return this._getCurrentViewTabData()['tabs'];
    }

    _getCurrentTabComponentProperties() {
        const tab = this._getCurrentTab();
        return _.isNull(tab) ? {} : tab.componentProperties || {};
    }

    _getCurrentTabComponent() {
        const tab = this._getCurrentTab();
        return _.isNull(tab) ? null : tab.component;
    }

    _getCurrentTab() {
        const currentTabs = this._getCurrentViewTabs(),
            selectedTab = this._getSelectedTab();
        let currentTab = null;

        currentTabs.forEach(tab => {
            if (tab.value === selectedTab) {
                currentTab = tab;
            }
        });

        return currentTab;
    }

    _onTabSelected = tabValue => {
        const currentTab = this._getCurrentTab();
        if (currentTab && currentTab.onExit) {
            currentTab.onExit();
        }

        this.props.setTab(this._getCurrentViewMemory(), tabValue);
    };

    render() {
        const leftColumnStatus = this.props.size.leftColumn,
            selectedViewTabs = this._getCurrentViewTabs();

        if (!selectedViewTabs.length) {
            return '';
        }

        let content = '';

        if (leftColumnStatus === EXPANDED && selectedViewTabs) {
            const component = this._getCurrentTabComponent();
            if (component) {
                const props = this._getCurrentTabComponentProperties();
                content = React.createElement(component, props);
            }
        }
        return (
            <div className="left-column" data-testid="left-column">
                <Tabs
                    data={selectedViewTabs}
                    selectedTab={this._getSelectedTab()}
                    onTabClicked={this._onTabSelected}
                    collapsable={true}
                    collapsed={leftColumnStatus === COLLAPSED}
                    setCollapsedStatus={collapsed => {
                        const newStatus = collapsed ? COLLAPSED : EXPANDED;
                        this.props.setLeftColumnStatus(newStatus);
                    }}
                />
                <div
                    className={classnames('left-column-content', {
                        hidden: leftColumnStatus === COLLAPSED,
                    })}
                >
                    {content}
                </div>
            </div>
        );
    }
}

LeftColumn.propTypes = {
    initialize: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    setLeftColumnStatus: PropTypes.func.isRequired,
    currentView: PropTypes.string,
    canLiveBook: PropTypes.bool,
    showBookedCountsType: PropTypes.string,
    selectedLOSportConfigSetting: PropTypes.string,
    selectedTabs: PropTypes.object,
    size: PropTypes.shape({
        height: PropTypes.number,
        leftColumn: PropTypes.number,
    }),
    showAsBooked: PropTypes.string,
    showAsBookedPrimary: PropTypes.string,
    showAsBookedSecondary: PropTypes.string,
    isNewProductsUIEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    selectedTabs: state.leftColumn.selectedTabs,
    currentView: state.viewport.currentView,
    size: state.calendar.size,
    showBookedCountsType: state.calendar.showBookedCountsType,
    showAsBooked: state.calendar.showAsBooked,
    showAsBookedPrimary: state.calendar.showAsBookedPrimary,
    showAsBookedSecondary: state.calendar.showAsBookedSecondary,
    selectedLOSportConfigSetting: state.calendar.selectedLOSportConfigSetting,
    canLiveBook: canLiveBook(state.calendar),
    isNewProductsUIEnabled:
        (state.viewport.selectedVersion !== 'old-version' &&
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .enable_ui_switcher) ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
});

const mapDispatchToProps = {
    initialize,
    setTab,
    setLeftColumnStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftColumn);
