import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../formInput/Button/Button';
import './optionButtonGroup.scss';

class OptionButtonGroup extends Component {
    _getButtonListener(index) {
        return () => {
            this.props.onClick(index);
        };
    }

    _getContent(options) {
        return options.map((option, index) => {
            let value = '',
                name = '',
                title = '';

            if (typeof option === 'object') {
                value = option.value;
                name = option.name;
                if (option.title !== undefined) {
                    title = option.title;
                }
            } else {
                value = index;
                name = option;
            }

            const isSelected = value === this.props.value;
            return (
                <Button
                    key={['option', 'button', value].join('_')}
                    type={
                        isSelected
                            ? Button.types.LARGE_GREEN_3D
                            : Button.types.LARGE_GREY_INSET
                    }
                    style={{
                        height: 27 - (isSelected ? 2 : 0),
                    }}
                    title={title}
                    fixedWidth={false}
                    onClick={this._getButtonListener(value).bind(this)}
                >
                    {name}
                </Button>
            );
        });
    }

    render() {
        return (
            <div className="option-button-group">
                {this._getContent(this.props.options)}
            </div>
        );
    }
}

OptionButtonGroup.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default OptionButtonGroup;
