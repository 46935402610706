import _ from 'underscore';
import GenericError from './GenericError';
import { getCookieValueByName } from './cookieUtils';

class Xhr {
    constructor() {
        this.hash = null;
        this.onHashMismatch = null;
        this.onXhrError = null;
    }

    setOnHashMismatchListener(listener = null) {
        this.onHashMismatch = listener;
    }

    _callMismatchListener() {
        if (_.isFunction(this.onHashMismatch)) {
            this.onHashMismatch();
        }
    }

    setOnXhrErrorListener(listener = null) {
        this.onXhrError = listener;
    }

    _callErrorListener(error, autoErrorHandling) {
        if (autoErrorHandling && _.isFunction(this.onXhrError)) {
            this.onXhrError(error);
        }
    }

    _appendFormData(formData, root, data) {
        root = root || '';
        if (data instanceof File) {
            formData.append(root, data);
        } else if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                this._appendFormData(formData, `${root}[${i}]`, data[i]);
            }
        } else if (typeof data === 'object' && data) {
            for (const key of Object.keys(data)) {
                const currentRoot = root === '' ? key : `${root}[${key}]`;
                this._appendFormData(formData, currentRoot, data[key]);
            }
        } else {
            if (data !== null && typeof data !== 'undefined') {
                formData.append(root, data);
            }
        }
    }

    _getBaseUrl() {
        const base = document.getElementsByTagName('base')[0];
        if (base && base.href) {
            return base.href;
        }
        return window.location.origin;
    }

    async _fetchRequest(url, method, body, headers) {
        try {
            const response = await fetch(url, {
                method,
                body,
                headers,
            });
            const responseJSON = await response.json();

            if (!response.ok) {
                const error = new GenericError(
                    responseJSON.message,
                    response.status,
                    responseJSON
                );
                console.error(error);
                if (response.status === 401) {
                    return Promise.reject(error);
                }
                this._callErrorListener(error, true);
                return Promise.reject(error);
            }

            return responseJSON;
        } catch (fetcherror) {
            return Promise.reject(new GenericError(fetcherror.message));
        }
    }

    async backendRequestLbcQuarkus(
        uri,
        fncValidate = null,
        method = 'GET',
        data = null,
        autoErrorHandling = true
    ) {
        const url = new URL(
            ('/lbc/' + uri).replace('//', '/'),
            this._getBaseUrl()
        );
        const headers = {};
        let body = null;
        if (data) {
            if (method === 'GET') {
                url.search = new URLSearchParams(data).toString();
            } else {
                body = JSON.stringify(data);
                headers['content-type'] = 'application/json';
            }
        }
        let response;
        let responseJSON;
        try {
            response = await fetch(url.toString(), {
                method,
                body,
                headers,
            });
            if (method === 'DELETE' && response.status === 204) {
                // No content
                responseJSON = '';
            } else {
                responseJSON = await response.json();
            }
        } catch (fetcherror) {
            return Promise.reject(new GenericError(fetcherror.message));
        }

        return new Promise((resolve, reject) => {
            if (!response.ok) {
                const error = new GenericError(
                    responseJSON.message,
                    response.status,
                    responseJSON
                );
                console.error(error);
                if (response.status === 401) {
                    autoErrorHandling = true;
                }
                this._callErrorListener(error, autoErrorHandling);
                return reject(error);
            }

            // custom validation?
            if (_.isFunction(fncValidate)) {
                if (!fncValidate.apply(null, [responseJSON])) {
                    const error = new GenericError(
                        'response did not validate',
                        1000,
                        {
                            validateFunction: fncValidate,
                        }
                    );
                    this._callErrorListener(error, autoErrorHandling);
                    return reject(error);
                }
            }

            // all good, resolve
            return resolve(responseJSON);
        });
    }

    async backendRequest(
        uri,
        fncValidate,
        method = 'GET',
        data = null,
        autoErrorHandling = true
    ) {
        const isTest = Boolean(window && window.Cypress);
        const url = new URL(
            ('/loweb/api/' + uri).replace('//', '/'),
            this._getBaseUrl()
        );
        let body = null;
        if (data) {
            if (method === 'GET') {
                url.search = new URLSearchParams(data).toString();
            } else {
                body = new FormData();
                this._appendFormData(body, '', data);
            }
        }

        const headers = {};
        const jwt = getCookieValueByName('jwt');
        if (jwt !== null) {
            headers['Authorization'] = `Bearer ${jwt}`;
        }

        const responseJSON = await this._fetchRequest(
            url.toString(),
            method,
            body,
            headers
        );

        if (_.isObject(responseJSON.debug) && responseJSON.debug.hash) {
            if (uri === 'start') {
                this.hash = responseJSON.debug.hash;
            } else if (
                !_.isNull(this.hash) &&
                this.hash !== responseJSON.debug.hash &&
                !isTest
            ) {
                const error = new GenericError(
                    'This session is expired. Please reload the page.',
                    1004
                );
                this._callMismatchListener();
                return Promise.reject(error);
            }
        } else {
            const error = new GenericError(
                'Response did not contain any hash',
                1003
            );
            this._callErrorListener(error, autoErrorHandling);
            return Promise.reject(error);
        }

        if (_.isFunction(fncValidate)) {
            if (!fncValidate.apply(null, [responseJSON])) {
                const error = new GenericError(
                    'response did not validate',
                    1000,
                    {
                        validateFunction: fncValidate,
                    }
                );
                this._callErrorListener(error, autoErrorHandling);
                return Promise.reject(error);
            }
        }

        return Promise.resolve(responseJSON);
    }

    async fileRequest(uri, data, method = 'POST', isExport, enableQuarkusApi) {
        return new Promise((resolve, reject) => {
            const prefix = enableQuarkusApi ? '/lbc/' : '/loweb/api/';
            uri = isExport
                ? new URL((prefix + uri).replace('//', '/'), this._getBaseUrl())
                : prefix + (uri[0] === '/' ? uri.substr(1) : uri);

            if (isExport) {
                let body = null;
                if (data) {
                    if (method === 'GET') {
                        uri.search = new URLSearchParams(data).toString();
                    } else {
                        body = new FormData();
                        this._appendFormData(body, '', data);
                    }
                }
            }

            const xhr = new XMLHttpRequest();
            xhr.open(method, uri, true);
            xhr.responseType = 'arraybuffer';
            const jwt = getCookieValueByName('jwt');
            if (jwt !== null) {
                xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const arrayBuffer = xhr.response;
                        return resolve(arrayBuffer);
                    } else {
                        let error = new GenericError(
                            'An error occurred',
                            xhr.status
                        );
                        try {
                            const response = JSON.parse(
                                String.fromCharCode.apply(
                                    null,
                                    new Uint8Array(xhr.response)
                                )
                            );
                            error = new GenericError(
                                response.message,
                                response.status,
                                response
                            );
                        } catch (e) {}
                        return reject(error);
                    }
                }
            };

            xhr.send(JSON.stringify(data));
        });
    }

    async exportFileRequest(uri, data, method, enableQuarkusApi) {
        return await this.fileRequest(
            uri,
            data,
            method,
            true,
            enableQuarkusApi
        );
    }

    async uploadImportFile(uri, file, enableQuarkusApi) {
        return new Promise((resolve, reject) => {
            const apiPrefix = enableQuarkusApi ? '/lbc/' : '/loweb/api/';
            uri = apiPrefix + (uri[0] === '/' ? uri.substr(1) : uri);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', uri, true);
            const formData = new FormData();
            formData.append('file', file);

            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status !== 200) {
                        const error = new GenericError(
                            xhr.response.message,
                            xhr.status
                        );
                        console.error(error);
                        return reject(error);
                    }
                    if (xhr.status === 200) {
                        const returnObject = JSON.parse(xhr.response);
                        return resolve(returnObject);
                    }
                }
            };
            xhr.send(formData);
        });
    }
}

const xhr = new Xhr();

export default xhr;
