import { createSelector } from 'reselect';
import settings from '../utils/DefaultSettings';
import moment from '../utils/CalendarMoment';
import enabledSports from './sport/enabledSports';
import filteredEvents from '../selectors/filter/filteredEvents';
import * as srds_colors from '@sr-design-system/ds-tokens-global-colors/dist/ds-tokens-global-colors.js';

export const colors = {
    1: srds_colors.TOKEN_GLOBAL_COLOR_NAVY_SR_400,
    2: srds_colors.TOKEN_GLOBAL_COLOR_BLUE_600,
    3: srds_colors.TOKEN_GLOBAL_COLOR_BLUE_200,
    4: srds_colors.TOKEN_GLOBAL_COLOR_GRAY_500,
    5: srds_colors.TOKEN_GLOBAL_COLOR_ORANGE_500,
    6: srds_colors.TOKEN_GLOBAL_COLOR_GRAY_800,
    9: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_1100,
    11: srds_colors.TOKEN_GLOBAL_COLOR_RED_1100,
    12: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_700,
    13: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_300,
    16: srds_colors.TOKEN_GLOBAL_COLOR_BLUE_400,
    19: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_900,
    20: srds_colors.TOKEN_GLOBAL_COLOR_ORANGE_200,
    21: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_1300,
    22: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_200,
    23: srds_colors.TOKEN_GLOBAL_COLOR_ORANGE_400,
    29: srds_colors.TOKEN_GLOBAL_COLOR_RED_1400,
    31: srds_colors.TOKEN_GLOBAL_COLOR_GREEN_400,
    34: srds_colors.TOKEN_GLOBAL_COLOR_ORANGE_1000,
    37: srds_colors.TOKEN_GLOBAL_COLOR_ORANGE_400,
    40: srds_colors.TOKEN_GLOBAL_COLOR_RED_300,
    109: srds_colors.TOKEN_GLOBAL_COLOR_BLUE_BR_1100,
    110: srds_colors.TOKEN_GLOBAL_COLOR_BLUE_BR_400,
    111: srds_colors.TOKEN_GLOBAL_COLOR_RED_SR_200,
};
const secondsInOneDay = 86400;

export default createSelector(
    state => state.calendar.selectedDayTimestamp,
    state => enabledSports(state),
    state => filteredEvents(state),
    state => state.calendar.selectedProducts,
    (selectedDayTimestamp, enabledSportsList, events, products) => {
        const data = {
                columns: {},
                pxPerMatch: 1,
                interval:
                    3600 / settings.timeLine.scheduleOverview.columnsPerHour,
            },
            countPerSportId = {},
            totalsColumns = ['all', 'booked'];

        data.columns = initializeDataContainers(
            data.interval,
            enabledSportsList,
            totalsColumns
        );

        events.forEach(event => {
            if (!enabledSportsList[event.sportId]) {
                return;
            }
            const secondsSinceStartOfDay =
                    event.startDate -
                    moment
                        .fromTimestamp(selectedDayTimestamp)
                        .startOf('day')
                        .toFormat('X'),
                firstZone =
                    Math.floor(secondsSinceStartOfDay / data.interval) *
                    data.interval, //rounding to nearest zone
                numberOfZones = Math.ceil(
                    (enabledSportsList[event.sportId].duration * 60) /
                        data.interval
                );

            if (!countPerSportId.hasOwnProperty(event.sportId)) {
                countPerSportId[event.sportId] = 0;
            }

            countPerSportId[event.sportId]++;

            for (let x = 0; x < numberOfZones; x++) {
                const zone = firstZone + x * data.interval;
                if (zone < secondsInOneDay) {
                    addToTotal(data, 'all', zone, event.sportId);

                    const selectedProducts = !products.length
                        ? ['lo', 'ld', 'bp', 'lco', 'lcr', 'lct']
                        : products;

                    if (
                        selectedProducts.some(
                            prod =>
                                event.products[prod] &&
                                event.products[prod].isBooked
                        )
                    ) {
                        addToTotal(data, 'booked', zone, event.sportId);
                    }
                } else {
                    break;
                }
            }
        });

        const sportsSorted = Object.keys(countPerSportId)
            .map(sportId => ({
                sportId: parseInt(sportId, 10),
                count: countPerSportId[sportId],
            }))
            .sort((a, b) => {
                return b.count - a.count;
            });

        if (
            sportsSorted.length >
            settings.timeLine.scheduleOverview.maxColorCount
        ) {
            for (
                let x = settings.timeLine.scheduleOverview.maxColorCount;
                x < sportsSorted.length;
                x++
            ) {
                sportsSorted[x].isOthers = true;
            }
        }

        sportsSorted.sort((a, b) => {
            if (a.isOthers && !b.isOthers) {
                return 1;
            }
            if (!a.isOthers && b.isOthers) {
                return -1;
            }

            return a.sportId - b.sportId;
        });

        data.mostMatchInZone = getMostMatchInZone(data);
        data.pxPerMatch =
            (settings.timeLine.scheduleOverview.expandedHeight -
                settings.timeLine.scheduleOverview.canvasTopPadding) /
            data.mostMatchInZone;
        data.sportsSorted = sportsSorted;

        return data;
    }
);

const initializeDataContainers = (interval, sports, totalsColumns) => {
    const columns = {};
    for (let x = 0; x < secondsInOneDay; x += interval) {
        columns[x] = {
            ...getIntervalData(sports, totalsColumns),
            id: x,
        };
    }
    return columns;
};

const getIntervalData = (sports, totalsColumns) => {
    const intervalData = {
        id: 0,
        sports: {},
        totals: {},
    };

    totalsColumns.forEach(total => {
        intervalData.totals[total] = 0;
    });

    Object.values(sports).forEach(sport => {
        const sportTotals = {};
        totalsColumns.forEach(total => {
            sportTotals[total] = 0;
        });

        intervalData.sports[sport.id] = sportTotals;
    });

    return intervalData;
};

const addToTotal = (data, totalName, interval, sportId = null) => {
    if (data.columns[interval]) {
        data.columns[interval].totals[totalName]++;

        if (sportId) {
            data.columns[interval].sports[sportId][totalName]++;
        }
    }
};

const getMostMatchInZone = data => {
    const shiftThreshold = 60;
    const stepSizeSmall = 10;
    const stepSizeBig = 20;
    let mostMatchInOneZone = 1;

    Object.values(data.columns).forEach(column => {
        if (column.totals.all > mostMatchInOneZone) {
            mostMatchInOneZone = column.totals.all;
        }
    });

    if (mostMatchInOneZone <= shiftThreshold) {
        return Math.ceil(mostMatchInOneZone / stepSizeSmall) * stepSizeSmall;
    }

    return Math.ceil(mostMatchInOneZone / stepSizeBig) * stepSizeBig;
};
