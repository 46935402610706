//Legg inn noe som ligner på case (linje 474 i calendar\reducer)
import types from '../logger/constants';

const initialState = {
    debug: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.FETCH_LOGS_SUCCESS:
            return {
                ...state,
                debug: [...state.debug, action.payload],
            };
        default:
            return state || initialState;
    }
};
