import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toggleHiddenPackages } from '../../../../../../../stores/calendar/actions';
import moment from '../../../../../../../utils/CalendarMoment';
import HoverTooltip from './HoverTooltip/HoverTooltip.jsx';
import ProgressBar from './ProgressBar/ProgressBar.jsx';
import './packageRow.scss';

const HOVER_SECONDS_ANALYTICS = 2;

class PackageRow extends React.Component {
    startHoverTime = null;

    state = {
        hoverData: null,
    };

    clickHandler = e => {
        if (e.ctrlKey && e.altKey) {
            this.props.toggleHiddenPackages();
        }
    };

    _isPackageWithCount(p) {
        return p.booked !== undefined;
    }

    _isPackageWithLimit(p) {
        return p.booked !== undefined && p.limit !== undefined;
    }

    _isPackageWithDates(p) {
        return p.startDate !== undefined && p.endDate !== undefined;
    }

    _createPackageDetails(p) {
        let detail = ' ',
            limit;
        if (this._isPackageWithCount(p)) {
            if (p.limit !== undefined && p.limit > 0) {
                limit = p.limit;
            } else if (p.limit === 0) {
                limit = '0';
            } else {
                limit = '∞'; // infinite char
            }
            detail += `(${p.booked} / ${limit})`;
        }
        return detail;
    }

    _createPackageProducts(p) {
        if (p.products !== undefined) {
            return p.products.join(', ');
        }

        return null;
    }

    _createProgressBar(p) {
        if (!this._isPackageWithCount(p) && !this._isPackageWithDates(p)) {
            return null;
        }

        let startDate = '',
            endDate = '',
            percentageDone = -1;
        if (this._isPackageWithDates(p)) {
            startDate = moment.fromTimestamp(p.startDate);
            endDate = moment.fromTimestamp(p.endDate);
        }
        if (this._isPackageWithCount(p)) {
            percentageDone = p.usedPercent;
            if (startDate === '' || endDate === '') {
                startDate = moment.now().startOf('month');
                endDate = moment.now().endOf('month');
            }
        }

        const dateBarDone = Math.round(
            ((moment.now().toFormat('X') - startDate.toFormat('X')) /
                (endDate.toFormat('X') - startDate.toFormat('X'))) *
                100
        );

        return (
            <ProgressBar
                percentageDone={percentageDone}
                startDate={startDate.toFormat('dd/MM/yyyy')}
                endDate={endDate.toFormat('dd/MM/yyyy')}
                dateBarDone={dateBarDone}
            />
        );
    }

    _createPercentageBubble(p) {
        if (!this._isPackageWithLimit(p)) {
            return '';
        }
        return (
            <span
                className="ui-400 text-green-700 text-right"
                style={{
                    float: 'right',
                    color: this.props.highlightColor,
                }}
            >
                {`${p.usedPercent}%`}
            </span>
        );
    }

    _onMouseEnter = e => {
        const element = e.target;

        const style = getComputedStyle(element, null);
        const width = parseFloat(style.width.replace('px', ''));
        const height = parseFloat(style.height.replace('px', ''));

        const rect = element.getBoundingClientRect();
        const offset = {
            top: rect.top + document.body.scrollTop,
            left: rect.left + document.body.scrollLeft,
        };

        this.setState({
            hoverData: {
                width: width,
                height: height,
                offset: offset,
                hoverInfo: this.props.packageDetails.hoverInfo,
            },
        });
        this.startHoverTime = moment.now().toFormat('X');
    };

    _onMouseLeave = () => {
        this.setState({ hoverData: null });

        if (this.startHoverTime === null) {
            return;
        }
        this.startHoverTime = null;
    };

    render() {
        const p = this.props.packageDetails,
            progressBar = this._createProgressBar(p);
        let onMouseEnter = null,
            onMouseLeave = null,
            tooltip = '';

        if (progressBar && p.hoverInfo) {
            onMouseEnter = this._onMouseEnter;
            onMouseLeave = this._onMouseLeave;
        }

        if (this.state.hoverData) {
            tooltip = <HoverTooltip hoveredElement={this.state.hoverData} />;
        }

        const shouldDisplay = p.display === undefined || p.display,
            doDisplay = shouldDisplay || this.props.showHiddenPackages;

        return (
            <div
                className={classnames(
                    'row package-row textline-400 text-black text-left text-semibold ',
                    {
                        highlight: !shouldDisplay,
                        hidden: !doDisplay,
                    }
                )}
                onClick={this.clickHandler}
            >
                {this._createPercentageBubble(p)}
                <span className="package-name textline-400 text-black text-left text-semibold">
                    {p.packageName}
                </span>
                <span className="package-detail ui-400 text-green-700 text-left">
                    {this._createPackageDetails(p)}
                </span>
                <div
                    className="bar"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {progressBar}
                </div>
                <div className="products ui-200 text-gray-1100 text-left ">
                    {this._createPackageProducts(p)}
                </div>
                {tooltip}
            </div>
        );
    }
}

PackageRow.propTypes = {
    showHiddenPackages: PropTypes.bool.isRequired,
    toggleHiddenPackages: PropTypes.func.isRequired,
    highlightColor: PropTypes.string,
    dateColor: PropTypes.string,
    packageDetails: PropTypes.shape({
        hoverInfo: PropTypes.object,
        packageName: PropTypes.string,
        display: PropTypes.bool,
    }),
};

PackageRow.defaultProps = {
    highlightColor: '#118330',
    dateColor: '#68BEE1',
};

const mapStateToProps = state => ({
    showHiddenPackages: state.calendar.showHiddenPackages,
});

const mapDispatchToProps = {
    toggleHiddenPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageRow);
