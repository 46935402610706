import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from '../../../icons/FontIcon/FontIcon.jsx';

const Word = props => {
    return (
        <div className="word">
            <div className="close" onClick={props.onClose}>
                <FontIcon icon="" />
            </div>
            <div className="text">{props.text}</div>
        </div>
    );
};

Word.propTypes = {
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Word;
