import React from 'react';
import PropTypes from 'prop-types';
import './footer.scss';

const Footer = props => {
    return <div className="dialog-footer">{props.children}</div>;
};

Footer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Footer;
