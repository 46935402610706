import React from 'react';
import PropTypes from 'prop-types';
import { PdsIconButton } from '@podium-design-system/react-components';
import {
    PdsTableSortIcon,
    PdsTableAscendingIcon,
    PdsTableDescendingIcon,
} from '@podium-design-system/react-icons';

const SortIcon = ({ sortingOrder, onSortClick }) => {
    return (
        <>
            {sortingOrder === null && (
                <PdsIconButton
                    title="Sort Icon"
                    size="sm"
                    rank="ghost"
                    color="neutral"
                    onClick={() => onSortClick('asc')}
                >
                    <PdsTableSortIcon slot="icon" />
                </PdsIconButton>
            )}
            {sortingOrder === 'asc' && (
                <PdsIconButton
                    title="Sort Descending"
                    size="sm"
                    rank="ghost"
                    color="neutral"
                    onClick={() => onSortClick('desc')}
                >
                    <PdsTableDescendingIcon slot="icon" />
                </PdsIconButton>
            )}
            {sortingOrder === 'desc' && (
                <PdsIconButton
                    title="Sort Ascending"
                    size="sm"
                    rank="ghost"
                    color="neutral"
                    onClick={() => onSortClick('asc')}
                >
                    <PdsTableAscendingIcon slot="icon" />
                </PdsIconButton>
            )}
        </>
    );
};

SortIcon.propTypes = {
    sortingOrder: PropTypes.string,
    onSortClick: PropTypes.func.isRequired,
};
export default SortIcon;
