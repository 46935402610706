import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import classnames from 'classnames';
import './dropDown.scss';

const _getOptions = options => {
    return options.map((option, index) => {
        return (
            <option value={option.value} key={index}>
                {option.text}
            </option>
        );
    });
};

const DropDown = ({
    label,
    labelWidth,
    labelLeft,
    options,
    value,
    onChange,
}) => {
    const labelStyle = {};
    let optionContent = [];

    if (labelWidth) {
        labelStyle.width = labelWidth;
    }

    if (!_.isArray(options)) {
        Object.keys(options).forEach((optionGroupKey, index) => {
            const optionComponents = _getOptions(options[optionGroupKey]);
            if (optionComponents.length > 0) {
                optionContent.push(
                    <optgroup label={optionGroupKey} key={index}>
                        {optionComponents}
                    </optgroup>
                );
            }
        });
    } else {
        optionContent = _getOptions(options);
    }
    return (
        <div className={classnames('dropdown', { 'label-left': labelLeft })}>
            <div className="label" style={labelStyle}>
                {label}
            </div>
            <select
                value={value}
                onChange={e => {
                    onChange(e.target.value);
                }}
            >
                {optionContent}
            </select>
        </div>
    );
};

DropDown.propTypes = {
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
        .isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    labelWidth: PropTypes.number,
    labelLeft: PropTypes.bool,
};

export default DropDown;
