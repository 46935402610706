export const recommenderSettingsFn = (
    events,
    selectedNumber,
    isSelectedPriority = true,
    isSelected = false,
    isRecommendedChecked = false
) => {
    let recommendedMatchIds = [];
    let type = isSelectedPriority ? 'mts' : 'lco';
    let count = 0;
    let ratedEvents = [];
    // Sorry for ugly code, but lo-mts can appear as both 'lo-mts' and 'mts'
    if (type === 'mts') {
        ratedEvents = events.filter(
            event =>
                !!event.recommendations[type] ||
                !!event.recommendations['lo-mts']
        );
    } else {
        ratedEvents = events.filter(event => !!event.recommendations[type]);
    }
    count = ratedEvents.length;

    if (isSelected) {
        if (type === 'mts') {
            recommendedMatchIds = ratedEvents
                .sort(
                    (a, b) =>
                        (!!b.recommendations[type]
                            ? b.recommendations[type].score
                            : b.recommendations['lo-mts'].score) -
                        (!!a.recommendations[type]
                            ? a.recommendations[type].score
                            : a.recommendations['lo-mts'].score)
                )
                .slice(0, isRecommendedChecked ? count : selectedNumber);
            if (isRecommendedChecked)
                recommendedMatchIds = recommendedMatchIds.slice(
                    0,
                    selectedNumber
                );
            recommendedMatchIds = recommendedMatchIds.map(event => event.uri);
        } else {
            recommendedMatchIds = ratedEvents
                .sort(
                    (a, b) =>
                        b.recommendations[type].score -
                        a.recommendations[type].score
                )
                .slice(0, isRecommendedChecked ? count : selectedNumber);
            if (isRecommendedChecked)
                recommendedMatchIds = recommendedMatchIds.slice(
                    0,
                    selectedNumber
                );
            recommendedMatchIds = recommendedMatchIds.map(event => event.uri);
        }
    }

    return {
        selectedPriority: type,
        selectedNumber: selectedNumber,
        showRecommended: isSelected,
        count: count,
        matchIds: recommendedMatchIds,
    };
};
