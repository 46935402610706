import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './textInput.scss';

export const labelPos = {
    BEFORE: 'BEFORE',
    AFTER: 'AFTER',
    ABOVE: 'ABOVE',
    UNDER: 'UNDER',
};

const TextInput = ({
    onChange,
    value,
    labelText = null,
    className = '',
    inputClassName = '',
    labelClassName = '',
    labelPos = 'BEFORE',
    maxLength = -1,
    onBlur = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    invalid = false,
    disabled = false,
    autoFocus = false,
    type = 'text',
    placeHolder = '',
}) => {
    const label = labelText ? (
        <label className={labelClassName}>{labelText}</label>
    ) : null;
    const isHorizontal =
        labelPos === labelPos.BEFORE || labelPos === labelPos.AFTER;
    const comesBefore =
        labelPos === labelPos.BEFORE || labelPos === labelPos.ABOVE;

    return (
        <div
            className={classnames('text-input', className, {
                column: !isHorizontal,
            })}
        >
            <div className="centered-column">
                {label && comesBefore && label}
            </div>
            <input
                className={classnames(inputClassName, { disabled, invalid })}
                type={type}
                value={value}
                onChange={e => onChange(e.target.value)}
                onKeyDown={e => {
                    if (e.keyCode === 27) {
                        // ESC
                        onChange('');
                        e.target.blur();
                    }
                    onKeyDown(e);
                }}
                maxLength={maxLength}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                placeholder={placeHolder}
            />
            <div className="centered-column">
                {label && !comesBefore && label}
            </div>
        </div>
    );
};

TextInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    labelText: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    labelPos: PropTypes.oneOf(Object.values(labelPos)),
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    placeHolder: PropTypes.string,
};

export default TextInput;
