import PropTypes from 'prop-types';
import React from 'react';
import './PackageDebug.scss';
import PricingSetup from './PricingSetup/PricingSetup';

const PackageDebug = ({ access }) => {
    return (
        <div className="package-debug">
            <PricingSetup access={access} />
        </div>
    );
};

PackageDebug.propTypes = {
    access: PropTypes.object,
};
export default PackageDebug;
