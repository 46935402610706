import PropTypes from 'prop-types';
import React from 'react';
import TimePicker from '../TimePicker/TimePicker';
import './timeRangePicker.scss';

const TimeRangePicker = props => {
    const { disabled = false } = props;
    return (
        <div className="time-range-picker">
            <TimePicker
                className="range-field"
                value={props.from}
                onChange={props.onFromChange}
                disabled={disabled}
            />

            <div className="range-separator"> -</div>

            <TimePicker
                className="range-field"
                value={props.to}
                onChange={props.onToChange}
                disabled={disabled}
            />
        </div>
    );
};

TimeRangePicker.propTypes = {
    onFromChange: PropTypes.func.isRequired,
    onToChange: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default TimeRangePicker;
