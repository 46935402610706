import { applyMiddleware, compose, legacy_createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import eventInfoTypes from './eventInfo/constants';

const createCalendarStore = () => {
    const middleware = [thunk];

    let composeEnhancers = compose;

    if (import.meta.env.MODE === 'development') {
        const composeWithDevToolsExtension =
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        if (typeof composeWithDevToolsExtension === 'function') {
            composeEnhancers = composeWithDevToolsExtension({
                maxAge: 50,
                actionsBlacklist: [eventInfoTypes.SET_HOVERED_EVENT],
            });
        }
    }

    return legacy_createStore(
        reducers,
        {},
        composeEnhancers(applyMiddleware(...middleware))
    );
};

export default () => {
    if (import.meta.hot) {
        if (window.store) {
            if (import.meta.env.MODE !== 'production' && import.meta.hot) {
                import.meta.hot.accept('./reducers', () =>
                    window.store.replaceReducer(reducers)
                );
            }
            return window.store;
        }

        window.store = createCalendarStore();
        return window.store;
    }

    return createCalendarStore();
};
