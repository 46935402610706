import React from 'react';
import PropTypes from 'prop-types';
import './ContentBoxTitle.scss';

const ContentBoxTitle = props => {
    return (
        <div
            className={`content-box-title ${props.className || ''}`}
            title={props.title}
        >
            {props.children}
        </div>
    );
};

ContentBoxTitle.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
};

export default ContentBoxTitle;
