import moment from '../utils/CalendarMoment';
import { dayNames } from '../components/formInput/WeekDayPicker/WeekDayPicker';

export const noResultString = '-';

export const getDateTimeRangeString = (dateFromTimestamp, dateToTimestamp) => {
    let dateFrom = null,
        dateFromString = '',
        dateTo = null,
        dateToString = '';

    dateFromTimestamp = parseInt(dateFromTimestamp, 10);
    if (dateFromTimestamp > 0) {
        dateFrom = moment.fromTimestamp(dateFromTimestamp);
        dateTo = moment.fromTimestamp(dateToTimestamp);
        if (
            dateFrom.hour === 0 &&
            dateFrom.minute === 0 &&
            dateTo.hour === 23 &&
            dateTo.minute === 59
        ) {
            dateFromString = dateFrom.toFormat('dd MMMM yyyy');
            dateToString = dateTo.toFormat('dd MMMM yyyy');
        } else {
            dateFromString = dateFrom.toFormat('dd MMMM yyyy (HH:mm)');
            dateToString = dateTo.toFormat('dd MMMM yyyy (HH:mm)');
        }

        return {
            from: dateFromString,
            to: dateToString,
        };
    }

    return null;
};

export const getTimeRangeString = (startTimestamp, endTimestamp) => {
    startTimestamp = parseInt(startTimestamp, 10);
    if (startTimestamp !== 0 && !isNaN(startTimestamp)) {
        return {
            from: moment.fromTimestamp(startTimestamp).toFormat('HH:mm'),
            to: moment.fromTimestamp(endTimestamp).toFormat('HH:mm'),
        };
    }

    return null;
};

export const getDaysString = daysOfWeek => {
    let daysString = noResultString,
        daysOfWeekArray = [];

    if (daysOfWeek) {
        daysOfWeekArray = String(daysOfWeek)
            .split(',')
            .map(d => parseInt(d, 10));
    }

    if (daysOfWeekArray.length === 7) {
        return 'All days';
    }

    if (daysOfWeekArray.length > 0) {
        daysString = daysOfWeekArray
            .map(day => {
                for (const dayName in dayNames) {
                    if (
                        dayNames.hasOwnProperty(dayName) &&
                        dayNames[dayName] === day
                    ) {
                        return capitalize(dayName);
                    }
                }
                return '';
            })
            .join(', ');
    }
    return daysString;
};

const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
