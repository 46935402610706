import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classnames from 'classnames';
import './checkbox.scss';

class Checkbox extends React.Component {
    _clicked = () => {
        if (_.isFunction(this.props.onClick)) {
            !this.props.disabled && this.props.onClick(this.props.id);
        }
    };

    render() {
        let contentAfter = '',
            containerHeight = this.props.size;
        const contentPos = this.props.contentPosition,
            containerClassName = classnames('flex', 'form-checkbox-container', {
                disabled: this.props.disabled,
                column: ['below', 'above'].indexOf(contentPos) >= 0,
            }),
            checkboxClassName = classnames(
                'form-checkbox',
                this.props.className,
                {
                    'form-checkbox-on': this.props.checked,
                    'form-checkbox-off': !this.props.checked,
                }
            );

        if (['right', 'below'].indexOf(contentPos) >= 0) {
            contentAfter = this.props.children;
        }

        if (['below', 'above'].indexOf(contentPos) >= 0) {
            containerHeight *= 2;
        }

        return (
            <div
                className={containerClassName}
                onClick={this._clicked}
                style={{ height: containerHeight }}
                title={this.props.title}
            >
                <div
                    className={checkboxClassName}
                    style={{
                        width: this.props.size,
                        height: this.props.size,
                    }}
                />
                <div
                    style={{
                        height: this.props.size,
                        lineHeight: `${this.props.size}px`,
                        marginLeft:
                            this.props.contentPosition === 'right' ? 5 : 0,
                    }}
                >
                    {contentAfter}
                </div>
            </div>
        );
    }
}

Checkbox.propTypes = {
    size: PropTypes.number,
    contentPosition: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.any,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string,
};

Checkbox.defaultProps = {
    size: 15,
    contentPosition: 'right',
    className: '',
    checked: false,
    onClick: null,
    id: null,
    disabled: false,
    title: '',
};

export default Checkbox;
