import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openUserSwitcher } from '../../../../../stores/calendar/actions';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import Button from '../../../../formInput/Button/Button';
import './userInfo.scss';

export const UserInfo = props => {
    let user = (
        <>
            <span className="user">
                {`${props.userInfo.name} (${props.userInfo.id})`}
            </span>
            <FontIcon icon="" />
            <span className="bookmaker">
                {`${props.userInfo.bookmakerName} (${props.userInfo.bookmakerId})`}
            </span>
        </>
    );

    if (
        props.access &&
        Object.keys(props.access).length &&
        props.userSwitcherEnabled
    ) {
        user = (
            <button className="user-button" onClick={props.openUserSwitcher}>
                {user}
            </button>
        );
    }

    const logOut = () => {
        if (props.logoutEnabled) {
            window.location.href = '/api/logout';
        }
    };

    return (
        <div className="user-info">
            Logged in as
            <FontIcon icon="" onClick={logOut} />
            {user}
        </div>
    );
};

UserInfo.propTypes = {
    userInfo: PropTypes.shape({
        bookmakerId: PropTypes.number,
        bookmakerName: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    openUserSwitcher: PropTypes.func,
    access: PropTypes.object,
    userSwitcherEnabled: PropTypes.bool,
    logoutEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    userInfo: state.calendar.auth.userInfo,
    access: state.calendar.access,
    userSwitcherEnabled: state.calendar.auth.userSwitcher.enabled,
    logoutEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_lbc_logout,
});

const mapDispatchToProps = {
    openUserSwitcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
