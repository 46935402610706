import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../Alert/Alert';
import settings from '../../utils/DefaultSettings';
import { setTab } from '../../stores/leftColumn/actions';
import transformTextLinks from '../../utils/transformTextLinks';
import './TextBanner.scss';
import { connect } from 'react-redux';
import { tabs } from '../../stores/leftColumn/constants';

export const bannerTypes = {
    INFO: 0,
    MAINTENANCE: 1,
    POPUP: 2,
};

const openRecommenderTab = (event, props) => {
    event.preventDefault();
    props.setTab('event_views', tabs.RECOMMENDER);
};

const getTextBannerMessage = message => {
    return message.split(/\\n|\n/).map((paragraph, pid) => {
        return paragraph ? (
            <React.Fragment key={pid}>
                {transformTextLinks(paragraph, (text, url, _, index) => (
                    <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {text}
                    </a>
                ))}
            </React.Fragment>
        ) : (
            <div className="empty-line" key={pid} />
        );
    });
};

const getTextBannerMessageForRecommender = props => {
    return props.subTitle.split(/\\n|\n/).map((paragraph, pid) => {
        return paragraph ? (
            <React.Fragment key={pid}>
                {transformTextLinks(paragraph, (text, url, _, index) => (
                    <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={event => openRecommenderTab(event, props)}
                    >
                        {text}
                    </a>
                ))}
            </React.Fragment>
        ) : (
            <div className="empty-line" key={pid} />
        );
    });
};

const TextBanner = props => {
    const { subTitle = '', constrained = false } = props;
    let alertType = Alert.types.INFO,
        icon = '';

    if (props.type === bannerTypes.MAINTENANCE) {
        alertType = Alert.types.DANGER;
        icon = '';
    }

    if (props.type === bannerTypes.POPUP) {
        alertType = Alert.types.DANGER;
        icon = '';
    }

    return (
        <div className="text-banner">
            <Alert
                type={alertType}
                icon={icon}
                float={false}
                constrained={constrained}
                style={{ minHeight: settings.monkeyHeaderRowHeight }}
            >
                {getTextBannerMessage(props.title)}
                <br />
                <span className="sub-title">
                    {/recommender/i.test(subTitle)
                        ? getTextBannerMessageForRecommender(props)
                        : getTextBannerMessage(subTitle)}
                </span>
            </Alert>
        </div>
    );
};

TextBanner.propTypes = {
    type: PropTypes.oneOf(Object.values(bannerTypes)).isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    constrained: PropTypes.bool,
    setTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setTab,
};

export default connect(null, mapDispatchToProps)(TextBanner);
