import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import './NotificationDialog.scss';
import { confirmTextBanner } from '../../../../stores/textBanner/actions';

const NotificationDialog = props => {
    const scrollEnd = useRef(null);
    const [scrolledToEnd, setScrolledToEnd] = useState(false);

    const onConfirm = () => {
        props.confirmTextBanner(props.textBannerId);
        props.onClose();
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setScrolledToEnd(true);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );
        if (scrollEnd.current) {
            observer.observe(scrollEnd.current);
        }
    }, [scrollEnd]);

    return (
        <Confirmation
            onReject={props.onClose}
            confirmText="I have read and agree to all of these terms"
            showCancelButton={false}
            confirmButtonDisabled={!scrolledToEnd}
            onConfirm={onConfirm}
        >
            <div className="notification-dialog">
                {props.message.split(/\\n|\n/).map(paragraph => {
                    return paragraph ? (
                        <div>{paragraph}</div>
                    ) : (
                        <div className="empty-line"></div>
                    );
                })}
                <div className="scroll-end" ref={scrollEnd}></div>
            </div>
        </Confirmation>
    );
};

NotificationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    confirmTextBanner: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    textBannerId: PropTypes.number.isRequired,
};

const mapDispatchToProps = { confirmTextBanner };

export default connect(null, mapDispatchToProps)(NotificationDialog);
