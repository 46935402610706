export default {
    GET_AUTH_STATUS_START: 'GET_AUTH_STATUS_START',
    GET_AUTH_STATUS_SUCCESS: 'GET_AUTH_STATUS_SUCCESS',
    GET_AUTH_STATUS_ERROR: 'GET_AUTH_STATUS_ERROR',
    CHECKBOX_FILTER_TOGGLE: 'CHECKBOX_FILTER_TOGGLE',
    SWITCH_TO_VIEW: 'SWITCH_TO_VIEW',
    CLEAR_XHR_ERROR: 'CLEAR_XHR_ERROR',
    FETCH_EVENTS_FOR_DAY_START: 'FETCH_EVENTS_FOR_DAY_START',
    FETCH_EVENTS_FOR_DAY_SUCCESS: 'FETCH_EVENTS_FOR_DAY_SUCCESS',
    FETCH_EVENTS_FOR_DAY_ERROR: 'FETCH_EVENTS_FOR_DAY_ERROR',
    FETCH_EVENTS_FOR_DAY_GROUP_SUCCESS: 'FETCH_EVENTS_FOR_DAY_GROUP_SUCCESS',
    FREETEXT_SEARCH_PHRASE_CHANGE: 'FREETEXT_SEARCH_PHRASE_CHANGE',
    LEGEND_OPEN_OR_CLOSE: 'LEGEND_OPEN_OR_CLOSE',
    CHECK_XHR_MANAGER_DATA: 'CHECK_XHR_MANAGER_DATA',
    TOGGLE_BOOKING_HISTORY_FILTER: 'TOGGLE_BOOKING_HISTORY_FILTER',
    SET_HISTORY_DETAIL_URI: 'SET_HISTORY_DETAIL_URI',
    TOGGLE_HELP_BALLOON: 'TOGGLE_HELP_BALLOON',
    SET_LEFT_COLUMN_STATUS: 'SET_LEFT_COLUMN_STATUS',
    SET_RIGHT_COLUMN_STATUS: 'SET_RIGHT_COLUMN_STATUS',
    HASH_MISMATCH: 'HASH_MISMATCH',
    SET_FILTER_SELECTION: 'SET_FILTER_SELECTION',
    PROGRAM_OVERVIEW_MOUSE_ENTER: 'PROGRAM_OVERVIEW_MOUSE_ENTER',
    PROGRAM_OVERVIEW_MOUSE_LEAVE: 'PROGRAM_OVERVIEW_MOUSE_LEAVE',
    PROGRAM_OVERVIEW_SET_COLUMN_INFO: 'PROGRAM_OVERVIEW_SET_COLUMN_INFO',
    TOGGLE_HIDDEN_PACKAGES: 'TOGGLE_HIDDEN_PACKAGES',
    ADD_TEXT_BANNER: 'ADD_TEXT_BANNER',
    POP_TEXT_BANNER: 'POP_TEXT_BANNER',
    DELETE_TEXT_BANNER: 'DELETE_TEXT_BANNER',
    UPDATE_TEXT_BANNER: 'UPDATE_TEXT_BANNER',
    SET_SHOW_BOOKED_COUNTS_TYPE: 'SET_SHOW_BOOKED_COUNTS_TYPE',
    SET_SELECTED_LO_SPORT_CONFIG_SETTING:
        'SET_SELECTED_LO_SPORT_CONFIG_SETTING',
    SET_CONTEXT_MENU_OPEN: 'SET_CONTEXT_MENU_OPEN',
    DELETE_FILTER_SET: 'DELETE_FILTER_SET',
    PRODUCTS_CHANGE: 'PRODUCTS_CHANGE',
    NCAA_FILTER_CHANGE: 'NCAA_FILTER_CHANGE',
    SET_DEFAULT_FILTER_ID: 'SET_DEFAULT_FILTER_ID',
    RECOMMENDER_SETTINGS_CHANGE: 'RECOMMENDER_SETTINGS_CHANGE',
    SET_SHOW_AS_BOOKED_PRODUCT: 'SET_SHOW_AS_BOOKED_PRODUCT',
    SET_SHOW_AS_BOOKED_PRODUCT_PRIMARY: 'SET_SHOW_AS_BOOKED_PRODUCT_PRIMARY',
    SET_SHOW_AS_BOOKED_PRODUCT_SECONDARY:
        'SET_SHOW_AS_BOOKED_PRODUCT_SECONDARY',
    SORT_RECOMMENDATIONS: 'SORT_RECOMMENDATIONS',
};

export const exportTypes = {
    PDF: 0,
    XML: 1,
    CSV: 2,
};

export const matches = {
    ALL: 0,
    MY_MATCHES: 1,
    FILTERED: 2,
};
