import _ from 'underscore';
import FilterConstants from '../../constants/FilterConstants';
import moment from '../../utils/CalendarMoment';
import defaultSettings from '../../utils/DefaultSettings';
import StorageLocal from '../../utils/StorageLocal';
import {
    filterMapping,
    getAllRelevantFilters,
    getDefaultAttributeSet,
    getNonDefaultFilters,
    getSavedAttributeSets,
} from '../../utils/autobookingUtils';
import actionConstants from './constants';

const storageLocal = new StorageLocal();

let initialStartDate, initialEndDate, initialStartTime, initialEndTime;

const clone = data => {
    return JSON.parse(JSON.stringify(data));
};

const getSelectedFilters = autobooking => {
    return Object.fromEntries(
        getNonDefaultFilters(autobooking).map(filter => [filter, true])
    );
};

let initialFilters = {
    formFilters: {
        sportId: -1,
        tournamentId: -1,
        realCategoryId: -1,
        homeTeamId: -1,
        awayTeamId: -1,
        withoutSurchargeOnly: false,
        isMatchActiveInLiveoddsConfig: true,
        bookableWithSinglePackage: false,
    },
    options: {},
};

const setAutobookingFormInitialDates = function () {
    initialStartDate = moment.now().startOf('day').toFormat('X');
    initialEndDate = moment.now().endOf('day').plus({ days: 14 }).toFormat('X');
    initialStartTime = moment
        .now()
        .set({ hour: 13, minutes: 0, seconds: 0 })
        .toFormat('X');
    initialEndTime = moment
        .now()
        .set({ hour: 17, minutes: 0, seconds: 0 })
        .toFormat('X');
    initialFilters = {
        ...initialFilters,
        formFilters: {
            ...initialFilters.formFilters,
            startDate: initialStartDate,
            endDate: initialEndDate,
            startTime: initialStartTime,
            endTime: initialEndTime,
        },
    };
};

const getPresetFilters = (state, action) => {
    const formFilters = { ...action.payload.filters };
    const options = {
        ...state.options,
    };

    if (Object.keys(action.payload.filters).includes('sportId')) {
        formFilters.homeTeamId = -1;
        delete formFilters.homeTeamName;
        formFilters.awayTeamId = -1;
        delete formFilters.awayTeamName;
    }
    return {
        ...state,
        formFilters,
        options,
    };
};

const getUpdatedFilters = (state, action) => {
    const formFilters = {
        ...state.formFilters,
        ...action.payload.filters,
    };
    const options = {
        ...state.options,
    };

    if (Object.keys(action.payload.filters).includes('sportId')) {
        formFilters.realCategoryId = -1;
        delete formFilters.realCategoryName;
        formFilters.tournamentId = -1;
        delete formFilters.tournamentName;
        formFilters.homeTeamId = -1;
        delete formFilters.homeTeamName;
        formFilters.awayTeamId = -1;
        delete formFilters.awayTeamName;

        delete options.category;
        delete options.isloadingcategory;
        delete options.tournament;
        delete options.isloadingtournament;
        delete options.match1;
        delete options.isloadingmatch1;
        delete options.match2;
        delete options.isloadingmatch2;
    }

    if (Object.keys(action.payload.filters).includes('realCategoryId')) {
        formFilters.tournamentId = -1;
        delete formFilters.tournamentName;
        formFilters.homeTeamId = -1;
        delete formFilters.homeTeamName;
        formFilters.awayTeamId = -1;
        delete formFilters.awayTeamName;

        delete options.tournament;
        delete options.isloadingtournament;
        delete options.match1;
        delete options.isloadingmatch1;
        delete options.match2;
        delete options.isloadingmatch2;
    }

    if (Object.keys(action.payload.filters).includes('tournamentId')) {
        formFilters.homeTeamId = -1;
        delete formFilters.homeTeamName;
        formFilters.awayTeamId = -1;
        delete formFilters.awayTeamName;

        delete options.match1;
        delete options.isloadingmatch1;
        delete options.match2;
        delete options.isloadingmatch2;
    }

    return {
        ...state,
        formFilters,
        options,
    };
};

let savedFilters = getDefaultAttributeSet();

const initialFilterOrder = Object.keys(filterMapping);

if (Object.keys(savedFilters).length) {
    // TODO

    // add any items from the initial filter order that's not saved
    const missingFilters = initialFilterOrder.filter(
        item => !savedFilters.filterOrder.includes(item)
    );
    savedFilters.filterOrder.push(...missingFilters);

    // remove any items not in the initial filter order
    savedFilters.filterOrder.filter(item => initialFilterOrder.includes(item));
}

export const defaultSavedFilters = {
    selectedFilters: { sport: true, category: true, tournament: true },
    disabledFilters: {},
    filterOrder: initialFilterOrder,
};

const initialState = {
    autobookings: {},
    isFetching: false,
    isFetched: false,
    activeFilters: { selectedFilters: {}, formFilters: {} },
    filteredAutobookingIds: [],
    editId: null,
    selectedAutobookingType: FilterConstants.autobookingTypes.ALL,
    ...defaultSavedFilters,
    ...initialFilters,
    ...savedFilters,
};

export default (state, action) => {
    switch (action.type) {
        case actionConstants.LOAD_AUTOBOOKINGS:
            return {
                ...state,
                isFetching: true,
                isFetched: false,
            };

        case actionConstants.LOAD_AUTOBOOKINGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                autobookings: action.payload.autobookings.reduce(
                    (autobookings, autobooking) => {
                        autobookings[autobooking.id] = autobooking;
                        return autobookings;
                    },
                    { ...state.autobookings }
                ),
            };

        case actionConstants.LOAD_AUTOBOOKINGS_FAIL:
            return {
                ...state,
                isFetching: false,
            };

        case actionConstants.AUTOBOOKING_DELETED:
            let newState = state;
            for (const id of action.payload.ids) {
                if (state.autobookings[id]) {
                    newState = {
                        ...newState,
                        autobookings: _.omit(newState.autobookings, id),
                    };
                } else {
                    console.error(
                        `Trying to delete an autobooking rule that does not exist (id: ${id})`
                    );
                }
            }
            return newState;

        case actionConstants.DELETE_FAILED:
        case actionConstants.SAVE_FAILED:
            console.error(action.payload.error);
            return state;

        case actionConstants.AUTOBOOKING_SAVED:
            return {
                ...state,
                autobookings: {
                    ...state.autobookings,
                    [action.payload.autobooking.id]: action.payload.autobooking,
                },
            };

        case actionConstants.UPDATE_FILTER:
            return getUpdatedFilters(state, action);

        case actionConstants.SET_FILTERS:
            const filters = getPresetFilters(state, action);
            return {
                ...state,
                formFilters: filters.formFilters,
                selectedFilters: {
                    ...state.selectedFilters,
                    sport: true,
                    category: true,
                },
            };

        case actionConstants.TOGGLE_FILTER_SELECTION:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [action.payload.filter]:
                        !state.selectedFilters[action.payload.filter],
                },
            };
        case actionConstants.ADD_FILTERS_SELECTED:
            const newFilters = Object.fromEntries(
                action.payload.filters.map(filter => [filter, true])
            );
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...newFilters,
                },
            };
        case actionConstants.SET_FILTERS_SELECTED_TO_RELEVANT:
            const relevantFilters = Object.fromEntries(
                getAllRelevantFilters(Object.values(state.autobookings)).map(
                    filter => [filter, true]
                )
            );
            return {
                ...state,
                selectedFilters: relevantFilters,
            };
        case actionConstants.TOGGLE_FILTER_DISABLED:
            return {
                ...state,
                disabledFilters: {
                    ...state.disabledFilters,
                    [action.payload.filter]:
                        !state.disabledFilters[action.payload.filter],
                },
            };

        case actionConstants.SET_FILTER_ORDER:
            return {
                ...state,
                filterOrder: action.payload.order,
            };

        case actionConstants.RESET_FILTERS:
            return {
                ...state,
                ...initialFilters,
            };

        case actionConstants.RESET_ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: { selectedFilters: {}, formFilters: {} },
            };

        case actionConstants.ACTIVATE_FILTERS:
            const selectedFilters = { ...state.selectedFilters };
            for (const filter of Object.keys(state.disabledFilters)) {
                if (state.disabledFilters[filter]) {
                    selectedFilters[filter] = false;
                }
            }
            return {
                ...state,
                activeFilters: {
                    filterValues: { ...state.formFilters },
                    selectedFilters,
                },
            };

        case actionConstants.SET_EDIT_ID:
            const changes = { editId: action.payload.editId };
            if (action.payload.editId) {
                changes.formFilters = clone(
                    state.autobookings[action.payload.editId]
                );
                changes.selectedFilters = {
                    ...state.selectedFilters,
                    ...getSelectedFilters(
                        state.autobookings[action.payload.editId]
                    ),
                };
            }
            return { ...state, ...changes };

        case actionConstants.LOAD_OPTIONS:
            return {
                ...state,
                options: {
                    ...state.options,
                    [`isloading${action.payload.optionName}`]: true,
                    [action.payload.optionName]: null,
                },
            };

        case actionConstants.LOAD_OPTIONS_SUCCESS:
            if (
                state.formFilters[action.payload.parentKey] !==
                action.payload.parentValue
            ) {
                return state;
            }
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.payload.optionName]: action.payload.options,
                    [`isloading${action.payload.optionName}`]: false,
                },
            };

        case actionConstants.LOAD_OPTIONS_FAIL:
            return {
                ...state,
                options: {
                    ...state.options,
                    [`isloading${action.payload.optionName}`]: false,
                },
            };

        case actionConstants.LOAD_FILTER_SET:
            const filterSets = getSavedAttributeSets();
            const newData =
                action.payload.filterSetName ===
                defaultSettings.defaultAttributeSetName
                    ? defaultSavedFilters
                    : filterSets[action.payload.filterSetName];
            return { ...state, ...newData };

        case actionConstants.SET_INITIAL_DATES:
            setAutobookingFormInitialDates();
            return {
                ...state,
                ...initialFilters,
            };

        case actionConstants.SET_AUTOBOOKING_TYPE:
            return {
                ...state,
                selectedAutobookingType: action.payload.selectedAutobookingType,
            };
        case actionConstants.AUTOBOOKING_RULES_IMPORT:
            return {
                ...state,
                importedAutobookingRules: action.payload,
                importedAutobookingRulesSuccess: null,
            };

        case actionConstants.AUTOBOOKING_RULES_IMPORT_SUCCESS:
            return {
                ...state,
                importedAutobookingRulesSuccess: action.payload,
            };

        default:
            return state || initialState;
    }
};
