import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableChanges from '../../../../TableChanges/TableChanges';
import Checkbox from '../../../../formInput/Checkbox/Checkbox.jsx';
import Button from '../../../../formInput/Button/Button';
import DropDown from '../../../../formInput/Dropdown/DropDown';
import {
    expiredRuleIds,
    filteredAutobookings,
    orderedSelectedFilters,
    autobookingsByType,
} from '../../../../../selectors/autobooking';
import { addDialog } from '../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../stores/dialog/constants';
import Alert from '../../../../Alert/Alert';
import {
    noResultString,
    getDaysString,
    getDateTimeRangeString,
    getTimeRangeString,
} from '../../../../../utils/autobookingTableUtils';
import FilterConstants from '../../../../../constants/FilterConstants';
import canLiveBookSelector from '../../../../../selectors/canLiveBook';
import { create as createNotification } from '../../../../../stores/notification/actions';
import {
    deleteAutobooking,
    loadAllAutobookings,
    setEditId,
    addFiltersSelected,
    setAutobookingType,
} from '../../../../../stores/autobooking/actions';
import {
    fetchAutobookingConfig,
    saveAutobookingConfig,
} from '../../../../../stores/autobookingConfig/actions';
import { setTotalItems } from '../../../../../stores/pagination/actions';
import { getNonDefaultFilters } from '../../../../../utils/autobookingUtils';
import moment from '../../../../../utils/CalendarMoment';
import './autobookingListOverview.scss';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import AutobookingFilters from '../../LeftColumn/AutobookingFilters/AutobookingFilters';
import CustomizeFilters from './CustomizeFilters';
import FontIcon from '../../../../icons/FontIcon/FontIcon.jsx';
import Badge from '../../../../Badge/Badge.jsx';
import SportIcon from '../../../../icons/SportIcon/SportIcon.jsx';

class AutobookingListOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            historyOpenForRows: {},
            isLoading: true,
            isEditingFilters: false,
        };

        if (this.props.canLiveBook) {
            this.props.fetchAutobookingConfig();
        }
    }

    componentDidMount() {
        if (!this.props.isFetched) {
            this.props.loadAllAutobookings();
        }
        this.props.setTotalItems(this.props.filteredAutobookings.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.filteredAutobookings.length !==
            prevProps.filteredAutobookings.length
        ) {
            this.props.setTotalItems(this.props.filteredAutobookings.length);
        }
    }

    _getDeleteButtonListener(id) {
        return () => {
            this.props.addDialog(dialogTypes.DELETE_AUTO_BOOKING, {
                ids: [id],
            });
        };
    }

    _onHistoryClicked(ruleId) {
        this.setState({
            historyOpenForRows: {
                ...this.state.historyOpenForRows,
                [ruleId]: !this.state.historyOpenForRows[ruleId],
            },
        });
    }

    _getButtons(autobooking) {
        let editClicked = () => this.props.setEditId(autobooking.id),
            editText = 'Edit',
            editIcon = '';

        if (this.props.editId) {
            editClicked = () => this.props.setEditId();
            editText = 'Cancel edit';
            editIcon = '';
        }

        const historyClicked = () => this._onHistoryClicked(autobooking.id);

        return (
            <>
                <button className="edit" onClick={editClicked}>
                    <FontIcon
                        className="edit-icon"
                        icon={editIcon}
                        title={editText}
                    />
                </button>

                <button
                    className="delete"
                    onClick={this._getDeleteButtonListener(autobooking.id).bind(
                        this
                    )}
                >
                    <FontIcon
                        className="delete-icon"
                        icon=""
                        title={'Delete'}
                    />
                </button>

                {(this.props.isAutobookingRuleHistoryEnabled ||
                    this.props.access.support) && (
                    <button className="history" onClick={historyClicked}>
                        <FontIcon icon="" title="History" />
                    </button>
                )}
            </>
        );
    }

    _isRowDisabled(endDateTimestamp) {
        return (
            !isNaN(endDateTimestamp) &&
            endDateTimestamp > 0 &&
            endDateTimestamp < moment.now().toFormat('X')
        );
    }

    _getRowClasses(autobooking) {
        const classes = [];

        if (this._isRowDisabled(parseInt(autobooking.endDate, 10))) {
            classes.push('disabled');
        }

        if (autobooking.id === this.props.editId) {
            classes.push('highlight');
        }

        return classes;
    }

    _buildAutobookingRow(autobooking) {
        const cells = [];
        for (const filter of this.props.orderedSelectedFilters) {
            cells.push(
                <div
                    className={`autobooking-cell ${filter}`}
                    key={`${autobooking.id}_${filter}`}
                >
                    {this._buildAutobookingCell(autobooking, filter)}
                </div>
            );
        }
        cells.push(this._buildShowMoreCell(autobooking));
        const row = [
            <div
                className={`autobooking-container ${this._getRowClasses(
                    autobooking
                )}`}
                key={`autobooking-row_${autobooking.id}`}
            >
                <div className="autobooking-row">{cells}</div>
                <div className="actions">
                    {this.props.canLiveBook && this._getButtons(autobooking)}
                </div>
            </div>,
        ];
        this.state.historyOpenForRows[autobooking.id] &&
            row.push(
                <TableChanges
                    key={`autobooking-changes_${autobooking.id}`}
                    tableName="scout_match_autobooking"
                    primaryKey={autobooking.id}
                    metaColumns="no-package"
                    extraColumns={[
                        'enabled',
                        'sportid',
                        'realcategoryid',
                        'tournamentid',
                        'bookmakerstartdate',
                        'bookmakerenddate',
                        'bookmakerstarttime',
                        'bookmakerendtime',
                        'bookmakerdaysofweek',
                        'enabled',
                        'flat_price_only',
                        'without_surcharge_only',
                        'available_in_config',
                        'book_liveodds',
                        'book_lco',
                        'sport_uris',
                        'category_uris',
                        'tournament_uris',
                        'home_competitor_uris',
                        'away_competitor_uris',
                        'competitor_ranking_from',
                        'competitor_ranking_to',
                        'age_groups',
                        'days_of_week',
                        'date_from',
                        'date_to',
                        'time_from',
                        'time_to',
                    ]}
                />
            );

        return row;
    }

    _buildAutobookingCell(autobooking, filter) {
        switch (filter) {
            case 'sport':
                return (
                    <>
                        <SportIcon
                            className="sport-icon"
                            sportId={autobooking.sportId}
                        />
                        {autobooking.sportName || 'All'}
                    </>
                );
            case 'category':
                return autobooking.realCategoryName || noResultString;
            case 'tournament':
                const now = moment.now();
                const isPastTournament =
                    autobooking.tournamentEnd != null &&
                    moment.fromTimestamp(autobooking.tournamentEnd) < now;
                return isPastTournament ? (
                    <div>
                        {autobooking.tournamentName}
                        <span className="past">{' [Expired]'}</span>
                    </div>
                ) : (
                    autobooking.tournamentName || noResultString
                );
            case 'matches':
                return autobooking.homeTeamName && autobooking.awayTeamName
                    ? `${autobooking.homeTeamName} vs. ${autobooking.awayTeamName}`
                    : noResultString;
            case 'products':
                return autobooking.products
                    .map(str => (str === 'lo' ? 'LO/LD' : str.toUpperCase()))
                    .join(', ');
            case 'types':
                return autobooking.types
                    .map(str => str.toUpperCase())
                    .join(', ');
            case 'tiers':
                return autobooking.tierContent
                    .map(str => str.toUpperCase())
                    .join(', ');
            case 'dates':
                const dates = getDateTimeRangeString(
                    autobooking.startDate,
                    autobooking.endDate
                );
                return dates ? `${dates.from} - ${dates.to}` : noResultString;
            case 'times':
                const times = getTimeRangeString(
                    autobooking.startTime,
                    autobooking.endTime
                );
                return times ? `${times.from} to ${times.to}` : noResultString;
            case 'weekdays':
                return getDaysString(autobooking.daysOfWeek);
            case 'agegroups':
                return autobooking.ageGroups
                    ? autobooking.ageGroups
                          .map(ageGroup => FilterConstants.ageGroups[ageGroup])
                          .join(', ')
                    : noResultString;
            case 'surcharge':
                return autobooking.withoutSurchargeOnly ? (
                    <FontIcon icon="" />
                ) : (
                    ''
                );
            case 'loconfig':
                return autobooking.isMatchActiveInLiveoddsConfig ? (
                    <FontIcon icon="" />
                ) : (
                    ''
                );
            case 'single':
                return autobooking.bookableWithSinglePackage ? (
                    <FontIcon icon="" />
                ) : (
                    ''
                );

            // to add a new autobooking filter/column, edit here and every other place this comment exists

            default:
                return '?';
        }
    }

    _buildShowMoreCell(autobooking) {
        const missingFilters = getNonDefaultFilters(autobooking).filter(
            filter => !this.props.orderedSelectedFilters.includes(filter)
        );
        const showMissingFilters = () => {
            this.props.addFiltersSelected(missingFilters);
        };

        let content = '';

        if (missingFilters.length) {
            content = (
                <Button
                    type={Button.types.SMALL_WHITE_BORDER}
                    fixedWidth={false}
                    onClick={showMissingFilters}
                    title={`Show ${missingFilters.length} additional attributes relevant for this autobooking rule`}
                >
                    show {missingFilters.length} more
                </Button>
            );
        }

        return (
            <div
                className={'autobooking-cell more'}
                key={`${autobooking.id}_more`}
            >
                {content}
            </div>
        );
    }

    // TODO use this, or delete it
    _getSportClassAndWarning(autobooking) {
        let sportClass = '';
        let sportWarning = '';

        if (autobooking.isMatchActiveInLiveoddsConfig) {
            const hasDisabledChildren =
                autobooking.sportId &&
                this.props.allSports[autobooking.sportId].hasDisabledChildren;
            sportClass = hasDisabledChildren
                ? 'hasDisabledChildren'
                : sportClass;
            sportWarning = hasDisabledChildren
                ? 'Categories/Tournaments disabled within the Sport in LO configuration. Matches in those categories/tournaments will not be booked'
                : sportWarning;

            const disabled =
                autobooking.sportId &&
                this.props.allSports[autobooking.sportId].disabledInLOConfig;
            sportClass = disabled ? 'disabled' : sportClass;
            sportWarning = disabled
                ? 'Sport disabled in LO config, matches will not be booked'
                : sportWarning;
        }

        return { sportClass, sportWarning };
    }

    _buildAutobookingRows() {
        const { currentPage, autobookingsPerPage } = this.props;
        const autobookingsToShow = this.props.filteredAutobookings.slice(
            currentPage * autobookingsPerPage,
            currentPage * autobookingsPerPage + autobookingsPerPage
        );

        const rows = [];
        autobookingsToShow.forEach(autobooking =>
            rows.push(...this._buildAutobookingRow(autobooking))
        );

        return rows;
    }

    _getCheckboxClickedListener() {
        return () => {
            this.props.saveAutobookingConfig(
                this.props.sendConfirmation ? 0 : 1
            );
        };
    }

    _buildSendEmailCheckbox() {
        if (this.props.canLiveBook) {
            return (
                <div className="email-config ui-400 text-gray-1100 text-left">
                    <Checkbox
                        size={15}
                        checked={this.props.sendConfirmation}
                        id="emailConfirmationCheckbox"
                        onClick={this._getCheckboxClickedListener()}
                        contentPosition="right"
                    >
                        Send e-mail confirmation
                    </Checkbox>
                </div>
            );
        }

        return '';
    }

    _deleteFilteredAutobookings() {
        this.props.addDialog(dialogTypes.DELETE_AUTO_BOOKING, {
            ids: this.props.expiredRuleIds,
        });
    }

    _buildAutobookingRuleTypeDropdown() {
        const options = Object.values(FilterConstants.autobookingTypes).map(
            item => ({ value: item, text: item })
        );
        return (
            <DropDown
                options={options}
                value={this.props.selectedAutobookingType}
                onChange={value => this.props.setAutobookingType(value)}
                label={''}
            ></DropDown>
        );
    }

    _buildExpiredAutobookingButton() {
        if (this.props.expiredRuleIds.length) {
            return (
                <Button
                    icon=""
                    type={Button.types.SMALL_WHITE_BORDER}
                    fixedWidth={230}
                    onClick={this._deleteFilteredAutobookings.bind(this)}
                    title={'Delete rules that are no longer valid'}
                >
                    delete
                    <Badge
                        color="gray-light"
                        className="textline-100 text-black text-center"
                        content={this.props.expiredRuleIds.length}
                    />
                    expired autobookings
                </Button>
            );
        }
        return '';
    }

    render() {
        let content = '';
        if (this.props.filteredAutobookings.length > 0) {
            content = this._buildAutobookingRows();
        } else if (Object.keys(this.props.autobookings).length > 0) {
            content = (
                <Alert type={Alert.types.EXPLANATORY} style={{ marginTop: 5 }}>
                    {'Nothing matches these filters. Try different filters'}
                </Alert>
            );
        } else {
            content = (
                <Alert type={Alert.types.EXPLANATORY} style={{ marginTop: 5 }}>
                    {'No autobookings. Use the form on top to create one'}
                </Alert>
            );
        }

        if (this.props.isFetching) {
            return <BusySpinner />;
        }

        return (
            <div className="autobooking-list-overview">
                <div className="show-rules-container">
                    <div className="show-rules-text textline-400 text-gray-1100 text-left text-semibold ">
                        SHOW RULES
                    </div>
                    <div className="show-rules-items">
                        {this._buildAutobookingRuleTypeDropdown()}
                        {this._buildExpiredAutobookingButton()}
                        {this._buildSendEmailCheckbox()}
                    </div>
                </div>
                <div className="show-filters-button-container">
                    <div className="filters-header">
                        <div className="filters-title headline-700 text-black text-left">
                            <FontIcon className="filter-icon" icon="" />
                            ATTRIBUTES
                        </div>
                        <CustomizeFilters />
                    </div>
                </div>
                <AutobookingFilters
                    filteredAutobookingRulesCount={
                        this.props.filteredAutobookings.length
                    }
                    totalAutobookingRulesCount={
                        Object.keys(this.props.autobookings).length
                    }
                />
                <div className="autobooking-table">{content}</div>
            </div>
        );
    }
}

AutobookingListOverview.propTypes = {
    canLiveBook: PropTypes.bool.isRequired,
    createNotification: PropTypes.func.isRequired,
    deleteAutobooking: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    fetchAutobookingConfig: PropTypes.func.isRequired,
    saveAutobookingConfig: PropTypes.func.isRequired,
    addFiltersSelected: PropTypes.func.isRequired,
    setTotalItems: PropTypes.func.isRequired,
    editId: PropTypes.number,
    autobookings: PropTypes.object,
    expiredRuleIds: PropTypes.array.isRequired,
    filteredAutobookings: PropTypes.array,
    orderedSelectedFilters: PropTypes.array.isRequired,
    setEditId: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    isFetched: PropTypes.bool,
    sendConfirmation: PropTypes.bool,
    loadAllAutobookings: PropTypes.func.isRequired,
    allSports: PropTypes.object.isRequired,
    access: PropTypes.object.isRequired,
    isAutobookingRuleHistoryEnabled: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    autobookingsPerPage: PropTypes.number.isRequired,
    selectedAutobookingType: PropTypes.string.isRequired,
    setAutobookingType: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        autobookings: autobookingsByType(state),
        filteredAutobookings: filteredAutobookings(state),
        expiredRuleIds: expiredRuleIds(state),
        orderedSelectedFilters: orderedSelectedFilters(state.autobooking),
        editId: state.autobooking.editId,
        isFetching: state.autobooking.isFetching,
        isFetched: state.autobooking.isFetched,
        canLiveBook: canLiveBookSelector(state.calendar),
        selectedAutobookingType: state.autobooking.selectedAutobookingType,
        sendConfirmation: state.autobookingConfig.sendConfirmation,
        allSports: state.calendar.sports,
        access: state.calendar.access,
        isAutobookingRuleHistoryEnabled:
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .enable_autobooking_rule_history,
        currentPage: state.pagination.currentPage,
        autobookingsPerPage: state.pagination.itemsPerPage,
    };
};

const mapDispatchToProps = {
    createNotification,
    deleteAutobooking,
    addDialog,
    fetchAutobookingConfig,
    saveAutobookingConfig,
    loadAllAutobookings,
    setEditId,
    setTotalItems,
    addFiltersSelected,
    setAutobookingType,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutobookingListOverview);
