import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import isEqual from 'react-fast-compare';
import Button from '../../../../../formInput/Button/Button';
import FontIcon from '../../../../../icons/FontIcon/FontIcon.jsx';
import TextBanner, { bannerTypes } from '../../../../../TextBanner/TextBanner';
import {
    addTextBanner,
    updateTextBanner,
    setTextBanners,
    fetchAllTextBanners,
} from '../../../../../../stores/textBanner/actions';
import textBannerIsForDay, {
    textBannerIsPast,
    textBannerIsFuture,
} from '../../../../../../selectors/textBannerIsForDay';
import TextInput from '../../../../../formInput/TextInput/TextInput';
import Alert from '../../../../../Alert/Alert';
import DatePicker from 'react-datepicker';
import moment from '../../../../../../utils/CalendarMoment';
import { addDialog } from '../../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../../stores/dialog/constants';
import classnames from 'classnames';
import './textBannerAdmin.scss';

const unlimitedTimeNames = {
    from: 'Since Time Immemorial',
    to: 'Forever More',
};

class TextBannerAdmin extends Component {
    constructor(props) {
        super(props);
        this.textarea = createRef();

        const initialTime = new Date(new Date().setHours(0, 0, 0, 0));

        this.state = {
            title: '',
            subTitle: '',
            type: bannerTypes.INFO,
            validFrom: initialTime,
            validFromDisabled: false,
            validTo: initialTime,
            validToDisabled: false,
            editId: -1,
            currentlyEditing: {},
            actionInProgress: false,
            rows: 1,
            fromDate: new Date(),
            toDate: new Date(),
        };
    }

    componentDidMount() {
        this.props.fetchAllTextBanners();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.textBanners, this.props.textBanners)) {
            this._onCancelEditBtnClicked();
        }
    }

    toggleValidFromDisabled() {
        this.setState({ validFromDisabled: !this.state.validFromDisabled });
    }

    toggleValidToDisabled() {
        this.setState({ validToDisabled: !this.state.validToDisabled });
    }

    _onSubmitClick() {
        const validFrom = Math.floor(this.state.validFrom.getTime() / 1000);
        const validTo = Math.floor(this.state.validTo.getTime() / 1000);

        const textBanner = {
            id: this.state.editId,
            title: this.state.title,
            subTitle: this.state.subTitle,
            type: this.state.type,
            validFrom: !this.state.validFromDisabled && validFrom,
            validTo: !this.state.validToDisabled && validTo,
        };

        this.setState({
            actionInProgress: true,
        });

        if (this.state.editId > -1) {
            this.props.updateTextBanner(textBanner);
        } else {
            this.props.addTextBanner(textBanner);
        }
    }

    _deleteBanner(banner) {
        this.props.addDialog(dialogTypes.DELETE_TEXT_BANNER, { banner });
    }

    _onEditBtnClicked(banner) {
        const currentlyEditing = { ...banner };

        const initialTime = new Date();

        const validFrom = new Date(banner.validFrom * 1000) || initialTime;
        const validTo = new Date(banner.validTo * 1000) || initialTime;

        const rows = this._getSubtitleRows(banner.subTitle);

        this.setState({
            editId: banner.id,
            title: banner.title,
            subTitle: banner.subTitle,
            rows,
            type: banner.type,
            validFrom,
            validFromDisabled: !banner.validFrom,
            validTo,
            validToDisabled: !banner.validTo,
            currentlyEditing, // Store the banner we are editing so that we can revert changes if necessarry
        });
    }

    _onCancelEditBtnClicked() {
        this.setState({
            editId: -1,
            title: '',
            subTitle: '',
            rows: 1,
            currentlyEditing: {},
            actionInProgress: false,
        });
    }

    _buildCurrentBannerList() {
        const banners = this._buildBannerList(
            this.props.textBanners.filter(banner =>
                textBannerIsForDay(Date.now() / 1000, banner)
            )
        );
        return (
            <div className="textBannerList">
                <h2>Current text banners</h2>
                {banners.length ? banners : 'No current banners.'}
            </div>
        );
    }

    _buildUpcomingBannerList() {
        const banners = this._buildBannerList(
            this.props.textBanners.filter(banner =>
                textBannerIsFuture(Date.now() / 1000, banner)
            )
        );
        return (
            <div className="textBannerList">
                <h2>Upcoming text banners</h2>
                {banners.length ? banners : 'No upcoming banners.'}
            </div>
        );
    }

    _buildOldBannerList() {
        const banners = this._buildBannerList(
            this.props.textBanners.filter(banner =>
                textBannerIsPast(Date.now() / 1000, banner)
            )
        );
        return (
            <div className="textBannerList">
                <h2>Old text banners</h2>
                {banners.length ? banners : 'No old banners.'}
            </div>
        );
    }

    _buildBannerList(textBanners) {
        return textBanners.map((banner, key) => {
            let buttons = <></>;

            if (this.state.editId > -1) {
                if (this.state.editId === banner.id) {
                    buttons = (
                        <div className="editBtns">
                            <div
                                className="cancelEditBtn"
                                onClick={this._onCancelEditBtnClicked.bind(
                                    this
                                )}
                            >
                                (Exit edit mode)
                            </div>
                        </div>
                    );
                }
            } else {
                buttons = (
                    <div className="editBtns">
                        <div
                            className="editBtn"
                            onClick={() => this._onEditBtnClicked(banner)}
                            title="edit"
                        >
                            <FontIcon icon="" />
                        </div>
                        <div
                            className="deleteBtn"
                            onClick={() => this._deleteBanner(banner)}
                            title="delete"
                        >
                            <FontIcon icon="" />
                        </div>
                    </div>
                );
            }

            return (
                <div className="textBannerRow" key={'text_banner_row_' + key}>
                    <div className="textBannerDates">
                        <div className="test">
                            <span>
                                {this._formatDateTime(banner.validFrom, true)}
                            </span>
                            <span>-</span>
                            <span>
                                {this._formatDateTime(banner.validTo, false)}
                            </span>
                        </div>
                    </div>
                    <TextBanner
                        title={banner.title}
                        subTitle={banner.subTitle}
                        type={parseInt(banner.type, 10)}
                        constrained
                    />
                    {this.props.access.admin_write && buttons}
                </div>
            );
        });
    }

    _formatDateTime(timestamp, isFrom) {
        if (!timestamp) {
            return isFrom ? unlimitedTimeNames.from : unlimitedTimeNames.to;
        }
        return moment.fromTimestamp(timestamp).toFormat('dd.MM.yyyy T');
    }

    _isUpdatingButHasNotChangedAnything() {
        if (
            !(
                this.state.currentlyEditing &&
                Object.keys(this.state.currentlyEditing).length > 0
            )
        ) {
            return false;
        }

        return (
            this.state.currentlyEditing.validFrom === this.state.validFrom &&
            !this.state.currentlyEditing.validFrom ===
                this.state.validFromDisabled &&
            this.state.currentlyEditing.validTo === this.state.validTo &&
            !this.state.currentlyEditing.validTo ===
                this.state.validToDisabled &&
            this.state.currentlyEditing.title === this.state.title &&
            this.state.currentlyEditing.subTitle === this.state.subTitle &&
            this.state.currentlyEditing.type === this.state.type
        );
    }

    _getSubtitleRows = subTitle => {
        if (this.textarea.current.value !== subTitle) {
            this.textarea.current.value = subTitle;
        }
        const oldRows = this.textarea.current.rows;
        this.textarea.current.rows = 1;
        const lineHeight = parseFloat(
            window
                .getComputedStyle(this.textarea.current)
                .getPropertyValue('line-height')
        );

        const newRows = ~~(this.textarea.current.scrollHeight / lineHeight);

        if (newRows === oldRows) {
            this.textarea.current.rows = newRows;
        }

        return newRows;
    };

    onChangeTitle = title => {
        this.onChangeMessage('title', title);
    };

    onChangeSubTitle = subTitle => {
        const rows = this._getSubtitleRows(subTitle);
        this.onChangeMessage('subTitle', subTitle, rows);
    };

    onChangeMessage = (state, title, rows) => {
        const stateChanges = {
            [state]: title,
        };
        if (rows) {
            stateChanges.rows = rows;
        }

        this.setState(stateChanges);
    };

    _buildTextBannerControls() {
        let errorMsg = '',
            inputIsValid = false;

        if (
            !this.state.validFromDisabled &&
            !this.state.validToDisabled &&
            this.state.validFrom > this.state.validTo
        ) {
            errorMsg = "'Valid from' can not be after 'Valid to'";
        } else if (this.state.title.length > 0) {
            inputIsValid = true;
        }

        const daysBetween =
            (this.state.validTo.getTime() - this.state.validFrom.getTime()) /
            (1000 * 3600 * 24);

        const warningMsg =
            this.state.validFromDisabled ||
            this.state.validToDisabled ||
            daysBetween > 1
                ? 'Warning: the selected date range is more than 1 day long. Are you sure this is what you want?'
                : daysBetween === 0
                ? 'Warning: there is no difference between the selected dates.'
                : '';

        const options = _.map(bannerTypes, (value, text) => (
            <option value={value} key={'banner_type_' + value}>
                {text.toLowerCase()}
            </option>
        ));

        return (
            <>
                <Alert type={Alert.types.EXPLANATORY} icon="" float={false}>
                    <p>
                        <b>Info: </b>The title and sub title accepts{' '}
                        <a
                            href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#links"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            markdown links
                        </a>
                        . This means you can for example write a [link to
                        betradar](https://betradar.com) in the middle of the
                        content.
                    </p>
                </Alert>
                <TextInput
                    labelText="Title"
                    className="adminInputWrapper"
                    onChange={this.onChangeTitle}
                    value={this.state.title}
                    inputClassName="adminInput wide"
                />
                <div className="adminInputWrapper text-area">
                    <div className="centered-label-container">
                        <label className="text-label">Text</label>
                    </div>
                    <textarea
                        rows={this.state.rows}
                        className="text-input"
                        onChange={e => this.onChangeSubTitle(e.target.value)}
                        value={this.state.subTitle}
                        ref={this.textarea}
                    />
                </div>

                <div className="adminInputWrapper">
                    <label>Type</label>
                    <select
                        className="adminInput"
                        value={this.state.type}
                        onChange={e => this.setState({ type: e.target.value })}
                    >
                        {options}
                    </select>
                </div>
                <div className="adminInputWrapper dateTimePickerWrapper">
                    <div className="centered-label-container">
                        <label className="text-label">Valid from</label>
                    </div>
                    <div>
                        <DatePicker
                            title="Valid from"
                            placeholderText="Valid from"
                            selected={this.state.validFrom}
                            onChange={date =>
                                this.setState({ validFrom: date })
                            }
                            className="text-input"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            todayButton="Today"
                            dateFormat="dd.MM.yyyy HH:mm" // aa to get AM/PM
                            disabled={this.state.validFromDisabled}
                        />
                    </div>
                    <button
                        className={classnames('disableButton ', {
                            active: this.state.validFromDisabled,
                        })}
                        onClick={() => this.toggleValidFromDisabled()}
                    >
                        {unlimitedTimeNames.from} (click to toggle)
                    </button>
                </div>

                <div className="adminInputWrapper dateTimePickerWrapper">
                    <div className="centered-label-container">
                        <label className="text-label">Valid to</label>
                    </div>
                    <div>
                        <DatePicker
                            title="Valid to"
                            placeholderText="Valid to"
                            selected={this.state.validTo}
                            onChange={date => this.setState({ validTo: date })}
                            showTimeSelect
                            className="text-input"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            todayButton="Today"
                            dateFormat="dd.MM.yyyy HH:mm"
                            disabled={this.state.validToDisabled}
                            minDate={this.state.validFrom}
                        />
                    </div>
                    <button
                        className={classnames('disableButton ', {
                            active: this.state.validToDisabled,
                        })}
                        onClick={() => this.toggleValidToDisabled()}
                    >
                        {unlimitedTimeNames.to} (click to toggle)
                    </button>
                </div>
                <div className="adminInputWrapper">
                    <Button
                        className="adminInput submitBtn"
                        type={Button.types.LARGE_BLUE_3D}
                        onClick={this._onSubmitClick.bind(this)}
                        disabled={
                            !inputIsValid ||
                            this.state.actionInProgress ||
                            this._isUpdatingButHasNotChangedAnything()
                        }
                    >
                        {this.state.editId > -1 ? 'Update' : 'Save'}
                    </Button>
                    <div className={errorMsg.length > 0 ? 'error' : 'warning'}>
                        {errorMsg || warningMsg}
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="text-banner-admin">
                {this.props.access.admin_write &&
                    this._buildTextBannerControls()}
                {this._buildCurrentBannerList()}
                {this._buildUpcomingBannerList()}
                {this._buildOldBannerList()}
            </div>
        );
    }
}

TextBannerAdmin.propTypes = {
    addTextBanner: PropTypes.func.isRequired,
    updateTextBanner: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    setTextBanners: PropTypes.func.isRequired,
    fetchAllTextBanners: PropTypes.func.isRequired,
    textBanners: PropTypes.array.isRequired,
    access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    textBanners: [...state.textBanner.textBanners],
    access: state.calendar.access,
});

const mapDispatchToProps = {
    addDialog,
    addTextBanner,
    updateTextBanner,
    setTextBanners,
    fetchAllTextBanners,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextBannerAdmin);
