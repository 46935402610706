import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './stageQuickInfo.scss';
import { connect } from 'react-redux';
import { stageNames } from '../../../../constants/sports';

class StageQuickInfo extends Component {
    _buildKeyValue(key, value) {
        return (
            <div className="key-value" key={key}>
                <span>{key}</span>
                <span>{value}</span>
            </div>
        );
    }

    render() {
        const { event } = this.props;

        let stages = [];
        Object.values(event.ancestors).forEach(stage =>
            stages.push(
                this._buildKeyValue(
                    stageNames.find(el => el.id === stage.typeId).name,
                    stage.name
                )
            )
        );

        return (
            <div className="stage-quick-info">
                <div className="row">
                    <div className="col">
                        {this._buildKeyValue('Event id', event.uri)}
                        {this._buildKeyValue(
                            stageNames.find(stage => stage.id === event.typeId)
                                .name,
                            event.name
                        )}
                        {this._buildKeyValue(
                            'Category id',
                            event.realCategoryName
                        )}
                    </div>
                    <div className="col">{stages}</div>
                </div>
            </div>
        );
    }
}

StageQuickInfo.defaultProps = {
    optionalKeyValue: null,
};

StageQuickInfo.propTypes = {
    event: PropTypes.object.isRequired,
    sports: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        sports: state.calendar.sports,
    };
};
export default connect(mapStateToProps)(StageQuickInfo);
