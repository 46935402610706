import _ from 'underscore';
import { Info } from 'luxon';
import types, { type as historyType } from './constants';

const fieldsToSum = [
    'bookings-total',
    'bookings-package',
    'bookings-single',
    'bookings-surcharged',
];

const parseSport = (sport, key) => {
    return {
        ...sport,
        name: sport.sportName,
        key: `${key}-${sport.sportName}`,
    };
};

const parsePackage = (year, month, packageName, packageData) => {
    const key = `${year}-${month}-${packageName}`;
    const parsedData = {
        name: packageName,
        key,
        children: [],
    };
    for (const field of fieldsToSum) {
        parsedData[field] = 0;
    }

    for (const sport of Object.values(packageData)) {
        const parsedSport = parseSport(sport, key);

        for (const field of fieldsToSum) {
            parsedData[field] += parsedSport[field];
        }
        parsedData.children.push(parsedSport);
    }

    return parsedData;
};

const parseMonth = (year, month, monthData) => {
    const parsedData = {
        name: `${year} ${month}`,
        key: `${year}-${month}`,
        children: [],
        year: year,
        month: month,
    };
    for (const field of fieldsToSum) {
        parsedData[field] = 0;
    }
    for (const [packageName, packageData] of Object.entries(monthData)) {
        const parsedPackage = parsePackage(
            year,
            month,
            packageName,
            packageData
        );

        for (const field of fieldsToSum) {
            parsedData[field] += parsedPackage[field];
        }
        parsedData.children.push(parsedPackage);
    }

    return parsedData;
};

const parseOverviewData = data => {
    const parsedData = [];
    const allMonthNamesSorted = Info.months().map(m => m.toLowerCase());
    const sortedYears = Object.entries(data).sort((a, b) => b[0] - a[0]);
    for (const [year, yearData] of sortedYears) {
        const sortedMonths = Object.entries(yearData).sort(
            (a, b) =>
                allMonthNamesSorted.indexOf(b[0].toLowerCase()) -
                allMonthNamesSorted.indexOf(a[0].toLowerCase())
        );
        for (const [month, monthData] of sortedMonths) {
            parsedData.push(parseMonth(year, month, monthData));
        }
    }

    return parsedData;
};

const getDefaultDataState = () => {
    return {
        loading: false,
        error: false,
        loadedAt: null,
        data: null,
        loadingMore: false,
    };
};

const getMyTimezone = (defaultValue = true) => {
    const myTimezone = localStorage.getItem('detail_view_timezone');
    if (!myTimezone && !_.isNull(myTimezone)) {
        return defaultValue;
    }
    if (_.isNull(myTimezone)) {
        return defaultValue;
    }
    return parseInt(myTimezone, 10) === 1;
};

const initialHistoryStates = {};
for (const type of Object.values(historyType)) {
    initialHistoryStates[type] = {
        overview: getDefaultDataState(),
    };
}

const initialState = {
    ...initialHistoryStates,
    isDisplayTimezoneMyTimeZone: getMyTimezone(),
};

export default (state, action) => {
    switch (action.type) {
        case types.LOAD_OVERVIEW_START:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    overview: {
                        ...getDefaultDataState(),
                        loading: true,
                    },
                },
            };
        case types.LOAD_OVERVIEW_SUCCESS:
            const newData =
                action.payload.type === 'LO'
                    ? parseOverviewData(action.payload.data)
                    : action.payload.data;
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    overview: {
                        ...state[action.payload.type].overview,
                        data: newData,
                        loading: false,
                        loadedAt: action.payload.loadedAt,
                    },
                },
            };
        case types.LOAD_MORE_START:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    overview: {
                        ...state[action.payload.type].overview,
                        loadingMore: true,
                    },
                },
            };
        case types.LOAD_MORE_SUCCESS:
            let data;
            if (action.payload.type === 'LO') {
                data = [
                    ...state[action.payload.type].overview.data,
                    ...parseOverviewData(action.payload.data),
                ];
            } else {
                data = state[action.payload.type].overview.data;

                for (var year in action.payload.data) {
                    if (!action.payload.data.hasOwnProperty(year)) {
                        continue;
                    }
                    if (data[year]) {
                        data[year] = {
                            ...data[year],
                            ...action.payload.data[year],
                        };
                    } else {
                        data[year] = action.payload.data[year];
                    }
                }
            }
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    overview: {
                        ...state[action.payload.type].overview,
                        data: data,
                        loadingMore: false,
                    },
                },
            };
        case types.LOAD_MORE_FAIL:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    overview: {
                        ...state[action.payload.type].overview,
                        loadingMore: false,
                    },
                },
            };
        case types.SET_IS_DISPLAY_MY_TIME_ZONE:
            return {
                ...state,
                isDisplayTimezoneMyTimeZone: action.payload,
            };
        default:
            return state || initialState;
    }
};
