import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../../../Alert/Alert';
import Button from '../../../formInput/Button/Button';
import DropDown from '../../../formInput/Dropdown/DropDown';
import Event from '../../../Event/Event';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import './controlOddsDialog.scss';
import classnames from 'classnames';

const loading = props => {
    const loadingData = loadingStepData[props.loadingStep];
    return (
        <div className="control-odds-dialog-loading">
            <Alert type={loadingData.alertType}>{loadingData.message}</Alert>
            <BusySpinner />
        </div>
    );
};

const error = props => {
    return (
        <div className="control-odds-dialog-loading">
            <Alert type={Alert.types.ERROR}>{props.error}</Alert>
        </div>
    );
};

const alert = props => {
    if (props.price.priceChanged) {
        return (
            <Alert type={Alert.types.INFO}>
                The match is not imported to Betpal because of a price change.
                Please check and import again.
            </Alert>
        );
    }
    return '';
};

const willSwitch = props => {
    if (props.willSwitch) {
        return (
            <Alert type={Alert.types.INFO}>
                Please confirm the switch in the BetPal Trading Platform.
            </Alert>
        );
    }
    return '';
};

const price = props => {
    if (props.price.isLoading) {
        return (
            <div className="price">
                <BusySpinner size={16} />
            </div>
        );
    }
    return (
        <div
            className={classnames('price', {
                orange: props.price.priceChanged,
            })}
        >
            {props.price.price}
        </div>
    );
};

const content = props => {
    return (
        <div className="control-odds-dialog">
            <DropDown
                label={'Choose Trader'}
                onChange={props.traderChange}
                options={props.traderOptions}
                value={props.traderValue}
            />
            <DropDown
                label={'Choose Template'}
                onChange={props.templateChange}
                options={props.templateOptions}
                value={props.templateValue}
            />
            <div className="legend-row">
                <div>Price</div>
            </div>
            <div className="event-row">
                <Event event={props.event} />
                {price(props)}
            </div>
            <div className="feedback-container">
                {alert(props)}
                {willSwitch(props)}
            </div>
        </div>
    );
};

const buildContent = props => {
    if (props.loadingStep) {
        return loading(props);
    } else if (props.error) {
        return error(props);
    }
    return content(props);
};

const Component = props => {
    return (
        <div className="dialog-content-wrapper control-odds">
            <div className="content">{buildContent(props)}</div>
            <div className="dialog-footer">
                <Button
                    onClick={props.onClose}
                    type={Button.types.LARGE_GREY_SHADOW}
                    fixedWidth={false}
                    icon={''}
                >
                    Cancel
                </Button>
                <Button
                    onClick={props.onConfirm}
                    type={Button.types.LARGE_BLUE_SHADOW}
                    fixedWidth={false}
                    disabled={!!props.loadingStep || !!props.error}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
};

Component.loadingStep = {
    DATA: 1,
    SWITCHING_MATCH_TO_BETPAL: 2,
};

const loadingStepData = {
    [Component.loadingStep.DATA]: {
        message: 'Loading BetPal configurations',
        alertType: Alert.types.INFO,
    },
    [Component.loadingStep.SWITCHING_MATCH_TO_BETPAL]: {
        message: 'Importing match.',
        alertType: Alert.types.INFO,
    },
};

loading.propTypes = {
    loadingStep: PropTypes.number,
};

error.propTypes = {
    error: PropTypes.string,
};

alert.propTypes = {
    price: PropTypes.object.isRequired,
};

willSwitch.propTypes = {
    willSwitch: PropTypes.bool,
};

price.propTypes = {
    price: PropTypes.object.isRequired,
};

content.propTypes = {
    traderChange: PropTypes.func.isRequired,
    traderValue: PropTypes.any.isRequired,
    traderOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    templateChange: PropTypes.func.isRequired,
    templateValue: PropTypes.any.isRequired,
    templateOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    event: PropTypes.object.isRequired,
};

Component.propTypes = {
    ...loading,
    ...error,
    ...alert,
    ...willSwitch,
    ...price,
    ...content,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default Component;
