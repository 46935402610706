import { createSelector } from 'reselect';
import moment from '../../utils/CalendarMoment';

const isAutobookingExpired = autobooking => {
    if (
        autobooking.tournamentEnd != null &&
        moment.fromTimestamp(autobooking.tournamentEnd) < moment.now()
    ) {
        return true;
    }
    if (
        autobooking.endDate &&
        moment.fromTimestamp(autobooking.endDate) < moment.now()
    ) {
        return true;
    }
    return false;
};

export default createSelector(
    state => state.autobooking.autobookings,
    autobookings => {
        return Object.values(autobookings)
            .filter(autobooking => isAutobookingExpired(autobooking))
            .map(autobooking => autobooking.id);
    }
);
