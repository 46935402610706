import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import { deleteAutobooking } from '../../../../stores/autobooking/actions';

const DeleteAutobookingDialog = props => {
    const onConfirm = () => {
        props.deleteAutobooking(props.ids);
        props.onClose();
    };
    const ruleString =
        props.ids.length === 1
            ? 'this rule'
            : `these ${props.ids.length} rules`;

    return (
        <Confirmation onConfirm={onConfirm} onReject={props.onClose}>
            {`Are you sure you want to delete ${ruleString}?`}
        </Confirmation>
    );
};

DeleteAutobookingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    deleteAutobooking: PropTypes.func.isRequired,
    ids: PropTypes.array,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { deleteAutobooking };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteAutobookingDialog);
