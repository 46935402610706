import shouldReload from '../shouldReload';

export default (state, historyType) => {
    if (
        state.bookingHistory &&
        state.bookingHistory[historyType] &&
        state.bookingHistory[historyType].overview
    ) {
        return shouldReload(state.bookingHistory[historyType].overview);
    } else {
        return true;
    }
};
