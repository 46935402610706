import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import { deleteFilterSet } from '../../../../stores/calendar/actions';

const DeleteFilterSetDialog = props => {
    const onConfirm = () => {
        props.deleteFilterSet(props.filterSetId);
        props.onClose();
    };

    return (
        <Confirmation onConfirm={onConfirm} onReject={props.onClose}>
            {`Are you sure you want to delete "${props.filterSetTitle}"?`}
        </Confirmation>
    );
};

DeleteFilterSetDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    filterSetId: PropTypes.number.isRequired,
    filterSetTitle: PropTypes.string.isRequired,
    deleteFilterSet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { deleteFilterSet };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteFilterSetDialog);
