import React from 'react';
import PropTypes from 'prop-types';
import RemoteData from '../../../RemoteData/RemoteData';
import Component from './Component';
import settings from '../../../../utils/DefaultSettings';

const LoadFilterSelectionDialog = props => {
    return (
        <RemoteData
            key="formLoadFilterSelection"
            uri={settings.remoteData.filter.uri}
            caching={settings.remoteData.filter.caching}
            validatorFunction={() => true}
        >
            <Component onClose={props.onClose} />
        </RemoteData>
    );
};

LoadFilterSelectionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LoadFilterSelectionDialog;
