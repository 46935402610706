export const tabs = {
    FILTERS: 'FILTERS',
    MATCH_INFO: 'MATCH_INFO',
    PACKAGES: 'PACKAGES',
    RECOMMENDER: 'RECOMMENDER',
    LEGEND: 'LEGEND',
    SETTINGS: 'SETTINGS',
    FORM: 'FORM',
    INFO: 'INFO',
    EXPORT: 'EXPORT',
};

export default {
    SET_TAB: 'SET_TAB',
    INITIALIZE: 'INITIALIZE',
};
