import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { removeDialog } from '../../../stores/dialog/actions';
import { clearXhrError } from '../../../stores/calendar/actions';
import { closeBetpalBooking } from '../../../stores/shoppingBasket/actions';
import LoadFilterSelection from '../dialogComponents/LoadFilterSelectionDialog/LoadFilterSelectionDialog';
import SaveFilterSelection from '../dialogComponents/SaveFilterSelectionDialog/SaveFilterSelectionDialog';
import DialogMask from '../DialogMask/DialogMask';
import { dialogTypes } from '../../../stores/dialog/constants';
import ExportDialog from '../dialogComponents/ExportDialog/ExportDialog';
import ControlOddsDialog from '../dialogComponents/ControlOddsDialog/ControlOddsDialog';
import UserSwitcher from '../dialogComponents/UserSwitcherDialog/UserSwitcherDialog';
import ObjectDialog from '../dialogComponents/ObjectDialog/ObjectDialog';
import EventInfoDialog from '../dialogComponents/EventInfoDialog/EventInfoDialog';
import ExtendedEventInfoDialog from '../dialogComponents/ExtendedEventInfoDialog/ExtendedEventInfoDialog';
import BetpalCheckoutDialog from '../dialogComponents/BetpalCheckoutDialog/BetpalCheckoutDialog';
import SaveAutobookingDialog from '../dialogComponents/SaveAutobookingDialog/SaveAutobookingDialog';
import SaveFilterSetDialog from '../dialogComponents/SaveFilterSetDialog/SaveFilterSetDialog';
import BulkSelectDialog from '../dialogComponents/BulkSelectDialog/BulkSelectDialog';
import DeleteFilterSetDialog from '../dialogComponents/DeleteFilterSetDialog/DeleteFilterSetDialog';
import DeleteTextBannerDialog from '../dialogComponents/DeleteTextBannerDialog/DeleteTextBannerDialog';
import DeleteAutobookingDialog from '../dialogComponents/DeleteAutobookingDialog/DeleteAutobookingDialog';
import OverLimitDialog from '../dialogComponents/OverLimitDialog/OverLimitDialog';
import ChangelogDialog from '../dialogComponents/ChangelogDialog/ChangelogDialog';
import './dialog.scss';
import DeleteFeatureDialog from '../dialogComponents/DeleteFeatureDialog/DeleteFeatureDialog';
import LiveChannelInfoDialog from '../dialogComponents/LiveChannelInfoDialog/LiveChannelInfoDialog';
import DeleteChangelogEntryDialog from '../dialogComponents/DeleteChangelogEntryDialog/DeleteChangelogEntryDialog';
import LoggerDialog from '../dialogComponents/LoggerDialog/LoggerDialog';
import NotificationDialog from '../dialogComponents/NotificationDialog/NotificationDialog';
import MatchConfigDialog from '../dialogComponents/MatchConfigDialog/MatchConfigDialog';
import SaveAttributeSelectionDialog from '../dialogComponents/SaveAttributeSelectionDialog/SaveAttributeSelectionDialog';
import LoadAttributeSelectionDialog from '../dialogComponents/LoadAttributeSelectionDialog/LoadAttributeSelectionDialog';
import ErrorDialog from '../dialogComponents/ErrorDialog/ErrorDialog';
import DeleteAccessDialog from '../dialogComponents/DeleteAccessDialog/DeleteAccessDialog';
import FontIcon from '../../icons/FontIcon/FontIcon';
import LegendDialog from '../dialogComponents/LegendDialog/LegendDialog';

const dialogs = {
    [dialogTypes.LOAD_FILTER]: {
        title: 'Load Filters',
        component: LoadFilterSelection,
    },
    [dialogTypes.SAVE_FILTER]: {
        title: 'Save Filters',
        component: SaveFilterSelection,
    },
    [dialogTypes.OVERWRITE_SAVED_FILTER_SET]: {
        title: 'Confirm',
        component: SaveFilterSetDialog,
    },
    [dialogTypes.DELETE_FILTER_SET]: {
        title: 'Confirm',
        component: DeleteFilterSetDialog,
    },
    [dialogTypes.EXPORT]: {
        title: 'Export',
        component: ExportDialog,
    },
    [dialogTypes.CONTROL_ODDS]: {
        title: 'Control Odds',
        component: ControlOddsDialog,
    },
    [dialogTypes.USER_SWITCHER]: {
        title: 'User Switcher',
        component: UserSwitcher,
    },
    [dialogTypes.EVENT_OBJECT]: {
        title: 'Event Object',
        component: ObjectDialog,
    },
    [dialogTypes.EVENT_INFO]: {
        title: 'Event Info',
        component: EventInfoDialog,
    },
    [dialogTypes.EXTENDED_EVENT_INFO]: {
        title: 'Extended Event Info',
        component: ExtendedEventInfoDialog,
    },
    [dialogTypes.MASTER_INFO]: {
        title: 'Master Event Information',
        component: LiveChannelInfoDialog,
    },
    [dialogTypes.LIVE_CHANNEL_INFO]: {
        title: 'Live Channel Information',
        component: LiveChannelInfoDialog,
    },
    [dialogTypes.OVER_LIMIT]: {
        title: 'Limit Reached',
        component: OverLimitDialog,
    },
    [dialogTypes.BETPAL_BOOKING]: {
        title: 'BetPal Booking',
        component: BetpalCheckoutDialog,
    },
    [dialogTypes.DELETE_TEXT_BANNER]: {
        title: 'Confirm',
        component: DeleteTextBannerDialog,
    },
    [dialogTypes.DELETE_FEATURE]: {
        title: 'Confirm',
        component: DeleteFeatureDialog,
    },
    [dialogTypes.DELETE_ACCESS]: {
        title: 'Confirm',
        component: DeleteAccessDialog,
    },
    [dialogTypes.DELETE_AUTO_BOOKING]: {
        title: 'Confirm',
        component: DeleteAutobookingDialog,
    },
    [dialogTypes.SAVE_AUTO_BOOKING]: {
        title: 'Confirm',
        component: SaveAutobookingDialog,
    },
    [dialogTypes.CHANGELOGS]: {
        title: 'Changelogs',
        component: ChangelogDialog,
    },
    [dialogTypes.BULK_SELECT]: {
        title: 'Bulk Select',
        component: BulkSelectDialog,
    },
    [dialogTypes.DELETE_CHANGELOG_ENTRY]: {
        title: 'Confirm',
        component: DeleteChangelogEntryDialog,
    },
    [dialogTypes.GET_LOGS]: {
        title: 'Get logs',
        component: LoggerDialog,
    },
    [dialogTypes.NOTIFICATION]: {
        title: 'Notification',
        component: NotificationDialog,
    },
    [dialogTypes.MATCH_CONFIG]: {
        title: 'Live Odds Match Configuration',
        component: MatchConfigDialog,
    },
    [dialogTypes.SAVE_ATTRIBUTES]: {
        title: 'Save Attributes',
        component: SaveAttributeSelectionDialog,
    },
    [dialogTypes.LOAD_ATTRIBUTES]: {
        title: 'Load Attributes',
        component: LoadAttributeSelectionDialog,
    },
    [dialogTypes.XHR_ERROR]: {
        title: 'Error',
        component: ErrorDialog,
    },
    [dialogTypes.LEGEND]: {
        title: 'Legend',
        component: LegendDialog,
    },
};

class Dialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    getDialogComponent = (dialogType, index) => {
        if (!dialogs.hasOwnProperty(dialogType)) {
            console.error(`Invalid dialog type: ${dialogType}`);
            return null;
        }

        const dialogData = dialogs[dialogType],
            customProps = this.getCustomProps(dialogType);

        const maskOnMouseDown = customProps.inertDialogMask
            ? () => {
                  //intentionally empty function, to make dialog mask inert
              }
            : () => this.closeDialog(dialogType);

        return (
            <div key={`dialog_${dialogType}`}>
                <DialogMask
                    zIndex={1000 + index}
                    onMouseDown={maskOnMouseDown}
                />
                <div
                    className={classnames('dialog-window', {
                        error: dialogType === dialogTypes.XHR_ERROR,
                    })}
                    style={{ zIndex: 1001 + index }}
                >
                    <div className="header">
                        {customProps.title || dialogData.title}
                        {!customProps.hideCornerX &&
                            dialogType !== 'XHR_ERROR' && (
                                <div
                                    className="close-btn"
                                    onClick={() => this.closeDialog(dialogType)}
                                >
                                    <FontIcon icon={''} />
                                </div>
                            )}
                    </div>
                    <dialogData.component
                        {...customProps}
                        onClose={() => this.closeDialog(dialogType)}
                    />
                </div>
            </div>
        );
    };

    getCustomProps = dialogType => {
        return this.props.customPropsByType[dialogType] || {};
    };

    closeDialog = dialogType => {
        if (dialogType === dialogTypes.BETPAL_BOOKING) {
            this.props.closeBetpalBooking();
        } else if (dialogType === dialogTypes.XHR_ERROR) {
            this.props.clearXhrError();
        } else {
            this.props.removeDialog(dialogType);
        }
    };

    escFunction(event) {
        if (event.key === 'Escape') {
            this.closeDialog(this.props.dialogTypes[0]);
        }
    }

    render() {
        const renderedDialogs = [];
        for (const index in this.props.dialogTypes) {
            if (this.props.dialogTypes.hasOwnProperty(index)) {
                renderedDialogs.push(
                    this.getDialogComponent(
                        this.props.dialogTypes[index],
                        parseInt(index, 10)
                    )
                );
            }
        }

        return <div className="dialogs-container">{renderedDialogs}</div>;
    }
}

Dialog.propTypes = {
    dialogTypes: PropTypes.array.isRequired,
    customPropsByType: PropTypes.object.isRequired,
    removeDialog: PropTypes.func.isRequired,
    closeBetpalBooking: PropTypes.func.isRequired,
    clearXhrError: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        dialogTypes: state.dialog.types,
        customPropsByType: state.dialog.customPropsByType,
    };
};

const mapDispatchToProps = {
    removeDialog,
    closeBetpalBooking,
    clearXhrError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
