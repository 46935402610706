import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import views from '../../../constants/views';
import { setCurrentPage } from '../../../stores/pagination/actions';

import './Paginator.scss';

class Paginator extends React.Component {
    state = { currentPage: 1, pageInput: '', pageInputError: '' };

    componentDidUpdate() {
        if (this.state.currentPage > this.props.numberOfPages) {
            this._setNewPage(this.props.numberOfPages, null);
        }
    }

    _getPages = () => {
        const pages = [],
            leftPadding = 1,
            pagesToDisplay = 4,
            firstPage = Math.max(
                1,
                Math.min(
                    this.state.currentPage - leftPadding,
                    this.props.numberOfPages - (pagesToDisplay - 1)
                )
            );

        const lastPage = Math.min(
            firstPage + pagesToDisplay,
            this.props.numberOfPages + 1
        );
        for (let i = firstPage; i < lastPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    _buildPages = () => {
        const pages = this._getPages();
        let isCurrentPage, className, onClick;

        return _.map(pages, (pageNum, index) => {
            isCurrentPage = pageNum === this.state.currentPage;
            className = classnames('page', {
                selected: isCurrentPage,
            });
            onClick = e => this._setNewPage(pageNum, e);

            return (
                <div
                    key={`paginatorPage_${index}`}
                    onClick={onClick}
                    className={className}
                >
                    <span>{pageNum}</span>
                </div>
            );
        });
    };

    _onPreviousClick = e => {
        const nextPage = this.state.currentPage - 1;
        return this._setNewPage(nextPage, e);
    };

    _onNextClick = e => {
        const nextPage = this.state.currentPage + 1;
        return this._setNewPage(nextPage, e);
    };

    _onFirstClick = e => {
        return this._setNewPage(1, e);
    };

    _onLastClick = e => {
        return this._setNewPage(this.props.numberOfPages, e);
    };

    _setNewPage = (pageNum, e) => {
        if (!_.isNull(e)) {
            e.preventDefault();
        }

        pageNum = parseInt(pageNum, 10);
        if (
            _.isNaN(pageNum) ||
            pageNum < 1 ||
            pageNum > this.props.numberOfPages
        ) {
            return false;
        }
        this.setState({
            currentPage: pageNum,
            pageInputError: '',
        });
        this.props.setCurrentPage(pageNum - 1); // -1 to zero index
        return true;
    };

    _pageInput = () => {
        const onClick = e => {
            this._setNewPageFromInput(this.state.pageInput, e);
        };

        return (
            <div className="page-input">
                <input
                    type="text"
                    onKeyUp={this._onPageInputKeyUp}
                    onChange={this._onPageInputChange}
                    placeholder={`1 - ${this.props.numberOfPages}`}
                    value={this.state.pageInput}
                />
                <div className="go-to-page page" onClick={onClick}>
                    Go to page
                </div>
                <span className="error">{this.state.pageInputError}</span>
            </div>
        );
    };

    _onPageInputChange = e => {
        this.setState({ pageInput: e.target.value });
    };

    _onPageInputKeyUp = e => {
        if (e.which === 13) {
            // ENTER
            this._setNewPageFromInput(e.target.value, e);
        }
    };

    _setNewPageFromInput = (pageNum, e) => {
        const isValidPageNum = this._setNewPage(pageNum, e),
            error = isValidPageNum
                ? ''
                : `Invalid number! Must be between 1 and ${this.props.numberOfPages}`;
        this.setState({
            pageInput: '',
            pageInputError: error,
        });
    };

    render() {
        if (
            this.props.currentView !==
            views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW
        ) {
            return '';
        }
        return (
            <div className="paginator">
                <div className="navigation">
                    <div className="page" onClick={this._onFirstClick}>
                        «
                    </div>
                    <div className="page" onClick={this._onPreviousClick}>
                        ‹
                    </div>
                    <div className="pages">{this._buildPages()}</div>
                    <div className="page" onClick={this._onNextClick}>
                        ›
                    </div>
                    <div className="page" onClick={this._onLastClick}>
                        »
                    </div>
                    {this._pageInput()}
                </div>
            </div>
        );
    }
}

Paginator.propTypes = {
    numberOfPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    currentPage: state.pagination.currentPage,
    numberOfPages: state.pagination.totalPages || 1,
});

const mapDispatchToProps = {
    setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Paginator);
