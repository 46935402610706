import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../../formInput/Button/Button';
import OptionButtonGroup from '../../../../OptionButtonGroup/OptionButtonGroup';
import DateStepMonth, { getInitialDropDownValues } from './DateStepMonth';
import DateStepRange from './DateStepRange';
import DateStepToday from './DateStepToday';
import { reportTypes } from './TypeStep';
import DateStepCurrentDay from './DateStepCurrentDay';
import Alert from '../../../../Alert/Alert';
import { set } from '../../../../../utils/StateHelpers';
import moment from '../../../../../utils/CalendarMoment';
import { connect } from 'react-redux';

export const dateTypes = {
    TODAY: 0,
    MONTH: 1,
    RANGE: 2,
    CURRENT_DAY: 3,
};

const limitDaysOneWeek = 7,
    limitDaysOneMonth = 31;

class DateStep extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign(
            {
                dateType: 0,
                error: null,
            },
            getInitialDropDownValues()
        );
    }

    componentDidMount() {
        this.props.onDateRangeChange(moment.now(), moment.now());
    }

    componentDidUpdate(prevProps) {
        if (this.props.dateType !== prevProps.dateType) {
            this.setState({ error: null });
            switch (this.props.dateType) {
                case dateTypes.TODAY:
                    this.props.onDateRangeChange(moment.now(), moment.now());
                    break;
                case dateTypes.MONTH:
                    this.props.onDateRangeChange(
                        moment.now().startOf('month'),
                        moment.now().endOf('month')
                    );
                    this.setState(getInitialDropDownValues());
                    break;
                case dateTypes.RANGE:
                    this.props.onDateRangeChange(
                        moment.now(),
                        moment.now().plus({ days: 6 })
                    );
                    break;
                case dateTypes.CURRENT_DAY:
                    this.props.onDateRangeChange(
                        this.props.selectedCalendarDate,
                        this.props.selectedCalendarDate
                    );
                    break;
                default:
                    console.error(`Invalid dateType: ${this.props.dateType}`);
            }
        }
    }

    _onError(error) {
        this.setState({ error });
    }

    _buildError() {
        if (!this.state.error) {
            return '';
        }
        return (
            <Alert type={Alert.types.ERROR} float={false}>
                {this.state.error}
            </Alert>
        );
    }

    render() {
        const {
            height,
            onNext,
            onPrevious,
            onCancel,
            dateType,
            reportType,
            onDateTypeChange,
            from,
            to,
            onDateRangeChange,
            selectedCalendarDate,
        } = this.props;

        const { selectedYear, selectedMonth } = this.state;

        let content = <></>;

        // Only Prev-step if bookmaker has AV-product, else THIS is first step
        const prevButton = this.props.userInfo.bookmakerInfo.audioVisual
            .hasClient ? (
            <Button
                onClick={onPrevious}
                type={Button.types.LARGE_BLUE_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Previous
            </Button>
        ) : (
            <></>
        );

        switch (dateType) {
            case dateTypes.TODAY:
                content = <DateStepToday />;
                break;
            case dateTypes.MONTH:
                content = (
                    <DateStepMonth
                        onSelectedMonthChange={set('selectedMonth').bind(this)}
                        onSelectedYearChange={set('selectedYear').bind(this)}
                        onDateRangeChange={onDateRangeChange}
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                    />
                );
                break;
            case dateTypes.RANGE:
                content = (
                    <DateStepRange
                        onDateRangeChange={onDateRangeChange}
                        from={from}
                        to={to}
                        limit={
                            // Limit days to export to prevent memory crash
                            this.props.reportType === reportTypes.match
                                ? limitDaysOneMonth
                                : limitDaysOneWeek
                        }
                        onError={this._onError.bind(this)}
                    />
                );
                break;
            case dateTypes.CURRENT_DAY:
                content = <DateStepCurrentDay date={selectedCalendarDate} />;
                break;
            default:
                console.error('unknown date type');
                break;
        }

        const dateOptions = [{ name: 'today', value: dateTypes.TODAY }];
        if (reportType === reportTypes.match) {
            dateOptions.push({ name: 'month', value: dateTypes.MONTH });
        }
        dateOptions.push({ name: 'range', value: dateTypes.RANGE });
        dateOptions.push({
            name: 'selected day',
            value: dateTypes.CURRENT_DAY,
        });

        return (
            <div className="export-wrapper export-step-date" style={{ height }}>
                <div className="content">
                    <fieldset>
                        <legend>Timespan</legend>
                        <OptionButtonGroup
                            options={dateOptions}
                            value={dateType}
                            onClick={onDateTypeChange}
                        />
                    </fieldset>
                    {content}
                    {this._buildError()}
                </div>
                <div className="footer">
                    {prevButton}
                    <Button
                        onClick={onCancel}
                        type={Button.types.LARGE_GREY_SHADOW}
                        fixedWidth={false}
                        icon={''}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onNext}
                        type={Button.types.LARGE_BLUE_SHADOW}
                        fixedWidth={false}
                        icon={''}
                        disabled={this.state.error !== null}
                    >
                        Next
                    </Button>
                </div>
            </div>
        );
    }
}

DateStep.propTypes = {
    height: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    dateType: PropTypes.number.isRequired,
    reportType: PropTypes.number.isRequired,
    onDateTypeChange: PropTypes.func.isRequired,
    selectedCalendarDate: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userInfo: state.calendar.auth.userInfo,
});

export default connect(mapStateToProps, {})(DateStep);
