import maintenanceWindows from './maintenanceWindows';

export default (state, ownProps) => {
    const mWindows = maintenanceWindows(state.textBanner),
        eventStart = ownProps.event.startDate,
        eventEnd = ownProps.event.startDate + ownProps.event.durationSeconds;

    for (const mWindow of mWindows) {
        if (mWindow.validFrom < eventEnd && eventStart < mWindow.validTo) {
            return true;
        }
    }

    return false;
};
