import React from 'react';
import PropTypes from 'prop-types';

const TimePicker = props => {
    const { disabled = false, className = '' } = props;
    const onChange = event => {
        props.onChange(event.target.value);
    };

    return (
        <input
            type="time"
            className={className}
            onChange={onChange}
            value={props.value}
            disabled={disabled}
            maxLength={5}
        />
    );
};

TimePicker.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default TimePicker;
