import moment from '../utils/CalendarMoment';

export default (state, responseValidFor = 60) => {
    if (!state) {
        return true;
    }
    return (
        !state.loading &&
        (state.error ||
            state.loadedAt === null ||
            (state.loadedAt &&
                moment.now().toFormat('X') - state.loadedAt > responseValidFor))
    );
};
