import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classname from 'classnames';
import FontIcon from '../../icons/FontIcon/FontIcon';
import './button.scss';

class Button extends Component {
    _getIcon() {
        const { icon } = this.props;
        if (icon) {
            return <FontIcon icon={icon} />;
        }
        return '';
    }

    _onClick() {
        const { onClick, disabled, onDisabledClick } = this.props;
        if (!disabled) {
            onClick();
        } else if (onDisabledClick) {
            onDisabledClick();
        }
    }

    render() {
        const {
            children,
            type,
            className,
            disabled,
            fixedWidth,
            style,
            hasPadding,
            iconPosition,
            cssId,
            cyTarget,
        } = this.props;
        const newStyle = Object.assign({}, style);
        if (fixedWidth) {
            if (fixedWidth === 'default') {
                newStyle.width = 134;
            } else {
                newStyle.width = fixedWidth;
            }
            if ((!style || !style.padding) && !hasPadding) {
                newStyle.padding = 0;
            }
        }

        return (
            <div
                data-cy={cyTarget}
                id={cssId}
                className={classname(
                    'button',
                    className,
                    type + (disabled ? '-disabled' : ''),
                    {
                        'button-primary': type === Button.types.PRIMARY,
                        'button-secondary': type === Button.types.SECONDARY,
                        'button-tertiary': type === Button.types.TERTIARY,
                    },
                    'icon-' + iconPosition
                )}
                style={newStyle}
                title={this.props.title}
                onClick={this._onClick.bind(this)}
            >
                {iconPosition === 'left' && this._getIcon()}
                {children}
                {iconPosition === 'right' && this._getIcon()}
            </div>
        );
    }
}

Button.types = {
    PRIMARY: 1,
    SECONDARY: 2,
    TERTIARY: 3,
    LARGE_GREEN: 'large-green',
    LARGE_GREEN_3D: 'large-green-3d',
    LARGE_WHITE_BORDER: 'large-white-border',
    LARGE_GREY: 'large-grey',
    LARGE_GREY_3D: 'large-grey-3d',
    LARGE_GREY_INSET: 'large-grey-inset',
    LARGE_BLUE_3D: 'large-blue-3d',
    LARGE_BLUE_SHADOW: 'large-blue-shadow',
    LARGE_GREY_SHADOW: 'large-grey-shadow',
    LARGE_GREY_SHADOW_HOVER_RED: 'large-grey-shadow-hover-red',
    LARGE_GREY_BLUE: 'large-grey-blue',
    LARGE_BLUE_WHITE: 'large-blue-white',
    LARGE_BLUE_BORDER: 'large-blue-border',
    TRANSPARENT_SOLID: 'transparent-solid',
    LARGE_TRANSPARENT_SOLID: 'large-transparent-solid',
    LARGE_TRANSPARENT_SOLID_ACTIVE: 'large-transparent-solid active',
    LARGE_TRANSPARENT_SOLID_PASSIVE: 'large-transparent-solid passive',
    LARGE_DARKBLUE_SOLID: 'large-darkblue-solid',
    LARGE_DARKBLUEMAIN_SOLID: 'large-darkbluemain-solid',
    LARGE_RED_SOLID: 'large-red-solid',
    LARGE_RED_SOLID_NO_HOVER: 'large-red-solid-no-hover',
    MEDIUM_GREEN_LIGHT: 'medium-green-light',
    SMALL_BLUE_WHITE: 'small-blue-white',
    SMALL_GREY_SHADOW: 'small-grey-shadow',
    SMALL_DARKGREY_SHADOW: 'small-darkgrey-shadow',
    SMALL_DARKBLUE_SOLID: 'small-darkblue-solid',
    SMALL_GREY_SOLID: 'small-grey-solid',
    SMALL_RED_SOLID: 'small-red-solid',
    SMALL_BLUE_SOLID: 'small-blue-solid',
    SMALL_DARKGREYWHITE_SHADOW: 'small-darkgreywhite-shadow',
    SMALL_WHITE_BORDER: 'small-white-border',
    SMALL_BLUE_BORDER: 'small-blue-border',
    SMALL_BLUE_BORDER_FILLED: 'small-blue-border-filled',
    SMALL_RED_BORDER: 'small-red-border',
    LIST_DARK: 'list-dark',
    BLUE_BORDER_FILLED: 'blue-border-filled',
    BLUE_BORDER: 'blue-border',
};

Button.propTypes = {
    cyTarget: PropTypes.string,
    onDisabledClick: PropTypes.func,
    children: PropTypes.any,
    type: PropTypes.oneOf(Object.values(Button.types)).isRequired,
    icon: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    fixedWidth: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
    ]),
    style: PropTypes.object,
    hasPadding: PropTypes.bool,
    title: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    cssId: PropTypes.string,
};

Button.defaultProps = {
    onDisabledClick: null,
    fixedWidth: 'default',
    hasPadding: true,
    iconPosition: 'left',
    onClick: () => {
        // intentionally empty function
        // to be overwritten by props if needed
    },
    cssId: '',
};

export default Button;
