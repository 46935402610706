export default (state, historyType) => {
    if (
        state.bookingHistory[historyType] &&
        state.bookingHistory[historyType].overview
    ) {
        return state.bookingHistory[historyType].overview;
    } else {
        return null;
    }
};
