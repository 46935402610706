export default {
    FILTER_GROUP_FREETEXT: 'FILTER_GROUP_FREETEXT',
    FILTER_SUBGROUP_FREETEXT: 'FILTER_SUBGROUP_FREETEXT',
    FILTER_GROUP_SPORTS: 'FILTER_GROUP_SPORTS',
    FILTER_SUBGROUP_SPORTS_REGULAR: 'FILTER_SUBGROUP_SPORTS_REGULAR',
    FILTER_SUBGROUP_SPORTS_ELECTRONIC: 'FILTER_SUBGROUP_SPORTS_ELECTRONIC',
    FILTER_SUBGROUP_SPORTS_ELECTRONIC_LEAGUES:
        'FILTER_SUBGROUP_SPORTS_ELECTRONIC_LEAGUES',
    FILTER_GROUP_MATCHES: 'FILTER_GROUP_MATCHES',
    FILTER_SUBGROUP_MATCHES: 'FILTER_SUBGROUP_MATCHES',
    FILTER_GROUP_COVERED_FROM: 'FILTER_GROUP_COVERED_FROM',
    FILTER_GROUP_SEGMENT_TYPE: 'FILTER_GROUP_SEGMENT',
    FILTER_SUBGROUP_SEGMENT_TYPE: 'FILTER_SUBGROUP_SEGMENT',
    FILTER_SUBGROUP_COVERED_FROM: 'FILTER_SUBGROUP_COVERED_FROM',
    FILTER_GROUP_COVERED_WITH: 'FILTER_GROUP_COVERED_WITH',
    FILTER_GROUP_COVERAGE_PROVIDER: 'FILTER_GROUP_COVERAGE_PROVIDER',
    FILTER_SUBGROUP_LIVEODDS: 'Live Odds',
    FILTER_SUBGROUP_LIVEDATA: 'Live Data',
    FILTER_SUBGROUP_LIVECHANNEL: 'Live Channel',
    FILTER_SUBGROUP_LIVECHANNEL_VARIANT: 'Live Channel Variant',
    FILTER_SUBGROUP_LIVECHANNEL_QUALITY: 'Live Channel Quality',
    FILTER_SUBGROUP_LIVECHANNEL_PROMOTION: 'Live Channel Promotion',
    FILTER_SUBGROUP_LIVEODDS_FEED: 'Live Odds Feed',
    FILTER_GROUP_AGE_GROUPS: 'Age Groups',
    FILTER_SUBGROUP_AGE_GROUPS: 'Age Groups',
    FILTER_GROUP_SPORT_TYPE: 'Sport Type',
    FILTER_SUBGROUP_SPORT_TYPE: 'Sport Type',
    CHECKOUT_ADAPTER_UID_LIVEODDS: 'CHECKOUT_ADAPTER_UID_LIVEODDS',
    CHECKOUT_ADAPTER_UID_LCO: 'CHECKOUT_ADAPTER_UID_LCO',
    CHECKOUT_ADAPTER_UID_BETPAL: 'CHECKOUT_ADAPTER_UID_BETPAL',
    APP_SIZE_SMALL: 1,
    APP_SIZE_MEDIUM: 2,
    APP_SIZE_LARGE: 3,
    APP_SIZE_LARGEST: 4,
    BALLOON_TYPE_FILTERS_HELP: 1,
    BALLOON_TYPE_LO_SPORT_CONFIG_HELP: 2,
    LAZY_TABLE_AUTOBOOKING: 'LAZY_TABLE_AUTOBOOKING',
    LAZY_TABLE_HISTORY: 'LAZY_TABLE_HISTORY',
    LAZY_TABLE_LCO_HISTORY: 'LAZY_TABLE_LCO_HISTORY',
    LAZY_TABLE_LCR_HISTORY: 'LAZY_TABLE_LCR_HISTORY',
    LAZY_TABLE_LCT_HISTORY: 'LAZY_TABLE_LCT_HISTORY',
    LAZY_TABLE_LCP_HISTORY: 'LAZY_TABLE_LCP_HISTORY',
    LAZY_TABLE_HISTORY_DETAIL: 'LAZY_TABLE_HISTORY_DETAIL',
    LAZY_TABLE_MATCH_CONFIG: 'LAZY_TABLE_MATCH_CONFIG',
    LAZY_TABLE_USER_ROLES: 'LAZY_TABLE_USER_ROLES',
    LOCAL_STORAGE: {
        LEFT_COLUMN: 'LEFT_COLUMN',
        RIGHT_COLUMN: 'RIGHT_COLUMN',
        SHOW_BOOKED_COUNT_TYPE: 'SHOW_BOOKED_COUNT_TYPE',
        SELECTED_LO_SPORT_CONFIG_SETTING: 'SELECTED_LO_SPORT_CONFIG_SETTING',
        SHOW_AS_BOOKED_PRODUCT: 'SHOW_AS_BOOKED_PRODUCT',
        SHOW_AS_BOOKED_PRODUCT_PRIMARY: 'SHOW_AS_BOOKED_PRODUCT_PRIMARY',
        SHOW_AS_BOOKED_PRODUCT_SECONDARY: 'SHOW_AS_BOOKED_PRODUCT_SECONDARY',
    },
    SIDE_COLUMN_STATUS: {
        EXPANDED: 1,
        COLLAPSED: 2,
    },
    SHOW_BOOKED_COUNT_TYPES: {
        HIDDEN: 'booked-count-hidden',
        BADGE: 'booked-count-badge',
        TEXTUAL: 'booked-count-textual',
    },
    LO_SPORT_CONFIG_SETTINGS: {
        MATCHES_HIDDEN: 'hidden',
        MATCHES_SHOWN: 'shown',
    },
    ALERT_INFORMATION: 100,
    ALERT_ERROR: 101,
    MATCH_COLOR_TYPES: {
        INACTIVE: 1,
        BOOKED: 2,
        AVAILABLE: 3,
        IN_BASKET: 4,
        SELECTED: 5,
    },
    DEFAULT_COLOR_SET: 'default',
    SIDE_BORDERS_TYPES: {
        SCOUT_COVERAGE: 'sideBordersLiveData',
        LIVEODDS: 'sideBordersLiveOdds',
        COVERED_FROM: 'sideBordersCoveredFrom',
    },
    PRODUCTS: {
        LO: 'HAS_LIVEODDS',
        LD: 'HAS_LIVEDATA',
        AV: 'HAS_AUDIOVISUAL',
    },
    SHOW_AS_BOOKED_PRODUCT: {
        LO: 'LO',
        LD: 'LD',
        BP: 'BP',
        LCO: 'LCO',
        LCT: 'LCT',
        LCR: 'LCR',
        LCP: 'LCP',
        ANY: 'Any',
        BOTH: 'Both',
    },
    AV_4SIGHT: '4Sight',
};
