import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';
import './switch.scss';

class Switch extends React.Component {
    _onSwitchClicked = () => {
        const props = this.props,
            onClick = props.onClick;
        if (_.isFunction(onClick) && !props.disabled) {
            onClick(props.id, !props.selected);
        }
        if (_.isFunction(props.onChange) && !props.disabled) {
            props.onChange(!props.selected);
        }
    };

    _buildLabel = label => {
        if (!label) {
            return '';
        }
        return <div className="label">{label}</div>;
    };

    render() {
        const {
                selected: activated,
                height,
                className,
                tooltipText,
                disabled,
            } = this.props,
            attributes = {},
            switchClass = classNames(className, {
                switch: true,
                'switch-off': !activated,
                disabled: disabled,
            }),
            smallSquareClass = classNames({
                'switch-square': true,
            });

        if (tooltipText) {
            attributes.title = tooltipText;
        }

        if (disabled) {
            attributes.title = 'Disabled';
        }

        return (
            <div className="switch-inner-container">
                {this._buildLabel(this.props.label)}
                <div
                    className={switchClass}
                    style={{
                        width: height * 2,
                        height: height,
                    }}
                    onClick={this._onSwitchClicked}
                    {...attributes}
                >
                    <div
                        className={smallSquareClass}
                        style={{
                            height: height - 6,
                            width: height - 6,
                            left: activated ? height + 3 : 3,
                        }}
                    />
                </div>
            </div>
        );
    }
}

Switch.propTypes = {
    selected: PropTypes.bool,
    height: PropTypes.number,
    className: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.number,
    onChange: PropTypes.func,
};

Switch.defaultProps = {
    height: 20,
    onClick: null,
    onChange: null,
    selected: true,
    className: '',
    disabled: false,
    id: null,
    tooltipText: '',
    label: null,
};

export default Switch;
