import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import TableChanges from '../../../../../TableChanges/TableChanges';
import TextInput from '../../../../../formInput/TextInput/TextInput';
import Button from '../../../../../formInput/Button/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './changes.scss';

const unlimitedTimeNames = {
    from: 'Since Time Immemorial',
    to: 'Forever More',
};

const initialTime = () => {
    const initialDate = new Date();
    return new Date(initialDate.setDate(initialDate.getDate() - 1));
};
class DbChanges extends React.Component {
    state = {
        table: 'scout_match_autobooking',
        source: 'booking calendar',
        userId: this.props.currentUser.id,
        bookmakerId: this.props.currentUser.bookmakerId,
        primaryKey: '',
        fromDate: initialTime(),
        fromDateError: false,
        fromDateDisabled: false,
        toDate: new Date(),
        toDateDisabled: false,
        toDateError: false,
        requestData: {},
    };

    onFetchData = () => {
        const fromDate = Math.floor(this.state.fromDate.getTime() / 1000);
        const toDate = Math.floor(this.state.toDate.getTime() / 1000);

        this.setState({
            requestData: {
                table: this.state.table,
                userId: parseInt(this.state.userId) || 0,
                primaryKey: parseInt(this.state.primaryKey) || 0,
                bookmakerId: parseInt(this.state.bookmakerId) || 0,
                fromDate: this.state.fromDateDisabled
                    ? 0
                    : fromDate.toString() || 0,
                toDate: this.state.toDateDisabled ? 0 : toDate.toString() || 0,
            },
        });
    };

    onChangeUserId = userId => {
        this.setState({ userId });
    };

    onChangePrimaryKey = primaryKey => {
        this.setState({ primaryKey });
    };

    onChangeBookmakerId = bookmakerId => {
        this.setState({ bookmakerId });
    };

    render() {
        let errorMsg = '';

        if (this.state.fromDateError) {
            errorMsg =
                "The 'Valid from' time is invalid. Please enter a time with format HH:MM";
        } else if (this.state.toDateError) {
            errorMsg =
                "The 'Valid to' time is invalid. Please enter a time with format HH:MM";
        } else if (
            !this.state.fromDateDisabled &&
            !this.state.toDateDisabled &&
            this.state.fromDate > this.state.toDate
        ) {
            errorMsg = "'Valid from' can not be after 'Valid to'";
        }
        return (
            <>
                <div className="filters">
                    <TextInput
                        labelText="Source"
                        className="adminInputWrapper"
                        disabled={true}
                        onChange={() => {
                            // change disabled
                        }}
                        value={this.state.source}
                        inputClassName="adminInput wide"
                    />
                    <TextInput
                        labelText="Table"
                        className="adminInputWrapper"
                        disabled={true}
                        onChange={() => {
                            // change disabled
                        }}
                        value={this.state.table}
                        inputClassName="adminInput wide"
                    />
                    <TextInput
                        labelText="User Id"
                        className="adminInputWrapper"
                        onChange={this.onChangeUserId}
                        value={this.state.userId}
                        inputClassName="adminInput wide"
                    />
                    <TextInput
                        labelText="Bookmaker Id"
                        className="adminInputWrapper"
                        onChange={this.onChangeBookmakerId}
                        value={this.state.bookmakerId}
                        inputClassName="adminInput wide"
                    />
                    <TextInput
                        labelText="Primary Key"
                        className="adminInputWrapper"
                        onChange={this.onChangePrimaryKey}
                        value={this.state.primaryKey}
                        inputClassName="adminInput wide"
                    />
                    <div className="adminInputWrapper dateTimePickerWrapper">
                        <div className="centered-label-container">
                            <label className="text-label">Date from</label>
                        </div>
                        <div>
                            <DatePicker
                                title="Date from"
                                placeholderText="Date from"
                                selected={this.state.fromDate}
                                onChange={date =>
                                    this.setState({ fromDate: date })
                                }
                                className="text-input"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                todayButton="Today"
                                dateFormat="dd.MM.yyyy HH:mm" // aa to get AM/PM
                                disabled={this.state.fromDateDisabled}
                            />
                        </div>
                        <button
                            className={classnames('disableButton ', {
                                active: this.state.fromDateDisabled,
                            })}
                            onClick={() =>
                                this.setState({
                                    fromDateDisabled:
                                        !this.state.fromDateDisabled,
                                })
                            }
                        >
                            {unlimitedTimeNames.from} (click to toggle)
                        </button>
                    </div>
                    <div className="adminInputWrapper dateTimePickerWrapper">
                        <div className="centered-label-container">
                            <label className="text-label">Date to</label>
                        </div>
                        <div>
                            <DatePicker
                                title="Date to"
                                placeholderText="Date to"
                                selected={this.state.toDate}
                                onChange={date =>
                                    this.setState({ toDate: date })
                                }
                                className="text-input"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                todayButton="Today"
                                dateFormat="dd.MM.yyyy HH:mm" // aa to get AM/PM
                                disabled={this.state.toDateDisabled}
                            />
                        </div>
                        <button
                            className={classnames('disableButton ', {
                                active: this.state.toDateDisabled,
                            })}
                            onClick={() =>
                                this.setState({
                                    toDateDisabled: !this.state.toDateDisabled,
                                })
                            }
                        >
                            {unlimitedTimeNames.to} (click to toggle)
                        </button>
                    </div>
                    <div className="adminInputWrapper">
                        <Button
                            className="adminInput submitBtn"
                            onClick={this.onFetchData}
                            type={Button.types.LARGE_BLUE_SHADOW}
                        >
                            Fetch Changes
                        </Button>
                        <div className="error">{errorMsg}</div>
                    </div>
                </div>
                <TableChanges
                    tableName={this.state.requestData.table}
                    userId={this.state.requestData.userId}
                    primaryKey={this.state.requestData.primaryKey}
                    bookmakerId={this.state.requestData.bookmakerId}
                    fromDate={this.state.requestData.fromDate}
                    toDate={this.state.requestData.toDate}
                />
            </>
        );
    }
}

DbChanges.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    currentUser: state.calendar.auth.userInfo,
});

export default connect(mapStateToProps)(DbChanges);
