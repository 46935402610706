import React from 'react';
import PropTypes from 'prop-types';
import {
    PdsIconButton,
    PdsTooltip,
} from '@podium-design-system/react-components';
import {
    PdsStar1Icon,
    PdsStarSolidIcon,
} from '@podium-design-system/react-icons';

const RecommendedStatus = ({ event, recommenderSettings }) => {
    const recommendationType =
        recommenderSettings.selectedPriority === 'mts'
            ? 'lo-mts'
            : recommenderSettings.selectedPriority;
    const isRecommended =
        recommenderSettings.showRecommended &&
        event.recommendations.hasOwnProperty(recommendationType);

    return (
        <div className="recommendation">
            <PdsTooltip
                key="priority"
                size="md"
                arrow={true}
                preferredPosition="top"
                fixedPosition="top"
                intensity="low"
                surface="on-light"
                visible={
                    isRecommended &&
                    event?.recommendations[recommendationType]?.rank
                }
            >
                <div
                    slot="text"
                    style={{
                        fontSize: '12px',
                        fontWeight: 600,
                        display: 'flex',
                    }}
                >
                    {event?.recommendations[recommendationType]?.rank}
                </div>
                <PdsIconButton
                    size="xs"
                    rank="ghost"
                    color="attention"
                    surface="on-dark"
                >
                    {isRecommended ? (
                        <PdsStarSolidIcon
                            slot="icon"
                            color="attention"
                            size="xs"
                        />
                    ) : (
                        <PdsStar1Icon
                            slot="icon"
                            hexColor="#bfbfbf"
                            size="xs"
                        />
                    )}
                </PdsIconButton>
            </PdsTooltip>
        </div>
    );
};

RecommendedStatus.propTypes = {
    event: PropTypes.object.isRequired,
    recommenderSettings: PropTypes.object.isRequired,
};
export default RecommendedStatus;
