import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loadFilterSet } from '../../../../stores/autobooking/actions';
import { defaultSavedFilters } from '../../../../stores/autobooking/reducer';
import {
    removeAttributeSet,
    setDefaultAttributeSet,
} from '../../../../stores/autobookingSave/actions';
import defaultSettings from '../../../../utils/DefaultSettings';
import Alert from '../../../Alert/Alert';
import FilterSelectionBox from '../../../FilterSelectionBox/FilterSelectionBox';
import Button from '../../../formInput/Button/Button';
import './loadAttributeSelectionDialog.scss';

const FormLoadAttributeSelection = props => {
    const _loadAttributeSelection = name => {
        props.onClose();
        props.loadFilterSet(name);
    };

    const _renderItem = (name, attributeSelection, isDefault = false) => {
        let removeButton = '';

        if (!isDefault) {
            removeButton = (
                <Button
                    type={Button.types.LARGE_GREY_SHADOW_HOVER_RED}
                    icon=""
                    onClick={() => props.removeAttributeSet(name)}
                    className="remove-button"
                    fixedWidth={false}
                >
                    remove
                </Button>
            );
        }

        return (
            <FilterSelectionBox
                title={name}
                attributeSelection={attributeSelection}
                showStarIcon={true}
                key={`FilterSelectionBox${name}`}
                starClickHandler={() => props.setDefaultAttributeSet(name)}
                isDefault={
                    name === props.defaultAttributeSet ||
                    (!props.defaultAttributeSet && isDefault)
                }
                autobooking={true}
            >
                <Button
                    type={Button.types.LARGE_BLUE_SHADOW}
                    onClick={() => _loadAttributeSelection(name)}
                    className="load-button"
                    fixedWidth={false}
                >
                    load
                </Button>
                {removeButton}
            </FilterSelectionBox>
        );
    };

    let content = '',
        items = [];

    content = Object.entries(props.savedAttributeSets).map(([name, item]) => {
        return _renderItem(name, item);
    });

    content.unshift(
        _renderItem(
            defaultSettings.defaultAttributeSetName,
            defaultSavedFilters,
            true
        )
    );

    return (
        <div className="dialog-content-wrapper load-attributes">
            <div className="sub-header">
                <Alert type={Alert.types.EXPLANATORY} icon="">
                    <p>
                        By saving and loading your attributes you can easily
                        switch between different selections.
                    </p>
                    <p>
                        Click <strong>load</strong> to load attribute selections
                        you have saved earlier.
                    </p>
                    <p>
                        You can select a favorite by selecting the{' '}
                        <strong>star icon</strong> in front of it. It will be{' '}
                        <strong>loaded by default</strong> next time you open
                        the Live Booking Calendar.
                    </p>
                </Alert>
            </div>
            <div className="content">{content}</div>
            <div className="dialog-footer">
                <Button
                    onClick={props.onClose}
                    type={Button.types.LARGE_GREY_SHADOW}
                    icon={''}
                    fixedWidth={false}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

FormLoadAttributeSelection.propTypes = {
    onClose: PropTypes.func.isRequired,
    loadFilterSet: PropTypes.func.isRequired,
    removeAttributeSet: PropTypes.func.isRequired,
    setDefaultAttributeSet: PropTypes.func.isRequired,
    defaultAttributeSet: PropTypes.string,
    savedAttributeSets: PropTypes.object,
};

const mapStateToProps = state => ({
    savedAttributeSets: state.autobookingSave.savedAttributeSets,
    defaultAttributeSet: state.autobookingSave.defaultAttributeSet,
});

const mapDispatchToProps = {
    loadFilterSet,
    removeAttributeSet,
    setDefaultAttributeSet,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLoadAttributeSelection);
