import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../formInput/Button/Button';
import OptionButtonGroup from '../../../../OptionButtonGroup/OptionButtonGroup';
import Alert from '../../../../Alert/Alert';

export const reportTypes = {
    match: 1,
    lcExtended: 2,
    lcBooking: 3,
};

const labelReportMatch =
        'Live Odds and Live Data coverage information on Betradar matches.',
    labelReportLcExtended =
        'Live Channel Extended Report is based on LiveChannel products with additional information of your available countries.',
    labelReportLcBooking =
        'Live Channel Booking Report is a combined report with Live Odds booking information.';

const TypeStep = props => (
    <div
        className="export-wrapper export-step-type"
        style={{ height: props.height }}
    >
        <div className="content">
            <fieldset>
                <legend>Report type</legend>
                <OptionButtonGroup
                    options={[
                        { name: 'Matches', value: reportTypes.match },
                        {
                            name: 'LC Extended',
                            value: reportTypes.lcExtended,
                        },
                        {
                            name: 'LC Booking',
                            value: reportTypes.lcBooking,
                        },
                    ]}
                    value={props.reportType}
                    onClick={props.onReportTypeChange}
                />
            </fieldset>
            <Alert
                type={Alert.types.INFO_BOX}
                minWidth={false}
                icon={''}
                float={false}
            >
                {props.reportType === reportTypes.match ? labelReportMatch : ''}
                {props.reportType === reportTypes.lcExtended
                    ? labelReportLcExtended
                    : ''}
                {props.reportType === reportTypes.lcBooking
                    ? labelReportLcBooking
                    : ''}
            </Alert>
        </div>
        <div className="footer">
            <Button
                onClick={props.onClose}
                type={Button.types.LARGE_GREY_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Cancel
            </Button>
            <Button
                onClick={props.onNext}
                type={Button.types.LARGE_BLUE_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Next
            </Button>
        </div>
    </div>
);

TypeStep.propTypes = {
    height: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    reportType: PropTypes.number.isRequired,
    onReportTypeChange: PropTypes.func.isRequired,
};

export default TypeStep;
