import types from './constants';

const initialState = {
    width: 0,
    height: 0,
    isResizing: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.RESIZE_CALENDAR:
            return {
                ...state,
                isResizing: true,
                width: action.payload.width,
                height: action.payload.height,
            };

        case types.RESIZE_FINISHED:
            return {
                ...state,
                isResizing: false,
            };

        default:
            return state || initialState;
    }
};
