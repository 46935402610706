import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from '../../../../constants/Constants';
import Button from '../../../formInput/Button/Button';
import FontIcon from '../../../icons/FontIcon/FontIcon';
import classnames from 'classnames';
import _ from 'underscore';
import moment from '../../../../utils/CalendarMoment';
import { toggleHelpBalloon } from '../../../../stores/calendar/actions';
import filterDescription from '../../../../selectors/filter/filterDescription';
import './HelpBalloon.scss';

class HelpBalloon extends React.Component {
    _closeBalloon = () => {
        this.props.toggleHelpBalloon(false);
    };

    _buildFilterDescription() {
        const parts = [...this.props.filterDescription];

        // This adds a description for the current selected day, e.g.:
        // - "on Thursday 2 June 2016"
        parts.push(`on ${this._getSelectedDayString()}`);

        // add line breaks between parts
        const elements = [];
        _.each(parts, (part, i) => {
            elements.push(part, <br key={`break${i}`} />);
        });
        return elements;
    }

    _getSelectedDayString() {
        return this.props.momentSelectedDay.toFormat('DDDD');
    }

    getContent() {
        let result = ['Error: unknown type.'];
        const matchCounts = {
                visible: this.props.amounts.matchesFiltered,
                total: this.props.amounts.matchesUnfiltered,
            },
            getMatchWord = function (count) {
                return count === 1 ? 'match' : 'matches';
            };

        switch (this.props.data.type) {
            case constants.BALLOON_TYPE_FILTERS_HELP:
                result = [
                    <div className="row" key="p1">
                        <strong>Filters</strong>
                        <br />
                        The filters in the left column allow you to control
                        which matches are visible.
                    </div>,
                    <hr key="line" />,
                    <div className="row" key="p2">
                        You are currently viewing{' '}
                        <strong>{matchCounts.visible}</strong>{' '}
                        {getMatchWord(matchCounts.visible)}:
                    </div>,
                    <div className="row block" key="p3">
                        {this._buildFilterDescription()}
                    </div>,
                    <div className="row note" key="p4">
                        {`${this._getSelectedDayString()} has`}
                        <strong> {matchCounts.total} </strong>
                        {`${getMatchWord(matchCounts.total)} in total.`}
                    </div>,
                ];
                break;
            case constants.BALLOON_TYPE_LO_SPORT_CONFIG_HELP:
                result = [
                    <div className="row block" key="p2">
                        This setting allows you to control which matches are
                        visible in the Live Booking Calendar.
                        <br />
                        It considers your Live Odds Sport Configuration, which
                        can be found under:
                        <br />
                        <br />
                        Configuration &raquo; Live &raquo; Liveodds Config
                        &raquo; Configure Sports
                    </div>,
                    <hr key="line" />,
                    <div className="row" key="p3">
                        <FontIcon icon="" />
                        <strong>
                            Show matches outside my configuration (default)
                        </strong>
                    </div>,
                    <div className="row smaller" key="p4">
                        Matches that belong to a sport/category/tournament that
                        you have disabled in the
                        <br />
                        Live Odds Sport Configuration are <span>
                            shown
                        </span> and <span>available for booking</span>.
                    </div>,
                    <div className="row" key="p5">
                        <FontIcon icon="" />
                        <strong>Hide matches outside my configuration</strong>
                    </div>,
                    <div className="row smaller" key="p6">
                        Matches that belong to a sport/category/tournament that
                        you have disabled in the
                        <br />
                        Live Odds Sport Configuration are <span>
                            not shown
                        </span>{' '}
                        and are <span>not available for booking</span>.
                    </div>,
                ];
                break;
            default:
                console.error(`Invalid type: ${this.state.data.type}`);
        }
        return result;
    }

    render() {
        const helpBalloon = (
            <div
                className={classnames('help-balloon', {
                    topArrow:
                        this.props.data.type ===
                        constants.BALLOON_TYPE_LO_SPORT_CONFIG_HELP,
                })}
                style={
                    this.props.data.position && {
                        left: this.props.data.position.x,
                        top: this.props.data.position.y,
                    }
                }
            >
                {this.getContent()}
                <Button
                    type={Button.types.LARGE_GREY_SHADOW}
                    onClick={this._closeBalloon}
                    fixedWidth={false}
                    icon=""
                >
                    Close
                </Button>
            </div>
        );

        if (this.props.data.position) {
            return helpBalloon;
        }

        return (
            <div
                className="help-balloon-container"
                style={{ '--area': this.props.data.area }}
            >
                {helpBalloon}
            </div>
        );
    }
}

HelpBalloon.propTypes = {
    filterDescription: PropTypes.array.isRequired,
    momentSelectedDay: PropTypes.object.isRequired,
    amounts: PropTypes.object.isRequired,
    toggleHelpBalloon: PropTypes.func.isRequired,
    data: PropTypes.shape({
        type: PropTypes.number,
        area: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
};

const mapStateToProps = state => ({
    filterDescription: filterDescription(state),
    momentSelectedDay: moment.fromTimestamp(
        state.calendar.selectedDayTimestamp
    ),
    amounts: state.calendar.amounts,
});

const mapDispatchToProps = {
    toggleHelpBalloon,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpBalloon);
