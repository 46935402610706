import React from 'react';
import PropTypes from 'prop-types';
import EventTimeLong from '../EventTimeLong';
import FontIcon from '../../icons/FontIcon/FontIcon';
import SportIcon from '../../icons/SportIcon/SportIcon';
import HighlightedContent from '../HighlightedContent/HighlightedContent';
import './StageEvent.scss';
import MatchEventDate from '../MatchEvent/MatchEventDate';
import classnames from 'classnames';
import AddOns from '../AddOns/AddOns';
import CommonProductBookingStatus from '../CommonProductBookingStatus';

function StageEvent(props) {
    return (
        <div
            className={classnames(
                'stage-event event-condensed-row textline-400',
                'calendar-event',
                {
                    'in-basket': props.inBasket,
                    'in-timeline': props.inTimeLine,
                    'event-xtra-small': props.isExtraSmall,
                    'event-xtra-xtra-small': props.isExtraExtraSmall,
                    'event-expanded': props.isExpanded,
                    recommended: props.isRecommended,
                    'products-row': props.isNewProductsUIEnabled,
                    piwikTrackContent: props.isRecommended,
                    piwikContentPiece: props.isRecommended,
                }
            )}
        >
            <div className="sport">
                <SportIcon sportId={props.event.sportId} />
            </div>

            <div className="event-time-long">
                <EventTimeLong
                    startDateTimestamp={props.event.startDate}
                    endDateTimestamp={props.event.endDate}
                    selectedDayTimestamp={props.selectedDayTimestamp}
                />
                {props.inBasket && (
                    <>
                        {' - '}
                        <MatchEventDate
                            startDateTimestamp={props.event.startDate}
                        />
                    </>
                )}
            </div>
            <div className="round" title="Competition description">
                <HighlightedContent
                    search={props.freeTextSearchPhrase}
                    content={`${props.event.ancestors[1].name} (${props.event.name})`}
                />
            </div>
            <div className="category" title="Competition description">
                <HighlightedContent
                    search={props.freeTextSearchPhrase}
                    content={props.event.realCategoryName}
                />
            </div>
            <div className="season" title="Competition description">
                <HighlightedContent
                    search={props.freeTextSearchPhrase}
                    content={props.event.ancestors[2].name}
                />
            </div>

            <CommonProductBookingStatus {...props} />

            {props.isNewProductsUIEnabled && <AddOns event={props.event} />}

            {!props.inTimeLine && (
                <div
                    className="options"
                    onClick={mouseEvent => {
                        mouseEvent.preventDefault();
                        props.openContextMenu(
                            props.event,
                            mouseEvent.pageX,
                            mouseEvent.pageY
                        );
                    }}
                >
                    <FontIcon title="Options" icon="" />
                </div>
            )}
        </div>
    );
}

StageEvent.propTypes = {
    event: PropTypes.object.isRequired,
    inBasket: PropTypes.bool,
    freeTextSearchPhrase: PropTypes.array.isRequired,
    selectedDayTimestamp: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    inTimeLine: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isExtraSmall: PropTypes.bool,
    openContextMenu: PropTypes.func,
    isExtraExtraSmall: PropTypes.bool,
    isRecommended: PropTypes.bool,
    recommenderSettings: PropTypes.object,
    isNewProductsUIEnabled: PropTypes.bool,
    isLcpEnabled: PropTypes.bool,
};

export default StageEvent;
