import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import PackageRow from './PackageRow/PackageRow.jsx';
import Alert from '../../../../../Alert/Alert.jsx';
import BusySpinner from '../../../../../BusySpinner/BusySpinner';
import './packagesTable.scss';
import { connect } from 'react-redux';

class PackagesTable extends React.Component {
    static defaultProps = {
        title: 'undefined',
        className: '',
        titleSuffix: '',
        packages: [],
        isLoading: true,
        hideWhenLoading: false,
        hideWhenEmpty: true,
        remoteData: [],
        error: false,
    };

    _buildExtraInfo() {
        const { remoteData: data } = this.props;
        let perSport = '',
            rowKey = 0;

        const buildRow = (title, text) => {
            const row = (
                <div className="row" key={`extraInfoRow_${rowKey}`}>
                    <div className="first-column">{title}</div>
                    <div className="second-column">{text}</div>
                </div>
            );
            rowKey++;
            return row;
        };

        if (!data || !data.bookedStats) {
            return '';
        }

        if (data.matchesPerSport.length > 0) {
            perSport = _.map(data.matchesPerSport, sport =>
                buildRow(sport.sportName, sport.count)
            );
            perSport.unshift(
                <div className="title" key="_extraTotalTitle_">
                    Total matches per sport (until now)
                </div>
            );
        }

        return (
            <div className="extra-info list">
                <div className="title">Extra info</div>
                {buildRow(
                    'Average matches per day',
                    data.bookedStats.averageMatchPerDay.toFixed(1)
                )}
                {buildRow(
                    'Upcoming matches included in packages',
                    data.bookedStats.upcomingMatchesInPackages
                )}
                {buildRow(
                    'CDN consumption in GB (until now)',
                    Math.floor(data.bookedStats.cdnUsage)
                )}
                {buildRow(
                    'Total matches (until now)',
                    data.bookedStats.totalBooked
                )}
                {perSport}
            </div>
        );
    }

    render() {
        let content;
        const { isLoading, hideWhenLoading, error } = this.props;

        if (isLoading) {
            // status: is loading
            if (hideWhenLoading) {
                return <div />;
            }
            content = <BusySpinner />;
        } else if (error) {
            let errorMessage = JSON.stringify(error);
            if (error.message) {
                errorMessage = error.message;
            }
            content = (
                <Alert type={Alert.types.ERROR} minWidth={false}>
                    {errorMessage}
                </Alert>
            );
        } else {
            let data = [];
            if (this.props.packages.length !== 0) {
                data = this.props.packages;
            } else if (
                this.props.remoteData &&
                this.props.remoteData.packages
            ) {
                data = this.props.remoteData.packages;
            }

            // status: finished loading
            if (this.props.hideWhenEmpty && !_.size(data)) {
                return <div />;
            }
            content = _.map(data, (value, key) => {
                return (
                    <PackageRow
                        packageDetails={value}
                        key={`${this.props.title}packageRow_${key}`}
                    />
                );
            });
        }

        // result
        return (
            <div className={`packages-table ${this.props.className}`}>
                <div className="title">
                    {this.props.title}
                    {this.props.titleSuffix}
                </div>
                <div className="list">{content}</div>
                {this._buildExtraInfo()}
            </div>
        );
    }
}

PackagesTable.propTypes = {
    remoteData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.bool,
    ]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    packages: PropTypes.array,
    isLoading: PropTypes.bool,
    hideWhenEmpty: PropTypes.bool,
    hideWhenLoading: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    titleSuffix: PropTypes.string,
    bookmakerFeatures: PropTypes.object,
};

const mapStateToProps = state => ({
    bookmakerFeatures: state.calendar.auth.userInfo.bookmakerInfo.features,
});

export default connect(mapStateToProps)(PackagesTable);
