import React from 'react';
import './Badge.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Badge = ({ content, color = 'gray', className }) => {
    return (
        <div className={classNames('badge', color, className)}>{content}</div>
    );
};

Badge.propTypes = {
    className: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.oneOf([
        'gray-light',
        'gray',
        'blue',
        'red',
        ' orange',
        'green',
    ]),
};

export default Badge;
