import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import Button from '../../../../formInput/Button/Button';
import {
    toggleFilterSelected,
    setFilterOrder,
    setFiltersToAllRelevant,
} from '../../../../../stores/autobooking/actions';
import { addDialog } from '../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../stores/dialog/constants';
import { getFilterName } from '../../../../../utils/autobookingUtils';
import './CustomizeFilters.scss';

const CustomizeFilters = props => {
    const [draggedItem, setDraggedItem] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const focusRoot = useRef(null);

    const onDragStart = (e, index) => {
        setDraggedItem(props.filterOrder[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    const onDragOver = (e, index) => {
        e.preventDefault();
        const draggedOverItem = props.filterOrder[index];

        //If the item is dragged over itself, ignore
        if (draggedItem === draggedOverItem) {
            return;
        }

        // Filter out the currently dragged item
        const newItems = [...props.filterOrder].filter(
            item => item !== draggedItem
        );

        // add the dragged item after the dragged over item
        if (draggedItem) {
            newItems.splice(index, 0, draggedItem);
        }

        props.setFilterOrder(newItems);
    };

    const onDragEnd = () => {
        setDraggedItem(null);
    };

    const showFilter = (filter, index) => {
        return (
            <li
                className="attribute-list-item ui-400 text-gray-1100 text-left"
                key={filter}
                onDragOver={e => onDragOver(e, index)}
            >
                <input
                    className="attribute-checkbox"
                    type="checkbox"
                    id={`attribute-checkbox-${filter}`}
                    checked={props.selectedFilters[filter] || false}
                    onChange={() => props.toggleFilterSelected(filter)}
                />
                <label htmlFor={`attribute-checkbox-${filter}`}>
                    {getFilterName(filter)}
                </label>
                <div
                    className="drag"
                    draggable
                    onDragStart={e => onDragStart(e, index)}
                    onDragEnd={onDragEnd}
                >
                    <FontIcon icon="" />
                </div>
            </li>
        );
    };

    return (
        <div className="filters-customization">
            <div className="filters-drop-down-container">
                <main
                    ref={focusRoot}
                    onBlur={event =>
                        !focusRoot.current.contains(event.relatedTarget) &&
                        setShowFilters(false)
                    }
                >
                    <button
                        className="customize-filters-button list-400 text-right text-blue-br-700"
                        onClick={() => setShowFilters(!showFilters)}
                    >
                        <div className="customize-text">
                            CUSTOMIZE ATTRIBUTES
                        </div>
                        <FontIcon className="customize-icon" icon="" />
                    </button>

                    {showFilters && (
                        <ul
                            className="attributes-list"
                            onDragOver={e => e.preventDefault()}
                            tabIndex="-1"
                        >
                            {props.filterOrder.map((filter, index) =>
                                showFilter(filter, index)
                            )}
                        </ul>
                    )}
                </main>
            </div>
            <Button
                className="save-button"
                icon=""
                type={Button.types.SMALL_BLUE_WHITE}
                fixedWidth={false}
                onClick={() => props.addDialog(dialogTypes.SAVE_ATTRIBUTES)}
            >
                SAVE
            </Button>
            <Button
                className="load-button"
                type={Button.types.SMALL_BLUE_WHITE}
                fixedWidth={false}
                onClick={() => props.addDialog(dialogTypes.LOAD_ATTRIBUTES)}
            >
                LOAD
            </Button>
            <Button
                className="save-button"
                icon=""
                type={Button.types.SMALL_BLUE_WHITE}
                fixedWidth={false}
                onClick={props.setFiltersToAllRelevant}
                title={
                    'Show all attributes that occur in any of your autobookings'
                }
            >
                RELEVANT
            </Button>
            <div className="bannerAttributes">
                <FontIcon className="banner-icon" icon="" />
                <div className="bannerText">
                    <div>
                        Don't forget to customize your attributes and save them
                        before creating or updating rules! For more information
                        about LBC usability, download the manual in events list!
                    </div>
                </div>
            </div>
        </div>
    );
};

CustomizeFilters.propTypes = {
    filterOrder: PropTypes.array.isRequired,
    setFilterOrder: PropTypes.func.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    toggleFilterSelected: PropTypes.func.isRequired,
    bookmakerFeatures: PropTypes.object.isRequired,
    bookmakerSegment: PropTypes.object.isRequired,
    setFiltersToAllRelevant: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    selectedFilters: state.autobooking.selectedFilters,
    filterOrder: state.autobooking.filterOrder,
    bookmakerFeatures: state.calendar.auth.userInfo.bookmakerInfo.features,
    bookmakerSegment: state.calendar.auth.userInfo.bookmakerInfo.segment,
});

const mapDispatchToProps = {
    toggleFilterSelected,
    setFilterOrder,
    setFiltersToAllRelevant,
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeFilters);
