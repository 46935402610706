import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventSelectorWrapper from './EventSelectorWrapper';
import { addEvents, removeEvent } from '../../stores/shoppingBasket/actions';
import './EventSelector.scss';

function EventSelector(props) {
    const { eventsAreCondensed = false } = props;
    function onSelectionFinish(selectedComponents, deletedItemUri) {
        const filteredSelectedComponents = selectedComponents.filter(
            selectedComponent =>
                // enforce the ignore list property on route change.
                selectedComponent.node.classList.contains('event') ===
                    eventsAreCondensed ||
                selectedComponent.node.classList.contains('event-master')
        );

        if (deletedItemUri) {
            props.removeEvent(deletedItemUri);
        } else {
            props.addEvents(
                filteredSelectedComponents.map(
                    selectedComponent => selectedComponent.props.event
                )
            );
        }
    }

    const ignoreList = ['.master-event-icon-container', '.options'];

    if (!eventsAreCondensed) {
        ignoreList.push('.event:not(.event-master)');
    }

    return (
        <EventSelectorWrapper
            deselectOnEsc={false}
            className="event-selector"
            onSelectionFinish={onSelectionFinish}
            scrollContainer={props.scrollContainer}
            ignoreList={ignoreList}
        >
            {props.children}
        </EventSelectorWrapper>
    );
}

EventSelector.propTypes = {
    scrollContainer: PropTypes.string.isRequired,
    eventsAreCondensed: PropTypes.bool,
    addEvents: PropTypes.func.isRequired,
    removeEvent: PropTypes.func.isRequired,
    children: PropTypes.node,
};

const mapDispatchToProps = {
    addEvents,
    removeEvent,
};

export default connect(null, mapDispatchToProps)(EventSelector);
