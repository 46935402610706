import types from './constants';

export const onMouseEnter = () => ({
    type: types.MOUSE_ENTER,
});

export const onMouseLeave = () => ({
    type: types.MOUSE_LEAVE,
});

export const setColumnInfo = info => ({
    type: types.UPDATE_INFO,
    payload: {
        info,
    },
});

export const setLeftOffset = leftOffset => ({
    type: types.SET_LEFT_OFFSET,
    payload: {
        leftOffset,
    },
});

export const setGadgetEnabled = gadgetEnabled => ({
    type: types.SET_GADGET_ENABLED,
    payload: {
        gadgetEnabled,
    }
})
