import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';
import { isInBasket } from '../../selectors/shoppingBasket';
import { openContextMenu } from '../../stores/eventContextMenu/actions';
import FontIcon from '../icons/FontIcon/FontIcon';

import './EventExpansionToggle.scss';

function EventExpansionToggle(props) {
    const innerContainerRef = useRef(null);
    const [hovered, setHovered] = useState(false);
    const [expandedLeft, setExpandedLeft] = useState(false);

    useEffect(() => {
        if (
            props.offsetLeft + innerContainerRef.current.offsetWidth >
            props.containerWidth
        ) {
            setExpandedLeft(true);
        }
    }, [props.offsetLeft, props.containerWidth]);

    const containerStyle = {
        bottom: 0,
        zIndex: 25,
        position: 'absolute',
        overflow: 'hidden',
        left: props.offsetLeft,
        minWidth: props.width,
        width: hovered ? 'auto' : props.width,
        '--event-width': `${props.width}px`,
    };

    if (hovered) {
        containerStyle.overflow = 'visible';
        containerStyle.zIndex = 30;
        containerStyle.borderRight = 0;

        if (expandedLeft) {
            containerStyle.right =
                props.containerWidth - (props.offsetLeft + props.width);
            containerStyle.left = 'auto';
        }
    }
    const updateChildrenWithProps = React.Children.map(
        props.children,
        child => {
            return React.cloneElement(child, {
                isExpanded: hovered,
                isExtraSmall: props.width < 108,
                isExtraExtraSmall: props.width < 44,
            });
        }
    );
    return (
        <div className="event-expansion-toggle" style={containerStyle}>
            <div ref={innerContainerRef}>{updateChildrenWithProps}</div>
            <div
                style={{
                    width: props.width,
                    zIndex: containerStyle.zIndex + 30,
                    [expandedLeft ? 'right' : 'left']: 0,
                }}
                ref={props.selectableRef}
                className="event-expansion-toggle-overlay"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div
                    className="options"
                    onClick={mouseEvent => {
                        mouseEvent.preventDefault();
                        props.openContextMenu(
                            props.event,
                            mouseEvent.pageX,
                            mouseEvent.pageY
                        );
                    }}
                >
                    <FontIcon title="options" icon="" />
                </div>
            </div>
        </div>
    );
}

EventExpansionToggle.propTypes = {
    event: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    offsetLeft: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    children: PropTypes.element.isRequired,
    selectableRef: PropTypes.any.isRequired,
    openContextMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    isSelected: isInBasket(state, ownProps),
});

const mapDispatchToProps = {
    openContextMenu,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createSelectable(EventExpansionToggle));
