import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DateStep from './components/DateStep';
import TypeStep, { reportTypes } from './components/TypeStep';
import LoadingStep from './components/LoadingStep';
import SettingStep, {
    exportTypes,
    getInfoMessage,
    isFilterEnabled,
} from './components/SettingStep';
import {
    incrementInteger,
    decrementInteger,
    set,
    setInteger,
} from '../../../../utils/StateHelpers';
import moment from '../../../../utils/CalendarMoment';
import { exportFeed } from '../../../../stores/calendar/actions';
import { matches } from '../../../../stores/calendar/constants';
import './exportDialog.scss';

const steps = {
    TYPE: 0,
    DATE: 1,
    SETTINGS: 2,
    LOADING: 3,
};

const separators = {
    COMMA: ',',
    SEMICOLON: ';',
};

class ExportDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exportError: null,
            filtered: 0,
            exportType: 0,
            reportType: reportTypes.match,
            pdfLayout: 0,
            csvSeparator: 0,
            step: props.userInfo.bookmakerInfo.audioVisual.hasClient
                ? steps.TYPE
                : steps.DATE,
            dateFrom: moment.now(),
            dateTo: moment.now(),
            dateType: 0,
            settings: {
                myMatches: matches.ALL,
                separator: separators.COMMA,
                isPortrait: 1,
                isCoveredWith: false,
                isCoveredFrom: false,
                isBookedIn: false,
            },
        };

        this.downloadStartTime = null;
        this._save = this._save.bind(this);
    }

    _save() {
        const { dateFrom, dateTo, reportType, exportType, settings } =
            this.state;

        incrementInteger('step').call(this, this.state.step);
        this.downloadStartTime = moment.now().toFormat('X');
        this.props.exportFeed(
            exportType,
            dateFrom.toFormat('X'),
            dateTo.toFormat('X'),
            this._exportDone.bind(this),
            reportType,
            settings
        );
    }

    _exportDone(error = null) {
        if (error === null) {
            this.props.onClose();
        } else {
            this.setState({ exportError: error });
            console.error(error);
        }
        this.downloadStartTime = null;
    }

    changeReportType = typeInt => {
        this.setState({
            reportType: typeInt,
            // Pdf is only available for match (for now), so set default to csv if selecting other report-type
            exportType:
                typeInt !== reportTypes.match &&
                this.state.exportType !== exportTypes.csv
                    ? exportTypes.csv
                    : this.state.exportType,
            settings: {
                ...this.state.settings,
                // These are default on if booking-report
                isCoveredFrom: reportTypes.lcBooking === typeInt,
                isCoveredWith: reportTypes.lcBooking === typeInt,
            },
        });
    };

    render() {
        const dialogHeight = 450;
        const {
            dateFrom,
            dateTo,
            dateType,
            step,
            reportType,
            exportType,
            settings,
        } = this.state;

        switch (step) {
            case steps.TYPE:
                return (
                    <TypeStep
                        height={dialogHeight}
                        onReportTypeChange={this.changeReportType}
                        reportType={reportType}
                        onNext={incrementInteger('step').bind(this)}
                        onClose={this.props.onClose}
                    />
                );
            case steps.DATE:
                return (
                    <DateStep
                        height={dialogHeight}
                        onDateRangeChange={(newDateFrom, newDateTo) => {
                            const df = newDateFrom.startOf('day');
                            const dt = newDateTo.endOf('day');
                            this.setState({
                                dateFrom: df,
                                dateTo: dt,
                            });
                        }}
                        onNext={incrementInteger('step').bind(this)}
                        onPrevious={decrementInteger('step').bind(this)}
                        onCancel={this.props.onClose}
                        from={dateFrom}
                        to={dateTo}
                        dateType={dateType}
                        reportType={reportType}
                        onDateTypeChange={set('dateType').bind(this)}
                        selectedCalendarDate={moment.fromTimestamp(
                            this.props.selectedDayTimestamp
                        )}
                    />
                );
            case steps.SETTINGS:
                return (
                    <SettingStep
                        exportType={
                            reportType === reportTypes.lcExtended &&
                            exportType === exportTypes.pdf
                                ? 2
                                : exportType
                        }
                        reportType={reportType}
                        infoText={getInfoMessage(dateType, dateFrom, dateTo)}
                        height={dialogHeight}
                        onClose={this.props.onClose}
                        onPrevious={decrementInteger('step').bind(this)}
                        onDownload={this._save}
                        onExportTypeChange={setInteger('exportType').bind(this)}
                        onSettingsChange={set('settings').bind(this)}
                        settings={settings}
                        filterEnabled={isFilterEnabled(
                            dateType,
                            dateFrom,
                            moment.fromTimestamp(
                                this.props.selectedDayTimestamp
                            )
                        )}
                    />
                );
            case steps.LOADING:
                return (
                    <LoadingStep
                        height={dialogHeight}
                        error={this.state.exportError}
                        onClose={this.props.onClose}
                    />
                );
            default:
                console.error('Unknown step');
                break;
        }
        return '';
    }
}

ExportDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    selectedDayTimestamp: PropTypes.number.isRequired,
    exportFeed: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    selectedDayTimestamp: state.calendar.selectedDayTimestamp,
    userInfo: state.calendar.auth.userInfo,
});

const mapDispatchToProps = {
    exportFeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportDialog);
