import PropTypes from 'prop-types';
import React from 'react';
import './dialogMask.scss';

const DialogMask = props => {
    const { zIndex = 999, onMouseDown = () => {}, transparent = false } = props;
    const opacity = transparent ? 0 : 0.3;
    return (
        <div
            className="dialog-mask"
            style={{
                zIndex: zIndex,
                opacity: opacity,
            }}
            onMouseDown={e => {
                e.stopPropagation();
                onMouseDown();
            }}
        />
    );
};

DialogMask.propTypes = {
    zIndex: PropTypes.number,
    onMouseDown: PropTypes.func,
    transparent: PropTypes.bool,
};

export default DialogMask;
