import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './mainHeader.scss';
import Button from '../../../formInput/Button/Button';
import { addDialog } from '../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../stores/dialog/constants';
import StorageLocal from '../../../../utils/StorageLocal';
import classnames from 'classnames';

const storageLocal = new StorageLocal();
class MainHeader extends React.Component {
    state = {
        ttlMinutes: storageLocal.getItem('ttlMinutes') || 0,
    };

    onInputChange = event => {
        let inputValue = event.target.value;
        inputValue = inputValue > 0 ? inputValue : 0;
        this.setState({ ttlMinutes: inputValue });
        storageLocal.setItem('ttlMinutes', inputValue);
    };

    render() {
        let environmentBar = '';
        if (
            this.props.backendSettings.environmentBar &&
            this.props.backendSettings.environmentBar.title &&
            import.meta.env.MODE === 'development'
        ) {
            environmentBar = (
                <div
                    className="environment-bar"
                    style={{
                        backgroundColor:
                            this.props.backendSettings.environmentBar.color,
                    }}
                >
                    <div className="title">
                        {this.props.backendSettings.environmentBar.title}
                    </div>
                    <div className="info">
                        <div className="logs">
                            <Button
                                type={Button.types.SMALL_DARKBLUE_SOLID}
                                onClick={() => {
                                    this.props.addDialog(dialogTypes.GET_LOGS);
                                }}
                                fixedWidth={65}
                            >
                                Get logs
                            </Button>
                        </div>

                        <div className="ttl-minutes">
                            <input
                                type="number"
                                placeholder="TTL minutes"
                                onChange={this.onInputChange}
                                value={this.state.ttlMinutes}
                                min="1"
                                max="240"
                                title={`Cache entire feed for ${this.state.ttlMinutes} minutes`}
                            />
                        </div>

                        {Object.keys(
                            this.props.backendSettings.apiEnvironments || {}
                        ).map(api => {
                            const env =
                                this.props.backendSettings.apiEnvironments[api];

                            const classNames = classnames(
                                'api',
                                this.props.backendSettings.apiEnvironments[api],
                                this.props.backendSettings.enabledApis[api]
                                    ? 'enabled'
                                    : 'disabled'
                            );

                            return (
                                <div
                                    key={api}
                                    className={classNames}
                                    title={`This api is using ${env} environment`}
                                >
                                    {api}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return <div className="main-header">{environmentBar}</div>;
    }
}

MainHeader.propTypes = {
    size: PropTypes.object.isRequired,
    backendSettings: PropTypes.object.isRequired,
    addDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    size: state.calendar.size,
    backendSettings: state.calendar.backendSettings || {},
});

const mapDispatchToProps = {
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
