import { createSelector } from 'reselect';

export default createSelector(
    state => state.calendar.events,
    events => {
        const eventsByUri = {};

        for (const event of events) {
            eventsByUri[event.uri] = event;
        }

        return eventsByUri;
    }
);
