import React from 'react';
import PropTypes from 'prop-types';
import kb from 'keyboardjs';
import { connect } from 'react-redux';
import LeftColumn from './LeftColumn/LeftColumn.jsx';
import RightColumn from './RightColumn/RightColumn.jsx';
import MonkeyHeader from './MonkeyHeader/MonkeyHeader.jsx';
import SubHeader from './SubHeader/SubHeader.jsx';
import MainHeader from './MainHeader/MainHeader.jsx';
import HelpBalloon from './HelpBallon/HelpBalloon.jsx';
import Paginator from '../../LazyTable/Paginator/Paginator';
import Viewport from './Viewport/Viewport.jsx';
import Banners from './Banners/Banners.jsx';
import FilterUtils from '../../../utils/FilterUtils';
import settings from '../../../utils/DefaultSettings';
import Chat from '../../../utils/Chat';
import EnvUtils from '../../../utils/EnvUtils';
import isBusy from '../../../selectors/calendarBusy';
import Notification from './Notification/NotificationContainer';
import Dialog from '../../dialog/Dialog/Dialog';
import { addDialog } from '../../../stores/dialog/actions';
import eventsByUri from '../../../selectors/eventsByUri';
import { matchesInBasketCount } from '../../../selectors/shoppingBasket';
import bookMatchesLimit from '../../../selectors/shoppingBasket/bookMatchesLimit';
import {
    closeBetpalBooking,
    betpalBookingComplete,
} from '../../../stores/shoppingBasket/actions';
import {
    setFilterSelection,
    openUserSwitcher,
    refetchEventsForDay,
    toggleHelpBalloon,
} from '../../../stores/calendar/actions';
import {
    resizeCalendar,
    resizeFinished,
} from '../../../stores/windowsize/actions';
import BusySpinner from '../../BusySpinner/BusySpinner';
import './calendar.scss';

class Calendar extends React.Component {
    componentDidMount() {
        // handle resizing
        if (settings.isResponsive) {
            this._attachResizeHandler();
        } else {
            this.props.resizeCalendar(
                settings.size.width,
                settings.size.height
            );
            this.props.resizeFinished();
        }

        // select initial filter selection
        if (this.props.auth.userInfo.defaultFilter) {
            this.props.setFilterSelection(
                FilterUtils.getFilterSelectionFromResponse(
                    this.props.auth.userInfo.defaultFilter
                )
            );
        } else {
            this.props.setFilterSelection(
                FilterUtils.getDefaultFilterSelection(
                    this.props.auth.userInfo.bookmakerInfo.isReseller
                )
            );
        }

        // init chat
        Chat.init(
            this.props.auth.userInfo,
            EnvUtils.isLocal(this.props.backendSettings.env)
        );

        // fetch events
        this.props.refetchEventsForDay(this.props.currentView);

        // keyboard bindings
        kb.bind('ctrl + insert', e => {
            e.preventDefault();
            this.props.openUserSwitcher();
        });

        // register handling of iframe events:
        this._registerPostMessageHandler();
    }

    componentWillUnmount() {
        Chat.showChat();
    }

    // LO-3667 / BP-1526
    // We open the BetPal booking in an iframe. This iframe can send
    // us events for which we have to listen. We define the listener here.
    // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    _registerPostMessageHandler = () => {
        if (!window) {
            return;
        }

        window.addEventListener(
            'message',
            e => {
                // check origin
                const regExp =
                    /^https?:\/\/(betpal\.localhost\.ag|localhost|(.+)?betradar.com)\/?$/;
                if (
                    regExp.test(e.origin) === false &&
                    e.origin !== window.location.origin
                ) {
                    console.info(`postMessage not accepted from: ${e.origin}`);
                    return;
                }

                // parse/process received data
                let data = null;
                try {
                    data = JSON.parse(e.data);
                } catch (e2) {}

                if (!data || !data.source || !data.method) {
                    return;
                }
                if (data.source !== 'betpal' && data.source !== 'self') {
                    return;
                }

                switch (data.method) {
                    case 'betpalBookingClose':
                        console.info(
                            'postMessage: closing BetPal booking dialog'
                        );
                        this.props.closeBetpalBooking();
                        break;
                    case 'betpalBookingComplete':
                        console.info(
                            'postMessage: BetPal booking completed. Parameters: ',
                            data.parameters || {}
                        );
                        this.props.betpalBookingComplete(data.parameters);
                        break;
                    default:
                        console.info(
                            'postMessage: unknown method received. ',
                            data
                        );
                        break;
                }
            },
            false
        );
    };

    _attachResizeHandler = () => {
        const parent = settings.useWindowDimensions
            ? window
            : document.querySelector(`#${settings.parentId}`);
        let timer = null;

        // resize on window-resize
        window.addEventListener('resize', () => {
            if (timer !== null) {
                clearTimeout(timer);
            }

            this.props.resizeCalendar(
                parent.offsetWidth || parent.innerWidth,
                parent.offsetHeight || parent.innerHeight
            );

            timer = setTimeout(() => {
                this.props.resizeFinished();
            }, settings.resizeTimeout);
        });

        // initial sizing
        this.props.resizeCalendar(
            parent.offsetWidth || parent.innerWidth,
            parent.offsetHeight || parent.innerHeight
        );
        this.props.resizeFinished();
    };

    _onModalClick = () => {
        this.props.helpBalloon.visible && this.props.toggleHelpBalloon(false);
    };

    render() {
        let busyLabel = <></>,
            modalMask = <></>,
            balloon = <></>;
        const showBalloon = this.props.helpBalloon.visible,
            showBusyLabel = this.props.isBusy,
            showModalMask =
                showBalloon ||
                showBusyLabel ||
                this.props.shoppingBasketExpanded;

        if (showModalMask) {
            modalMask = (
                <div
                    className="modal-mask"
                    onClick={this._onModalClick.bind(this)}
                />
            );
        }

        if (showBusyLabel) {
            busyLabel = (
                <div className="busy-spinner-container">
                    <BusySpinner />
                </div>
            );
        }

        if (!showBusyLabel) {
            if (showBalloon) {
                balloon = <HelpBalloon data={this.props.helpBalloon} />;
            }
        }
        return (
            <div className="calendar">
                <MainHeader />
                <SubHeader />
                <LeftColumn />
                <div className="monkey-container">
                    <RightColumn />
                    <Banners />
                    <Viewport />
                    <MonkeyHeader />
                    {busyLabel}
                </div>
                <Paginator />
                <Dialog />
                {modalMask}
                {balloon}
                <Notification />
            </div>
        );
    }
}

Calendar.propTypes = {
    closeBetpalBooking: PropTypes.func.isRequired,
    betpalBookingComplete: PropTypes.func.isRequired,
    basketItems: PropTypes.object.isRequired,
    matchesInBasketCount: PropTypes.number.isRequired,
    shoppingBasketExpanded: PropTypes.bool.isRequired,
    resizeCalendar: PropTypes.func.isRequired,
    resizeFinished: PropTypes.func.isRequired,
    setFilterSelection: PropTypes.func.isRequired,
    openUserSwitcher: PropTypes.func.isRequired,
    refetchEventsForDay: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    auth: PropTypes.shape({
        userInfo: PropTypes.shape({
            defaultFilter: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
            ]),
            bookmakerInfo: PropTypes.object,
        }),
    }),
    currentView: PropTypes.string.isRequired,
    eventsByUri: PropTypes.object,
    backendSettings: PropTypes.object,
    helpBalloon: PropTypes.shape({
        visible: PropTypes.bool,
    }),
    toggleHelpBalloon: PropTypes.func,
    isBusy: PropTypes.bool,
};

const mapStateToProps = state => ({
    basketItems: state.shoppingBasket.items,
    matchesInBasketCount: matchesInBasketCount(state),
    shoppingBasketExpanded: state.shoppingBasket.isExpanded,
    currentView: state.viewport.currentView,
    isBusy: isBusy(state),
    maxMatchesInBasket: bookMatchesLimit(state),
    auth: state.calendar.auth,
    helpBalloon: state.calendar.helpBalloon,
    eventsByUri: eventsByUri(state),
    backendSettings: state.calendar.backendSettings,
});

const mapDispatchToProps = {
    closeBetpalBooking,
    betpalBookingComplete,
    resizeCalendar,
    resizeFinished,
    setFilterSelection,
    openUserSwitcher,
    refetchEventsForDay,
    toggleHelpBalloon,
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
