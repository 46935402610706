import actionConstants from './constants';

export const resizeCalendar = (width, height) => ({
    type: actionConstants.RESIZE_CALENDAR,
    payload: {
        width,
        height,
    },
});

export const resizeFinished = () => ({
    type: actionConstants.RESIZE_FINISHED,
});
