import { combineReducers } from 'redux';
import notification from './notification/reducer';
import bookingHistory from './bookingHistory/reducer';
import leftColumn from './leftColumn/reducer';
import scheduleOverview from './scheduleOverview/reducer';
import shoppingBasket from './shoppingBasket/reducer';
import eventInfo from './eventInfo/reducer';
import textBanner from './textBanner/reducer';
import viewport from './viewport/reducer';
import inspector from './inspector/reducer';
import autobooking from './autobooking/reducer';
import autobookingSave from './autobookingSave/reducer';
import autobookingConfig from './autobookingConfig/reducer';
import calendar from './calendar/reducer';
import remoteData from './remoteData/reducer';
import dialog from './dialog/reducer';
import feature from './feature/reducer';
import changelog from './changelog/reducer';
import logger from './logger/reducer';
import windowsize from './windowsize/reducer';
import eventContextMenu from './eventContextMenu/reducer';
import template from './template/reducer';
import pagination from './pagination/reducer';

export default combineReducers({
    notification,
    bookingHistory,
    leftColumn,
    scheduleOverview,
    shoppingBasket,
    eventInfo,
    textBanner,
    viewport,
    inspector,
    autobooking,
    autobookingSave,
    autobookingConfig,
    calendar,
    remoteData,
    dialog,
    feature,
    changelog,
    logger,
    windowsize,
    eventContextMenu,
    template,
    pagination,
});
