import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../../../../Alert/Alert';

const DateStepCurrentDay = ({ date }) => (
    <div className="date-step-current-day">
        <Alert
            type={Alert.types.INFO_BOX}
            minWidth={false}
            icon={''}
            float={false}
        >
            Matches for {date.toFormat('DDD')} will be exported
        </Alert>
    </div>
);

DateStepCurrentDay.propTypes = {
    date: PropTypes.object.isRequired,
};

export default DateStepCurrentDay;
