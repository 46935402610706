import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import './dateRangePicker.scss';
class DateRangePicker extends Component {
    _onFromDateChanged(mm) {
        this.props.onFromChange(mm);
    }

    _onToDateChanged(mm) {
        this.props.onToChange(mm);
    }
    render() {
        return (
            <div className="date-range-picker">
                <div className="range-field">
                    <DatePicker
                        title="From date"
                        placeholderText="Select from date"
                        selected={this.props.selectedFrom}
                        onChange={date => this._onFromDateChanged(date)}
                        className="date-field"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                        disabled={this.props.disabled}
                    />
                </div>
                <div className="range-separator"> - </div>
                <div className="range-field">
                    <DatePicker
                        title="To date"
                        placeholderText="Select to date"
                        selected={this.props.selectedTo}
                        onChange={date => this._onToDateChanged(date)}
                        className="date-field"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
        );
    }
}

DateRangePicker.propTypes = {
    onFromChange: PropTypes.func.isRequired,
    onToChange: PropTypes.func.isRequired,
    selectedFrom: PropTypes.any,
    selectedTo: PropTypes.any,
    disabled: PropTypes.bool,
};

export default DateRangePicker;
