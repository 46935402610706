import types from './constants';

const initialState = {
    hoveredEventUri: null,
    info: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_HOVERED_EVENT:
            return {
                ...state,
                loading: false,
                hoveredEventUri: action.payload.uri,
            };
        case types.FETCH_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.payload.uri]: { loading: true },
                },
            };
        case types.FETCH_INFO_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.payload.uri]: {
                        ...action.payload.response.data,
                        loading: false,
                    },
                },
            };
        case types.FETCH_INFO_FAIL:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.payload.uri]: {
                        error: 'Could not load!',
                        loading: false,
                    },
                },
            };
        default:
            return state || initialState;
    }
};
