import _ from 'underscore';
import {
    BOOKING_STATUS,
    adapterIds,
    eventAction,
    responseStatus,
} from '../../constants/shoppingBasket';
import moment from '../../utils/CalendarMoment';
import types from './constants';

const initAdapters = () => {
    return Object.values(adapterIds).map(adapterId => {
        return {
            id: adapterId,
            isLoading: false,
            loadingText: '',
            error: null,
        };
    });
};

const addContentVariantsWithUri = (contentInfo, varOrQuality, eventUri) => {
    const data = [...contentInfo];
    const index = data.findIndex(uris => Object.keys(uris)[0] === eventUri);
    if (index === -1) {
        data.push({ [eventUri]: varOrQuality });
    } else {
        data[index][eventUri] = varOrQuality;
    }
    return data;
};

const getUpdatedItems = (state, prices, adapterId) => {
    return Object.values(state.items).reduce((items, item) => {
        if (
            prices.hasOwnProperty(item.uri) &&
            prices[item.uri].state === BOOKING_STATUS.BOOKED
        ) {
            if (
                adapterId === adapterIds.LIVEODDS &&
                ((item.event.products.lo && !item.event.products.lo.isBooked) ||
                    (item.event.products.ld &&
                        !item.event.products.ld.isBooked))
            ) {
                items[item.uri] = {
                    ...items[item.uri],
                    event: {
                        ...items[item.uri].event,
                        isBooked: true,
                    },
                };
            } else if (
                adapterId === adapterIds.LCO &&
                !item.event.products.lco.isBooked
            ) {
                items[item.uri] = {
                    ...items[item.uri],
                    event: {
                        ...items[item.uri].event,
                        products: {
                            ...items[item.uri].event.products,
                            lco: {
                                ...items[item.uri].event.products.lco,
                                isBooked: true,
                                bookingStatusId: 2,
                                bookingStatusName: 'Booked',
                            },
                        },
                    },
                };
            } else if (
                adapterId === adapterIds.LCR &&
                !item.event.products.lcr.isBooked
            ) {
                items[item.uri] = {
                    ...items[item.uri],
                    event: {
                        ...items[item.uri].event,
                        products: {
                            ...items[item.uri].event.products,
                            lcr: {
                                ...items[item.uri].event.products.lcr,
                                isBooked: true,
                                bookingStatusId: 2,
                                bookingStatusName: 'Booked',
                            },
                        },
                    },
                };
            } else if (
                adapterId === adapterIds.LCT &&
                !item.event.products.lct.isBooked
            ) {
                items[item.uri] = {
                    ...items[item.uri],
                    event: {
                        ...items[item.uri].event,
                        products: {
                            ...items[item.uri].event.products,
                            lct: {
                                ...items[item.uri].event.products.lct,
                                isBooked: true,
                                bookingStatusId: 2,
                                bookingStatusName: 'Booked',
                            },
                        },
                    },
                };
            } else if (
                adapterId === adapterIds.LCP &&
                !item.event.products.lct.isBooked
            ) {
                items[item.uri] = {
                    ...items[item.uri],
                    event: {
                        ...items[item.uri].event,
                        products: {
                            ...items[item.uri].event.products,
                            lcp: {
                                ...items[item.uri].event.products.lcp,
                                isBooked: true,
                                bookingStatusId: 2,
                                bookingStatusName: 'Booked',
                            },
                        },
                    },
                };
            }
        }

        return items;
    }, state.items);
};

const initialState = {
    canBookLiveodds: false,
    canBookLiveChannel: false,
    canBookBetpal: false,
    items: {},
    pricesByAdapterId: {},
    selectedAdapterId: null,
    adapters: initAdapters(),
    failedBookings: [],
    isExpanded: false,
    errorsDismissed: false,
    hasBookedEventsInBetpal: false,
    contentVariant: [],
    avQuality: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_DEFAULTS:
            let defaultAdapter = 0;
            if (action.payload.canBookLiveodds) {
                defaultAdapter = adapterIds.LIVEODDS;
            } else if (action.payload.canBookLiveChannel) {
                defaultAdapter = adapterIds.LCO;
            } else if (action.payload.canBookBetpal) {
                defaultAdapter = adapterIds.BETPAL;
            }

            return {
                ...state,
                canBookLiveodds: action.payload.canBookLiveodds,
                canBookLiveChannel: action.payload.canBookLiveChannel,
                canBookBetpal: action.payload.canBookBetpal,
                selectedAdapterId: defaultAdapter,
            };
        case types.SET_EVENTS:
            const addedAtTimestamp = moment.now().toFormat('X');
            const newItems = {};

            const comparisonFormat = 'dd/MM/yyyy';
            const selectedDay = moment
                .fromTimestamp(action.payload.selectedDayTimestamp)
                .toFormat(comparisonFormat);

            for (const uri in state.items) {
                const item = state.items[uri];

                if (
                    moment
                        .fromTimestamp(item.event.startDate)
                        .toFormat(comparisonFormat) !== selectedDay
                ) {
                    newItems[uri] = item;
                }
            }

            return {
                ...state,
                items: action.payload.events.reduce((items, event) => {
                    items[event.uri] = {
                        uri: event.uri,
                        addedAtTimestamp,
                        event: event,
                    };

                    return items;
                }, newItems),
                adapters: state.adapters.map(adapter => {
                    return {
                        ...adapter,
                        isBooking: false,
                        isPriceChange: false,
                        error: null,
                    };
                }),
            };
        case types.REMOVE_EVENT:
            return {
                ...state,
                items: _.omit(state.items, action.payload.eventUri),
                pricesByAdapterId: {},
                adapters: state.adapters.map(adapter => {
                    return {
                        ...adapter,
                        isBooking: false,
                        isPriceChange: false,
                        error: null,
                    };
                }),
                isExpanded:
                    Object.keys(state.items).length === 1
                        ? false
                        : state.isExpanded,
            };
        case types.REMOVE_ALL:
            return {
                ...state,
                isExpanded: false,
                items:
                    action.payload.except.length > 0
                        ? Object.keys(state.items).reduce((items, eventUri) => {
                              if (
                                  action.payload.except.indexOf(eventUri) !== -1
                              ) {
                                  items[eventUri] = state.items[eventUri];
                              }
                              return items;
                          }, {})
                        : {},
            };
        case types.CLEAR_FAILED_BOOKINGS:
            return {
                ...state,
                failedBookings: [],
            };
        case types.SET_IS_EXPANDED:
            return {
                ...state,
                isExpanded: action.payload.isExpanded,
                errorsDismissed: false,
                adapters: action.payload.isExpanded
                    ? state.adapters
                    : state.adapters.map(adapter => ({
                          ...adapter,
                          error: null,
                      })),
            };
        case types.SWITCH_TO_ADAPTER:
            return {
                ...state,
                errorsDismissed: false,
                selectedAdapterId: action.payload.adapterId,
            };

        case types.SET_CONTENT_VARIANT:
            return {
                ...state,
                contentVariant: addContentVariantsWithUri(
                    state.contentVariant,
                    action.payload.variant,
                    action.payload.eventUri
                ),
            };
        case types.SET_QUALITY:
            return {
                ...state,
                avQuality: addContentVariantsWithUri(
                    state.avQuality,
                    action.payload.selectedQuality,
                    action.payload.eventUri
                ),
            };

        case types.LOAD_PRICES:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === action.payload.adapterId) {
                        return {
                            ...adapter,
                            isLoading: true,
                            loadingText: 'Fetching prices...',
                            isBooking: false,
                            isPriceChange: false,
                            error: null,
                        };
                    }
                    return adapter;
                }),
            };
        case types.LOAD_PRICES_SUCCESS:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === action.payload.adapterId) {
                        return {
                            ...adapter,
                            isLoading: false,
                            loadingText: '',
                            isBooking: false,
                            isPriceChange: false,
                            error: null,
                        };
                    }
                    return adapter;
                }),
                pricesByAdapterId: {
                    ...state.pricesByAdapterId,
                    [action.payload.adapterId]: action.payload.response.prices,
                },
            };
        case types.LOAD_PRICES_FAIL:
        case types.BOOK_MATCHES_FAIL:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === action.payload.adapterId) {
                        return {
                            ...adapter,
                            isLoading: false,
                            loadingText: '',
                            isBooking: false,
                            isPriceChange: false,
                            error: {
                                code: action.payload.error.code,
                                message: action.payload.error.message,
                                detailedMessage:
                                    action.payload.error.detailedMessage,
                            },
                        };
                    }
                    return adapter;
                }),
            };
        case types.LOAD_BETPAL_PRICES:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === adapterIds.BETPAL) {
                        return {
                            ...adapter,
                            isLoading: false,
                            loadingText: '',
                            isBooking: false,
                            isPriceChange: false,
                            error: null,
                        };
                    }
                    return adapter;
                }),
                pricesByAdapterId: {
                    ...state.pricesByAdapterId,
                    [adapterIds.BETPAL]: action.payload.uris.reduce(
                        (obj, uri) => {
                            obj[uri] = {
                                price: 0,
                                state: BOOKING_STATUS.BOOKABLE,
                                action: eventAction.NONE,
                            };
                            return obj;
                        },
                        {}
                    ),
                },
            };
        case types.BOOK_MATCHES:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === action.payload.adapterId) {
                        return {
                            ...adapter,
                            isLoading: true,
                            loadingText: 'Booking matches...',
                            isBooking: false,
                            isPriceChange: false,
                            error: null,
                        };
                    }
                    return adapter;
                }),
            };
        case types.BOOK_MATCHES_SUCCESS:
            return {
                ...state,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === action.payload.adapterId) {
                        return {
                            ...adapter,
                            isLoading: false,
                            loadingText: '',
                            isPriceChange:
                                action.payload.response.status ===
                                responseStatus.PRICE_CHANGE,
                            isBooking:
                                action.payload.response.status ===
                                responseStatus.SUCCESS,
                            error: null,
                        };
                    }
                    return adapter;
                }),
                pricesByAdapterId: {
                    ...state.pricesByAdapterId,
                    [action.payload.adapterId]: action.payload.response.prices,
                },
                items: {
                    ...state.items,
                    ...getUpdatedItems(
                        state,
                        action.payload.response.prices,
                        action.payload.adapterId
                    ),
                },
            };
        case types.DISMISS_ERRORS_DIALOG:
            return {
                ...state,
                errorsDismissed: true,
            };
        case types.CLOSE_BETPAL_BOOKING:
            return {
                ...state,
                hasBookedEventsInBetpal: false,
            };
        case types.BETPAL_BOOKING_COMPLETE:
            const failedBookings = [];
            _.each(action.payload.parameters, matchIdArray => {
                _.each(matchIdArray, matchId => {
                    failedBookings.push(parseInt(matchId, 10));
                });
            });

            return {
                ...state,
                hasBookedEventsInBetpal: true,
                failedBookings: failedBookings,
                adapters: state.adapters.map(adapter => {
                    if (adapter.id === adapterIds.BETPAL) {
                        return {
                            ...adapter,
                            isBooking: true,
                        };
                    }
                    return adapter;
                }),
                items: Object.keys(state.items).reduce((items, matchId) => {
                    if (failedBookings.indexOf(matchId) === -1) {
                        // match was booked in Betpal
                        items[matchId].event.products.bp.isBooked = true;
                    }
                    return items;
                }, state.items),
            };
        default:
            return state || initialState;
    }
};
