export const toggle = key => {
    return function (e) {
        setState.call(this, key, !this.state[key]);
    };
};

export const setInteger = key => {
    return function (e) {
        setState.call(this, key, parseInt(getValue(e), 10));
    };
};

export const incrementInteger = key => {
    return function () {
        let number = parseInt(this.state[key], 10);
        number++;
        setState.call(this, key, number);
    };
};

export const decrementInteger = key => {
    return function () {
        let number = parseInt(this.state[key], 10);
        number--;
        setState.call(this, key, number);
    };
};

export const set = (...keys) => {
    return function () {
        if (keys.length !== arguments.length) {
            console.error(
                'SetValue: key length does not match arguments length'
            );
        }
        keys.forEach((singleKey, index) => {
            setState.call(this, singleKey, getValue(arguments[index]));
        });
    };
};

const getValue = e => {
    if (e && e.target) {
        return e.target.value;
    }
    return e;
};

const setState = function (key, value) {
    this.setState({
        [key]: value,
    });
};
