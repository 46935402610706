import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authenticate } from '../../../stores/calendar/actions';
import PiwikPro from '@piwikpro/react-piwik-pro';
import _ from 'underscore';
import './AuthenticationGuard.scss';
import EnvUtils from '../../../utils/EnvUtils';

function AuthenticationGuard({
    login,
    isAuthenticating,
    isAuthenticated,
    error,
    children,
    backendSettings,
    consumerRoute,
    isPiwikInitialized,
}) {
    useEffect(() => {
        if (!window.hotReloaded) {
            login(consumerRoute);
        }
    }, [login, consumerRoute]);

    const lsKey = 'REMAINING_LOGIN_ATTEMPTS';
    const remainingLoginAttempts = window.sessionStorage.getItem(lsKey) || 5;

    useEffect(() => {
        if (isAuthenticating || window.hotReloaded) {
            return;
        }

        if (isAuthenticated) {
            window.sessionStorage.removeItem(lsKey);

            if (!isPiwikInitialized && !_.isNull(backendSettings?.piwik.id)) {
                PiwikPro.initialize(
                    backendSettings?.piwik.id,
                    backendSettings?.piwik.url
                );
            }
        } else {
            if (remainingLoginAttempts > 0) {
                const nextSessionStorageValue = remainingLoginAttempts - 1;
                window.sessionStorage.setItem(lsKey, nextSessionStorageValue);
                window.location.href = '/api/login';
            }
        }
    }, [
        isAuthenticated,
        isAuthenticating,
        remainingLoginAttempts,
        isPiwikInitialized,
        backendSettings,
    ]);

    if (isAuthenticating) {
        return (
            <div className="authentication-guard">
                <p>Loading...</p>
            </div>
        );
    }

    if (isAuthenticated) {
        return children;
    }

    if (error) {
        return (
            <div className="authentication-guard error">
                <b>Server error: {error.debug.code}</b>
                <p>An error occurred. Please try to reload the page.</p>
            </div>
        );
    }

    const portalUrl = EnvUtils.getPortalUrl();

    return (
        <div className="authentication-guard error">
            <b>
                Access denied.
                {remainingLoginAttempts > 0 && ' Redirecting to login page.'}
            </b>
            {remainingLoginAttempts < 1 && (
                <p>
                    Please make sure you are connecting via{' '}
                    <a href={portalUrl} target="_blank" rel="noreferrer">
                        Portal
                    </a>
                </p>
            )}
        </div>
    );
}

AuthenticationGuard.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    error: PropTypes.object,
    login: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    backendSettings: PropTypes.object,
    consumerRoute: PropTypes.string,
    isPiwikInitialized: PropTypes.bool,
};

const mapStateToProps = state => ({
    isAuthenticated: state.calendar.auth.isAuthenticated,
    isAuthenticating: state.calendar.auth.isAuthenticating,
    error: state.calendar.xhrError,
    backendSettings: state.calendar.backendSettings,
});

const mapDispatchToProps = {
    login: authenticate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticationGuard);
