import accounting from 'accounting';

// config
const decimals = 2;
let decimalSeparator = '.',
    thousandSeparator = ''; // no separator

// see https://www.npmjs.com/package/accounting
accounting.settings.currency.format = '%v';
accounting.settings.currency.decimal = decimalSeparator;
accounting.settings.currency.thousand = thousandSeparator;
accounting.settings.currency.precision = decimals;
accounting.settings.number.decimal = decimalSeparator;
accounting.settings.number.thousand = thousandSeparator;
accounting.settings.number.precision = decimals;

export default accounting;
