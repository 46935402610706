import React from 'react';
import Xhr from '../../../../../../utils/Xhr';
import { connect } from 'react-redux';
import BusySpinner from '../../../../../BusySpinner/BusySpinner';
import Button from '../../../../../formInput/Button/Button';
import FontIcon from '../../../../../icons/FontIcon/FontIcon.jsx';
import DatePicker from 'react-datepicker';
import './stats.scss';
import moment, { toISODate } from '../../../../../../utils/CalendarMoment';

class Stats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoTable: [],
            isLoading: true,
            textEndpoint: '',
            dateMMFrom: new Date(),
            dateMMTo: new Date(),
            aggregate: false,
            dateTo: '',
            sortBy: '',
            columns: ['Type', 'Host', 'Count', 'Duration', 'Average'],
            types: ['All', 'Api', 'Query', 'Api-fail'],
            type: '',
            columnClass: [],
        };
    }

    componentDidMount() {
        this.setState({
            columnClass: Array(this.state.columns.length).fill('none'),
        });
        this.search();
    }

    sortText(a, b) {
        if (a > b) {
            return 1;
        }

        if (a < b) {
            return -1;
        }

        return 0;
    }

    requestSort(prop, idx) {
        const tmp = this.state.infoTable;
        const classes = Array(this.state.columns.length).fill('none');
        let asc = true;
        if (this.state.sortBy.length) {
            asc = this.state.sortBy.slice(-1) === 'A';
        }

        const sort = (a, b, prop) => {
            if (prop === 'type' || prop === 'host') {
                //Text
                return this.sortText(a[prop], b[prop]);
            } else if (prop === 'date') {
                //Dates
                return new Date(a[prop]) - new Date(b[prop]);
            } else {
                //Numbers
                return a[prop] - b[prop];
            }
        };

        tmp.sort(function (a, b) {
            return asc ? sort(a, b, prop) : sort(b, a, prop);
        });

        classes[idx] = asc ? 'ascending' : 'descending';

        this.setState({
            infoTable: tmp,
            sortBy: prop + (asc ? 'D' : 'A'),
            columnClass: classes,
        });
    }

    onFilterKeyUp = e => {
        if (e.keyCode === 13 /* Enter */) {
            this.search();
        }
    };

    dpFromSelect = m => {
        setTimeout(() => {
            this.setState({
                dateMMFrom: m,
            });
        }, 175);
    };

    dpToSelect = m => {
        setTimeout(() => {
            this.setState({
                dateMMTo: m,
            });
        }, 175);
    };

    showFilters() {
        return (
            <div className="filters">
                <div className="filterSection">
                    <div className="filterLabel">From:</div>

                    <DatePicker
                        title="From"
                        placeholderText="From"
                        selected={this.state.dateMMFrom}
                        onChange={date => this.setState({ dateMMFrom: date })}
                        className="text-input"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
                <div className="filterSection">
                    <div className="filterLabel">To:</div>
                    <DatePicker
                        title="To"
                        placeholderText="To"
                        selected={this.state.dateMMTo}
                        onChange={date => this.setState({ dateMMTo: date })}
                        className="text-input"
                        timeCaption="Time"
                        todayButton="Today"
                        dateFormat="dd.MM.yyyy"
                    />
                </div>

                <div className="filterSection">
                    <div className="filterLabel">Aggregate:</div>
                    <div>
                        <label className="checkboxContainer">
                            <input
                                type="checkbox"
                                className="inputNormal"
                                defaultChecked={this.state.aggregate}
                                onChange={value => {
                                    this.setState({
                                        aggregate: !this.state.aggregate,
                                    });
                                }}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                </div>

                <div className="filterSection">
                    <div className="filterLabel">EndPoint/DB:</div>
                    <div>
                        <input
                            type="text"
                            placeholder="Search (* = wildcard)"
                            className="inputNormal"
                            value={this.state.textEndpoint}
                            onChange={text => {
                                this.setState({
                                    textEndpoint: text.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="filterSection">
                    <div className="filterLabel">Type:</div>
                    <div>
                        <input
                            type="text"
                            className="inputNormal"
                            list="opts"
                            placeholder="all"
                            value={this.state.type}
                            onChange={text => {
                                this.setState({
                                    type: text.target.value,
                                });
                            }}
                        />
                        <datalist id="opts">
                            {this.state.types.map((opt, index) => (
                                <option key={index} value={opt}>
                                    {opt}
                                </option>
                            ))}
                        </datalist>
                    </div>
                </div>

                <div className="break" />

                <div className="options">
                    <div>
                        <Button
                            type={Button.types.LARGE_GREEN_3D}
                            fixedWidth={false}
                            className="search-button"
                            onClick={() => this.search()}
                        >
                            <FontIcon icon="" /> Search
                        </Button>
                    </div>
                    <div>
                        <Button
                            type={Button.types.LARGE_WHITE_BORDER}
                            fixedWidth={false}
                            className="search-button export"
                            onClick={() => this.export()}
                        >
                            <FontIcon icon="" />
                            Export
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    printInfo() {
        if (this.state.isLoading) {
            return <BusySpinner />;
        }
        return (
            <div className="result-table">
                <table className="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            {this.state.columns.map((col, index) => (
                                <th
                                    className="text-center"
                                    key={index}
                                    scope="col"
                                >
                                    <button
                                        type="button"
                                        onClick={() =>
                                            this.requestSort(
                                                col.toLowerCase(),
                                                index
                                            )
                                        }
                                        className={
                                            this.state.columnClass[index]
                                        }
                                    >
                                        {col}
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.infoTable.map((res, index) => (
                            <tr key={index}>
                                <td
                                    className="text-center"
                                    style={{
                                        display:
                                            this.state.columns.length === 6
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    {res.date}
                                </td>
                                <td className="text-center">{res.type}</td>
                                <td>
                                    <FontIcon icon="" /> {res.host}
                                </td>
                                <td className="text-center">{res.count}</td>
                                <td className="text-center">{res.duration}</td>
                                <td className="text-center">{res.average}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    export() {
        let csvContent = `${this.state.columns.join()}\n`;
        for (const i in this.state.infoTable) {
            const stat = this.state.infoTable[i];
            const statDate = !this.state.aggregate ? stat.date + ',' : '';
            csvContent += `${statDate}${stat.type},${stat.host},${stat.count},${stat.duration}, ${stat.average}\n`;
        }
        const element = document.createElement('a');
        element.setAttribute(
            'href',
            `data:text/plain;charset=utf-8, ${encodeURIComponent(csvContent)}`
        );
        element.setAttribute('download', `data.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    search() {
        this.setState({
            isLoading: true,
        });

        let api = '/stats';
        const urlX = new URL('http://url' + api);
        const params = urlX.searchParams;

        if (toISODate(this.state.dateMMFrom) !== '') {
            params.set('from', toISODate(this.state.dateMMFrom));
        }

        if (toISODate(this.state.dateMMTo) !== '') {
            params.set('to', toISODate(this.state.dateMMTo));
        }

        if (this.state.dateTo !== '') {
            params.set('to', this.state.dateTo);
        }

        if (this.state.type !== '' && this.state.type !== 'All') {
            params.set('type', this.state.type.toLowerCase());
        }

        if (this.state.textEndpoint.length > 0) {
            params.set('search', this.state.textEndpoint);
        }
        const colTmp = this.state.columns;
        if (this.state.aggregate) {
            params.set('aggregate', 1);
            if (this.state.columns[0] === 'Date') {
                colTmp.shift();
                this.setState({
                    columns: colTmp,
                });
            }
        } else {
            params.set('aggregate', 0);
            //Check Date column exist
            if (this.state.columns[0] !== 'Date') {
                colTmp.unshift('Date');
                this.setState({
                    columns: colTmp,
                });
            }
        }

        urlX.search = params.toString();
        if (Array.from(urlX.searchParams).length) {
            api = `${api}?${params.toString()}`;
        }

        Xhr.backendRequest(api, null, 'GET', null, true).then(response => {
            this.setState({
                infoTable: response.response,
                isLoading: false,
            });
        });
    }

    render() {
        return (
            <div className="container" onKeyUp={this.onFilterKeyUp}>
                {this.showFilters()}
                {this.printInfo()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sortBy: '',
});

export default connect(mapStateToProps)(Stats);
