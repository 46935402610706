import PropTypes from 'prop-types';
import React from 'react';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import Alert from '../../../../Alert/Alert';
import Button from '../../../../formInput/Button/Button';

const buildSpinner = error => {
    if (error !== null) {
        return '';
    }
    return (
        <div className="content">
            <Alert
                type={Alert.types.INFO_BOX}
                minWidth={false}
                icon={''}
                float={false}
            >
                Please wait until your file is downloaded. This might take a
                minute
            </Alert>
            <BusySpinner />
        </div>
    );
};

const buildError = error => {
    if (error === null) {
        return '';
    }
    return (
        <div className="content">
            <Alert type={Alert.types.ERROR} float={false}>
                {error.message}({error.code})
            </Alert>
        </div>
    );
};

const LoadingStep = props => (
    <div
        className="export-wrapper export-step-loading"
        style={{ height: props.height }}
    >
        {buildError(props.error)}
        {buildSpinner(props.error)}
        <div className="footer">
            {props.error !== null ? (
                <Button
                    onClick={props.onClose}
                    type={Button.types.LARGE_GREY_SHADOW}
                    fixedWidth={false}
                    icon={''}
                >
                    Close
                </Button>
            ) : (
                ''
            )}
        </div>
    </div>
);

LoadingStep.propTypes = {
    height: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default LoadingStep;
