import _ from 'underscore';

const Utils = {
    MapToArray: function (map) {
        const values = [];
        map.forEach(value => {
            values.push(value);
        });
        return values;
    },

    implodeByKey(pieces, key, glue = ',') {
        if (!Array.isArray(pieces) || pieces.length === 0) {
            return '';
        }

        const result = pieces.reduce(
            (carry, piece) => carry + piece[key] + glue,
            ''
        );

        return result.substr(0, result.length - 1);
    },

    buildQueryString: function (options, separator = '&') {
        return _.map(options, function (value, key) {
            return `${key}=${value}`;
        }).join(separator);
    },

    openWindow: function (url, parameters = {}, options = {}, name = null) {
        const q = url.indexOf('?') !== -1 ? '&' : '?';
        url = _.size(parameters)
            ? url + q + this.buildQueryString(parameters)
            : url;
        const specs = _.extend(
                {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    left: 0,
                    top: 0,
                    toolbar: 'no',
                    status: 'no',
                    resizable: 'yes',
                    resize: 'yes',
                    scrollbars: 'yes',
                    menubar: 'no',
                    location: 'no',
                },
                options
            ),
            specsString = this.buildQueryString(specs, ','),
            win = window.open(url, name, specsString);

        if (!win) {
            return;
        }

        if (_.isFunction(win.resizeTo)) {
            win.resizeTo(specs.width, specs.height);
        }
        win.focus();
    },

    toKeySortedObject: function (o) {
        const keys = _.sortBy(_.keys(o), function (key) {
            return key;
        });
        return _.object(
            keys,
            _.map(keys, function (key) {
                return o[key];
            })
        );
    },
};

export default Utils;
