import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from '../../../../formInput/Button/Button';
import AutobookerCopy from './AutobookerCopy/AutobookerCopy';
import Changes from './Changes/Changes';
import FeatureConfig from './FeatureConfig/FeatureConfig';
import PackageDebug from './PackageDebug/PackageDebug';
import Roles from './Roles/Roles';
import Stats from './Stats/Stats';
import TextBannerAdmin from './TextBannerAdmin/TextBannerAdmin';
import './admin.scss';

const interfaces = {
    TEXT_BANNERS: 'TEXT BANNERS',
    FEATURE_CONFIG: 'FEATURE CONFIG',
    STATS: 'STATS',
    CHANGES: 'CHANGES',
    ROLES: 'ROLES',
    AUTOBOOKER: 'AUTOBOOKER',
    PACKAGE_DEBUG: 'PACKAGE DEBUG',
};

const interfaceAccess = {
    TEXT_BANNERS: ['admin'],
    FEATURE_CONFIG: ['admin'],
    CHANGELOG_ENTRIES: ['admin'],
    STATS: ['admin'],
    CHANGES: ['support'],
    ROLES: ['admin'],
    AUTOBOOKER: ['admin'],
    PACKAGE_DEBUG: ['admin', 'support', 'developer'],
};

class Admin extends React.Component {
    state = {
        selectedView: Object.keys(interfaces)
            .filter(key => {
                const allowedRoles = interfaceAccess[key];
                return (
                    allowedRoles &&
                    allowedRoles.some(role => this.props.access[role])
                );
            })
            .map(key => interfaces[key])[0],
    };

    _onButtonClick(selectedView) {
        this.setState({
            selectedView,
        });
    }

    _buildViewSwitcher() {
        const views = [];

        for (const key in interfaces) {
            if (!interfaces.hasOwnProperty(key)) {
                continue;
            }
            if (!interfaceAccess[key].some(role => this.props.access[role])) {
                continue;
            }

            const viewName = interfaces[key];
            const isSelected = viewName === this.state.selectedView;
            views.push(
                <Button
                    key={'view_btn_' + key}
                    type={
                        isSelected
                            ? Button.types.LARGE_DARKBLUEMAIN_SOLID
                            : Button.types.LARGE_DARKBLUE_SOLID
                    }
                    onClick={this._onButtonClick.bind(this, viewName)}
                >
                    {viewName}
                </Button>
            );
        }

        return <div className="admin-view-switcher">{views}</div>;
    }

    render() {
        let content = <div></div>;

        switch (this.state.selectedView) {
            case interfaces.TEXT_BANNERS:
                content = <TextBannerAdmin />;
                break;
            case interfaces.FEATURE_CONFIG:
                content = <FeatureConfig />;
                break;
            case interfaces.STATS:
                content = <Stats />;
                break;
            case interfaces.CHANGES:
                content = <Changes />;
                break;
            case interfaces.ROLES:
                content = <Roles />;
                break;
            case interfaces.AUTOBOOKER:
                content = <AutobookerCopy />;
                break;
            case interfaces.PACKAGE_DEBUG:
                content = <PackageDebug access={this.props.access} />;
                break;
            default:
                console.error('Invalid interface: ' + this.state.selectedView);
        }
        return (
            <div className="admin">
                {this._buildViewSwitcher()}
                {content}
            </div>
        );
    }
}

Admin.propTypes = {
    access: PropTypes.object,
};

const mapStateToProps = state => ({
    access: state.calendar.access,
});

export default connect(mapStateToProps)(Admin);
