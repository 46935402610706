import types from './constants';

const initialState = {
    defaultUri: '',
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_DEFAULT_URI:
            return {
                ...state,
                defaultUri: action.payload.uri,
            };
        default:
            return state || initialState;
    }
};
