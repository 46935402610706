import { createSelector } from 'reselect';
import constants from '../../constants/Constants';

export default createSelector(
    state => state.calendar.selectedCheckboxes,
    selectedCheckboxes => {
        return selectedCheckboxes.filter(
            checkbox => checkbox.name === constants.FILTER_GROUP_SPORTS
        );
    }
);
