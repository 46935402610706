import PropTypes from 'prop-types';
import React from 'react';
import './KeyValue.scss';

const KeyValue = props => {
    return (
        <div className={`key-value-item ${props.keyString}`}>
            <div className="ui-100">{props.keyString.toUpperCase()}</div>
            <div className="key-value-box ui-400">{props.value}</div>
        </div>
    );
};

KeyValue.propTypes = {
    keyString: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
};

export default KeyValue;
