import React from 'react';
import PropTypes from 'prop-types';
import sportIds from '../../../constants/sports';
import FontIcon from '../FontIcon/FontIcon';

const SportIcon = props => {
    const { className = '', isSupported = true } = props;
    const sport = sportIds.find(sport => sport.id === props.sportId);

    return isSupported ? (
        <FontIcon
            title={sport ? sport.name : ''}
            icon={sport ? sport.icon : ''}
            className={className}
        />
    ) : (
        <></> //TODO what to show when not supported
    );
};

SportIcon.propTypes = {
    sportId: PropTypes.number.isRequired,
    sportName: PropTypes.string,
    className: PropTypes.string,
    isSupported: PropTypes.bool,
};

export default SportIcon;
