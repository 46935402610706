const EnvUtils = {
    isLocal: function (env) {
        return ['local', 'docker'].indexOf(env) !== -1;
    },
    getPortalUrl: function () {
        const currentUrl = window.location.href;

        if (currentUrl.includes('staging') || currentUrl.includes('stg')) {
            return 'https://bookmaker-portal.stg.nonprod.euc1.srodds.io';
        } else if (currentUrl.includes('integration')) {
            return 'https://integration.portal.betradar.com/';
        } else {
            return 'https://portal.betradar.com/';
        }
    },
};

export default EnvUtils;
