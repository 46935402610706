import types from './constants';

const initialState = {
    sendConfirmation: null,
};

export default (state, action) => {
    if (action.type === types.SET) {
        return {
            sendConfirmation: Boolean(action.payload),
        };
    }

    return state || initialState;
};
