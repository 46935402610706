import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './radioButton.scss';

const RadioButton = ({
    onClick,
    value,
    name,
    checked,
    disabled = false,
    children,
}) => {
    return (
        <div
            className={classnames('radio-button', { disabled })}
            onClick={disabled ? undefined : onClick}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onClick}
                disabled={disabled}
            />
            <div className="content" />
            <div>{children}</div>
        </div>
    );
};

RadioButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.number,
    name: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

export default RadioButton;
