import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import './progressBar.scss';
import classnames from 'classnames';

class ProgressBar extends React.Component {
    render() {
        const barWidth =
            this.props.percentageDone === -1
                ? 0
                : _.max([this.props.minBarWidth, this.props.percentageDone]);
        const barColor =
            barWidth >= 100 ? 'red' : barWidth >= 90 ? 'orange' : 'green';
        return (
            <div
                className={classnames(
                    'progress-bar-container',
                    'text-blue-br-700'
                )}
            >
                <div
                    className={classnames(
                        'startDate',
                        'ui-400',
                        'text-blue-br-700',
                        'text-left'
                    )}
                >
                    {this.props.startDate}
                </div>
                <div className="progress-bar">
                    <div
                        className={`progress-bar-inner ${barColor}`}
                        style={{
                            width: `${barWidth}%`,
                        }}
                    />
                    <div
                        className="progress-date-bar-inner"
                        style={{
                            width: `${this.props.dateBarDone}`,
                        }}
                    />
                </div>
                <div
                    className={classnames(
                        'endDate',
                        'ui-400',
                        'text-blue-br-700',
                        'text-left'
                    )}
                >
                    {this.props.endDate}
                </div>
            </div>
        );
    }
}

ProgressBar.propTypes = {
    minBarWidth: PropTypes.number,
    percentageDone: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    dateBarDone: PropTypes.number,
};

ProgressBar.defaultProps = {
    minBarWidth: 0,
    percentageDone: 0,
    startDate: '',
    endDate: '',
    dateBarDone: 0,
};

export default ProgressBar;
