import { connect } from 'react-redux';
import { SelectableGroup } from 'react-selectable-fast';
import { addDialog } from '../../stores/dialog/actions';
import { dialogTypes } from '../../stores/dialog/constants';
import { eventCanBeAddedToBasket } from '../../utils/EventUtils';
import { bookMatchesLimit } from '../../selectors/shoppingBasket';

// This class extends the react-selectable-fast dependency to override some
// internal methods and put additional synchronization logic to maintain what is
// on the redux store identical to what is on the internal component state.
class EventSelectorWrapper extends SelectableGroup {
    constructor(props) {
        super(props);

        this.selectItems = this.overridenSelectItems;
        this.mouseUp = this.overridenMouseUp;

        // the original implementation was calling this.props.onSelectionFinish;
        // we do not want it as the calendar will never unselect items that are
        // unmounted. As for example, when we switch the date the events from
        // first date must remain selected.
        this.contextValue.selectable.unregister = selectableItem => {
            this.registry.delete(selectableItem);
            this.selectedItems.delete(selectableItem);
            this.selectingItems.delete(selectableItem);
        };
    }

    // we need to do some logic to ensure synchronized inner state and redux
    // state. this is only needed because our redux state is modified by other
    // sources then only this component, such as the "deselect all" button.
    componentDidUpdate(prevProps) {
        const prevSelectedItems = Object.keys(prevProps.selectedItems);
        const currSelectedItems = Object.keys(this.props.selectedItems);

        this.syncFullDeselection(prevSelectedItems, currSelectedItems);
        this.syncBulkSelection(prevSelectedItems, currSelectedItems, prevProps);
        this.syncPartialDeselection(prevSelectedItems, currSelectedItems);
    }

    // we trigger the clear selection when we detect that the store state was fully
    // erased. This case is most likely triggered from the deselect all button.
    syncFullDeselection(prevSelectedItems, currSelectedItems) {
        if (prevSelectedItems.length > 0 && currSelectedItems.length === 0) {
            this.clearSelection();
        }
    }

    // we identify that items were added to the store by some side statement such
    // as the bulk selection or the load of selected items from the localStorage.
    syncBulkSelection(prevSelectedItems, currSelectedItems, prevProps) {
        if (
            prevSelectedItems.length < currSelectedItems.length &&
            currSelectedItems.length > this.selectedItems.size
        ) {
            this.registry.forEach(item => {
                const uri = item.props.event.uri;

                if (
                    this.props.selectedItems[uri] &&
                    !prevProps.selectedItems[uri]
                ) {
                    item.setState({ isSelected: true });
                    this.selectedItems.add(item);
                }
            });
        }
    }

    syncPartialDeselection(prevSelectedItems, currSelectedItems) {
        if (prevSelectedItems.length > currSelectedItems.length) {
            this.selectedItems.forEach(item => {
                if (!currSelectedItems.includes(item.props.event.uri)) {
                    this.selectedItems.delete(item);
                    item.setState({ isSelected: false });
                }
            });
        }
    }

    // we override this method because it gets called everytime the selectbox get
    // dragged, thus making it useful to maintain the selectbox bounds upgraded.
    overridenSelectItems(selectboxBounds) {
        if (this.scrollContainer) {
            selectboxBounds.top += this.scrollContainer.scrollTop;
            selectboxBounds.left += this.scrollContainer.scrollLeft;
        }

        this.selectboxBounds = selectboxBounds;
    }

    // this method is called on mouse up of dragging and clicking, here we wrote
    // the calendar specific logic of selection in place of the default one.
    overridenMouseUp = event => {
        if (this.mouseUpStarted) {
            return;
        }

        this.mouseUpStarted = true;
        this.mouseDownStarted = false;
        this.removeTempEventListeners();

        if (!this.mouseDownData) {
            return;
        }

        const isFromClick =
            !this.mouseMoved ||
            (this.selectboxBounds.width < 8 && this.selectboxBounds.height < 8);

        if (isFromClick) {
            this.selectboxBounds = {
                top:
                    event.clientY +
                    this.scrollContainer.scrollTop +
                    window.scrollY,
                left: event.clientX + this.scrollContainer.scrollLeft,
                width: 0,
                height: 0,
                offsetWidth: 0,
                offsetHeight: 0,
            };
        }

        let deletedItemUri = null;
        let selectingItemsLength = 0;
        const selectedItemsLength = Object.keys(this.props.selectedItems)
            .length;

        this.registry.forEach(item => {
            ({
                deletedItemUri,
                selectingItemsLength,
            } = this.selectOrDeselectItem(
                item,
                isFromClick,
                selectedItemsLength,
                selectingItemsLength,
                deletedItemUri
            ));
        });

        this.selectbox.setState({
            isSelecting: false,
            width: 0,
            height: 0,
        });

        this.props.onSelectionFinish(
            Array.from(this.selectedItems),
            deletedItemUri
        );

        if (
            selectedItemsLength + selectingItemsLength >
            this.props.bookMatchesLimit
        ) {
            this.props.addDialog(dialogTypes.OVER_LIMIT);
        }

        this.cleanUp();
        this.toggleSelectionMode();
        this.mouseMoved = false;
    };

    selectOrDeselectItem(
        item,
        isFromClick,
        selectedItemsLength,
        selectingItemsLength,
        deletedItemUri
    ) {
        if (!this.areElementsColliding(this.selectboxBounds, item.bounds)) {
            return { deletedItemUri, selectingItemsLength };
        }

        if (!eventCanBeAddedToBasket(item.props.event)) {
            if (isFromClick) {
                this.selectedItems.delete(item);
                deletedItemUri = item.props.event.uri;
                item.setState({ isSelected: false });
            }
            return { deletedItemUri, selectingItemsLength };
        }

        const isSelection = !isFromClick || !this.selectedItems.has(item);

        if (isSelection) {
            if (!item.state.isSelected) {
                selectingItemsLength++;
                if (
                    selectedItemsLength + selectingItemsLength <=
                    this.props.bookMatchesLimit
                ) {
                    this.selectedItems.add(item);
                    item.setState({ isSelected: true });
                }
            }
        } else {
            if (isFromClick) {
                this.selectedItems.delete(item);
                deletedItemUri = item.props.event.uri;
                item.setState({ isSelected: false });
            }
        }

        return { deletedItemUri, selectingItemsLength };
    }

    areElementsColliding(selectboxBounds, itemBounds) {
        const tolerance = 1;

        return !(
            selectboxBounds.top + selectboxBounds.offsetHeight - tolerance <
                itemBounds.top ||
            // selectbox top doesn't touch item bottom
            selectboxBounds.top + tolerance >
                itemBounds.top + itemBounds.offsetHeight ||
            // selectbox right doesn't touch item left
            selectboxBounds.left + selectboxBounds.offsetWidth - tolerance <
                itemBounds.left ||
            // selectbox left doesn't touch item right
            selectboxBounds.left + tolerance >
                itemBounds.left + itemBounds.offsetWidth
        );
    }
}

const mapStateToProps = state => ({
    selectedItems: state.shoppingBasket.items,
    bookMatchesLimit: bookMatchesLimit(state),
});

const mapDispatchToProps = {
    addDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventSelectorWrapper);
