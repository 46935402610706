import {
    PdsToggleButton,
    PdsToggleButtonGroup,
} from '@podium-design-system/react-components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setTab } from '../../../../../stores/leftColumn/actions';
import { tabs } from '../../../../../stores/leftColumn/constants';
import { setSelectedVersion } from '../../../../../stores/viewport/actions';
import './UiSwitcher.scss';

export const UiSwitcher = ({ selectedVersion, setSelectedVersion, setTab }) => {
    useEffect(() => {
        setSelectedVersion('new-version');
    }, []);

    const handleVersionChange = version => {
        setSelectedVersion(version);
        setTab('event_views', tabs.FILTERS);
    };

    return (
        <div className="ui-switcher">
            <PdsToggleButtonGroup
                data-testid="ui-switcher"
                size="xs"
                value={selectedVersion}
                color="action"
            >
                <PdsToggleButton
                    value="new-version"
                    onClick={() => handleVersionChange('new-version')}
                    selected={selectedVersion === 'new-version' ? true : false}
                >
                    New Version
                </PdsToggleButton>
                <PdsToggleButton
                    value="old-version"
                    onClick={() => handleVersionChange('old-version')}
                >
                    Old Version
                </PdsToggleButton>
            </PdsToggleButtonGroup>
        </div>
    );
};

UiSwitcher.propTypes = {
    selectedVersion: PropTypes.string,
    setSelectedVersion: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    selectedVersion: state.viewport.selectedVersion,
});

const mapDispatchToProps = {
    setSelectedVersion,
    setTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(UiSwitcher);
