import PropTypes from 'prop-types';
import React from 'react';
import './group.scss';

const Group = props => {
    let title = '';
    const style = {};

    if (props.title) {
        title = <div className="group-header">{props.title}</div>;
    }

    if (props.minHeight) {
        style['minHeight'] = props.minHeight;
    }

    const classNameIfAny = props.className || '';

    return (
        <div
            className={`event-info-group ${classNameIfAny}`}
            style={style}
            key={classNameIfAny}
        >
            {title}
            {props.children}
        </div>
    );
};

Group.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    minHeight: PropTypes.number,
    className: PropTypes.string,
};

export default Group;
