import Xhr from '../../utils/Xhr';
import actionConstants from './constants';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const isQuarkusEnabled = getState =>
    getState().calendar.auth.userInfo.bookmakerInfo.features
        .enable_lbc_quarkus_uf_template;

export const assignTemplate = (uri, id) => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOADING });
    const assignTemplateReq =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus(`/templates/assign`, null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              })
            : Xhr.backendRequest(`/templates/assign`, null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              });
    assignTemplateReq.then(response => {
        if (response.ok) {
            dispatch({
                type: actionConstants.ASSIGN,
                payload: {
                    assignable: response.assignable,
                    assigned: response.assigned,
                },
            });
        } else {
            dispatch({
                type: actionConstants.FAILED,
            });
        }
    });
};

export const switchTemplate = (uri, id) => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOADING });
    const switchTemplateReq =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus('/templates/switch', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              })
            : Xhr.backendRequest('/templates/switch', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              });
    switchTemplateReq.then(response => {
        if (response.ok) {
            dispatch({
                type: actionConstants.ASSIGN,
                payload: {
                    assignable: response.assignable,
                    assigned: response.assigned,
                },
            });
        } else {
            dispatch({
                type: actionConstants.FAILED,
            });
        }
    });
};

export const copyTemplate = (uri, id, name) => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOADING });
    const copyTemplateReq =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus('/templates/copy', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
                  templateName: name,
              })
            : Xhr.backendRequest('/templates/copy', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
                  templateName: name,
              });
    copyTemplateReq.then(response => {
        if (response.ok) {
            dispatch({
                type: actionConstants.ASSIGN,
                payload: {
                    assignable: response.assignable,
                    assigned: response.assigned,
                },
            });
        } else {
            dispatch({
                type: actionConstants.FAILED,
            });
        }
    });
};

export const removeTemplate = (uri, id) => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOADING });
    const removeTemplateReq =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus('/templates/remove', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              })
            : Xhr.backendRequest('/templates/remove', null, 'POST', {
                  eventUri: uri,
                  templateId: id,
              });
    removeTemplateReq.then(response => {
        if (response.ok) {
            // Will return total new state, like fetchTemplates, since MATCH
            // can no longer be assigned a template
            dispatch({
                type: actionConstants.REMOVE,
                payload: {
                    assignable: response.assignable,
                    assigned: response.assigned,
                },
            });
        } else {
            dispatch({
                type: actionConstants.FAILED,
            });
        }
    });
};

export const fetchTemplates = uri => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOADING });
    const fetchTemplatesReq =
        isMicrofrontend && isQuarkusEnabled(getState)
            ? Xhr.backendRequestLbcQuarkus(
                  '/templates/getAssignable',
                  null,
                  'GET',
                  {
                      eventUri: uri,
                  }
              )
            : Xhr.backendRequest('/templates/getAssignable', null, 'GET', {
                  eventUri: uri,
              });
    fetchTemplatesReq.then(response => {
        dispatch({
            type: actionConstants.FETCH,
            payload: {
                assignable: response.assignable,
                assigned: response.assigned,
            },
        });
    });
};
