import types from './constants';

const initialState = {
    availableTemplates: [],
    assignedTemplateId: null,
    effectiveType: null,
    effectiveTemplates: null,
    loading: true,
};

export default (state, action) => {
    switch (action.type) {
        case types.ASSIGN:
        case types.REMOVE:
        case types.FETCH:
            return {
                ...state,
                availableTemplates: action.payload.assignable,
                assignedTemplates: action.payload.assigned.all,
                effectiveTemplateId: action.payload.assigned.effectiveId,
                effectiveType: action.payload.assigned.effectiveType,
                loading: false,
            };
        case types.LOADING:
            return {
                ...state,
                loading: true,
            };
        case types.FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state || initialState;
    }
};
