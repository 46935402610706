import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getOverview,
    shouldReloadOverview,
} from '../../../../../selectors/history';
import { type as historyType } from '../../../../../stores/bookingHistory/constants';
import {
    loadBookingHistory,
    loadMoreBookingHistory,
} from '../../../../../stores/bookingHistory/actions';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import SportIcon from '../../../../icons/SportIcon/SportIcon';
import Button from '../../../../formInput/Button/Button';
import DropDown from '../../../../formInput/Dropdown/DropDown';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import Xhr from '../../../../../utils/Xhr';
import { Info } from 'luxon';
import './bookingHistory.scss';

const formatNumber = x => {
    if (x === 0) {
        return <span className="zero-value">0</span>;
    }
    const a = String(x);
    let b = '';
    for (let i = a.length - 1, y = 0; i >= 0; i--, y++) {
        b = a[i] + (y > 0 && y % 3 === 0 ? ' ' : '') + b;
    }
    return b;
};

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const monthNameToNumber = name =>
    Info.months('long', { locale: 'en-US' }).indexOf(name) + 1;

class BookingHistory extends React.Component {
    state = {
        downloadType: 'csv',
        expandedRows: [],
        from: -2,
        to: 2,
    };

    componentDidMount() {
        if (this.props.shouldReload) {
            this.props.loadBookingHistory(
                this.state.from,
                this.state.to,
                this.props.type,
                this.props.subProductId
            );
        }
    }

    _buildLoadMoreButton() {
        return !this.props.matchInfo.loadingMore ? (
            <div className="load-more-button-container">
                <Button
                    type={Button.types.LARGE_TRANSPARENT_SOLID}
                    icon=""
                    onClick={() => {
                        if (this.props.matchInfo.loadingMore) {
                            return;
                        }
                        this.loadMore();
                    }}
                    fixedWidth={false}
                >
                    Load More
                </Button>
            </div>
        ) : (
            <div className="load-more-button-container">
                <BusySpinner margin={'auto'} />
            </div>
        );
    }

    loadMore() {
        const from = this.state.from - 3;
        const to = this.state.from - 1;

        this.props.loadMoreBookingHistory(
            from,
            to,
            this.props.type,
            this.props.subProductId
        );

        this.setState({
            from: from,
            to: to,
        });
    }

    buildExportButton(year, monthName, type) {
        const month = monthNameToNumber(monthName);
        const downloadLink =
            isMicrofrontend && this.props.isHistoryExportEnabled
                ? `${Xhr._getBaseUrl()}/lbc/liveodds/historyExport?type=${type}&month=${month}&year=${year}`
                : `${Xhr._getBaseUrl()}/loweb/api/liveodds/historyExport?type=${type}&month=${month}&year=${year}`;
        return (
            <a href={downloadLink} download onClick={e => e.stopPropagation()}>
                <Button
                    type={Button.types.LARGE_BLUE_WHITE}
                    icon=""
                    fixedWidth={false}
                >
                    Download Report
                </Button>
            </a>
        );
    }

    _buildTableHeader() {
        return (
            <thead>
                <tr className="header text-left">
                    <th>Month</th>
                    <th title="All bookings done">Total</th>
                    <th title="Bookings done inside package limits">
                        Package Bookings
                    </th>
                    <th title="Bookings done after exceed package limits, using Single Match Price">
                        Single Bookings
                    </th>
                    <th title="Bookings done with surcharges applied">
                        Surcharged Bookings
                    </th>
                    <th className="exportHeader">
                        <DropDown
                            onChange={downloadType =>
                                this.setState({ downloadType })
                            }
                            options={[
                                { value: 'csv', text: 'CSV' },
                                { value: 'xml', text: 'XML' },
                            ]}
                            value={this.state.downloadType}
                            label="Download report as"
                            labelLeft
                        />
                    </th>
                </tr>
            </thead>
        );
    }

    _buildRow(rowData, level) {
        const isExpanded =
            rowData.children && this.state.expandedRows.includes(rowData.key);

        const icon = rowData.children ? (
            <FontIcon icon={isExpanded ? '' : ''} />
        ) : (
            <SportIcon sportId={rowData.sportId} />
        );

        const row = (
            <tr
                onClick={() => this._toggleRowIsExpanded(rowData.key)}
                key={rowData.key}
            >
                <td className={`level-${level}`}>
                    {icon}
                    {rowData.name}
                </td>
                <td>{formatNumber(rowData['bookings-total'])}</td>
                <td>{formatNumber(rowData['bookings-package'])}</td>
                <td>{formatNumber(rowData['bookings-single'])}</td>
                <td>{formatNumber(rowData['bookings-surcharged'])}</td>
                <td>
                    {level === 1 &&
                        this.buildExportButton(
                            rowData.year,
                            rowData.month,
                            this.state.downloadType
                        )}
                </td>
            </tr>
        );
        let children = [];

        if (isExpanded) {
            children = rowData.children.map(childRow =>
                this._buildRow(childRow, level + 1)
            );
        }

        return [row, ...children];
    }

    _toggleRowIsExpanded(key) {
        const index = this.state.expandedRows.indexOf(key),
            newExpandedRows = [...this.state.expandedRows];

        if (index >= 0) {
            newExpandedRows.splice(index, 1);
        } else {
            newExpandedRows.push(key);
        }
        this.setState({
            expandedRows: newExpandedRows,
        });
    }

    _buildTable() {
        const rows = [];
        this.props.matchInfo.data.forEach(row =>
            rows.push(...this._buildRow(row, 1))
        );
        return (
            <div className="table-wrapper">
                <table className="table-400 text-gray-1100">
                    {this._buildTableHeader()}
                    <tbody>{rows}</tbody>
                </table>
            </div>
        );
    }

    render() {
        if (
            !this.props.matchInfo ||
            !this.props.matchInfo.data ||
            this.props.matchInfo.loading
        ) {
            return <BusySpinner />;
        }

        const content = (
            <>
                {this._buildTable()}
                {this._buildLoadMoreButton()}
            </>
        );

        return <div className="booking-history">{content}</div>;
    }
}

BookingHistory.propTypes = {
    type: PropTypes.oneOf(Object.values(historyType)),
    subProductId: PropTypes.number,
    loadBookingHistory: PropTypes.func.isRequired,
    loadMoreBookingHistory: PropTypes.func.isRequired,
    shouldReload: PropTypes.bool.isRequired,
    matchInfo: PropTypes.object.isRequired,
    isHistoryExportEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    matchInfo: getOverview(state, ownProps.type),
    shouldReload: shouldReloadOverview(state, ownProps.type),
    isHistoryExportEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_history_overview,
});

const mapDispatchToProps = {
    loadBookingHistory,
    loadMoreBookingHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
