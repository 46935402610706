import React from 'react';
import PropTypes from 'prop-types';
import './ContentBox.scss';

const ContentBox = props => (
    <div className={`content-box ${props.className || ''}`} style={props.style}>
        {props.children}
    </div>
);

ContentBox.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};

export default ContentBox;
