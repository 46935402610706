import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import todayIsSelected from '../../../../../selectors/todayIsSelected';
import { fetchEventsForDay } from '../../../../../stores/calendar/actions';
import { saveScrollPosition } from '../../../../../stores/viewport/actions';
import moment from '../../../../../utils/CalendarMoment';
import Button from '../../../../formInput/Button/Button';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import './dateSelection.scss';

export const dateSelectionSizes = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

const dateSelectionSizesOrdered = [
    dateSelectionSizes.LARGE,
    dateSelectionSizes.MEDIUM,
    dateSelectionSizes.SMALL,
];

const dateSelectionWidth = {
    large: 600,
    medium: 450,
    small: 375,
};

export class DateSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderSize: this.props.forceSize || dateSelectionSizes.SMALL,
        };
    }

    componentDidUpdate() {
        const renderSize = this.calculateRenderSize();
        if (renderSize !== this.state.renderSize) {
            this.setState({ renderSize });
        }
    }

    calculateRenderSize() {
        const availableSize = this.props.availableSize;
        let renderSize = dateSelectionSizes.SMALL;

        for (const size of dateSelectionSizesOrdered) {
            if (availableSize > dateSelectionWidth[size]) {
                renderSize = size;
                break;
            }
        }
        return renderSize;
    }

    _fetchForDay(mm) {
        this.props.saveScrollPosition(0);
        this.props.fetchEventsForDay(mm, this.props.currentView);
    }

    _gotoToday = () => {
        if (!this.props.todayIsSelected) {
            this._fetchForDay(moment.now());
        }
    };

    _gotoPrevDay = () => {
        this._fetchForDay(
            moment
                .fromTimestamp(this.props.momentSelectedDay)
                .minus({ days: 1 })
        );
    };

    _gotoNextDay = () => {
        this._fetchForDay(
            moment.fromTimestamp(this.props.momentSelectedDay).plus({ days: 1 })
        );
    };

    _datePickerDateSelected = mm => {
        this.props.saveScrollPosition(0);
        this.props.fetchEventsForDay(mm, this.props.currentView);
    };

    _buildTimeZoneDiv() {
        if (this.state.renderSize === dateSelectionSizes.LARGE) {
            return (
                <div className="timezone-holder ui-400">
                    <FontIcon icon="" />
                    {moment.getTimezone()}
                </div>
            );
        }
        return '';
    }

    _buildTodayButton(buttonHeight) {
        if (this.state.renderSize !== dateSelectionSizes.SMALL) {
            return (
                <Button
                    className="today-button ui-500" //I think button mostly overrides font
                    type={Button.types.LARGE_GREY}
                    onClick={this._gotoToday}
                    disabled={this.props.todayIsSelected}
                    fixedWidth="70px"
                    style={{
                        height: buttonHeight,
                    }}
                >
                    today
                </Button>
            );
        }
        return '';
    }

    _buildDateSelector() {
        const DateSelector = forwardRef(({ onClick }, ref) => (
            <button
                onClick={onClick}
                ref={ref}
                className="date-holder"
                style={{
                    width:
                        this.state.renderSize !== dateSelectionSizes.LARGE
                            ? 280
                            : 300,
                }}
            >
                {moment
                    .fromTimestamp(this.props.momentSelectedDay)
                    .toFormat('DDDD')}
                <span className="todayString">
                    {this.props.todayIsSelected ? '(today)' : ''}
                </span>
                <FontIcon icon="" />
            </button>
        ));
        return <DateSelector />;
    }

    render() {
        const buttonHeight = 32;
        return (
            <div className="date-selection">
                {this._buildTodayButton(buttonHeight)}
                <Button
                    className="prev-day-button"
                    type={Button.types.LARGE_GREY}
                    onClick={this._gotoPrevDay}
                    fixedWidth={buttonHeight}
                    style={{
                        height: buttonHeight,
                    }}
                >
                    <FontIcon icon="" />
                </Button>
                <div>
                    <DatePicker
                        customInput={this._buildDateSelector()}
                        title=""
                        placeholderText=""
                        onChange={date => {
                            const time = Date.UTC(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                0,
                                0,
                                0
                            );
                            this._datePickerDateSelected(
                                moment.fromTimestamp(time / 1000)
                            );
                        }}
                        className="text-input"
                        todayButton="Today"
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <Button
                    className="next-day-button"
                    type={Button.types.LARGE_GREY}
                    onClick={this._gotoNextDay}
                    fixedWidth={buttonHeight}
                    style={{
                        height: buttonHeight,
                    }}
                >
                    <FontIcon icon="" />
                </Button>
                {this._buildTimeZoneDiv()}
            </div>
        );
    }
}

DateSelection.propTypes = {
    fetchEventsForDay: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    momentSelectedDay: PropTypes.any.isRequired,
    backendSettings: PropTypes.object,
    size: PropTypes.object.isRequired,
    todayIsSelected: PropTypes.bool.isRequired,
    forceSize: PropTypes.string,
    availableSize: PropTypes.number,
    onClick: PropTypes.func,
    value: PropTypes.any,
    saveScrollPosition: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    momentSelectedDay: state.calendar.selectedDayTimestamp,
    backendSettings: state.calendar.backendSettings,
    size: state.calendar.size,
    todayIsSelected: todayIsSelected(state),
});

const mapDispatchToProps = { fetchEventsForDay, saveScrollPosition };

export default connect(mapStateToProps, mapDispatchToProps)(DateSelection);
