import React from 'react';
import Alert from '../../../../Alert/Alert';

const DateStepToday = () => (
    <div className="date-step-today">
        <Alert
            type={Alert.types.INFO_BOX}
            minWidth={false}
            icon={''}
            float={false}
        >
            Matches for today will be exported
        </Alert>
    </div>
);

export default DateStepToday;
