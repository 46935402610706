/**
 * Temporary file that converts uris to match ids, used on backend endpoints that still don't support uris.
 * Once all backend endpoints supports uris this file and all of its usages can be cleaned up.
 */

const uriToId = function (uri) {
    return uri.split(':')[2];
};

export default uriToId;
