import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomSelect from '../../../../../../formInput/CustomSelect/CustomSelect';
import FontIcon from '../../../../../../icons/FontIcon/FontIcon.jsx';
import { fetchOptions } from '../../../../../../../stores/autobooking/actions';

// TODO this is no longer a row, rename it
class AutobookingDropDownRow extends Component {
    /**
     * Fetch data if our datasource changed
     */
    componentDidUpdate(prevProps) {
        if (prevProps.remoteDataUri !== this.props.remoteDataUri) {
            this.props.fetchOptions(
                this.props.remoteDataUri,
                this.props.optionName,
                this.props.parentKey,
                this.props.parentValue,
                this.props.defaultValue,
                this.props.sortValues
            );
        }
    }

    componentDidMount() {
        if (!this.props.options) {
            this.props.fetchOptions(
                this.props.remoteDataUri,
                this.props.optionName,
                this.props.parentKey,
                this.props.parentValue,
                this.props.defaultValue,
                this.props.sortValues
            );
        }
    }

    _onChange(selected) {
        selected.value = parseInt(selected.value, 10);

        if (isNaN(selected.value)) {
            this.props.onChange(this.props.defaultValue);
        } else {
            this.props.onChange(selected);
        }
    }

    render() {
        const loadingPlaceholder = (
            <FontIcon className="spinner" icon=""></FontIcon>
        );
        return (
            <CustomSelect
                id={this.props.id}
                onChange={this._onChange.bind(this)}
                value={this.props.currentValue}
                clearable={false}
                isDisabled={this.props.disabled || this.props.isLoading}
                searchable={true}
                optionHeight={20}
                options={this.props.options}
                isLoading={this.props.isLoading}
                placeholder={
                    this.props.isLoading
                        ? loadingPlaceholder
                        : this.props.placeholder
                }
            />
        );
    }
}

AutobookingDropDownRow.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object,
    remoteDataUri: PropTypes.string.isRequired,
    currentValue: PropTypes.object.isRequired,
    onDisabledClick: PropTypes.func,
    sortValues: PropTypes.bool,
    warning: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchOptions: PropTypes.func.isRequired,
    optionName: PropTypes.string.isRequired,
    parentKey: PropTypes.string.isRequired,
    parentValue: PropTypes.number.isRequired,
};

AutobookingDropDownRow.defaultProps = {
    id: '',
    defaultValue: { value: -1, label: 'All' },
    onDisabledClick: null,
    sortValues: false,
    disabled: false,
    warning: '',
    parentValue: -1,
    optionName: '',
};

const mapDispatchToProps = {
    fetchOptions,
};

export default connect(null, mapDispatchToProps)(AutobookingDropDownRow);
