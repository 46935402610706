import Event from './Event';
import { connect } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';
import { isInBasket } from '../../selectors/shoppingBasket';

const mapStateToProps = (state, ownProps) => ({
    isSelected: isInBasket(state, ownProps),
});

export default connect(mapStateToProps)(createSelectable(Event));
