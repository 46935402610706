import Xhr from '../../utils/Xhr';
import types from './constants';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const fetchData =
    (uri, validatorFunction, autoErrorHandling = true) =>
    (dispatch, getState) => {
        dispatch({
            type: types.START,
            payload: {
                uri,
            },
        });
        const isQuarkusEnabled = uri.includes('find-events')
            ? getState().calendar.auth.userInfo.bookmakerInfo.features
                  .enable_lbc_quarkus_find_events
            : getState().calendar.auth.userInfo.bookmakerInfo.features
                  .data_group;
        const fetchDataReq =
            isQuarkusEnabled && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      uri,
                      validatorFunction,
                      'GET',
                      null,
                      autoErrorHandling
                  )
                : Xhr.backendRequest(
                      uri,
                      validatorFunction,
                      'GET',
                      null,
                      autoErrorHandling
                  );

        fetchDataReq.then(
            response => {
                dispatch({
                    type: types.SUCCESS,
                    payload: {
                        uri,
                        response,
                    },
                });
            },
            error => {
                dispatch({
                    type: types.ERROR,
                    payload: {
                        uri,
                        error,
                    },
                });
            }
        );
    };
