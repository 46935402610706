import PropTypes from 'prop-types';
import React from 'react';
import { Info } from 'luxon';
import CustomSelect from '../../../../formInput/CustomSelect/CustomSelect';
import moment from '../../../../../utils/CalendarMoment';

const getMonthOptions = () => {
    return Info.months().map((month, index) => {
        return {
            value: index + 1,
            label: month,
        };
    });
};

const getYearOptions = () => {
    let year = moment.now().year - 1; // Start from last year
    const amountOfYears = 3,
        years = [];

    for (let x = 0; x < amountOfYears; x++) {
        years.push({
            value: year,
            label: year,
        });
        year++;
    }
    return years;
};

export const getInitialDropDownValues = () => {
    return {
        selectedYear: {
            value: moment.now().year,
            label: moment.now().year,
        },
        selectedMonth: {
            value: moment.now().month,
            label: moment.now().toFormat('MMMM'),
        },
    };
};

const DateStepMonth = props => (
    <div className="date-step-month">
        <div className="selectors">
            <fieldset>
                <legend>Select a month to export</legend>
                <div className="year">
                    <CustomSelect
                        onChange={selected => {
                            props.onSelectedYearChange(selected);
                            const from = moment
                                .now()
                                .set({
                                    month: props.selectedMonth.value,
                                    year: selected.value,
                                })
                                .startOf('month');
                            const to = moment
                                .now()
                                .set({
                                    month: props.selectedMonth.value,
                                    year: selected.value,
                                })
                                .endOf('month');
                            props.onDateRangeChange(from, to);
                        }}
                        value={props.selectedYear}
                        placeholder={'Year'}
                        clearable={false}
                        searchable={false}
                        optionHeight={20}
                        options={getYearOptions()}
                    />
                </div>
                <div className="month">
                    <CustomSelect
                        onChange={selected => {
                            props.onSelectedMonthChange(selected);
                            const from = moment
                                .now()
                                .set({
                                    month: selected.value,
                                    year: props.selectedYear.value,
                                })
                                .startOf('month');
                            const to = moment
                                .now()
                                .set({
                                    month: selected.value,
                                    year: props.selectedYear.value,
                                })
                                .endOf('month');
                            props.onDateRangeChange(from, to);
                        }}
                        value={props.selectedMonth}
                        placeholder={'Select a month'}
                        clearable={false}
                        searchable={false}
                        optionHeight={20}
                        options={getMonthOptions()}
                    />
                </div>
            </fieldset>
        </div>
    </div>
);

DateStepMonth.propTypes = {
    selectedMonth: PropTypes.object.isRequired,
    selectedYear: PropTypes.object.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
    onSelectedYearChange: PropTypes.func.isRequired,
    onSelectedMonthChange: PropTypes.func.isRequired,
};

export default DateStepMonth;
