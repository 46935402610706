import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'underscore';
import Checkbox from '../Checkbox/Checkbox.jsx';
import './weekDayPicker.scss';

export const dayNames = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
};

class WeekDayPicker extends Component {
    componentDidUpdate() {
        if (this.props.disabled && this.props.selectedDays) {
            this.props.onChange(null);
        }
    }

    _onCheckboxClick(id) {
        const selectedDays = [...this._getSelectedDays()],
            index = selectedDays.indexOf(id);

        if (index >= 0) {
            selectedDays.splice(index, 1);
        } else {
            selectedDays.push(id);
        }

        this.props.onChange(selectedDays);
    }

    _renderCheckBoxes(disabled, selectedDays) {
        return _.map(dayNames, (dayIndex, dayName) => {
            return (
                <div key={dayName}>
                    <Checkbox
                        size={15}
                        contentPosition="below"
                        checked={selectedDays.indexOf(dayIndex) > -1}
                        onClick={this._onCheckboxClick.bind(this)}
                        disabled={disabled}
                        id={dayIndex}
                    >
                        {dayName.substr(0, 3).toUpperCase()}
                    </Checkbox>
                </div>
            );
        });
    }

    _getSelectedDays() {
        return this.props.selectedDays || [];
    }

    _buildLabel(label) {
        if (!label) {
            return '';
        }
        return <div className="label">{label}</div>;
    }

    render() {
        return (
            <div className="week-day-picker">
                {this._buildLabel(this.props.label)}
                <div className="checkboxes">
                    {this._renderCheckBoxes(
                        this.props.disabled,
                        this._getSelectedDays()
                    )}
                </div>
            </div>
        );
    }
}

WeekDayPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    selectedDays: PropTypes.array,
};

WeekDayPicker.defaultProps = {
    disabled: false,
    label: '',
};

export default WeekDayPicker;
