import React from 'react';
import PropTypes from 'prop-types';
import './FlagIcon.scss';

const FlagIcon = props => {
    return <div className={`flag-icon ${props.countryCode}`} />;
};

FlagIcon.propTypes = {
    countryCode: PropTypes.string.isRequired,
};

export default FlagIcon;
