import { PdsCheckbox } from '@podium-design-system/react-components';
import {
    PdsCheckboxSelectedMdIcon,
    PdsCheckboxUnselectedMdIcon,
    PdsChevronDownSmIcon,
    PdsChevronUpSmIcon,
    PdsVideoOnIcon,
} from '@podium-design-system/react-icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './collapsibleRow.scss';

const CollapsibleRow = ({
    isDefault,
    name,
    children,
    onParentCheckboxChange,
    isReadOnly = false,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isChecked, setIsChecked] = useState(isDefault);

    useEffect(() => {
        setIsChecked(isDefault);
    }, [isDefault]);

    return (
        <div className="collapsible-row">
            <div
                className={classnames('header', { collapsible: isCollapsed })}
                onClick={e => {
                    setIsCollapsed(!isCollapsed);
                }}
            >
                <div className="collapsible-row-title">
                    {isReadOnly ? (
                        isDefault ? (
                            <PdsCheckboxSelectedMdIcon />
                        ) : (
                            <PdsCheckboxUnselectedMdIcon />
                        )
                    ) : (
                        <PdsCheckbox
                            checked={isChecked}
                            className="checkbox"
                            onClick={e => {
                                e.stopPropagation();
                                setIsChecked(!e.target.checked);
                                onParentCheckboxChange(isChecked);
                            }}
                        />
                    )}
                    <PdsVideoOnIcon className="icon-video" />
                    <div className="title text-semibold">{name}</div>
                </div>
                <div className="indicators">{buildChevron(isCollapsed)}</div>
            </div>
            {!isCollapsed && <div className="content">{children}</div>}
        </div>
    );
};

CollapsibleRow.propTypes = {
    isDefault: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    onParentCheckboxChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
};

function buildChevron(collapsed) {
    return collapsed ? <PdsChevronDownSmIcon /> : <PdsChevronUpSmIcon />;
}

export default CollapsibleRow;
