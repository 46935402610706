import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import bookMatchesLimit from '../../../../selectors/shoppingBasket/bookMatchesLimit';

const OverLimitDialog = props => {
    return (
        <Confirmation
            onConfirm={props.onClose}
            confirmText={'Ok'}
            showCancelButton={false}
        >
            {`You are only allowed to book ${props.maxMatchesInBasket} matches at once`}
        </Confirmation>
    );
};

OverLimitDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    maxMatchesInBasket: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    maxMatchesInBasket: bookMatchesLimit(state),
});

export default connect(mapStateToProps, {})(OverLimitDialog);
