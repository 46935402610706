import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FilterUtils from '../../../../utils/FilterUtils';
import FilterSelectionBox from '../../../FilterSelectionBox/FilterSelectionBox';
import _ from 'underscore';
import Alert from '../../../Alert/Alert';
import Button from '../../../formInput/Button/Button';
import Xhr from '../../../../utils/Xhr';
import settings from '../../../../utils/DefaultSettings';
import { addDialog } from '../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../stores/dialog/constants';
import {
    setFilterSelection,
    refetchEventsForDay,
    setDefaultFilterId,
} from '../../../../stores/calendar/actions';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import './loadFilterSelectionDialog.scss';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class FormLoadFilterSelection extends React.Component {
    _isMounted = false;

    state = {
        defaultFilterId: 0,
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.deletedFilterSets.indexOf(props.defaultFilterId) !== -1) {
            return { defaultFilterId: 0 };
        }
        return null;
    }

    _getItems() {
        const { remoteData } = this.props,
            items = [];
        const data = this.props.enableQuarkusInPortal
            ? remoteData
            : remoteData.response;
        items.push(
            FilterUtils.getDefaultFilterSelection(this.props.isReseller)
        );
        data.length > 0 &&
            data.forEach(filter => {
                items.push(FilterUtils.getFilterSelectionFromResponse(filter));
            });

        items.length > 0 &&
            items.forEach((filteredSelection, i) => {
                filteredSelection.selectedCheckboxes.forEach(
                    (selectedCheckbox, j) => {
                        items[i].selectedCheckboxes[j].subGroupId =
                            FilterUtils.getSubGroupId(selectedCheckbox.value);
                    }
                );
            });

        return items;
    }

    _loadFilterSelection(filterSelection) {
        this.props.onClose();
        this.props.setFilterSelection(filterSelection);
        this.props.refetchEventsForDay(this.props.currentView);
    }

    _removeFilterSelection(filterSelectionUid, filterTitle) {
        this.props.addDialog(dialogTypes.DELETE_FILTER_SET, {
            filterSetId: filterSelectionUid,
            filterSetTitle: filterTitle,
        });
    }

    _renderItem(filterSelection, key) {
        const { defaultFilterId } = this.props;
        let removeButton = '';

        if (!_.isNull(filterSelection.uid)) {
            removeButton = (
                <Button
                    type={Button.types.LARGE_GREY_SHADOW_HOVER_RED}
                    icon=""
                    onClick={this._removeFilterSelection.bind(
                        this,
                        filterSelection.uid,
                        filterSelection.title
                    )}
                    className="remove-button"
                    fixedWidth={false}
                >
                    remove
                </Button>
            );
        }

        return (
            <FilterSelectionBox
                title={filterSelection.title}
                filterSelection={filterSelection}
                showStarIcon={true}
                key={`FilterSelectionBox${key}`}
                starClickHandler={() =>
                    this._setDefaultFilterSet(
                        filterSelection.uid ? filterSelection.uid : 0
                    )
                }
                isDefault={
                    (_.isNull(filterSelection.uid) && defaultFilterId === 0) ||
                    filterSelection.uid === defaultFilterId
                }
            >
                <Button
                    type={Button.types.LARGE_BLUE_SHADOW}
                    onClick={this._loadFilterSelection.bind(
                        this,
                        filterSelection
                    )}
                    className="load-button"
                    fixedWidth={false}
                >
                    load
                </Button>
                {removeButton}
            </FilterSelectionBox>
        );
    }

    _setDefaultFilterSet(id) {
        if (id !== this.props.defaultFilterId) {
            const uri = settings.setDefaultFilterUri.replace('{id}', id);
            const setDefaultFilterSetReq = this.props.enableQuarkusInPortal
                ? Xhr.backendRequestLbcQuarkus(uri, null, 'POST')
                : Xhr.backendRequest(uri, null, 'POST');
            setDefaultFilterSetReq.then(() => {
                this._isMounted && this.props.setDefaultFilterId(id);
            });
        }
    }

    render() {
        let content = '',
            items = [];
        const { remoteData, isLoading } = this.props;

        if (isLoading) {
            content = <BusySpinner />;
        } else if (remoteData) {
            items = this._getItems();

            const visibleItems = _.filter(items, item => {
                return (
                    _.isNull(item.uid) ||
                    this.props.deletedFilterSets.indexOf(item.uid) < 0
                );
            });
            content = visibleItems.map((item, i) => {
                return this._renderItem(item, i);
            });
        }

        return (
            <div className="dialog-content-wrapper load-filters">
                <div className="sub-header">
                    <Alert type={Alert.types.EXPLANATORY} icon="">
                        <p>
                            By saving and loading your filters you can easily
                            switch between different selections.
                        </p>
                        <p>
                            Click <strong>load</strong> to load filter
                            selections you have saved earlier.
                        </p>
                        <p>
                            You can select a favorite by selecting the{' '}
                            <strong>star icon</strong> in front of it. It will
                            be <strong>loaded by default</strong> next time you
                            open the Live Booking Calendar.
                        </p>
                    </Alert>
                </div>
                <div className="content">{content}</div>
                <div className="dialog-footer">
                    <Button
                        onClick={this.props.onClose}
                        type={Button.types.LARGE_GREY_SHADOW}
                        icon={''}
                        fixedWidth={false}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

FormLoadFilterSelection.propTypes = {
    remoteData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    deletedFilterSets: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    setFilterSelection: PropTypes.func.isRequired,
    refetchEventsForDay: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    isReseller: PropTypes.bool,
    setDefaultFilterId: PropTypes.func.isRequired,
    defaultFilterId: PropTypes.number.isRequired,
    enableQuarkusInPortal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    enableQuarkusInPortal:
        state.calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend,
    deletedFilterSets: state.calendar.deletedFilterSets,
    isReseller: state.calendar.auth.userInfo.bookmakerInfo.isReseller,
    defaultFilterId: state.calendar.defaultFilterId,
});

const mapDispatchToProps = {
    setFilterSelection,
    refetchEventsForDay,
    addDialog,
    setDefaultFilterId,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLoadFilterSelection);
