const DefaultSettings = {
    parentId: 'calendar',
    isResponsive: true,
    size: { width: 800, height: 600 },
    checkoutWidth: 140,
    resizeTimeout: 50,
    noMatchesMsg: 'No matches found for this day. Adjust your filter settings.',
    useWindowDimensions: true,
    reRenderDelay: 750, // used when user changes/clicks filter settings
    authUri: 'start',
    feedUri: 'feed/{timestampStart}/{timestampEnd}',
    feedUriCache: 'feed/{timestampStart}/{timestampEnd}/{ttlMinutes}',
    feedAutoReload: 600000, // 10 minutes
    deleteAutobookingRuleUri: 'autobook/delete',
    exportAutobookingRuleUri: 'autobook/export?',
    importAutobookingRuleUri: '/autobook/import',
    filterUri: 'filter',
    saveFilterUri: 'filter-settings/create',
    updateFilterUri: 'filter-settings/update/{id}',
    deleteFilterUri: 'filter-settings/delete/{id}',
    setDefaultFilterUri: 'filter-settings/set-default/{id}',
    defaultFilterSetName: 'Sportradar default',
    defaultAttributeSetName: 'Sportradar default',
    categoriesUri: 'categories/{sportId}',
    tournamentsUri: 'tournaments/{realCategoryId}',
    event: {
        list: {
            size: { width: 168, height: 55 },
            spacing: { x: 1, y: 1 },
        },
        listCondensed: {
            size: { height: 24 },
            spacing: { x: 0, y: 0 },
        },
    },
    timeLine: {
        headerHeight: 30, // px
        sportHeaderHeight: 20, //px
        blockWidth: 60, // px
        sportPillar: {
            width: 90,
        },
        scheduleOverview: {
            height: 150,
            expandedHeight: 150,
            canvasTopPadding: 10,
            columnsPerHour: 4,
            gridLineCount: 4,
            maxColorCount: 6,
            defaultColor: 'red',
        },
    },
    remoteData: {
        bookingHistory: {
            uri: '/liveodds/historyOverview',
            caching: true,
        },
        streamingBookingHistory: {
            uri: '/streaming/history',
            caching: true,
        },
        packagesOverview: {
            uri: '/packages/overview',
            caching: false,
            autoErrorHandling: false,
        },
        filter: {
            uri: 'filter-settings/get',
            caching: false,
        },
    },
    autobooking: {
        getAllUri: '/autobook/get-all',
        saveUri: '/autobook/save',
        saveQuarkusUri: '/autobook',
        tournamentInfoUri: '/tournament-info/{tournament_id}',
        textOutputs: {
            // TODO most of these aren't used
            // we could just delete them, but it might be better to use them?
            noPeriodFilterSelected:
                'No period filter has been selected. Autobooking will book' +
                ' matches until the rule is deleted. The rule created/edited will autobook events 30 days in advance!',
            periodFilterSelected:
                'Period filter has been selected.\n Autobooking will continue booking' +
                ' matches until {date_end}. The rule created/edited will autobook events 30 days in advance!',
            noFilterCantCreate:
                'No filters applied, can not create autobooking.',
            noSelectionMatchesOnDayIgnore:
                "No days have been selected in 'Matches on days' filter, the filter" +
                ' will not be used.',
            timeOrderError: 'Invalid value(s)',
            dateOrderError: 'Invalid value(s)',
            sportRequired: 'Sport is a required field',
            productRequired: 'You must choose at least one product to book',
        },
    },
};

export default DefaultSettings;
