import React from 'react';
import PropTypes from 'prop-types';
import moment from '../../../utils/CalendarMoment';

function MatchEventDate(props) {
    const date = moment.fromTimestamp(props.startDateTimestamp);
    let dateStr = '';

    if (date.hasSame(moment.now(), 'day')) {
        dateStr = 'today';
    } else {
        const tomorrow = moment.now().plus({ days: 1 });

        if (date.hasSame(tomorrow, 'day')) {
            dateStr = 'tomorrow';
        } else {
            dateStr = date.toFormat('dd MMMM');
        }
    }

    return <span className="event-date">{dateStr}</span>;
}

MatchEventDate.propTypes = {
    startDateTimestamp: PropTypes.number.isRequired,
};

export default MatchEventDate;
