import PropTypes from 'prop-types';
import React from 'react';
import { AV_PRODUCT } from '../../../constants/audioVisual';
import moment from '../../../utils/CalendarMoment';
import StorageLocal from '../../../utils/StorageLocal';
import Xhr from '../../../utils/Xhr';
import uriToId from '../../../utils/uriToId';
import Alert from '../../Alert/Alert';
import BusySpinner from '../../BusySpinner/BusySpinner';
import MatchQuickInfo from '../../dialog/dialogComponents/MatchQuickInfo/MatchQuickInfo';
import Button from '../../formInput/Button/Button';
import CameraIcon from '../../icons/CameraIcon/CameraIcon';
import './FullStreamPlayer.scss';

const PLAYER_STATE_INIT = 0,
    PLAYER_STATE_CHOOSE_STREAM = 1,
    PLAYER_STATE_LOADED = 2,
    PLAYER_STATE_ERROR_NO_MATCH = 3,
    PLAYER_STATE_ERROR_NO_STREAM = 3,
    PLAYER_STATE_ERROR_NO_URL = 4;

let playerInstance = null;

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class FullStreamPlayer extends React.Component {
    constructor(props) {
        super(props);

        const playerId = 'player-' + moment.now().toString();

        this.state = {
            uri: props.uri,
            avvpl: null,
            event: null,
            playerId: playerId, //player-ts,
            playerState: PLAYER_STATE_INIT,
            streamData: {},
            streamKey: null,
            error: false,
            errorMsg: '',
        };

        this.fetchMatchInfo();
    }

    componentDidMount() {
        document.title = 'Calendar - Full Stream Player';
    }

    componentWillUnmount() {
        // If used in a dialog or elsewhere it's important to clean up the instance (remove timeouts etc)
        this.removePlayerInstance();
    }

    componentDidUpdate() {
        if (this.state.playerState === PLAYER_STATE_LOADED) {
            this.playBack();
        }
    }

    render() {
        if (this.state.playerState === PLAYER_STATE_INIT) {
            return <BusySpinner size={64} />;
        }

        return (
            <div className="fullstream-player">
                {this.buildMatchQuickInfo()}
                <div className="content">
                    {this.buildStreamSelection()}
                    {this.state.error ? this.buildError() : ''}
                    <div id={this.state.playerId} className="player-container">
                        {this.state.playerState ===
                        PLAYER_STATE_CHOOSE_STREAM ? (
                            <CameraIcon
                                color="lightGrey"
                                height="300px"
                                width="300px"
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        );
    }

    buildMatchQuickInfo() {
        if (this.state.playerState !== PLAYER_STATE_ERROR_NO_MATCH) {
            return (
                <MatchQuickInfo
                    event={this.state.event}
                    includeGeneralInfo={false}
                    includeLinks={false}
                    optionalKeyValue={this.getStartEndTime()}
                />
            );
        }
        return '';
    }

    buildStreamSelection() {
        const parts = [];
        if (this.state.playerState !== PLAYER_STATE_ERROR_NO_STREAM) {
            const formats = {
                [AV_PRODUCT.ID.LCR]: [AV_PRODUCT.STREAM_TYPE.DASH_MANIFEST],
                [AV_PRODUCT.ID.LCO]: [
                    AV_PRODUCT.STREAM_TYPE.DASH_MANIFEST,
                    AV_PRODUCT.STREAM_TYPE.CMAF_DASH,
                    AV_PRODUCT.STREAM_TYPE.HLS_MANIFEST,
                    AV_PRODUCT.STREAM_TYPE.HLS,
                ],
            };

            const streamingInfo = this.state.event.streamingInfo;

            Object.keys(formats).forEach(id => {
                const subProductName = AV_PRODUCT.FULLNAME[id],
                    subProductKey = AV_PRODUCT.KEY[id];

                if (!streamingInfo.hasOwnProperty(subProductKey)) {
                    return;
                }

                const streamUri = streamingInfo[subProductKey].streamUri;
                if (streamUri !== null) {
                    parts.push(<h3 key={subProductName}>{subProductName}</h3>);

                    formats[id].forEach(streamFormat => {
                        const key = streamUri + streamFormat;
                        let buttonClass = '',
                            buttonText =
                                streamFormat ===
                                AV_PRODUCT.STREAM_TYPE.CMAF_DASH
                                    ? 'cmaf-dash'
                                    : streamFormat;

                        if (key === this.state.streamKey) {
                            buttonClass = 'active';
                            buttonText += ' (playing)';
                        }
                        parts.push(
                            <Button
                                key={key}
                                onClick={this.onSelectStream.bind(
                                    this,
                                    subProductKey,
                                    streamUri,
                                    streamFormat
                                )}
                                type={Button.types.LARGE_BLUE_SHADOW}
                                fixedWidth={false}
                                className={buttonClass}
                            >
                                {buttonText}
                            </Button>
                        );
                    });
                }
            });
            return <div className="playerStreamOptions">{parts}</div>;
        }
        return '';
    }

    fetchMatchInfo() {
        const [, uriType, uriId] = this.state.uri.split(':');
        let url = '';
        switch (uriType) {
            case 'match':
                url = '/feed/:match'.replace(':match', uriId);
                break;
            default:
                throw new Error(
                    'Cannot load information needed to display this.'
                );
        }
        const feedReq = Xhr.backendRequestLbcQuarkus(
            url,
            null,
            'GET',
            null,
            false
        );
        feedReq.then(
            response => {
                if (!response.matches.length) {
                    this.setState({
                        playerState: PLAYER_STATE_ERROR_NO_MATCH,
                        error: true,
                        errorMsg: 'Match not found',
                    });
                    return;
                }

                if (response.matches[0].streamingInfo.streamEventId !== null) {
                    this.setState({
                        event: response.matches[0],
                        playerState: PLAYER_STATE_CHOOSE_STREAM,
                    });
                } else {
                    this.setState({
                        playerState: PLAYER_STATE_ERROR_NO_STREAM,
                        error: true,
                        errorMsg: 'No stream found for this match.',
                    });
                }
            },
            error => {
                this.setState({
                    error,
                });
            }
        );
    }

    async onSelectStream(productKey, streamUri, streamType) {
        const storageLocal = new StorageLocal();
        this.removePlayerInstance();
        const streamId = uriToId(streamUri);

        if (!this.state.event.products[productKey].isBooked) {
            this.setState({
                streamKey: streamId + streamType,
                playerState: PLAYER_STATE_CHOOSE_STREAM,
                error: true,
                errorMsg: 'There is no booking on the selected product/format.',
            });
            return;
        }

        if (this.state.avvpl === null) {
            this.loadPlayerSources();
        }
        const isQuarkusEnabled = storageLocal.getItem(
            'isStreamingQuarkusEnabled'
        );

        const streamingReq =
            isMicrofrontend && isQuarkusEnabled
                ? Xhr.backendRequestLbcQuarkus(
                      `/streaming/fullstream/${streamId}/${streamType}`,
                      null,
                      'GET',
                      null,
                      false
                  )
                : Xhr.backendRequest(
                      `/streaming/fullstream/${streamId}/${streamType}`,
                      null,
                      'GET',
                      null,
                      false
                  );

        streamingReq.then(
            response => {
                this.setState({
                    error: false,
                    errorMsg: '',
                    streamData: response,
                    streamKey: streamId + streamType,
                    playerState: PLAYER_STATE_LOADED,
                });
            },
            error => {
                this.setState({
                    error: true,
                    errorMsg: error.message,
                });
            }
        );
    }

    playBack() {
        if (
            this.state.streamData.streamUrl === null ||
            this.state.streamData.streamUrl.length === 0
        ) {
            this.setState({
                playerState: PLAYER_STATE_ERROR_NO_URL,
                error: true,
                errorMsg: 'No stream found. Match not started yet?',
            });
        } else {
            if (this.state.avvpl !== null)
                playerInstance = this.state.avvpl.setupPlayer(
                    this.getPlayerConfig()
                );

            playerInstance?.load(
                this.state.streamData.streamUrl,
                this.state.streamData.contentType
            );
        }
    }

    buildError() {
        if (!this.state.error) {
            return '';
        }
        return (
            <div className="content">
                <Alert type={Alert.types.ERROR} float={false}>
                    {this.state.errorMsg}
                </Alert>
            </div>
        );
    }

    getStartEndTime() {
        const start = new Date(
            this.state.event.streamingInfo.streamEventStart.date
        );
        const end =
            this.state.event.streamingInfo.streamEventEnd !== null
                ? new Date(this.state.event.streamingInfo.streamEventEnd)
                : null;

        function pad(s) {
            return ('00' + s).slice(-2);
        }
        const startTime = `${pad(start.getHours())}:${pad(start.getMinutes())}`;
        const endTime =
            end !== null
                ? `${pad(end.getHours())}:${pad(end.getMinutes())}`
                : 'Unknown';
        const when = `${startTime} - ${endTime}`;

        return {
            key: 'Streaming',
            value: when,
        };
    }

    loadPlayerSources() {
        if (!document.getElementById('playerjs')) {
            const playerJs = document.createElement('script');
            const playerCss = document.createElement('link');
            playerJs.type = 'text/javascript';
            playerJs.id = 'playerjs';
            playerJs.src =
                'https://avplayer-cdn.sportradar.com/dist/latest/avvpl-player.js';

            playerCss.href =
                'https://avplayer-cdn.sportradar.com/dist/latest/styles.css';
            playerCss.rel = 'stylesheet';

            document.head.appendChild(playerJs);
            document.head.appendChild(playerCss);
            playerJs.onload = () => {
                this.setState({ avvpl: window.avvpl });
            };
        }
    }

    removePlayerInstance() {
        if (playerInstance !== null) {
            playerInstance.remove();
        }
    }

    getPlayerConfig() {
        // https://confluence.sportradar.ag/pages/viewpage.action?spaceKey=AV&title=Integration+of+the+Marvin+player
        const playerConfig = {
            id: this.state.playerId,
            handlers: ['html5', 'hls', 'dash', 'flash'],
            mute: false,
            volume: 50, // percent
            loglevel: 0, // max 3
            swf: '/resources/srplayer.swf',
            enableCmaf: false,
        };

        if (
            this.state.streamData.streamType ===
            AV_PRODUCT.STREAM_TYPE.CMAF_DASH
        ) {
            playerConfig.enableCmaf = true;
        }

        return playerConfig;
    }
}

FullStreamPlayer.propTypes = {
    uri: PropTypes.string,
};

export default FullStreamPlayer;
