import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '../../../Alert/Alert';
import TextInput, { labelPos } from '../../../formInput/TextInput/TextInput';
import DialogFooter from '../../Footer/Footer';
import Button from '../../../formInput/Button/Button';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import {
    bookMatchesLimit,
    matchesInBasketCount,
} from '../../../../selectors/shoppingBasket';
import Xhr from '../../../../utils/Xhr';
import { addEvents } from '../../../../stores/shoppingBasket/actions';
import './BulkSelectDialog.scss';

class BulkSelectDialog extends React.PureComponent {
    _mounted = false;

    state = {
        inputText: '',
        isLoading: false,
        xhrError: '',
    };

    filterDuplicates(matchIdsOrURIs) {
        return [...new Set(matchIdsOrURIs)];
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onAddClick = matchIdsOrURIs => {
        this.setState({
            isLoading: true,
            xhrError: '',
        });

        matchIdsOrURIs = this.filterDuplicates(matchIdsOrURIs);

        const isMicrofrontend =
            window.location.href.includes('portal') ||
            window.location.href.includes('9090');

        const feedReq =
            this.props.isQuarkusEnabled && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      `/feed/${matchIdsOrURIs.join(',')}`
                  )
                : Xhr.backendRequest(`/feed/${matchIdsOrURIs.join(',')}`);
        feedReq
            .then(response => {
                if (!this._mounted) {
                    return;
                }

                if (response.matches.length === matchIdsOrURIs.length) {
                    this.props.addEvents(response.matches);
                    this.setState({ isLoading: false }, this.props.onClose);
                } else {
                    const responseURIs = response.matches.map(m => m.uri);

                    const invalidmatchIdsOrURIs = matchIdsOrURIs.filter(
                        matchIdOrURI =>
                            !responseURIs.includes(
                                isNaN(matchIdOrURI)
                                    ? matchIdOrURI
                                    : `sr:match:${matchIdOrURI}`
                            )
                    );

                    const uris = invalidmatchIdsOrURIs.join(',');
                    this.setState({
                        isLoading: false,
                        xhrError: `Invalid values provided: ${uris}. No events have been added to your selection.`,
                    });
                }
            })
            .catch(error => {
                if (this._mounted) {
                    this.setState({
                        isLoading: false,
                        xhrError: error.message,
                    });
                }
            });
    };

    eventsString(numberofEvents) {
        return `${numberofEvents} event${numberofEvents > 1 ? 's' : ''}`;
    }

    render() {
        const realMaxEventsSelection =
            this.props.maxEventsSelected - this.props.eventsInBasketCount;
        let errorMsg = this.state.xhrError;
        let matchIdsOrURIs = [];

        if (
            /^(([0-9]+|sr:[a-z]+:[0-9]+)(\s|,)*\s*)+$/.test(
                this.state.inputText
            )
        ) {
            matchIdsOrURIs = this.state.inputText
                .replace(/[\s,]+/g, ',')
                .replace(/(,|\s)*$/g, '')
                .split(',');
            console.log(matchIdsOrURIs);
            if (matchIdsOrURIs.length > realMaxEventsSelection) {
                const eventCount = this.eventsString(matchIdsOrURIs.length);
                const maxCount = this.props.maxEventsSelected;
                errorMsg = `The provided list contains ${eventCount}, but you can only select ${maxCount} events at once`;
                if (this.props.eventsInBasketCount > 0) {
                    const selectedEvents = this.eventsString(
                        this.props.eventsInBasketCount
                    );
                    errorMsg += ` and you have already selected ${selectedEvents}`;
                }
                errorMsg += '.';
            }
        }

        return (
            <div className="dialog-content-wrapper bulk-select-dialog">
                <div className="sub-header">
                    <Alert type={Alert.types.EXPLANATORY} icon="">
                        <p>
                            Please provide a list of match IDs or URIs separated
                            by comma. These events will be added to your
                            selected events.
                        </p>
                    </Alert>
                </div>
                <div className="content">
                    <TextInput
                        labelText="Match IDs or URIs"
                        labelPos={labelPos.ABOVE}
                        onChange={inputText => this.setState({ inputText })}
                        value={this.state.inputText}
                        autoFocus={true}
                    />
                    <div className="error">{errorMsg}</div>
                </div>
                <DialogFooter>
                    <Button
                        onClick={this.props.onClose}
                        type={Button.types.LARGE_GREY_SHADOW}
                        icon={''}
                        fixedWidth={false}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.onAddClick(matchIdsOrURIs)}
                        type={Button.types.LARGE_BLUE_SHADOW}
                        icon={this.state.isLoading ? '' : ''}
                        fixedWidth={200}
                        disabled={
                            matchIdsOrURIs.length === 0 ||
                            matchIdsOrURIs.length > realMaxEventsSelection ||
                            this.state.isLoading
                        }
                    >
                        {this.state.isLoading ? (
                            <div className="spinner-container">
                                <BusySpinner size={16} margin={'auto'} />
                            </div>
                        ) : (
                            'Add to selection'
                        )}
                    </Button>
                </DialogFooter>
            </div>
        );
    }
}

BulkSelectDialog.propTypes = {
    maxEventsSelected: PropTypes.number.isRequired,
    eventsInBasketCount: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    addEvents: PropTypes.func.isRequired,
    isQuarkusEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    maxEventsSelected: bookMatchesLimit(state),
    eventsInBasketCount: matchesInBasketCount(state),
    isQuarkusEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_feed_quarkus,
});

export default connect(mapStateToProps, { addEvents })(BulkSelectDialog);
