import React from 'react';
import PropTypes from 'prop-types';
import moment from '../../utils/CalendarMoment';
import './EventTimeLong.scss';

function EventTimeLong(props) {
    const eventStartMoment = moment.fromTimestamp(props.startDateTimestamp);
    const eventEndMoment = moment.fromTimestamp(props.endDateTimestamp);

    let startTitle = '',
        startsBeforeIndicator = '',
        endTime = '',
        endTitle = '',
        endsAfterIndicator = '',
        to = '';

    if (props.startDateTimestamp < props.selectedDayTimestamp) {
        startsBeforeIndicator = <sup>*</sup>;
        startTitle = `* This competition started on ${eventStartMoment.toFormat(
            'DDD T'
        )}`;
    }

    if (props.endDateTimestamp !== null) {
        to = ' − ';
        if (props.endDateTimestamp > props.selectedDayTimestamp + 86400) {
            endsAfterIndicator = <sup>*</sup>;
            endTitle = `* This competition ends on ${eventEndMoment.toFormat(
                'DDD T'
            )}`;
        }
        endTime = (
            <time title={endTitle}>{eventEndMoment.toFormat('HH:mm')}</time>
        );
    }
    return (
        <span className="event-time-long">
            <time title={startTitle}>{eventStartMoment.toFormat('HH:mm')}</time>
            {startsBeforeIndicator}
            {to}
            {endTime}
            {endsAfterIndicator}
        </span>
    );
}

EventTimeLong.propTypes = {
    startDateTimestamp: PropTypes.number.isRequired,
    endDateTimestamp: PropTypes.number,
    selectedDayTimestamp: PropTypes.number.isRequired,
};

export default EventTimeLong;
