import PropTypes from 'prop-types';
import React from 'react';
import FontIcon from '../FontIcon/FontIcon';
import {
    simpleStatus,
    simplifyStatus,
    statusDescription,
} from '../../../constants/eventStatus';
import '../../icons/LiveStatusIcon/LiveStatus.scss';

const LiveStatusIcon = props => {
    const liveStatus = simplifyStatus(props.liveStatus);

    let description = statusDescription[liveStatus];

    /* if (props.access.support) {
        const liveStatusDetails = props.liveStatusText
            ? `/${props.liveStatusText}`
            : '';
        description += ` (${props.liveStatus}${liveStatusDetails})`;
    } */
    if (props.liveStatusText) {
        description += ` (${props.liveStatusText})`;
    }

    let icon, color, className;
    switch (liveStatus) {
        case 1: // Match not started
            if (props.isStreaming) {
                className = props.isStreaming ? 'status-streaming' : '';
            }
            break;
        case 2: // Match started
            className = props.isStreaming ? 'status-live' : 'status-no-stream';
            break;
        case 3: // Paused
            icon = '';
            color = 'color-gray-700'; //TODO, is there a set color for this (liveStatuses.STOPPED)?
            break;
        case 4: // Finished
            icon = '';
            color = 'color-gray-700';
            break;
        default:
            break;
    }

    return icon ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FontIcon
                icon={icon}
                color={color}
                title={description}
                className={className}
            />
        </div>
    ) : className ? (
        <div className={`livestatus-icon ${className}`} title={description} />
    ) : (
        <></>
    );
};
LiveStatusIcon.liveStatuses = simpleStatus;

LiveStatusIcon.propTypes = {
    liveStatus: PropTypes.number,
    liveStatusText: PropTypes.string,
    access: PropTypes.object.isRequired,
    isStreaming: PropTypes.bool,
};

export default LiveStatusIcon;
