import actionConstants from './constants';

export const setDefaultUri = uri => dispatch => {
    dispatch({
        type: actionConstants.SET_DEFAULT_URI,
        payload: {
            uri,
        },
    });
};
