import _ from 'underscore';
import Alert from '../../components/Alert/Alert';
import { AV_PRODUCT } from '../../constants/audioVisual';
import {
    BOOKING_STATUS,
    adapterIds,
    responseStatus,
} from '../../constants/shoppingBasket';
import { eventCanBeAddedToBasket } from '../../utils/EventUtils';
import StorageLocal from '../../utils/StorageLocal';
import Xhr from '../../utils/Xhr';
import {
    hasProduct,
    isConsideredBooked,
    tooLateToBook,
} from '../../utils/basketUtils';
import { refetchEventsForDay } from '../calendar/actions';
import { addDialog, removeDialog } from '../dialog/actions';
import { dialogTypes } from '../dialog/constants';
import {
    close as closeNotification,
    create as createNotification,
} from '../notification/actions';
import actionConstants from './constants';

const storageLocal = new StorageLocal();
const localStorageKey = 'selected_matches';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const setMatchesToLocalStorage = (events, state) => {
    const eventUrisString = events.map(event => event.uri).join(',');
    storageLocal.setItem(localStorageKey, eventUrisString);

    if (events.length > 100) {
        if (
            isMicrofrontend &&
            state.calendar.auth.userInfo.bookmakerInfo.features
                .enable_lbc_quarkus_frontend_log
        ) {
            Xhr.backendRequestLbcQuarkus(
                'frontend-log',
                null,
                'POST',
                { message: { 'Number of events: ': events.length } },
                false
            );
        } else {
            Xhr.backendRequest(
                'frontend-log',
                null,
                'POST',
                { message: { 'Number of events: ': events.length } },
                false
            );
        }
    }
};

const removeMatchFromLocalStorage = eventUri => {
    const storedString = storageLocal.getItem(localStorageKey);

    if (storedString !== null) {
        const nextValueInLocalStorage = storedString
            .split(',')
            .filter(function (id) {
                if (eventUri === id) {
                    return false;
                }
                if (eventUri.indexOf('sr:') === 0 && !isNaN(id)) {
                    return eventUri.split(':')[2] !== id;
                }
                return true;
            })
            .join(',');

        storageLocal.setItem(localStorageKey, nextValueInLocalStorage);
    }
};

const removeAllMatchesFromLocalStorage = except => {
    const currentValueInLocalStorage = storageLocal.getItem(localStorageKey);
    if (currentValueInLocalStorage !== null) {
        const nextValueInLocalStorage = currentValueInLocalStorage
            .split(',')
            .filter(uri => except.indexOf(uri) !== -1)
            .join(',');
        storageLocal.setItem(localStorageKey, nextValueInLocalStorage);
    }
};

const isAudioVisualAdapter = adapterId => {
    return [
        adapterIds.LCO,
        adapterIds.LCR,
        adapterIds.LCT,
        adapterIds.LCP,
    ].includes(adapterId);
};

const buildBasketError = err => {
    const MISSING_PRIVILEGE = 21;

    if (!err.getDebug) {
        return {
            code: 701,
            message: 'Failed to fetch prices',
            detailedMessage: '',
        };
    }

    if (err.getDebug() && err.getDebug().code === MISSING_PRIVILEGE) {
        return {
            code: MISSING_PRIVILEGE,
            message: err.getMessage(),
            detailedMessage: 'Missing privileges for user',
        };
    } else {
        return {
            code: 701,
            message: err.getMessage(),
            detailedMessage: JSON.stringify(err.getDebug()),
        };
    }
};

export const setEvents =
    (events, useLocalStorage = true) =>
    (dispatch, getState) => {
        const selectedEvents = events.filter(eventCanBeAddedToBasket);
        const state = getState();

        if (useLocalStorage) {
            setMatchesToLocalStorage(selectedEvents, state);
        }

        dispatch({
            type: actionConstants.SET_EVENTS,
            payload: {
                events: selectedEvents,
                selectedDayTimestamp: state.calendar.selectedDayTimestamp,
            },
        });
    };

export const addEvents =
    (events, addToLocalStorage = true) =>
    (dispatch, getState) => {
        const existentEventUris = [];

        const allSelectedEvents = Object.values(getState().shoppingBasket.items)
            .map(item => item.event)
            .concat(events);

        dispatch(
            setEvents(
                allSelectedEvents.filter(event => {
                    if (existentEventUris.includes(event.uri)) {
                        return false;
                    }

                    existentEventUris.push(event.uri);
                    return true;
                }),
                addToLocalStorage
            )
        );
    };

export const removeEvent = eventUri => {
    removeMatchFromLocalStorage(eventUri);

    return {
        type: actionConstants.REMOVE_EVENT,
        payload: {
            eventUri: eventUri,
        },
    };
};

export const removeAllEvents = (except = []) => {
    removeAllMatchesFromLocalStorage(except);

    return {
        type: actionConstants.REMOVE_ALL,
        payload: {
            except,
        },
    };
};

export const clearFailedBookings = () => ({
    type: actionConstants.CLEAR_FAILED_BOOKINGS,
});

export const setBasketDefaults = (
    canBookLiveodds,
    canBookLiveChannel,
    canBookBetpal
) => {
    return {
        type: actionConstants.SET_DEFAULTS,
        payload: {
            canBookLiveodds: canBookLiveodds,
            canBookLiveChannel: canBookLiveChannel,
            canBookBetpal: canBookBetpal,
        },
    };
};

export const setIsExpanded = isExpanded => {
    return {
        type: actionConstants.SET_IS_EXPANDED,
        payload: {
            isExpanded,
        },
    };
};

export const setSelectedAdapterId = adapterId => ({
    type: actionConstants.SWITCH_TO_ADAPTER,
    payload: {
        adapterId,
    },
});

export const setContentVariant = (variant, eventUri) => ({
    type: actionConstants.SET_CONTENT_VARIANT,
    payload: {
        eventUri,
        variant,
    },
});

export const setQuality = (selectedQuality, variantId, eventUri) => ({
    type: actionConstants.SET_QUALITY,
    payload: {
        eventUri,
        selectedQuality,
        variantId,
    },
});

export const loadPrices = (adapterId, events) => (dispatch, getState) => {
    const eventUrisToBook = events
        .filter(event => !isConsideredBooked(event.products, adapterId))
        .filter(event => hasProduct(event.products, adapterId))
        .filter(event => !tooLateToBook(event))
        .map(event => event.uri);

    if (eventUrisToBook.length === 0) {
        return;
    }

    dispatch({
        type: actionConstants.LOAD_PRICES,
        payload: {
            adapterId,
        },
    });

    let uri = '';
    let isQuarkusEnabledForPrice = false;
    switch (adapterId) {
        case adapterIds.LIVEODDS:
            isQuarkusEnabledForPrice =
                getState().calendar.auth.userInfo.bookmakerInfo.features
                    .enable_checkout_lo_quarkus;
            uri = '/price/liveodds';
            break;
        case adapterIds.LCO:
        case adapterIds.LCR:
        case adapterIds.LCT:
        case adapterIds.LCP:
            isQuarkusEnabledForPrice =
                getState().calendar.auth.userInfo.bookmakerInfo.features
                    .enable_checkout_av_quarkus;
            uri = '/price/livechannel';
            break;
        case adapterIds.BETPAL:
            dispatch({
                type: actionConstants.LOAD_BETPAL_PRICES,
                payload: {
                    uris: eventUrisToBook,
                },
            });
            return;
        default:
            dispatch({
                type: actionConstants.LOAD_PRICES_FAIL,
                payload: {
                    adapterId,
                    error: {
                        message: `Unsupported adapter : ${adapterId}`,
                        detailedMessage: '',
                    },
                },
            });
            console.error(`Unsupported adapter : ${adapterId}`);
            return;
    }
    const fetchPriceReq =
        isQuarkusEnabledForPrice && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  uri,
                  null,
                  'POST',
                  { uri: eventUrisToBook },
                  false
              )
            : Xhr.backendRequest(
                  uri,
                  null,
                  'POST',
                  { uri: eventUrisToBook },
                  false
              );

    fetchPriceReq.then(
        response => {
            if (response.status !== responseStatus.SUCCESS) {
                dispatch({
                    type: actionConstants.LOAD_PRICES_FAIL,
                    payload: {
                        adapterId,
                        error: {
                            code: 702,
                            message: 'Response status is not success',
                            detailedMessage: '',
                        },
                    },
                });
            } else {
                if (isAudioVisualAdapter(adapterId)) {
                    const buildResponseObject = (adaptId, priceProperty) => {
                        const responseObject = {};
                        for (const eventUri in response.prices) {
                            if (!response.prices.hasOwnProperty(eventUri)) {
                                continue;
                            }

                            responseObject[eventUri] =
                                response.prices[eventUri][priceProperty];
                        }

                        return {
                            type: actionConstants.LOAD_PRICES_SUCCESS,
                            payload: {
                                response: { prices: responseObject },
                                adapterId: adaptId,
                            },
                        };
                    };

                    dispatch(buildResponseObject(adapterIds.LCO, 'lco'));
                    dispatch(buildResponseObject(adapterIds.LCR, 'lcr'));
                    dispatch(buildResponseObject(adapterIds.LCT, 'lct'));
                    dispatch(buildResponseObject(adapterIds.LCP, 'lcp'));
                } else {
                    dispatch({
                        type: actionConstants.LOAD_PRICES_SUCCESS,
                        payload: {
                            response,
                            adapterId,
                        },
                    });
                }
            }
        },
        err => {
            console.error(err);
            dispatch({
                type: actionConstants.LOAD_PRICES_FAIL,
                payload: {
                    adapterId,
                    error: buildBasketError(err),
                },
            });
        }
    );
};

export const bookEvents =
    (
        currentView,
        adapterId,
        prices,
        eventsSorted,
        contentVariant,
        avQuality,
        eventTypes
    ) =>
    (dispatch, getState) => {
        dispatch({
            type: actionConstants.BOOK_MATCHES,
            payload: {
                adapterId,
            },
        });
        let uri = '',
            buildEventParamFnc = eventUri => null;
        let isQuarkusEnabledForBook = false;

        const matchedOption = (eventUri, varOrQuality) =>
            _.find(varOrQuality, function (o) {
                return Object.keys(o)[0] === eventUri;
            });

        switch (adapterId) {
            case adapterIds.LIVEODDS:
                isQuarkusEnabledForBook =
                    getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_checkout_lo_quarkus;
                uri = '/book/liveodds';
                buildEventParamFnc = eventUri => {
                    return {
                        uri: eventUri,
                        priceInEuros: prices[eventUri].priceInEuros,
                    };
                };
                break;
            case adapterIds.LCO:
            case adapterIds.LCR:
            case adapterIds.LCT:
            case adapterIds.LCP:
                isQuarkusEnabledForBook =
                    getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_checkout_av_quarkus;
                uri = '/book/livechannel';
                buildEventParamFnc = eventUri => {
                    const params = {
                        uri: eventUri,
                        productId: prices[eventUri].productId,
                        variantId: matchedOption(eventUri, contentVariant)
                            ? matchedOption(eventUri, contentVariant)[eventUri]
                                  .value
                            : 1,
                        isHighQuality: matchedOption(eventUri, avQuality)
                            ? !!matchedOption(eventUri, avQuality)[eventUri]
                                  .value
                            : true,
                    };

                    if (adapterId === adapterIds.LCP) {
                        params.preBookRequest = eventTypes[eventUri];
                    }

                    return params;
                };
                break;
            default:
                console.error('Unsupported adapter : ' + adapterId);
                return;
        }

        const params = [];
        eventsSorted.forEach(event => {
            // The book request needs matches to be in same order as price-request, so that correct packages
            // are used and prices are calculated as shown in the basket
            const eventUri = event.uri;
            if (prices.hasOwnProperty(eventUri)) {
                const price = prices[eventUri];
                if (price.state === BOOKING_STATUS.BOOKABLE) {
                    params.push(buildEventParamFnc(eventUri));
                }
            }
        });

        const fetchBookReq =
            isQuarkusEnabledForBook && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      uri,
                      null,
                      'POST',
                      { events: params },
                      false
                  )
                : Xhr.backendRequest(
                      uri,
                      null,
                      'POST',
                      { events: params },
                      false
                  );

        fetchBookReq.then(
            response => {
                if (
                    response.status !== responseStatus.SUCCESS &&
                    response.status !== responseStatus.PRICE_CHANGE
                ) {
                    dispatch({
                        type: actionConstants.BOOK_MATCHES_FAIL,
                        payload: {
                            adapterId,
                            error: {
                                code: 702,
                                message:
                                    'Response status is not success or price change',
                                detailedMessage: '',
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: actionConstants.BOOK_MATCHES_SUCCESS,
                        payload: {
                            response,
                            adapterId,
                        },
                    });
                    dispatch(refetchEventsForDay(currentView, true));
                }
            },
            err => {
                console.error(err);
                dispatch({
                    type: actionConstants.BOOK_MATCHES_FAIL,
                    payload: {
                        adapterId,
                        error: buildBasketError(err),
                    },
                });
            }
        );
    };

export const dismissErrorsDialog = () => ({
    type: actionConstants.DISMISS_ERRORS_DIALOG,
});

export const openBetpalBooking = uris => dispatch => {
    // close regular basket
    dispatch(setIsExpanded(false));
    dispatch(
        addDialog(dialogTypes.BETPAL_BOOKING, {
            matchIds: uris
                .filter(uri => uri.includes('match'))
                .map(uri => uri.split(':')[2]),
        })
    );
};

export const closeBetpalBooking = () => (dispatch, getState) => {
    const state = getState();

    dispatch(removeDialog(dialogTypes.BETPAL_BOOKING));
    dispatch(setIsExpanded(true));

    if (state.shoppingBasket.hasBookedEventsInBetpal) {
        dispatch(refetchEventsForDay(state.viewport.currentView, true));
        dispatch({
            type: actionConstants.CLOSE_BETPAL_BOOKING,
        });
    }
};

export const betpalBookingComplete = parameters => ({
    type: actionConstants.BETPAL_BOOKING_COMPLETE,
    payload: {
        parameters,
    },
});

export const addMatchesFromLocalStorage = () => (dispatch, getState) => {
    const storedString = storageLocal.getItem(localStorageKey);
    if (storedString !== null && storedString.length > 0) {
        let eventIds = storedString.split(',');
        let valid = true;
        eventIds = eventIds.map(function validateAndUrify(id) {
            if (id.match(/^\d+$/)) {
                // Return match-URI for backwards compatibility
                return 'sr:match:' + id;
            } else if (id.match(/^([a-z]+:)+\d+$/)) {
                // URI - ok
                return id;
            } else {
                valid = false;
                return id;
            }
        });
        if (valid) {
            const feedReq =
                getState().calendar.auth.userInfo.bookmakerInfo.features
                    .enable_feed_quarkus && isMicrofrontend
                    ? Xhr.backendRequestLbcQuarkus(
                          '/feed/' + eventIds.join(',')
                      )
                    : Xhr.backendRequest('/feed/' + eventIds.join(','));
            feedReq.then(response => {
                dispatch(addEvents(response.matches, false));
            });
        } else {
            console.error(
                `Invalid value in localStorage for ${localStorageKey}: ${eventIds.join(
                    ','
                )}`
            );
        }
    }
};

export const unbookEvent =
    (currentView, eventUri, subProductId) => (dispatch, getState) => {
        const state = getState();
        const is4sightEnabled =
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .enable_av_4Sight;
        const productName = subProductId
            ? AV_PRODUCT.SHORTNAME[subProductId]
            : 'LO';
        dispatch(
            createNotification(
                Alert.types.INFO,
                `unbooking match ${eventUri} for product ${productName}`,
                ''
            )
        );
        let apiLink;
        let isQuarkusEnabledForUnbook = false;
        switch (subProductId) {
            case AV_PRODUCT.ID.LCO:
            case AV_PRODUCT.ID.LCR:
            case AV_PRODUCT.ID.LCT:
            case AV_PRODUCT.ID.LCP:
                isQuarkusEnabledForUnbook =
                    getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_checkout_av_quarkus;
                apiLink = '/unbook/livechannel';
                break;
            default:
                // lo
                isQuarkusEnabledForUnbook =
                    getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_checkout_lo_quarkus;
                apiLink = '/unbook/liveodds';
                break;
        }
        const reqBody = {
            eventUri: eventUri,
            subProductId: subProductId,
            ...(is4sightEnabled &&
                [
                    AV_PRODUCT.ID.LCO,
                    AV_PRODUCT.ID.LCR,
                    AV_PRODUCT.ID.LCT,
                ].includes(subProductId)),
        };
        const fetchUnbookReq =
            isQuarkusEnabledForUnbook && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      apiLink,
                      null,
                      'POST',
                      reqBody,
                      false
                  )
                : Xhr.backendRequest(apiLink, null, 'POST', reqBody, false);

        fetchUnbookReq.then(
            response => {
                dispatch(closeNotification());
                if (response.code === 1) {
                    dispatch(
                        createNotification(
                            Alert.types.SUCCESS,
                            `event ${eventUri} has been unbooked for product ${productName}`,
                            ''
                        )
                    );
                    dispatch({
                        type: actionConstants.REMOVE_EVENT,
                        payload: {
                            eventUri: eventUri,
                        },
                    });
                    setTimeout(() => {
                        dispatch(refetchEventsForDay(currentView, true));
                    }, 500);
                } else {
                    dispatch(
                        createNotification(Alert.types.ERROR, response.message)
                    );
                }
            },
            err => {
                dispatch(closeNotification());
                dispatch(createNotification(Alert.types.ERROR, err.message));
            }
        );
    };
