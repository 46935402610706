import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import Xhr from '../../utils/Xhr';
import { getDaysString } from '../../utils/autobookingTableUtils';
import BusySpinner from '../BusySpinner/BusySpinner';
import './tableChanges.scss';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');
class TableChanges extends React.Component {
    state = {
        changes: [],
        extraHeaders: [],
        loading: false,
        stringNames: {},
    };

    componentDidMount() {
        this.props.tableName && this.loadTableData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.userId !== this.props.userId ||
            prevProps.bookmakerId !== this.props.bookmakerId ||
            prevProps.tableName !== this.props.tableName ||
            prevProps.primaryKey !== this.props.primaryKey ||
            prevProps.fromDate !== this.props.fromDate ||
            prevProps.toDate !== this.props.toDate
        ) {
            console.log('reloading table data');
            this.loadTableData();
        }
    }

    loadTableData = () => {
        this.setState({
            loading: true,
        });

        const requestBody = {
            tableName: this.props.tableName,
        };
        if (this.props.userId) {
            requestBody.userId = this.props.userId;
        }
        if (this.props.bookmakerId) {
            requestBody.bookmakerId = this.props.bookmakerId;
        }
        if (this.props.primaryKey) {
            requestBody.primaryKey = this.props.primaryKey;
        }
        if (this.props.fromDate) {
            requestBody.fromDate = this.props.fromDate;
        }
        if (this.props.toDate) {
            requestBody.toDate = this.props.toDate;
        }

        const tableChangeLogReq =
            this.props.isQuarkusEnabledForTableLog && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      'table-change-log',
                      null,
                      'GET',
                      requestBody
                  )
                : Xhr.backendRequest(
                      'table-change-log',
                      null,
                      'GET',
                      requestBody
                  );

        tableChangeLogReq.then(response => {
            if (!response.changes.length) {
                return this.setState({
                    ...this.state,
                    loading: false,
                    changes: response.changes,
                });
            }
            const newState = {
                ...this.state,
                loading: false,
                changes: response.changes,
                extraHeaders: this.getExtraHeaders(response.changes),
            };
            for (const [headerName, items] of Object.entries(response)) {
                if (
                    headerName === 'changes' ||
                    headerName === 'response' ||
                    headerName === 'debug'
                ) {
                    continue;
                }

                newState.stringNames[headerName] =
                    newState.stringNames[headerName] || {};

                for (const item of items) {
                    newState.stringNames[headerName] = {
                        ...this.state.stringNames[headerName],
                        [item.id]: item.name,
                    };
                }
            }
            this.setState(newState);
        });
    };

    getExtraHeaders(changes) {
        let fields = {};
        // if there is a delete or insert action, that will have all the fields:
        const deletedRow = changes.find(row => row.action === 'delete');
        const insertedRow = changes.find(row => row.action === 'insert');

        if (deletedRow) {
            fields = deletedRow.diff.old;
        } else if (insertedRow) {
            fields = insertedRow.diff.new;
        } else {
            for (const change of changes) {
                fields = { ...fields, ...change.diff.old };
            }
        }

        const availableColumns = Object.keys(fields);

        // Probably a very overcomplex way to find all columns that has at least one non-empty value
        let nonEmptyColumns = {};
        changes.forEach(row => {
            ['new', 'old'].forEach(key => {
                if (row.diff[key]) {
                    nonEmptyColumns = {
                        ...nonEmptyColumns,
                        ...Object.entries(row.diff[key]).reduce(
                            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                            {}
                        ),
                    };
                }
            });
        });
        nonEmptyColumns = Object.keys(nonEmptyColumns);

        if (this.props.extraColumns) {
            return _.intersection(
                availableColumns, // All columns present in diffs
                this.props.extraColumns, // All columns we CAN show
                nonEmptyColumns // All columns that has a non-empty value
            );
        }

        return _.intersection(availableColumns, nonEmptyColumns);
    }

    getMetaHeaders() {
        switch (this.props.metaColumns) {
            case 'no-package':
                return ['time', 'user', 'action'];
            default:
                return ['time', 'user', 'pk', 'action'];
        }
    }

    header() {
        return (
            <tr className="header-row" key="header">
                {this.getMetaHeaders().map(header => (
                    <th
                        className={`${header} ${this.props.metaColumns}`}
                        key={header}
                    >
                        <div className="widthfix">{header}</div>
                    </th>
                ))}
                {this.state.extraHeaders.map(header => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
        );
    }

    showRow(row) {
        const span = row.diff.old && row.diff.new && 2;
        const metaCellMap = {
            time: 'created',
            user: 'user_id',
            pk: 'pk',
            action: 'action',
        };
        const metacells = this.getMetaHeaders().map(header => (
            <td
                rowSpan={span}
                className={`${header} ${this.props.metaColumns}`}
                key={header}
            >
                {header === 'user'
                    ? this.getCellContent('userid', [row[metaCellMap[header]]])
                    : [row[metaCellMap[header]]]}
            </td>
        ));
        return (
            <>
                {row.diff.old && (
                    <tr key={`${row.id}old`}>
                        {metacells}
                        {this.state.extraHeaders.map(header => (
                            <td key={header}>
                                {this.getCellContent(
                                    header,
                                    row.diff.old[header]
                                )}
                            </td>
                        ))}
                    </tr>
                )}
                {row.diff.new && (
                    <tr key={`${row.id}new`}>
                        {!row.diff.old && metacells}
                        {this.state.extraHeaders.map(header => (
                            <td key={header}>
                                {this.getCellContent(
                                    header,
                                    row.diff.new[header]
                                )}
                            </td>
                        ))}
                    </tr>
                )}
            </>
        );
    }

    getStringName(headerName, cellValue) {
        return this.state.stringNames[headerName][cellValue] || cellValue;
    }

    getCellContent(headerName, cellValue) {
        if (Object.keys(this.state.stringNames).includes(headerName)) {
            return this.getStringName(headerName, cellValue);
        }

        if (!cellValue) {
            return '';
        }

        switch (headerName) {
            case 'sportid':
            case 'sport_uris':
                cellValue = cellValue.replace('sr:sport:', '');
                if (this.props.sports[cellValue]) {
                    return this.props.sports[cellValue].name;
                }
                break;
            case 'bookmakerdaysofweek':
            case 'days_of_week':
                return getDaysString(cellValue);
            default:
                return cellValue;
        }
    }

    render() {
        if (this.state.loading) {
            return <BusySpinner />;
        }
        if (!this.props.tableName) {
            return <div>Choose filters to start loading changes.</div>;
        }
        if (!this.state.changes.length) {
            return <div>No changes found.</div>;
        }

        return (
            <div className="db-changes-table">
                <table>
                    <thead>{this.header()}</thead>
                    <tbody>
                        {this.state.changes.map(row => this.showRow(row))}
                    </tbody>
                </table>
            </div>
        );
    }
}

TableChanges.propTypes = {
    tableName: PropTypes.string,
    userId: PropTypes.number,
    primaryKey: PropTypes.number,
    bookmakerId: PropTypes.number,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    metaColumns: PropTypes.array,
    extraColumns: PropTypes.array,
    stringNames: PropTypes.object,
    sports: PropTypes.object,
    isQuarkusEnabledForTableLog: PropTypes.bool,
};

const mapStateToProps = state => ({
    sports: state.calendar.sports,
    isQuarkusEnabledForTableLog:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_table_change_log,
});

export default connect(mapStateToProps)(TableChanges);
