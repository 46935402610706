import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import { fetchLogs } from '../../../../stores/logger/actions';
import './loggerDialog.scss';

class LoggerDialog extends React.Component {
    printAllQueries(allQueries) {
        if (!allQueries) {
            return (
                <div className="row">
                    {'No query information found'}
                </div>
            );
        }
        return allQueries.map((query, index) => (
            <div className="row" key={index} title={query}>
                {query}
            </div>
        ));
    }

    printAllApis(allApis) {
        if (!allApis) {
            return (
                <div className="row">
                    {'No api information found'}
                </div>
            );
        }
        return allApis.map((api, index) => (
            <div className="row" key={index} title={api}>
                {api}
            </div>
        ));
    }

    getContent() {
        return (
            <div className="logger">
                <div className="section">
                    <div className="row title">Queries</div>
                    {this.props.logs.map((log, index) => (
                        <div key={index}>
                            <div className="uri">URI: {log.uri}</div>
                            {this.printAllQueries(log.debug['_queries-all'])}
                        </div>
                    ))}
                </div>
                <div className="section">
                    <div className="row title">API calls</div>
                    {this.props.logs
                        .filter(log => log.debug['_api-requests'])
                        .map((log, index) => (
                            <div key={index}>
                                <div className="uri">URI: {log.uri}</div>
                                {this.printAllApis(
                                    Object.keys(log.debug['_api-requests'])
                                )}
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    render() {
        return (
            <Confirmation
                width={780}
                onConfirm={this.props.onClose}
                confirmText={'Close'}
                showCancelButton={false}
            >
                {this.getContent()}
            </Confirmation>
        );
    }
}

LoggerDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    fetchLogs: PropTypes.func.isRequired,
    logs: PropTypes.array,
};

const mapStateToProps = state => {
    return {
        logs: state.logger.debug,
    };
};

const mapDispatchToProps = {
    fetchLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggerDialog);
