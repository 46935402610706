import { createSelector } from 'reselect';
import constants from '../../constants/Constants';

export default createSelector(
    state => state.calendar.selectedLOSportConfigSetting,
    state => state.calendar.sports,
    (selectedLOSportConfigSetting, sports) => {
        if (
            selectedLOSportConfigSetting !==
            constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_HIDDEN
        ) {
            return sports;
        }

        return Object.values(sports).reduce((acc, sport) => {
            if (!sport.disabledInLOConfig) {
                acc[sport.id] = sport;
            }
            return acc;
        }, {});
    }
);
