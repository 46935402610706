export default state => {
    let hasRecentlyBookedMatches = false;
    for (const adapter of state.shoppingBasket.adapters) {
        if (adapter.id === state.shoppingBasket.selectedAdapterId) {
            hasRecentlyBookedMatches = adapter.isBooking;
            break;
        }
    }

    return Boolean(hasRecentlyBookedMatches);
};
