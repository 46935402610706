import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    LazyTableData as LazyTableModel,
    Cell as LazyTableModelCell,
    Row as LazyTableModelRow,
} from '../../../../../../models/LazyTableData';
import Xhr from '../../../../../../utils/Xhr';
import LazyTable from '../../../../../LazyTable/LazyTable.jsx';
import Button from '../../../../../formInput/Button/Button';
import Checkbox from '../../../../../formInput/Checkbox/Checkbox';
import RadioButton from '../../../../../formInput/RadioButton/RadioButton';
import TextInput from '../../../../../formInput/TextInput/TextInput';

import constants from '../../../../../../constants/Constants';

import { isEqual } from 'underscore';
import {
    addDialog,
    removeDialog,
} from '../../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../../stores/dialog/constants';
import BusySpinner from '../../../../../BusySpinner/BusySpinner';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import './Roles.scss';

const roles = ['support', 'developer', 'admin'];
const allPermissions = [
    'admin',
    'admin_write',
    'developer',
    'developer_write',
    'support',
    'support_write',
];

const newUser = {
    user_uri: '',
    developer: false,
    developer_write: false,
    admin: false,
    admin_write: false,
    support: false,
    support_write: false,
    description: '',
    displayName: '',
};

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {
                new: newUser,
            },
            loading: true,
            changingID: undefined,
            unChanged: undefined,
        };
        this.loadData();
    }

    loadData() {
        const loadDataReq =
            isMicrofrontend && this.props.isAccessEnabledInQuarkus
                ? Xhr.backendRequestLbcQuarkus('/access/all')
                : Xhr.backendRequest('/access/all');
        loadDataReq.then(response => {
            const parsedUsers = {};
            Object.entries(response.data).forEach(([key, user]) => {
                for (const permission of allPermissions) {
                    user[permission] = user[permission] === '1';
                }
                parsedUsers[key] = user;
            });
            this.setState({
                loading: false,
                users: { ...this.state.users, ...response.data },
                unChanged: { ...this.state.users, ...response.data },
            });
        });
    }

    roleSelection(role, user) {
        const active = this.state.users[user][role];
        const write = this.state.users[user][`${role}_write`];

        return (
            <div className="role-selection">
                <Checkbox
                    checked={active}
                    disabled={!this.props.access.admin_write}
                    onClick={this.toggleBasePermission.bind(this, role, user)}
                >
                    {role}
                </Checkbox>
                <div className="read-write">
                    <RadioButton
                        disabled={!active || !this.props.access.admin_write}
                        checked={!write}
                        onClick={this.togglePermission.bind(
                            this,
                            `${role}_write`,
                            user
                        )}
                    >
                        Read
                    </RadioButton>
                    <RadioButton
                        disabled={!active || !this.props.access.admin_write}
                        checked={write}
                        onClick={this.togglePermission.bind(
                            this,
                            `${role}_write`,
                            user
                        )}
                    >
                        Write
                    </RadioButton>
                </div>
            </div>
        );
    }

    toggleBasePermission(permission, user) {
        const writePermission = `${permission}_write`;
        this.setState({
            users: {
                ...this.state.users,
                [user]: {
                    ...this.state.users[user],
                    [permission]: !this.state.users[user][permission],
                    [writePermission]: false,
                },
            },
        });
    }

    togglePermission(permission, user) {
        this.setState({
            users: {
                ...this.state.users,
                [user]: {
                    ...this.state.users[user],
                    [permission]: !this.state.users[user][permission],
                },
            },
        });
    }

    onChangeNewUserUri(user_uri) {
        this.setState({
            users: {
                ...this.state.users,
                new: {
                    ...this.state.users.new,
                    user_uri,
                },
            },
        });
    }

    onChangeUserDescription(user_uri, description) {
        this.setState({
            users: {
                ...this.state.users,
                [user_uri]: {
                    ...this.state.users[user_uri],
                    description,
                },
            },
        });
    }

    getSaveButton(user) {
        return (
            <div className="button">
                <Button
                    onClick={this.updateRole.bind(this, user)}
                    type={Button.types.LARGE_GREEN_3D}
                    fixedWidth={true}
                    icon={''}
                    disabled={isEqual(
                        user,
                        Object.values(this.state.unChanged).find(
                            u => u.id === user.id
                        )
                    )}
                >
                    {this.state.changingID === user.id ? (
                        <div className="button spinnerButton">
                            <BusySpinner
                                size={16}
                                margin={'0'}
                                color={'#fff'}
                            />{' '}
                            Saving ...
                        </div>
                    ) : (
                        'Save Changes'
                    )}
                </Button>
            </div>
        );
    }

    getDeleteButton(user) {
        return (
            <div
                className="button"
                title="Delete role"
                onClick={() => this.deleteRole(user)}
            >
                <FontIcon icon="" className="iconButton" />
            </div>
        );
    }

    getNewButton() {
        const match =
            !this.state.users.new.user_uri.match(/sr:(user|book):\d+/);
        return (
            <div className="button">
                <Button
                    onClick={this.addRole.bind(this)}
                    type={Button.types.LARGE_GREEN_3D}
                    fixedWidth={false}
                    icon={''}
                    disabled={match}
                >
                    {this.state.changingID === 'new' ? (
                        <div className="button spinnerButton">
                            <BusySpinner
                                size={16}
                                margin={'0'}
                                color={'#fff'}
                            />{' '}
                            Creating ...
                        </div>
                    ) : (
                        'Create new role'
                    )}
                </Button>
            </div>
        );
    }

    deleteRole(user) {
        const deleteReq =
            isMicrofrontend && this.props.isAccessEnabledInQuarkus
                ? Xhr.backendRequestLbcQuarkus(
                      `/access/delete/${user.id}`,
                      null,
                      'DELETE'
                  )
                : Xhr.backendRequest(
                      `/access/delete/${user.id}`,
                      null,
                      'DELETE'
                  );
        this.props.addDialog(dialogTypes.DELETE_ACCESS, {
            onConfirm: () => {
                this.props.removeDialog(dialogTypes.DELETE_ACCESS);
                deleteReq.then(response => {
                    if (!response.success) {
                        throw new Error(
                            'An error occured while deleting access.'
                        );
                    } else {
                        const usrs = this.state.users;
                        delete usrs[user.user_uri];
                        this.setState({
                            changingID: undefined,
                            users: { ...usrs },
                            unChanged: {
                                ...this.state.users,
                                [user.user_uri]: response,
                            },
                        });
                    }
                });
            },
        });
    }

    updateRole(user) {
        this.setState({ changingID: user.id });
        let postData = { ...this.state.users[user.user_uri] };
        for (const permission of allPermissions) {
            postData[permission] = postData[permission] ? '1' : '0';
        }
        const updateRoleReq =
            isMicrofrontend && this.props.isAccessEnabledInQuarkus
                ? Xhr.backendRequestLbcQuarkus(
                      '/access/update',
                      null,
                      'POST',
                      postData
                  )
                : Xhr.backendRequest('/access/update', null, 'POST', postData);
        updateRoleReq.then(response => {
            if (response.user_uri !== user.user_uri) {
                throw new Error('An error occured while updating access.');
            } else {
                for (const permission of allPermissions) {
                    response[permission] =
                        response[permission] === '1' ? true : false;
                }
                this.setState({
                    changingID: undefined,
                    users: {
                        ...this.state.users,
                        [user.user_uri]: response,
                    },
                    unChanged: {
                        ...this.state.users,
                        [user.user_uri]: response,
                    },
                });
            }
        });
    }

    addRole() {
        this.setState({ changingID: 'new' });
        const postData = { ...this.state.users.new };
        for (const permission of allPermissions) {
            postData[permission] = postData[permission] ? '1' : '0';
        }
        if (!postData.user_uri.match(/sr:(user|book):\d+/)) {
            // incorrect user name! do not book.
            alert('User uri must be of format sr:user:[id] or sr:book:[id]');
            return;
        }
        const addRoleReq =
            isMicrofrontend && this.props.isAccessEnabledInQuarkus
                ? Xhr.backendRequestLbcQuarkus(
                      '/access/new',
                      null,
                      'POST',
                      postData
                  )
                : Xhr.backendRequest('/access/new', null, 'POST', postData);
        addRoleReq.then(response => {
            if (!response.id) {
                throw new Error('An error occured while updating access.');
            } else {
                for (const permission of allPermissions) {
                    response[permission] =
                        response[permission] === '1' ? true : false;
                }
                this.setState({
                    changingID: undefined,
                    users: {
                        ...this.state.users,
                        [response.user_uri]: response,
                        new: newUser,
                    },
                    unChanged: {
                        ...this.state.users,
                        [response.user_uri]: response,
                        new: newUser,
                    },
                });
            }
        });
    }

    createFooter() {
        const footerCells = [
            new LazyTableModelCell(
                (
                    <TextInput
                        labelText="User Uri"
                        className="adminInputWrapper"
                        onChange={this.onChangeNewUserUri.bind(this)}
                        value={this.state.users.new.user_uri}
                        inputClassName="adminInput wide"
                        placeHolder="Valid format: sr:user:[id] or sr:book:[id]"
                    />
                ),
                [],
                null,
                '',
                2
            ),
        ];
        roles.forEach(role => {
            footerCells.push(
                new LazyTableModelCell(this.roleSelection(role, 'new'))
            );
        });
        footerCells.push(
            new LazyTableModelCell(
                (
                    <TextInput
                        className="adminInputWrapper"
                        onChange={this.onChangeUserDescription.bind(
                            this,
                            'new'
                        )}
                        value={this.state.users.new.description}
                        inputClassName="adminInput wide"
                    />
                )
            )
        );
        footerCells.push(new LazyTableModelCell(this.getNewButton()));
        footerCells.push(new LazyTableModelCell(<></>));
        const footer = new LazyTableModelRow(footerCells);
        return footer;
    }

    getTable() {
        const table = new LazyTableModel([10, 10, 15, 15, 15, 20, 10, 5]);

        const headerCells = [
            new LazyTableModelCell('User Name'),
            new LazyTableModelCell('User Uri'),
            new LazyTableModelCell('Support'),
            new LazyTableModelCell('Developer'),
            new LazyTableModelCell('Admin'),
            new LazyTableModelCell('Description'),
            new LazyTableModelCell('Actions'),
            new LazyTableModelCell('Delete'),
        ];

        const header = new LazyTableModelRow(headerCells);
        table.setHeaderRow(header);

        table.config.rows = Object.entries(this.state.users)
            .filter(([userUri, user]) => userUri !== 'new')
            .map(([userUri, user]) => {
                const cells = [
                    new LazyTableModelCell(user.displayName),
                    new LazyTableModelCell(userUri),
                ];

                roles.forEach(role => {
                    cells.push(
                        new LazyTableModelCell(
                            this.roleSelection(role, userUri)
                        )
                    );
                });

                cells.push(
                    new LazyTableModelCell(
                        (
                            <div className="input">
                                <TextInput
                                    className="adminInputWrapper"
                                    onChange={this.onChangeUserDescription.bind(
                                        this,
                                        user.user_uri
                                    )}
                                    disabled={!this.props.access.admin_write}
                                    value={user.description}
                                    inputClassName="adminInput wide"
                                />
                            </div>
                        )
                    )
                );

                this.props.access.admin_write &&
                    cells.push(
                        new LazyTableModelCell(this.getSaveButton(user))
                    );

                this.props.access.admin_write &&
                    cells.push(
                        new LazyTableModelCell(this.getDeleteButton(user))
                    );
                return new LazyTableModelRow(cells);
            });

        this.props.access.admin_write &&
            table.setFooterRow(this.createFooter());

        return table;
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="roles-admin spinner">
                    <BusySpinner size={18} margin={'0'} color={'#000'} />
                </div>
            );
        }
        return (
            <div className="roles-admin">
                <LazyTable
                    lazyTableData={this.getTable()}
                    id={constants.LAZY_TABLE_USER_ROLES}
                />
            </div>
        );
    }
}

Roles.propTypes = {
    access: PropTypes.object.isRequired,
    addDialog: PropTypes.func.isRequired,
    removeDialog: PropTypes.func.isRequired,
    isAccessEnabledInQuarkus: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    access: state.calendar.access,
    isAccessEnabledInQuarkus:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_access,
});

const mapDispatchToProps = {
    addDialog,
    removeDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
