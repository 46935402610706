import { PdsIconButton } from '@podium-design-system/react-components';
import {
    PdsBroadcastAntennaIcon,
    PdsDiamondIcon,
    PdsMonitorDesktopIcon,
} from '@podium-design-system/react-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import '../../../../../Event/productBookingStatus.scss';
import CheckBoxIcon from '../../../../../icons/CheckBoxIcon/CheckBoxIcon';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import LiveStatusIcon from '../../../../../icons/LiveStatusIcon/LiveStatusIcon';
import './legend.scss';

class Legend extends React.Component {
    _createItemWithIcon = (text, icon) => {
        return (
            <div className="item">
                <span>{text}</span>
                <FontIcon icon={icon} />
            </div>
        );
    };

    _createBookingStatuses = () => {
        return (
            <div className="group">
                <div className="item">
                    Booked
                    <CheckBoxIcon filled={true} color="color-green-700" />
                </div>
                <div className="item">
                    Bookable
                    <CheckBoxIcon filled={false} color="color-green-700" />
                </div>
                <div className="item">
                    Buyable
                    <CheckBoxIcon filled={false} color="color-orange-700" />
                </div>
                <div className="item">
                    Not Available (no icon)
                    <CheckBoxIcon selected={false} color="transparent" />
                </div>
                {this.props.is4sightEnabled ? (
                    <>
                        <div className="item">
                            Content variations, one booked, another bookable
                            <CheckBoxIcon
                                className={'booked-bookable'}
                                title={'Booked Bookable'}
                                hasVariantIcon={true}
                            />
                        </div>
                        <div className="item">
                            Content variations, one booked, another buyable
                            <CheckBoxIcon
                                className={'booked-buyable'}
                                title={'Booked Buyable'}
                                hasVariantIcon={true}
                            />
                        </div>
                        <div className="item">
                            Content variations, one bookable, another buyable
                            <CheckBoxIcon
                                className={'bookable-buyable'}
                                title={'Bookable Buyable'}
                                hasVariantIcon={true}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="item">
                    Booked optional content
                    <br />
                    (LCO content charged per single booking)
                    <div
                        className={`optional-content booked_optional`}
                        title="Booked optional content"
                    />
                </div>
                <div className="item">
                    Bookable optional content
                    <br />
                    (LCO content charged per single booking)
                    <div
                        className={`optional-content bookable_optional`}
                        title="Bookable optional content"
                    />
                </div>
                <div className="item">
                    Recommended content
                    <CheckBoxIcon
                        isRecommended={true}
                        color="color-orange-recommender"
                    />
                </div>
            </div>
        );
    };

    _createLCStatuses = () => {
        return (
            <div className="group">
                <div className="item">
                    Event not started, streaming started
                    <LiveStatusIcon
                        liveStatus={0}
                        isStreaming={true}
                        liveStatusText={'Event not started, streaming started'}
                        access={{ support: false }}
                    />
                </div>
                <div className="item">
                    Event started, streaming started
                    <LiveStatusIcon
                        liveStatus={2}
                        isStreaming={true}
                        liveStatusText={'Event started, streaming started'}
                        access={{ support: false }}
                        style={{ style: 'border' }}
                    />
                </div>
                <div className="item">
                    Event started, no streaming
                    <LiveStatusIcon
                        liveStatus={2}
                        liveStatusText={'Event started'}
                        access={{ support: false }}
                    />
                </div>
                <div className="item">
                    Paused
                    <LiveStatusIcon
                        liveStatus={80}
                        liveStatusText={'Paused'}
                        access={{ support: false }}
                    />
                </div>
                <div className="item">
                    Finished
                    <LiveStatusIcon
                        liveStatus={100}
                        liveStatusText={'Finished'}
                        access={{ support: false }}
                    />
                </div>
            </div>
        );
    };

    render() {
        // const simTooltip =
        //     'The Simulated Reality product offering might be subject to applicable territorial conditions,\n' +
        //     'such as approval by regulatory authorities or other compliance requirements.\n' +
        //     'It is your obligation to comply with the legislation of each jurisdiction in which you are operating.';

        return (
            <div className="legend">
                {this._createBookingStatuses()}
                <div className="group">
                    <div className="item">
                        It is a model that takes into consideration the customer
                        historical behavior and variables like concurrent
                        matches and time of day to find the most suitable
                        events. Note: For our MTS customers the model takes into
                        consideration the expected turnover instead of booking
                        probability. 
                        <div style={{ width: '12%', color: 'rgb(51, 51, 51)' }}>
                            Forecasted LO/LD Bookings
                        </div>
                    </div>
                    <div className="item">
                        It is a model that takes into consideration the customer
                        historical behavior and expected number of end viewers
                        per LCO stream
                        <div style={{ width: '12%', color: 'rgb(51, 51, 51)' }}>
                            Number of LCO Viewers
                        </div>
                    </div>
                </div>

                <div className="group">
                    {this._createItemWithIcon('Maintenance Window', '')}
                </div>
                {this.props.isNewProductsUIEnabled && (
                    <div className="group">
                        <div className="item">
                            LO Latency: For ATP events if you don’t have the LO
                            Latency Premium Add-on, you will get a delayed feed
                            connection. By having the LO Latency Premium Add-on,
                            you'll gain access to the shortest latency possible.
                            In LBC, you will understand which events are subject
                            to this add-on and then, which of the options you
                            subscribed. 
                            <PdsIconButton
                                size="xs"
                                rank="outline"
                                color="neutral"
                                opaqueBackground={true}
                            >
                                <PdsMonitorDesktopIcon slot="icon" />
                            </PdsIconButton>
                        </div>
                        {this.props.is4sightEnabled && (
                            <div className="item">
                                4Sight Streaming (4Sight): 4Sight Streaming
                                introduces a new augmented reality streaming
                                feature to our Live Channel Online (LCO)
                                Product, enhancing your overall streaming
                                experience. 
                                <PdsIconButton
                                    size="xs"
                                    rank="outline"
                                    color="neutral"
                                    opaqueBackground={true}
                                >
                                    <PdsBroadcastAntennaIcon slot="icon" />
                                </PdsIconButton>
                            </div>
                        )}
                        {this.props.isHighQualityEnabled && (
                            <div className="item">
                                LCO high-quality enhances your viewing
                                experience by providing access to a wide range
                                of content in better quality, with minimal
                                buffering  
                                <PdsIconButton
                                    size="xs"
                                    rank="outline"
                                    color="neutral"
                                    opaqueBackground={true}
                                >
                                    <PdsDiamondIcon slot="icon" />
                                </PdsIconButton>
                            </div>
                        )}
                    </div>
                )}

                {
                    // TODO
                    // explain live icons
                    // not urgent, this was not a feature before the redesign
                }

                {this._createLCStatuses()}
            </div>
        );
    }
}

Legend.propTypes = {
    is4sightEnabled: PropTypes.bool.isRequired,
    isHighQualityEnabled: PropTypes.bool.isRequired,
    isNewProductsUIEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    is4sightEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_4Sight,
    isHighQualityEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_av_high_quality,
    isNewProductsUIEnabled:
        state.viewport.selectedVersion === 'new-version' ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
});

export default connect(mapStateToProps, {})(Legend);
