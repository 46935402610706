import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from '../FontIcon/FontIcon';
import '../../icons/CheckBoxIcon/CheckBoxIcon.scss';

// const checkBoxes = { //currently just for reference
//     'selected-outline-sm' : '',
//     'selected-outline-md' : '',
//     'selected-sm' : '',
//     'selected-md': '',
//     'unselected-sm' : '',
//     'unselected-md' : '',
//     'indeterminate-sm' : '',
//     'indeterminate-md' : '',
// }

const CheckBoxIcon = props => {
    const { selected = true, filled = true, checkBoxSize = 'md' } = props;
    const getIcon = () => {
        if (props.isRecommended) {
            return '';
        }
        return checkBoxSize === 'md'
            ? selected
                ? filled
                    ? ''
                    : ''
                : ''
            : selected
            ? filled
                ? ''
                : ''
            : '';
    };

    const icon = props.hasVariantIcon ? '' : getIcon();

    return props.hasVariantIcon ? (
        <div
            className={`bookingstatus-icon ${props.className}`}
            title={props.title}
        />
    ) : (
        <FontIcon
            icon={icon}
            color={props.color}
            className={props.className}
            title={props.title}
        />
    );
};

CheckBoxIcon.propTypes = {
    selected: PropTypes.bool,
    filled: PropTypes.bool,
    checkBoxSize: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    isRecommended: PropTypes.bool,
    hasVariantIcon: PropTypes.bool,
};

export default CheckBoxIcon;
