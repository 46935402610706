import React from 'react';
import PropTypes from 'prop-types';
import './ErrorDialog.scss';
import Button from '../../../formInput/Button/Button';

const ErrorDialog = props => {
    return (
        <div className="error-dialog">
            {props.errorMessage}{' '}
            <div className="button-area">
                <Button
                    type={Button.types.SMALL_WHITE_BORDER}
                    className="reload-button"
                    title="Reload"
                    onClick={() => window.location.reload()}
                >
                    Reload
                </Button>
            </div>
        </div>
    );
};

ErrorDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
};

export default ErrorDialog;
