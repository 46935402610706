import { createSelector } from 'reselect';
import { getIdentifier } from '../../utils/FilterUtils';

export default createSelector(
    state => state.calendar.checkboxes,
    (state, selectedCheckboxes) => selectedCheckboxes,
    (checkboxes, selectedCheckboxes) => {
        const result = {};
        checkboxes.forEach(checkbox => {
            const isSelected = selectedCheckboxes.some(selected => {
                return (
                    selected.groupId === checkbox.groupId &&
                    selected.value === checkbox.value
                );
            });

            if (isSelected) {
                const identifier = getIdentifier(checkbox);
                result[identifier] = checkbox;
            }
        });
        return result;
    }
);
