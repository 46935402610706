import views from '../../constants/views';

export default viewportStoreState => {
    const textBannerViews = [
        views.VIEWPORT_TYPE_LIST_CONDENSED,
        views.VIEWPORT_TYPE_TIMELINE,
    ];

    return textBannerViews.indexOf(viewportStoreState.currentView) > -1;
};
