import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextBanner from '../../../TextBanner/TextBanner';
import { deleteTextBanner } from '../../../../stores/textBanner/actions';
import Confirmation from '../../Confirmation/Confirmation';
import './deleteTextBannerDialog.scss';

const DeleteTextBannerDialog = props => {
    const onConfirm = () => {
        props.deleteTextBanner(props.banner.id);
        props.onClose();
    };

    return (
        <Confirmation onConfirm={onConfirm} onReject={props.onClose}>
            <div className="content">
                <TextBanner
                    title={props.banner.title}
                    subTitle={props.banner.subTitle}
                    type={parseInt(props.banner.type, 10)}
                    validFrom={props.banner.validFrom}
                    validto={props.banner.validTo}
                />
                <div className="confirm">
                    Are you sure you want to delete this text banner?
                </div>
            </div>
        </Confirmation>
    );
};

DeleteTextBannerDialog.propTypes = {
    banner: PropTypes.object.isRequired,
    deleteTextBanner: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = { deleteTextBanner };

export default connect(null, mapDispatchToProps)(DeleteTextBannerDialog);
