import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import StorageLocal from '../../../../utils/StorageLocal';
import Xhr from '../../../../utils/Xhr';
import './matchQuickInfo.scss';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');
class MatchQuickInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relevantLinks: [],
            generalInformation: [],
            isLoading: false,
        };
    }

    _buildGeneralInfo(generalInfo) {
        if (!this.props.includeGeneralInfo) {
            return null;
        }

        if (generalInfo.length !== 0) {
            const listItems = generalInfo.map((info, index) => {
                return <li key={`link_${index}`}>{info.text}</li>;
            });
            return (
                <div>
                    <h3>General Info</h3>
                    <ul>{listItems}</ul>
                </div>
            );
        }

        return null;
    }

    _buildInformationLinks(links) {
        if (!this.props.includeLinks) {
            return null;
        }

        if (links.length !== 0) {
            const listItems = links.map((link, index) => {
                return (
                    <li key={`link_${index}`}>
                        <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.description}
                        </a>
                    </li>
                );
            });
            return (
                <div>
                    <h3>Relevant links</h3>
                    <ul>{listItems}</ul>
                </div>
            );
        }

        return null;
    }

    _buildKeyValue(key, value) {
        return (
            <div className="key-value">
                <span>{key}</span>
                <span>{value}</span>
            </div>
        );
    }
    componentDidMount() {
        const storageLocal = new StorageLocal();

        if (this.props.includeLinks || this.props.includeGeneralInfo) {
            const fetchMatchInfo =
                isMicrofrontend &&
                (this.props.isMatchInfoEnabledForQuarkus ||
                    storageLocal.getItem('isMatchInfoEnabledForQuarkus'))
                    ? Xhr.backendRequestLbcQuarkus(
                          `/match-info/${this.props.event.id}`,
                          null,
                          'GET',
                          null,
                          false
                      )
                    : Xhr.backendRequest(
                          `/match-info/${this.props.event.id}`,
                          null,
                          'GET',
                          null,
                          false
                      );
            fetchMatchInfo.then(
                response => {
                    this.setState({
                        relevantLinks: response.links,
                        generalInformation: response.generalInfo,
                    });
                },
                error => {
                    this.setState({
                        error,
                    });
                }
            );
        }
    }

    render() {
        const { event, optionalKeyValue } = this.props;
        const { generalInformation, relevantLinks } = this.state;

        let optionalString = '';
        if (optionalKeyValue !== null) {
            optionalString = this._buildKeyValue(
                optionalKeyValue.key,
                optionalKeyValue.value
            );
        }

        let homeTeamName = event.teamHome.name;
        let awayTeamName = event.teamAway.name;
        let venueName = 'Not available';
        if (event.venue) {
            venueName = event.venue.name;
            if (event.venue && event.venue.state) {
                venueName += ' (' + event.venue.state + ')';
            }
        }

        let matchDivision =
            event.teamHome.division > event.teamAway.division
                ? event.teamHome.division
                : event.teamAway.division;

        if (
            event.teamHome.division === null ||
            event.teamAway.division === null
        ) {
            matchDivision = 'Not available';
        }

        if (event.teamHome.venue && event.teamHome.venue.state) {
            homeTeamName += ` (${event.teamHome.venue.state})`;
        }

        if (event.teamAway.venue && event.teamAway.venue.state) {
            awayTeamName += ` (${event.teamAway.venue.state})`;
        }

        return (
            <div className="match-quick-info">
                <div className="row">
                    <div className="col">
                        {this._buildKeyValue('Match id', event.id)}
                        {this._buildKeyValue('Home team', homeTeamName)}
                        {this._buildKeyValue('Away team', awayTeamName)}
                        {!event.isNcaa ||
                            this._buildKeyValue(
                                'Home Team Division',
                                event.teamHome.division
                                    ? 'Division ' + event.teamHome.division
                                    : 'Not available'
                            )}
                        {!event.isNcaa ||
                            this._buildKeyValue(
                                'Away Team Division',
                                event.teamAway.division
                                    ? 'Division ' + event.teamAway.division
                                    : 'Not available'
                            )}
                    </div>
                    <div className="col">
                        {this._buildKeyValue(
                            'Tournament id',
                            event.tournamentId
                        )}
                        {this._buildKeyValue(
                            'Tournament name',
                            event.tournamentName
                        )}
                        {!event.isNcaa ||
                            this._buildKeyValue('Match venue', venueName)}
                        {!event.isNcaa ||
                            this._buildKeyValue(
                                'Match Division',
                                matchDivision
                            )}

                        {optionalString}
                    </div>
                </div>
                <div className="row">
                    {this._buildGeneralInfo(generalInformation)}
                    {this._buildInformationLinks(relevantLinks)}
                </div>
            </div>
        );
    }
}

MatchQuickInfo.defaultProps = {
    optionalKeyValue: null,
    includeGeneralInfo: true,
    includeLinks: true,
};

MatchQuickInfo.propTypes = {
    event: PropTypes.object.isRequired,
    includeGeneralInfo: PropTypes.bool,
    includeLinks: PropTypes.bool,
    optionalKeyValue: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
    }),
    isMatchInfoEnabledForQuarkus: PropTypes.bool,
};

const mapStateToProps = state => ({
    isMatchInfoEnabledForQuarkus:
        !!state?.calendar?.auth?.userInfo?.bookmakerInfo?.features
            .enable_lbc_quarkus_match_info,
});

export default connect(mapStateToProps, null)(MatchQuickInfo);
