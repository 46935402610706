import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShoppingBasketContent from './ShoppingBasketContent/ShoppingBasketContent.jsx';
import Button from '../../../formInput/Button/Button.jsx';
import FontIcon from '../../../icons/FontIcon/FontIcon.jsx';
import Switch from '../../../Switch/Switch';
import DateSelection from './DateSelection/DateSelection.jsx';
import views from '../../../../constants/views';
import { matchesInBasketCount } from '../../../../selectors/shoppingBasket';
import { addDialog } from '../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../stores/dialog/constants';
import {
    removeAllEvents,
    setIsExpanded,
} from '../../../../stores/shoppingBasket/actions';
import { setGadgetEnabled } from '../../../../stores/scheduleOverview/actions';
import './monkeyHeader.scss';

import Badge from '../../../Badge/Badge.jsx';
import { PdsIconButton } from '@podium-design-system/react-components';
import { PdsTableSortIcon } from '@podium-design-system/react-icons';

class MonkeyHeader extends React.Component {
    constructor(props) {
        super(props);
        this.dateButtonContainerRef = React.createRef();
        this.selectionButtonsRef = React.createRef();
        this.state = {
            availableSize: 0,
        };
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver(entries => {
            const availableSize = this.calculateAvailableSize();
            availableSize !== this.state.availableSize &&
                this.setState({ availableSize: availableSize });
        });

        this.resizeObserver.observe(this.dateButtonContainerRef.current);
    }

    componentDidUpdate() {
        this.dateButtonContainerRef.current &&
            this.resizeObserver.observe(this.dateButtonContainerRef.current);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect();
    }

    calculateAvailableSize() {
        const containerSize = this.dateButtonContainerRef.current
            ? this.dateButtonContainerRef.current.clientWidth
            : 0;

        const selectionButtonsSize = this.selectionButtonsRef.current
            ? this.selectionButtonsRef.current.clientWidth
            : 0;

        const availableSize = containerSize - selectionButtonsSize;

        return availableSize;
    }

    _onRemoveAllClick = () => {
        this.props.removeAllMatches();
    };

    _onCheckoutButtonClick = () => {
        if (this.props.matchesInBasketCount <= 0) {
            return;
        }
        this.props.setIsExpanded(!this.props.basketExpanded);
    };

    _buildInvoiceWarning() {
        // TODO - move this somewhere else,
        // or show only this warning (not the rest of the monkey header) in these views
        const showWarningViews = [
            views.VIEWPORT_TYPE_BOOKING_HISTORY,
            views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY,
            views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY,
            views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY,
        ];

        if (showWarningViews.indexOf(this.props.currentView) === -1) {
            return '';
        }

        return (
            <div className="buttons-row">
                <span className="av-warning">
                    <FontIcon icon="" />
                    Please note that invoice status of matches can be changed
                    after final review
                </span>
            </div>
        );
    }

    _buildSelectionButtons() {
        const viewsWithSelectionButtons = [
            views.VIEWPORT_TYPE_LIST_CONDENSED,
            views.VIEWPORT_TYPE_TIMELINE_GADGET,
        ];

        if (!viewsWithSelectionButtons.includes(this.props.currentView)) {
            return '';
        }

        return (
            <div className="selection-buttons" ref={this.selectionButtonsRef}>
                <Button
                    type={Button.types.SMALL_BLUE_BORDER}
                    className="option-button"
                    onClick={() =>
                        this.props.addDialog(dialogTypes.BULK_SELECT)
                    }
                    fixedWidth="140px"
                    icon=""
                >
                    Bulk selection
                </Button>
                <Button
                    type={Button.types.SMALL_BLUE_BORDER}
                    className="option-button"
                    onClick={this._onRemoveAllClick}
                    fixedWidth="140px"
                    icon=""
                    disabled={
                        !this.props.matchesInBasketCount > 0 ||
                        this.props.basketExpanded
                    }
                >
                    Deselect all
                </Button>
                <PdsIconButton
                    size="sm"
                    rank="ghost"
                    color="neutral"
                    style={{ display: 'none' }}
                >
                    <PdsTableSortIcon slot="icon" />
                </PdsIconButton>
            </div>
        );
    }

    _buildTimelineGraphToggle() {
        if (this.props.currentView !== views.VIEWPORT_TYPE_TIMELINE_GADGET) {
            return;
        }

        return (
            <div className="graphtoggle ui-700 text-gray-1100 text-left">
                <Switch
                    selected={this.props.gadgetEnabled}
                    onChange={this.props.setGadgetEnabled}
                />
                Graph
            </div>
        );
    }

    render() {
        if (
            this.props.currentView !== views.VIEWPORT_TYPE_LIST_CONDENSED &&
            this.props.currentView !== views.VIEWPORT_TYPE_TIMELINE_GADGET
        ) {
            return '';
        }

        let counter = '',
            title = '';
        const isExpanded = this.props.basketExpanded;

        if (this.props.matchesInBasketCount !== 0) {
            title = 'Book selected matches';
            counter = (
                <Badge
                    className="textline-300"
                    content={this.props.matchesInBasketCount}
                    color="red"
                />
            );
        }

        // shopping basket content
        let shoppingBasketContent = '';
        if (!this.props.isReseller) {
            shoppingBasketContent = (
                <Button
                    className={`checkout-btn ${
                        isExpanded ? 'expanded-button' : ''
                    }`}
                    type={
                        isExpanded
                            ? Button.types.LARGE_TRANSPARENT_SOLID
                            : Button.types.LARGE_GREEN
                    }
                    onClick={this._onCheckoutButtonClick}
                    disabled={this.props.matchesInBasketCount === 0}
                    icon={isExpanded ? '' : ''}
                    title={title}
                    fixedWidth={!isExpanded ? 172 : false}
                >
                    {!isExpanded && counter}
                    Checkout
                </Button>
            );
            if (isExpanded) {
                shoppingBasketContent = (
                    <ShoppingBasketContent>
                        {shoppingBasketContent}
                    </ShoppingBasketContent>
                );
            }
        }

        const selectionButtons = this._buildSelectionButtons();
        const invoiceWarning = this._buildInvoiceWarning();

        const graphToggle = this._buildTimelineGraphToggle();

        return (
            <div className="monkey-header">
                <div
                    className="date-selection-container"
                    ref={this.dateButtonContainerRef}
                >
                    <DateSelection availableSize={this.state.availableSize} />
                    {selectionButtons}
                    {graphToggle}
                </div>

                <div className="checkout-group">{shoppingBasketContent}</div>
                {invoiceWarning}
            </div>
        );
    }
}

MonkeyHeader.propTypes = {
    matchesInBasketCount: PropTypes.number.isRequired,
    removeAllMatches: PropTypes.func.isRequired,
    setIsExpanded: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    basketExpanded: PropTypes.bool,
    isReseller: PropTypes.bool,
    currentView: PropTypes.string.isRequired,
    setGadgetEnabled: PropTypes.func.isRequired,
    gadgetEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    matchesInBasketCount: matchesInBasketCount(state),
    basketExpanded: state.shoppingBasket.isExpanded,
    viewportState: state.viewport,
    isReseller: state.calendar.auth.userInfo.bookmakerInfo.isReseller,
    currentView: state.viewport.currentView,
    gadgetEnabled: state.scheduleOverview.gadgetEnabled,
});

const mapDispatchToProps = {
    removeAllMatches: removeAllEvents,
    setIsExpanded,
    addDialog,
    setGadgetEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonkeyHeader);
