import { addPrefix } from '../../utils/storeUtils';

export default addPrefix('feature', {
    FETCH: 'FETCH',
    START_CREATION: 'START_CREATION',
    CREATE: 'CREATE',
    START_UPDATE: 'START_UPDATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
});
