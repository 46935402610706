import Xhr from '../../utils/Xhr';
import actionConstants from './constants';
import Alert from '../../components/Alert/Alert';
import { create as createNotification } from '../notification/actions';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const saveAutobookingConfig =
    sendConfirmation => (dispatch, getState) => {
        const saveAutobookingConfigReq =
            getState().calendar.auth.userInfo.bookmakerInfo.features
                .autobook_group && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      '/autobook/config/save',
                      null,
                      'POST',
                      {
                          sendConfirmation,
                      }
                  )
                : Xhr.backendRequest('/autobook/config/save', null, 'POST', {
                      sendConfirmation,
                  });
        saveAutobookingConfigReq
            .then(() => {
                dispatch({
                    type: actionConstants.SET,
                    payload: sendConfirmation,
                });
            })
            .catch(error => {
                createNotification(Alert.types.ERROR, error.message);
            });
    };

export const fetchAutobookingConfig = () => (dispatch, getState) => {
    const fetchAutobookingConfigReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .autobook_group && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus('/autobook/config')
            : Xhr.backendRequest('/autobook/config');
    fetchAutobookingConfigReq
        .then(response => {
            dispatch({
                type: actionConstants.SET,
                payload: response.sendConfirmation,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};
