import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from '../../utils/CalendarMoment';
import Button from '../formInput/Button/Button';
import Alert from '../Alert/Alert';
import defaultSettings from '../../utils/DefaultSettings';

class FilterEventMessageRenderer extends Component {
    isSelectDayMatchDay(matchMoment) {
        if (!matchMoment || !this.props.momentSelectedDay) {
            return false;
        }
        return matchMoment.hasSame(this.props.momentSelectedDay, 'day');
    }

    render() {
        const messages = [];
        const remoteData = this.props.remoteData;

        if (!this.props.visibleEvents) {
            messages.push(
                <div className="no-match-message" key="nomatches">
                    <Alert type={Alert.types.INFO}>
                        {defaultSettings.noMatchesMsg}
                    </Alert>
                </div>
            );
            return messages;
        }

        if (remoteData['not-found'] && remoteData['not-found'].length) {
            messages.push(
                <Alert icon="" type={Alert.types.ERROR} key="notfound">
                    Could not find event(s) matching &quot;
                    {remoteData['not-found'].join('", "')}
                    &quot;. <br />
                    <br />
                    - If you originally entered only digits, it will be
                    interpreted as a match (sr:match:dddddd).
                    <br />- If you are looking for a stage-event, use
                    sr:stage:dddddd
                </Alert>
            );
        }

        if (!remoteData || !remoteData.found) {
            return messages;
        }

        const notVisible = [],
            anotherDay = [];
        for (const uri in remoteData.found) {
            if (!remoteData.found.hasOwnProperty(uri)) {
                continue;
            }

            const matchMoment = moment.fromTimestamp(remoteData.found[uri]);

            if (
                this.isSelectDayMatchDay(matchMoment) &&
                !this.props.visibleEvents
            ) {
                notVisible.push(
                    <li key={uri}>
                        You need to adjust your filters in order to see the
                        event with id {uri}
                    </li>
                );
            } else {
                anotherDay.push(
                    <li key={uri}>
                        There is an event with id
                        <strong className="highlight-value">{uri}</strong>
                        on
                        <Button
                            onClick={this.props.dayClickHandler.bind(
                                null,
                                matchMoment
                            )}
                            className="date-event"
                            fixedWidth={false}
                            type="small-grey-shadow"
                        >
                            {matchMoment.toFormat('DDDD (HH:mm)')}
                        </Button>
                    </li>
                );
            }
        }
        if (notVisible.length) {
            messages.push(
                <Alert icon="" type={Alert.types.INFO} key="notVisible">
                    <ul>{notVisible}</ul>
                </Alert>
            );
        }
        if (anotherDay.length) {
            messages.push(
                <Alert icon="" type={Alert.types.INFO} key="anotherDay">
                    <ul>{anotherDay}</ul>
                </Alert>
            );
        }

        return messages;
    }
}

FilterEventMessageRenderer.propTypes = {
    remoteData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    visibleEvents: PropTypes.array,
    isLoading: PropTypes.bool,
    momentSelectedDay: PropTypes.object,
    dayClickHandler: PropTypes.func,
};

FilterEventMessageRenderer.defaultProps = {
    remoteData: {},
    visibleEvents: [],
    momentSelectedDay: null,
    dayClickHandler: mm => {
        // intentionally empty function
        // to be overwritten by props if needed
    },
};

export default FilterEventMessageRenderer;
