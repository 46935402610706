export default class GenericError {
    constructor(message, code = 800, debug = null) {
        this.message = message;
        this.code = code;
        this.debug = debug;
    }

    getMessage() {
        return this.message;
    }

    getCode() {
        return this.code;
    }

    getDebug() {
        return this.debug;
    }
}
