// http://stackoverflow.com/a/32382702 (with some modifications)
const linkRegExp = /\[([^\]]+)?\]\(([^)]+)\)/g;

export default function transformTextLinks(message, transformation) {
    const output = message.split('');
    let match;

    while ((match = linkRegExp.exec(message)) !== null) {
        const string = match[0];
        const text = match[1] || '';
        const url = match[2];

        output[match.index] = transformation(text, url, string, match.index);

        for (let i = match.index + 1; i < match.index + string.length; i++) {
            output[i] = '';
        }
    }

    return output;
}
