export const getCookieValueByName = name => {
    for (const cookie of getCookies()) {
        if (cookie.name === name) {
            return cookie.value;
        }
    }

    return null;
};

const getCookies = () => {
    return document.cookie.split(';').map(cookie => {
        const [name, value] = cookie.split('=');
        return { name: name.trim(), value: value ? value.trim() : undefined };
    });
};
