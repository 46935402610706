import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../Button/Button.jsx';
import './productPicker.scss';

export const productText = {
    LO: 'Live Odds',
    LD: 'Live Data',
    BP: 'Betpal',
    LCO: 'Live Channel Online',
    LCR: 'Live Channel Retail',
    LCT: 'Live Channel Trading',
};

class ProductPicker extends Component {
    _onProductClick(product) {
        const selectedProducts = this._getSelectedProducts();

        if (selectedProducts.includes(product)) {
            // remove product
            selectedProducts.splice(selectedProducts.indexOf(product), 1);
        } else {
            selectedProducts.push(product);
        }

        this.props.onChange(selectedProducts);
    }

    _onAllClick() {
        const selectedProducts = this._getSelectedProducts();

        if (selectedProducts.length) {
            this.props.onChange([]);
        } else {
            // if all is already selected, clicking on it again does nothing
        }
    }

    _renderButton(buttonName, checked, onClick) {
        const buttonType = checked
            ? Button.types.SMALL_BLUE_BORDER_FILLED
            : Button.types.SMALL_BLUE_BORDER;
        return (
            <div key={buttonName} title={productText[buttonName.toUpperCase()]}>
                <Button onClick={onClick} type={buttonType} fixedWidth={false}>
                    {buttonName.toUpperCase()}
                </Button>
            </div>
        );
    }

    _renderProductButtons(selectedProducts) {
        return this.props.products.map(productName =>
            this._renderButton(
                productName,
                selectedProducts.includes(productName),
                this._onProductClick.bind(this, productName)
            )
        );
    }

    _renderAllButton() {
        return this._renderButton(
            'All',
            !this.props.selectedProducts.length,
            this._onAllClick.bind(this)
        );
    }

    _getSelectedProducts() {
        return [...this.props.selectedProducts] || [];
    }

    _buildLabel(label) {
        if (!label) {
            return '';
        }
        return <div className="label">{label}</div>;
    }

    render() {
        return (
            <div className="product-picker">
                {this._buildLabel(this.props.label)}
                <div className="checkboxes">
                    {this.props.showAllButton && this._renderAllButton()}
                    {this._renderProductButtons(this._getSelectedProducts())}
                </div>
            </div>
        );
    }
}

ProductPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    selectedProducts: PropTypes.array,
    products: PropTypes.array,
    showAllButton: PropTypes.bool,
};

ProductPicker.defaultProps = {
    label: '',
    selectedProducts: [],
    showAllButton: true,
};

export default ProductPicker;
