import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import Utils from '../../../../utils/Utils';
import './objectDialog.scss';

const createRows = (o, _depth = 0, showNr = true) => {
    let res = [],
        type,
        renderedValue,
        i = 0;
    _.each(o, (value, key) => {
        type = typeof value;
        if (_.isObject(value) && !_.isArray(value)) {
            renderedValue = '{}';
        } else {
            renderedValue = String(value);
            if (renderedValue.indexOf('[object Object]') !== -1) {
                renderedValue = '[]';
            }
        }

        res.push({
            number: showNr ? i : '-',
            desc: key,
            value: renderedValue === '{}' ? '' : renderedValue,
            type: _.isNull(value) ? 'null' : type,
            padding: _depth * 20,
        });
        // render next depth?
        if (
            _.isObject(value) &&
            (!_.isArray(value) || renderedValue === '[]')
        ) {
            if (key !== 'mm' && key !== 'mmEnd') {
                res = res.concat(createRows(value, _depth + 1, false));
            }
        }
        i++;
    });
    return res;
};

const buildRows = object => {
    return createRows(Utils.toKeySortedObject(object)).map((row, index) => {
        return (
            <div className="row" key={`row_${index}`}>
                <div className="nr">{row.number}</div>
                <div className="desc" style={{ paddingLeft: row.padding }}>
                    {row.desc}
                </div>
                <div className="value {type}">{row.value}</div>
                <br />
            </div>
        );
    });
};

const ObjectDialog = props => {
    return (
        <div className="dialog-content-wrapper display-object">
            <div className="content key-value">{buildRows(props.object)}</div>
        </div>
    );
};

ObjectDialog.propTypes = {
    object: PropTypes.object.isRequired,
};

export default ObjectDialog;
