import { addPrefix } from '../../utils/storeUtils';

export default addPrefix('shopping_basket', {
    SET_DEFAULTS: 'SET_DEFAULTS',
    SET_EVENTS: 'SET_EVENTS',
    REMOVE_EVENT: 'REMOVE_EVENT',
    REMOVE_ALL: 'REMOVE_ALL',
    CLEAR_FAILED_BOOKINGS: 'CLEAR_FAILED_BOOKINGS',
    SET_IS_EXPANDED: 'SET_IS_EXPANDED',
    SWITCH_TO_ADAPTER: 'SWITCH_TO_ADAPTER',
    LOAD_PRICES: 'LOAD_PRICES',
    LOAD_BETPAL_PRICES: 'LOAD_BETPAL_PRICES',
    LOAD_PRICES_SUCCESS: 'LOAD_PRICES_SUCCESS',
    LOAD_PRICES_FAIL: 'LOAD_PRICES_FAIL',
    BOOK_MATCHES: 'BOOK_MATCHES',
    BOOK_MATCHES_SUCCESS: 'BOOK_MATCHES_SUCCESS',
    BOOK_MATCHES_FAIL: 'BOOK_MATCHES_FAIL',
    DISMISS_ERRORS_DIALOG: 'DISMISS_ERRORS_DIALOG',
    CLOSE_BETPAL_BOOKING: 'CLOSE_BETPAL_BOOKING',
    BETPAL_BOOKING_COMPLETE: 'BETPAL_BOOKING_COMPLETE',
    SET_CONTENT_VARIANT: 'SET_CONTENT_VARIANT',
    SET_QUALITY: 'SET_QUALITY',
});
