import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import filterDescription from '../../selectors/filter/filterDescription';
import autobookingFilterDescription from '../../selectors/autobooking/autobookingFilterDescription';
import FontIcon from '../icons/FontIcon/FontIcon.jsx';
import classNames from 'classnames';
import './filterSelectionBox.scss';

class FilterSelectionBox extends React.Component {
    render() {
        let description = '',
            starIconTooltip;

        if (!_.isNull(this.props.filterSelection) || this.props.autobooking) {
            const parts = [...this.props.filterDescription];

            if (this.props.isDefault) {
                starIconTooltip =
                    'Default filter set (will be loaded at startup)';
            } else {
                starIconTooltip =
                    'Click to set as default filter set (will be loaded at startup)';
            }

            // add line breaks between parts
            description = [];
            _.each(parts, (part, i) => {
                description.push(part, <br key={`break${i}`} />);
            });
        }

        let starIcon = '';
        if (this.props.showStarIcon) {
            starIcon = (
                <FontIcon
                    className={
                        this.props.isDefault ? 'default-color' : 'is-clickable'
                    }
                    title={starIconTooltip}
                    icon={this.props.isDefault ? '' : ''}
                    onClick={this.props.starClickHandler}
                />
            );
        }

        let title = '...';
        const titleClassNames = classNames({
            placeholder: _.isEmpty(this.props.title),
            'default-Color': this.props.isDefault,
        });
        if (this.props.title) {
            title = this.props.title;
        }

        return (
            <div className="filter-selection">
                {this.props.children}
                <h4 data-cy={title}>
                    {starIcon}
                    <span className={titleClassNames}>{title}</span>
                </h4>
                {description}
            </div>
        );
    }
}

FilterSelectionBox.propTypes = {
    sports: PropTypes.object.isRequired,
    filterDescription: PropTypes.array.isRequired,
    filterSelection: PropTypes.shape({
        searchPhrase: PropTypes.array,
        selectedCheckboxes: PropTypes.array,
        selectedProducts: PropTypes.array,
        ncaaFilter: PropTypes.object,
    }),
    isDefault: PropTypes.bool,
    showStarIcon: PropTypes.bool,
    starClickHandler: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.any,
    autobooking: PropTypes.bool,
};

FilterSelectionBox.defaultProps = {
    title: '-',
    filterSelection: null,
    starClickHandler: null,
    showStarIcon: false,
};

const mapStateToProps = (state, ownProps) => ({
    sports: state.calendar.sports,
    filterDescription: ownProps.autobooking
        ? autobookingFilterDescription(state, ownProps)
        : filterDescription(state, ownProps),
});

export default connect(mapStateToProps)(FilterSelectionBox);
