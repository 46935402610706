import views from '../constants/views';
import { createSelector } from 'reselect';

export default createSelector(
    state => state.calendar.events,
    state => state.calendar.expandMasterEvents,
    state => state.calendar.expandMasterEventsExclusionList,
    state => state.calendar.groupsListCondensed,
    state => state.viewport.currentView,
    (
        events,
        expandMasterEvents,
        expandMasterEventsExclusionList,
        groupsListCondensed,
        currentView
    ) => {
        if (currentView !== views.VIEWPORT_TYPE_LIST_CONDENSED) {
            return groupsListCondensed;
        }

        const visibleMasterEventUris = [];
        for (const time in groupsListCondensed) {
            visibleMasterEventUris.push(
                ...groupsListCondensed[time].events
                    .map(event => event.uri)
                    .filter(uri => uri.includes('master'))
            );

            groupsListCondensed[time].events.map(
                event => (event.indented = false)
            );
        }

        const isMasterEventOpen = masterEventUri =>
            visibleMasterEventUris.includes(masterEventUri) &&
            expandMasterEvents ^
                expandMasterEventsExclusionList.includes(masterEventUri);

        const immutableListCondensedGroups = groupsListCondensed;
        const mutableListCondensedGroups = {};

        for (const time in immutableListCondensedGroups) {
            const immutableListCondensedGroup =
                immutableListCondensedGroups[time];

            const mutableListCondensedGroup = {
                tsGroup: immutableListCondensedGroup.tsGroup,
            };

            // remove events that appear inside an open master event.
            mutableListCondensedGroup.events = immutableListCondensedGroup.events.filter(
                event => !event.parent || !isMasterEventOpen(event.parent)
            );

            if (
                !mutableListCondensedGroup.events.some(event =>
                    event.uri.includes('master')
                )
            ) {
                mutableListCondensedGroups[time] = mutableListCondensedGroup;
                continue;
            }

            const eventChunkMap = {};

            for (const index in mutableListCondensedGroup.events) {
                const event = mutableListCondensedGroup.events[index];

                if (
                    event.uri.includes('master') &&
                    isMasterEventOpen(event.uri)
                ) {
                    eventChunkMap[event.uri] = getMasterEventMatches(
                        event,
                        events
                    );
                }
            }

            for (const uri in eventChunkMap) {
                eventChunkMap[uri].map(event => (event.indented = true));

                // add events after the master event position on the events array.
                const index = mutableListCondensedGroup.events.findIndex(
                    event => event.uri === uri
                );

                mutableListCondensedGroup.events.splice(
                    index + 1,
                    0,
                    ...eventChunkMap[uri]
                );
            }

            mutableListCondensedGroups[time] = mutableListCondensedGroup;
        }

        return mutableListCondensedGroups;
    }
);

function getMasterEventMatches(masterEvent, events) {
    const matchesByTournament = Object.values(masterEvent.me_matches);
    const masterEventMatches = [];

    for (const matches of matchesByTournament) {
        for (const uri of matches) {
            const match = events.find(event => event.uri === uri);

            if (typeof match === 'undefined') {
                console.warn(
                    `Undefined match (${uri}) of master event ` +
                        `(${masterEvent.uri}).`
                );
            } else {
                masterEventMatches.push(match);
            }
        }
    }

    return masterEventMatches;
}
