import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import longestMatchDuration from '../../selectors/sport/longestMatchDuration';
import { fetchEventsForDay } from '../../stores/calendar/actions';
import moment from '../../utils/CalendarMoment';
import RemoteData from '../RemoteData/RemoteData';
import './FilterEventMessage.scss';
import FilterEventMessageRenderer from './FilterEventMessageRenderer';

class FilterEventMessage extends React.Component {
    onDayClicked = mm => {
        this.props.fetchEventsForDay(mm, this.props.currentView);
    };

    parseWord = word => {
        if (word.match(/^[0-9]{6,}$/)) {
            if (this.props.visibleEvents.indexOf(`sr:match:${word}`) !== -1) {
                return '';
            }
            return `sr:match:${word}`;
        }

        if (word.match(/^sr:(match|stage):[0-9]+$/)) {
            if (this.props.visibleEvents.indexOf(word) !== -1) {
                return '';
            }
            return word;
        }
        return '';
    };

    render() {
        if (!this.props.freeTextSearchPhrase) {
            return '';
        }

        const searchCanBeEventId = this.props.freeTextSearchPhrase.find(
            word => {
                return (
                    word.match(/^[0-9]{6,}$/) || word.match(/^sr:\w+:[0-9]+$/)
                );
            }
        );

        if (!searchCanBeEventId || !searchCanBeEventId.length) {
            return (
                <FilterEventMessageRenderer
                    visibleEvents={this.props.visibleEvents}
                />
            );
        }

        const eventIds = [];
        this.props.freeTextSearchPhrase.forEach(word => {
            const parsedWord = this.parseWord(word);
            parsedWord.length && eventIds.push(parsedWord);
        });

        if (!eventIds.length) {
            return null;
        }

        const style = this.props.position
            ? { position: 'absolute', top: `${this.props.position}px` }
            : {};

        return (
            <RemoteData
                uri={`/find-events/${eventIds.join(',')}`}
                validatorFunction={() => true}
                caching={true}
                style={style}
            >
                <FilterEventMessageRenderer
                    visibleEvents={this.props.visibleEvents}
                    dayClickHandler={this.onDayClicked}
                    momentSelectedDay={this.props.momentSelectedDay}
                />
            </RemoteData>
        );
    }
}

FilterEventMessage.propTypes = {
    currentView: PropTypes.string.isRequired,
    freeTextSearchPhrase: PropTypes.array,
    momentSelectedDay: PropTypes.object.isRequired,
    longestMatchDuration: PropTypes.number.isRequired,
    fetchEventsForDay: PropTypes.func.isRequired,
    visibleEvents: PropTypes.array.isRequired,
    position: PropTypes.number,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    freeTextSearchPhrase: state.calendar.freeTextSearchPhrase,
    momentSelectedDay: moment.fromTimestamp(
        state.calendar.selectedDayTimestamp
    ),
    longestMatchDuration: longestMatchDuration(state.calendar),
});

const mapDispatchToProps = {
    fetchEventsForDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterEventMessage);
