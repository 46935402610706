import actionConstants from './constants';

const initialState = {
    currentPage: 0,
    totalPages: 1,
    itemsPerPage: 100,
};

export default (state, action) => {
    switch (action.type) {
        case actionConstants.SET_TOTAL_ITEMS:
            return {
                ...state,
                totalPages: Math.ceil(
                    action.payload.totalItems / state.itemsPerPage
                ),
            };

        case actionConstants.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload.currentPage,
            };

        default:
            return state || initialState;
    }
};
