export const dialogTypes = {
    LOAD_FILTER: 'LOAD_FILTER',
    SAVE_FILTER: 'SAVE_FILTER',
    EXPORT: 'EXPORT',
    EVENT_INFO: 'EVENT_INFO',
    EXTENDED_EVENT_INFO: 'EXTRA_MATCH_INFO',
    MASTER_INFO: 'MASTER_INFO',
    LIVE_CHANNEL_INFO: 'LIVE_CHANNEL_INFO',
    USER_SWITCHER: 'USER_SWITCHER',
    BETPAL_BOOKING: 'BETPAL_BOOKING',
    EVENT_OBJECT: 'EVENT_OBJECT',
    CONTROL_ODDS: 'CONTROL_ODDS',
    OVER_LIMIT: 'OVER_LIMIT',
    DELETE_TEXT_BANNER: 'DELETE_TEXT_BANNER',
    SAVE_AUTO_BOOKING: 'SAVE_AUTO_BOOKING',
    DELETE_AUTO_BOOKING: 'DELETE_AUTO_BOOKING',
    OVERWRITE_SAVED_FILTER_SET: 'OVERWRITE_SAVED_FILTER_SET',
    DELETE_FILTER_SET: 'DELETE_FILTER_SET',
    DELETE_FEATURE: 'DELETE_FEATURE',
    DELETE_ACCESS: 'DELETE_ACCESS',
    CHANGELOGS: 'CHANGELOGS',
    BULK_SELECT: 'BULK_SELECT',
    DELETE_CHANGELOG_ENTRY: 'DELETE_CHANGELOG_ENTRY',
    GET_LOGS: 'GET_LOGS',
    NOTIFICATION: 'NOTIFICATION',
    MATCH_CONFIG: 'MATCH_CONFIG',
    XHR_ERROR: 'XHR_ERROR',
    SAVE_ATTRIBUTES: 'SAVE_ATTRIBUTES',
    LOAD_ATTRIBUTES: 'LOAD_ATTRIBUTES',
    LEGEND: 'LEGEND',
};

export default {
    ADD_DIALOG: 'ADD_DIALOG',
    REMOVE_DIALOG: 'REMOVE_DIALOG',
    CLEAR_DIALOGS: 'CLEAR_DIALOGS',
};
