import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ContentEventInfo from './ContentEventInfo/ContentEventInfo';
import constants from '../../../../constants/Constants';
import views from '../../../../constants/views';
import Tabs from '../../../Tabs/Tabs.jsx';
import HoverInfo from './HoverInfo/HoverInfo.jsx';
import {
    isEventView,
    isRightColumnExpanded,
} from '../../../../selectors/viewport';
import { selectedSports } from '../../../../selectors/sport';
import scheduleOverviewSelector from '../../../../selectors/scheduleOverview';
import { setRightColumnStatus } from '../../../../stores/calendar/actions';
import './RightColumn.scss';

const { EXPANDED, COLLAPSED } = constants.SIDE_COLUMN_STATUS;

class RightColumn extends React.Component {
    render() {
        let content = '';

        if (
            this.props.currentView !== views.VIEWPORT_TYPE_LIST_CONDENSED &&
            this.props.currentView !== views.VIEWPORT_TYPE_TIMELINE_GADGET
        ) {
            return '';
        }

        // prepare content
        if (this.props.isRightColumnExpanded) {
            if (this.props.isScheduleOverviewHovered) {
                content = (
                    <HoverInfo
                        info={this.props.scheduleOverviewInfo}
                        sportsSorted={this.props.sportsSorted}
                        selectedSports={this.props.selectedSports}
                        sports={this.props.sports}
                    />
                );
            } else if (this.props.isEventView) {
                content = <ContentEventInfo />;
            }
        }

        let text = 'Event Info';
        if (this.props.eventUri) {
            switch (this.props.eventUri.split(':')[1]) {
                case 'match':
                    text = 'Match';
                    break;
                case 'stage':
                    text = 'Competition';
                    break;
                default:
                    text = 'Event';
            }

            text += ' Info';
        }

        const tabs = [
            {
                text,
                icon: '',
            },
        ];

        return (
            <div className="right-column">
                <Tabs
                    data={tabs}
                    collapsable={true}
                    collapsed={!this.props.isRightColumnExpanded}
                    isOnRightSide={true}
                    setCollapsedStatus={collapsed => {
                        const newStatus = collapsed ? COLLAPSED : EXPANDED;
                        this.props.setRightColumnStatus(newStatus);
                    }}
                />

                <div
                    className={classnames('right-column-content', {
                        hidden: !this.props.isRightColumnExpanded,
                    })}
                >
                    {content}
                </div>
            </div>
        );
    }
}

RightColumn.propTypes = {
    isScheduleOverviewHovered: PropTypes.bool.isRequired,
    scheduleOverviewInfo: PropTypes.object.isRequired,
    setRightColumnStatus: PropTypes.func.isRequired,
    size: PropTypes.shape({
        rightColumn: PropTypes.number,
        height: PropTypes.number,
    }),
    currentView: PropTypes.string.isRequired,
    isEventView: PropTypes.bool,
    isRightColumnExpanded: PropTypes.bool,
    sportsSorted: PropTypes.arrayOf(PropTypes.object),
    selectedSports: PropTypes.arrayOf(PropTypes.object),
    sports: PropTypes.object,
    eventUri: PropTypes.string,
};

const mapStateToProps = state => ({
    isScheduleOverviewHovered: state.scheduleOverview.isHovered,
    scheduleOverviewInfo: state.scheduleOverview.info,
    currentView: state.viewport.currentView,
    isEventView: isEventView(state.viewport.currentView),
    size: state.calendar.size,
    sports: state.calendar.sports,
    selectedSports: selectedSports(state),
    sportsSorted: scheduleOverviewSelector(state).sportsSorted,
    isRightColumnExpanded: isRightColumnExpanded(
        state.viewport.currentView,
        state.calendar.size.rightColumn
    ),
    eventUri: state.eventInfo.hoveredEventUri,
});

const mapDispatchToProps = {
    setRightColumnStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightColumn);
