// To view icon-font:
// Install src\fonts\Sportradar16-6-2.ttf and add 'Sportradar16-6-2' to font family in editor settings
const sportIds = [
    { id: 1, icon: '', name: 'Soccer' },
    { id: 2, icon: '', name: 'Basketball' },
    { id: 3, icon: '', name: 'Baseball' },
    { id: 4, icon: '', name: 'Ice Hockey' },
    { id: 5, icon: '', name: 'Tennis' },
    { id: 6, icon: '', name: 'Handball' },
    { id: 9, icon: '', name: 'Golf' },
    { id: 40, icon: '', name: 'Formula 1' },
    { id: 142, icon: '', name: 'Formula E' },
    { id: 190, icon: '', name: 'Moto GP' },
    { id: 191, icon: '', name: 'Nascar' },
    { id: 12, icon: '', name: 'Rugby' },
    { id: 13, icon: '', name: 'Aussie Rules' },
    { id: 16, icon: '', name: 'Football' },
    { id: 19, icon: '', name: 'Snooker' },
    { id: 20, icon: '', name: 'Table Tennis' },
    { id: 21, icon: '', name: 'Cricket' },
    { id: 22, icon: '', name: 'Darts' },
    { id: 23, icon: '', name: 'Volleyball' },
    { id: 24, icon: '', name: 'Field Hockey' },
    { id: 29, icon: '', name: 'Futsal' },
    { id: 31, icon: '', name: 'Badminton' },
    { id: 32, icon: '', name: 'Bowls' },
    { id: 34, icon: '', name: 'Beach Volley' },
    { id: 37, icon: '', name: 'Squash' },
    { id: 55, icon: '', name: 'Horse Racing' },
    { id: 71, icon: '', name: 'Padel' },
    { id: 109, icon: '', name: 'CS:GO' }, // TODO: replace placeholder icon
    { id: 110, icon: '', name: 'League of Legends' }, // TODO: replace placeholder icon
    { id: 111, icon: '', name: 'Dota 2' }, // TODO: replace placeholder icon
    { id: 128, icon: '', name: 'Rocket League' }, // TODO: replace placeholder icon
    { id: 138, icon: '', name: 'Kabaddi' },
    { id: 155, icon: '', name: 'Basketball 3x3' },
    { id: 137, icon: '', name: 'ESport Soccer' },
    { id: 153, icon: '', name: 'Esport Basketball' },
    { id: 195, icon: '', name: 'ESport Ice Hockey' }, // TODO: replace placeholder icon
    { id: 117, icon: '', name: 'MMA' },
    { id: 43, icon: '', name: 'Alpine Skiing' },
    { id: 48, icon: '', name: 'Ski Jumping' },
    { id: 44, icon: '', name: 'Biathlon' },
    { id: 26, icon: '', name: 'Waterpolo' },
    { id: 15, icon: '', name: 'Bandy' },
    { id: 17, icon: '', name: 'Cycling' },
    { id: 60, icon: '', name: 'Beach Soccer' },
];

export const stageNames = [
    { id: 0, name: 'Invalid' },
    { id: 1, name: 'Unset' },
    { id: 2, name: 'Season' },
    { id: 3, name: 'Event' },
    { id: 4, name: 'Practice' },
    { id: 5, name: 'Qualifying' },
    { id: 6, name: 'Qualifying part' },
    { id: 7, name: 'Race' },
    { id: 8, name: 'Lap' },
    { id: 9, name: 'Stage' },
    { id: 10, name: 'Prologue' },
    { id: 11, name: 'Country' },
    { id: 12, name: 'Discipline' },
    { id: 13, name: 'Run' },
    { id: 14, name: 'Final' },
    { id: 15, name: 'Small final' },
    { id: 16, name: 'Semi-final' },
    { id: 17, name: 'Consolation' },
    { id: 18, name: 'Placement' },
    { id: 19, name: 'Quarter-final' },
    { id: 20, name: '1/8th final' },
    { id: 21, name: 'Sport' },
    { id: 22, name: 'Round' },
    { id: 23, name: 'Hole' },
    { id: 24, name: 'Tour' },
];

export const simulatedCategories = [2123, 2157, 2158, 2246, 2128];

export default sportIds;
