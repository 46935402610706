import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Button from '../../../../../formInput/Button/Button';
import './ActionRow.scss';

class ActionRow extends Component {
    _getButtonListener(id) {
        return () => {
            this.props.onClick(id);
        };
    }

    _getButtons(buttons, color) {
        return buttons.map((button, index) => {
            return (
                button && (
                    <Button
                        key={`action_button_${index}`}
                        type={color}
                        icon={button.icon}
                        className="action-row-button"
                        onClick={this._getButtonListener(button.id).bind(this)}
                        fixedWidth={false}
                    >
                        {button.text}
                    </Button>
                )
            );
        });
    }

    render() {
        const { className, buttons, buttonColor: color } = this.props;

        return (
            <div className={classnames(className, 'action-row')}>
                {this._getButtons(buttons, color)}
            </div>
        );
    }
}

ActionRow.defaultProps = {
    className: '',
    buttonColor: Button.types.SMALL_GREY_SHADOW,
};

ActionRow.propTypes = {
    buttons: PropTypes.array.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    buttonColor: PropTypes.oneOf(Object.values(Button.types)),
};

export default ActionRow;
