import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../../../formInput/Button/Button';
import './AutobookerCopy.scss';
import Select from 'react-select';
import {
    importAutobookingRules,
    importAutobookingRulesFile,
} from '../../../../../../stores/autobooking/actions';
import Xhr from '../../../../../../utils/Xhr';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const AutobookerCopy = props => {
    const [sourceBookmaker, setSourceBookmaker] = useState('');
    const [sports, setSports] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(undefined);

    const sportsOptions = () => {
        const sportOptions = Object.values(props.sports)
            .filter(sport => !sport.isCompetitionSport)
            .map(sport => ({
                label: sport.name,
                value: sport.id,
            }));

        sportOptions.unshift({
            label: 'All',
            value: '',
        });

        return sportOptions;
    };

    const onChangeSport = newValue => {
        setSports(newValue);
    };

    const importRules = () => {
        props.importAutobookingRules(
            uploadedFile,
            props.importedAutobookingRules.response.tmpFile
        );
    };

    const downloadLink = () => {
        const apiPrefix = props.enableQuarkusApi ? 'lbc' : 'loweb/api';
        const bmIds = sourceBookmaker.split(',');
        const sportIDs = sports.map(sport => sport.value);
        return `${Xhr._getBaseUrl()}/${apiPrefix}/autobook/export?sportIds=${sportIDs}&bmId=${bmIds}`;
    };

    return (
        <div className="autobooker-copy">
            <div className="column">
                <div className="elements">
                    <div className="header">Export option</div>
                    <div className="subheader">Export Rules:</div>
                    <div className="item">
                        <label htmlFor="source">BMID(s):</label>
                        <div className="text-input">
                            <input
                                type="text"
                                id="source"
                                title="Bookmaker source"
                                placeholder="Bookmaker id"
                                value={sourceBookmaker}
                                onChange={e =>
                                    setSourceBookmaker(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="sports">Sports:</label>
                        <Select
                            options={sportsOptions()}
                            isMulti
                            closeMenuOnSelect={false}
                            name="sports"
                            className="select-ab"
                            classNamePrefix="select"
                            onChange={onChangeSport}
                        />
                    </div>
                    <div className="item">
                        <a
                            href={downloadLink()}
                            download
                            onClick={e => e.stopPropagation()}
                        >
                            <Button
                                type={Button.types.LARGE_BLUE_SHADOW}
                                fixedWidth={false}
                                disabled={false}
                                className="save-button"
                            >
                                Copy rules
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="elements">
                    <div className="header">Import option</div>
                    <div className="subheader">Import Rules:</div>
                    <div className="item">
                        <label htmlFor="source">Rules to import:</label>
                        <div className="text-input">
                            <input
                                type="file"
                                title="Bookmaker destination"
                                accept={'.csv'}
                                onChange={file => {
                                    setUploadedFile(file.target.files[0]);
                                    props.importAutobookingRulesFile(
                                        file.target.files[0]
                                    );
                                }}
                            />
                        </div>
                    </div>
                    {props.importedAutobookingRules &&
                    props.importedAutobookingRules.response.message === 'ok' ? (
                        <>
                            <div className="item">
                                Number of rules to be imported:{' '}
                                {props.importedAutobookingRules.response.rules}
                            </div>
                            <div className="item">
                                <Button
                                    type={Button.types.LARGE_BLUE_SHADOW}
                                    fixedWidth={false}
                                    disabled={false}
                                    className="save-button"
                                    onClick={() => importRules()}
                                >
                                    Import rules
                                </Button>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <div className="item">
                        {props.importedAutobookingRulesSuccess &&
                        props.importedAutobookingRulesSuccess.response
                            .message === 'ok' ? (
                            <div>
                                Rules imported:{' '}
                                {
                                    props.importedAutobookingRulesSuccess
                                        .response.importedRules
                                }
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

AutobookerCopy.propTypes = {
    sports: PropTypes.object.isRequired,
    importAutobookingRulesFile: PropTypes.func.isRequired,
    importAutobookingRules: PropTypes.func.isRequired,
    importedAutobookingRules: PropTypes.object,
    importedAutobookingRulesSuccess: PropTypes.object,
    enableQuarkusApi: PropTypes.bool,
};

const mapStateToProps = state => ({
    access: state.calendar.access,
    sports: state.calendar.sports,
    importedAutobookingRules: state.autobooking.importedAutobookingRules,
    importedAutobookingRulesSuccess:
        state.autobooking.importedAutobookingRulesSuccess,
    enableQuarkusApi:
        state.calendar.auth.userInfo.bookmakerInfo.features.autobook_group &&
        isMicrofrontend,
});

const mapDispatchToProps = {
    importAutobookingRulesFile,
    importAutobookingRules,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutobookerCopy);
