import { DateTime } from 'luxon';

let definedTimezone = null;

export const toISODate = date => {
    return date.toISOString().split('T')[0];
};

const CalendarMoment = {
    now: function () {
        // returns a function so that when a component calls it,
        // we get the moment when it is called in the component, not the time when this is run
        return DateTime.now().setZone(definedTimezone);
    },
    fromTimestamp: function (unixTimestamp) {
        return DateTime.fromSeconds(Number(unixTimestamp)).setZone(
            definedTimezone
        );
    },
    fromISO: function (isoTimeString) {
        return DateTime.fromISO(isoTimeString, { zone: definedTimezone });
    },
    fromArray: function (array) {
        // array containing year, month, day, hour, etc in order
        // can be cutoff whenever
        return DateTime.local(...array, { zone: definedTimezone });
    },
    setTimezone: function (timezone) {
        definedTimezone = timezone;
    },
    getTimezone: function () {
        return definedTimezone + ' ' + this.now().toFormat('ZZ');
    },
    getDefinedTimezone: function () {
        return definedTimezone;
    },

    monthNumberFromName: function (name) {
        return {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
        }[name];
    },
};

export default CalendarMoment;
