import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../../Confirmation/Confirmation';

const DeleteChangelogEntryDialog = props => {
    return (
        <Confirmation onConfirm={props.onConfirm} onReject={props.onClose}>
            Are you sure you want to delete this changelog entry?
        </Confirmation>
    );
};

DeleteChangelogEntryDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default DeleteChangelogEntryDialog;
