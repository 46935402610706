import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation/Confirmation';
import defaultSettings from '../../../../utils/DefaultSettings';
import { saveFilterSet } from '../../../../stores/calendar/actions';

const SaveFilterSetDialog = props => {
    const onConfirm = () => {
        props.onClose();
        const uri = defaultSettings.updateFilterUri.replace(
            '{id}',
            props.existingFilterSetWithSameNameId
        );
        props.saveFilterSet(uri, props.postData);
    };
    return (
        <Confirmation onConfirm={onConfirm} onReject={props.onClose}>
            There already exists a saved filter set with this name. Do you want
            to overwrite this set?
        </Confirmation>
    );
};

SaveFilterSetDialog.propTypes = {
    postData: PropTypes.object.isRequired,
    existingFilterSetWithSameNameId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    saveFilterSet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { saveFilterSet };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveFilterSetDialog);
