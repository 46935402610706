import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import constants from '../../../../constants/Constants';
import { BOOKING_STATUS_NAME } from '../../../../constants/shoppingBasket';
import Xhr from '../../../../utils/Xhr';
import Alert from '../../../Alert/Alert';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import Tabs from '../../../Tabs/Tabs';
import MatchQuickInfo from '../MatchQuickInfo/MatchQuickInfo';
import StageQuickInfo from '../StageQuickInfo/StageQuickInfo';
import './extendedEventInfoDialog.scss';
import { AV_PRODUCT, AV_PRODUCT_ID } from '../../../../constants/audioVisual';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class ExtendedEventInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            isLoading: true,
            error: false,
            errorMsg: '',
            bookingHistory: false,
        };
    }

    componentDidMount() {
        const bookingHistoryReq =
            isMicrofrontend && this.props.isBookingHistoryEnabledInQuarkus
                ? Xhr.backendRequestLbcQuarkus(
                      `/booking-history/${this.props.event.uri}`,
                      null,
                      'GET',
                      null,
                      false
                  )
                : Xhr.backendRequest(
                      `/booking-history/${this.props.event.uri}`,
                      null,
                      'GET',
                      null,
                      false
                  );
        bookingHistoryReq.then(
            response => {
                this.setState({
                    bookingHistory: response.response,
                    isLoading: false,
                });
            },
            error => {
                this.setState({
                    error: true,
                    errorMsg: error.message,
                });
            }
        );
    }

    render() {
        const quickInfo = this.props.event.uri.includes('match') ? (
            <MatchQuickInfo {...this.props} />
        ) : (
            <StageQuickInfo {...this.props} />
        );

        const tabs = [
            {
                text: 'Booking History',
                value: 1,
            },
            {
                text: 'Product Matrix',
                value: 2,
            },
        ];

        return (
            <div className="dialog-content-wrapper extra-info-dialog">
                <div className="content">
                    {quickInfo}
                    <Tabs
                        data={tabs}
                        selectedTab={this.getSelectedTab()}
                        onlyHorizontal={true}
                        onTabClicked={this.onTabClick.bind(this)}
                    />
                    {this.buildError()}
                    {!this.state.error ? this.buildPane() : ''}
                </div>
            </div>
        );
    }

    getSelectedTab() {
        return this.state.selectedTab;
    }

    onTabClick(adapterId) {
        this.setState({
            selectedTab: adapterId,
        });
    }

    formatCell(info, additionalInfo) {
        let cellText = info;
        if (additionalInfo) {
            cellText += ` (${additionalInfo})`;
        }
        return cellText;
    }

    formatPackage(name, id, subPackage, restriction) {
        return (
            <div>
                {name} (<span title="Package ID">{id || '-'}</span>,
                <span title="Subpackage ID">{subPackage || '-'}</span>,
                <span title="Restriction ID">{restriction || '-'}</span>)
            </div>
        );
    }

    buildBookingHistory() {
        if (!this.state.bookingHistory.length) {
            return (
                <div className="tab-pane">
                    This match has never been booked.
                </div>
            );
        }
        const history = this.state.bookingHistory;
        const rows = [];
        let i = 0;
        history.forEach(rowData => {
            const packageInfo = this.formatPackage(
                rowData.packageName,
                rowData.packageId,
                rowData.subPackageId,
                rowData.restrictionId
            );

            const sourceInfo = this.formatCell(
                this.getSourceName(rowData.productType, rowData.sourceId),
                rowData.sourceId
            );

            const status = this.getBookingStatusText(
                rowData.productType,
                rowData.bookingStatusId
            );

            let invoiceable = 'No';
            if (this.invoiceableBooking) {
                if (this.invoiceableMatch) {
                    invoiceable = 'Yes';
                } else {
                    invoiceable = 'Only Booking';
                }
            } else if (this.invoiceableMatch) {
                invoiceable = 'Only Match';
            }

            rows.push(
                <tr key={i}>
                    <td>{rowData.bookingId}</td>
                    <td>{rowData.productType.toUpperCase()}</td>
                    <td>{status}</td>
                    <td>{rowData.dateChanged}</td>
                    <td>{packageInfo}</td>
                    <td>{rowData.userId}</td>
                    <td>{sourceInfo}</td>
                    <td>{invoiceable}</td>
                    <td>{rowData.hasPrice ? 'Extra Fee' : 'In Package'}</td>
                </tr>
            );
            i++;
        });
        return (
            <div className="tab-pane">
                <table className={'product-history'}>
                    <colgroup>
                        <col className="col-id" />
                        <col className="col-product" />
                        <col className="col-action" />
                        <col className="col-when" />
                        <col className="col-package" />
                        <col className="col-user" />
                        <col className="col-source" />
                        <col className="col-invoiceable" />
                        <col className="col-price" />
                    </colgroup>
                    <thead>
                        <tr key="header">
                            <th>ID</th>
                            <th>Product</th>
                            <th>Action</th>
                            <th>Date Changed</th>
                            <th>Package</th>
                            <th>User ID</th>
                            <th>Source</th>
                            <th>Invoiceable</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        );
    }

    buildMatrix() {
        const products = this.props.event.products;
        const headers = [];
        const rows = [];
        const allProducts = {
            lo: 'Live Odds',
            ld: 'Live Data',
            lcr: 'Live Channel Retail',
            lct: 'Live Channel Trading',
            lco: 'Live Channel Online',
            bp: 'Betpal',
        };

        if (this.props.isLcpEnabled) {
            allProducts.lcp = AV_PRODUCT.FULLNAME[AV_PRODUCT_ID.LCP];
        }

        for (const key in allProducts) {
            headers.push(<th key={key}>{allProducts[key]}</th>);
            let variant = '';
            if (products[key]) {
                if (
                    Object.keys(products).some(product =>
                        product.includes(`${key}-4sight`)
                    )
                ) {
                    variant = `${key}-4sight`;
                } else if (
                    Object.keys(products).some(product =>
                        product.includes(`${key}-as`)
                    )
                ) {
                    variant = `${key}-as`;
                }

                const addOn = products[key]?.addOns?.find(
                    addOn => addOn.name === 'HIGH_QUALITY'
                );

                const quality =
                    addOn?.value === 'true'
                        ? 'High Quality'
                        : addOn?.value === 'false'
                        ? 'Standard Quality'
                        : '';

                if (variant.length > 0) {
                    let bookingStatusName =
                        BOOKING_STATUS_NAME[products[key].bookingStatusId];
                    let bookingStatusClass =
                        this.props.isNewProductsUIEnabled &&
                        products[key].isBooked
                            ? getBookingClassname(this.props, products[key])
                            : bookingStatusName.toLowerCase().replace(' ', '-');
                    let variantBookingStatusName =
                        BOOKING_STATUS_NAME[products[variant].bookingStatusId];
                    let variantBookingStatusClass =
                        this.props.isNewProductsUIEnabled &&
                        products[variant].isBooked
                            ? getBookingClassname(this.props, products[variant])
                            : variantBookingStatusName
                                  .toLowerCase()
                                  .replace(' ', '-');

                    rows.push(
                        <td style={{ padding: 0 }} key={`${key}-${variant}`}>
                            <div
                                style={{
                                    padding: '5px',
                                    borderBottom: '1px solid black',
                                }}
                                key={key}
                                className={bookingStatusClass}
                            >
                                {`Standard feed ${
                                    this.props.isHighQualityEnabled
                                        ? ` (${quality})`
                                        : ''
                                }: ${bookingStatusName}`}
                            </div>
                            <div
                                style={{ padding: '5px' }}
                                key={variant}
                                className={variantBookingStatusClass}
                            >
                                {constants.AV_4SIGHT} feed:{' '}
                                {variantBookingStatusName}
                            </div>
                        </td>
                    );
                } else {
                    let bookingStatusName =
                        BOOKING_STATUS_NAME[products[key].bookingStatusId];
                    let bookingStatusClass =
                        this.props.isNewProductsUIEnabled &&
                        products[key].isBooked
                            ? getBookingClassname(this.props, products[key])
                            : bookingStatusName.toLowerCase().replace(' ', '-');
                    rows.push(
                        <td key={key} className={bookingStatusClass}>
                            {this.props.isHighQualityEnabled &&
                                quality &&
                                `${quality}:`}{' '}
                            {bookingStatusName}
                        </td>
                    );
                }
            } else {
                rows.push(
                    <td className="inactive" key={key}>
                        Not available
                    </td>
                );
            }
        }

        return (
            <div className="tab-pane">
                <table>
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        <tr>{rows}</tr>
                    </tbody>
                </table>
            </div>
        );

        function getBookingClassname(props, product) {
            const { showAsBookedPrimary, showAsBookedSecondary } = props;
            const { type } = product;

            if (type.toLowerCase() === showAsBookedPrimary.toLowerCase()) {
                return 'booked-primary';
            }

            if (type.toLowerCase() === showAsBookedSecondary.toLowerCase()) {
                return 'booked-secondary';
            }

            return '';
        }
    }

    buildPane() {
        if (this.state.isLoading) {
            return <BusySpinner />;
        }

        switch (this.state.selectedTab) {
            case 1:
                return this.buildBookingHistory();
            case 2:
                return this.buildMatrix();
            default:
                return (
                    <div className="tab-pane">
                        <div>Placeholder </div>
                        <div>{this.state.selectedTab}</div>
                    </div>
                );
        }
    }

    buildError() {
        if (!this.state.error) {
            return '';
        }
        return (
            <div className="content">
                <Alert type={Alert.types.ERROR} float={false}>
                    {this.state.errorMsg}
                </Alert>
            </div>
        );
    }

    getBookingStatusText(productType, bookingStatusId) {
        const actions = {
            lo: {
                0: 'Booked',
                1: 'Changed',
                2: 'Deleted',
            },
            lco: {
                '-1': 'Unbooked',
                0: 'Not set',
                1: 'Bookable',
                2: 'Booked',
                3: 'Buyable',
                4: 'Not Available',
            },
        };
        actions.lct =
            actions.lcr =
            actions.bp =
            actions['lco-4sight'] =
            actions['lct-4sight'] =
            actions['lcr-4sight'] =
            actions['lco-as'] =
            actions['lct-as'] =
            actions['lcr-as'] =
                actions.lco;
        actions.ld = actions['lo/ld'] = actions.lo;
        return actions[productType][bookingStatusId] || 'Unknown';
    }

    getSourceName(productType, sourceId) {
        const sources = {
            lo: {
                0: 'Unknown',
                2: 'Liveodds XML source',
                3: 'SMP Autobooker',
                6: 'Betpal',
                7: 'LBC Manual',
                12: 'LBC Autobooker',
                13: 'Recalculation',
                14: 'SMP Manual',
                15: 'Cloud Autobooker',
            },
            lco: {
                0: 'Unknown',
                1: 'LBC Manual',
                2: 'AV Autobooker',
                3: 'SMP autobooker',
                4: 'Booking service',
                5: 'LBC autobooker',
                6: 'AV Master Event Autobooker',
            },
        };
        sources.lct =
            sources.lcr =
            sources['lco-4sight'] =
            sources['lct-4sight'] =
            sources['lcr-4sight'] =
            sources['lco-as'] =
            sources['lct-as'] =
            sources['lcr-as'] =
                sources.lco;
        sources.ld = sources['lo/ld'] = sources.lo;
        return sources[productType][sourceId] || 'Unknown';
    }
}

ExtendedEventInfoDialog.propTypes = {
    event: PropTypes.object.isRequired,
    showAsBooked: PropTypes.string,
    showAsBookedPrimary: PropTypes.string,
    showAsBookedSecondary: PropTypes.string,
    isNewProductsUIEnabled: PropTypes.bool,
    isHighQualityEnabled: PropTypes.bool.isRequired,
    isBookingHistoryEnabledInQuarkus: PropTypes.bool,
    isLcpEnabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    showAsBooked: state.calendar.showAsBooked,
    showAsBookedPrimary: state.calendar.showAsBookedPrimary,
    showAsBookedSecondary: state.calendar.showAsBookedSecondary,
    isNewProductsUIEnabled:
        state.viewport.selectedVersion === 'new-version' ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
    isHighQualityEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_av_high_quality,
    isBookingHistoryEnabledInQuarkus:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_event_booking_history,
    isLcpEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.enable_av_lcp,
});

export default connect(mapStateToProps, null)(ExtendedEventInfoDialog);
