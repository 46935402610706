import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../../../Alert/Alert';
import Button from '../../../formInput/Button/Button';
import TextInput from '../../../formInput/TextInput/TextInput';
import DropDown from '../../../formInput/Dropdown/DropDown';
import defaultSettings from '../../../../utils/DefaultSettings';
import { saveAttributeSet } from '../../../../stores/autobookingSave/actions';
import FilterSelectionBox from '../../../FilterSelectionBox/FilterSelectionBox';
import { getSavedAttributeSets } from '../../../../utils/autobookingUtils';
import './saveAttributeSelectionDialog.scss';

class SaveAttributeSelectionDialog extends Component {
    constructor(props) {
        super(props);

        this.defaultDropdownOption = '- select -';
        this.state = {
            userInputString: '',
            selectedDropdownOption: this.defaultDropdownOption,
            savedAttributeSets: Object.keys(getSavedAttributeSets()),
        };
    }

    _attributeNameChange(value) {
        this.setState({
            userInputString: value,
            selectedDropdownOption: this.defaultDropdownOption,
        });
    }

    _onSelectChange(value) {
        this.setState({
            userInputString: '',
            selectedDropdownOption: value,
        });
    }

    _submitForm() {
        const currentAttributeSetName = this._getCurrentAttributeSetName(),
            existingAttributeSetWithSameName =
                this.state.savedAttributeSets.includes(currentAttributeSetName);

        const validationStatus = this._validateName(currentAttributeSetName);
        if (!validationStatus.isValid) {
            alert(validationStatus.message);
            return;
        }

        this._saveAttributeSet(currentAttributeSetName);
    }

    _getCurrentAttributeSetName() {
        const { userInputString, selectedDropdownOption } = this.state;
        return !selectedDropdownOption ||
            selectedDropdownOption === this.defaultDropdownOption
            ? userInputString
            : selectedDropdownOption;
    }

    _saveAttributeSet(currentAttributeSetName) {
        this.props.saveAttributeSet(
            currentAttributeSetName,
            this.props.dataToSave
        );
        this.props.onClose();
    }

    _onKeyDown(e) {
        if (e.keyCode === 13) {
            // ENTER
            this._submitForm();
        }
    }

    _close() {
        this.props.onClose();
    }

    _buildSelect() {
        const options = this.state.savedAttributeSets.map(name => ({
            value: name,
            text: name,
        }));

        options.unshift({
            value: null,
            text: this.defaultDropdownOption,
        });

        return (
            <div className="flex">
                <DropDown
                    onChange={this._onSelectChange.bind(this)}
                    value={this.state.selectedDropdownOption}
                    options={options}
                    label={'Update existing set:'}
                />
                <span>or</span>
            </div>
        );
    }

    _validateName(name) {
        const response = {
            isValid: false,
            message: '',
        };
        if (!name.length) {
            response.message = 'Attribute name cannot be empty';
            return response;
        }
        if (name.length > 50) {
            response.message =
                'Attribute name cannot be more than 50 characters';
            return response;
        }
        if (name === defaultSettings.defaultAttributeSetName) {
            response.message = `Attribute name cannot be ${name}`;
            return response;
        }
        response.isValid = true;
        return response;
    }

    render() {
        const showError = this.state.userInputString.length,
            validationStatus = this._validateName(
                this._getCurrentAttributeSetName()
            );

        return (
            <div className="dialog-content-wrapper save-attribute-selection">
                <div className="sub-header">
                    <Alert type={Alert.types.EXPLANATORY} icon="" float={false}>
                        <p>
                            By saving and loading your attributes you can easily
                            switch between different selections.
                        </p>
                        <p>
                            After saving below, open the{' '}
                            <strong>Load Attributes</strong> dialog to manage
                            and load your saved sets.
                        </p>
                    </Alert>
                </div>
                <div className="content">
                    <FilterSelectionBox
                        title={this._getCurrentAttributeSetName()}
                        autobooking={true}
                    />
                    <div className="inputs">
                        {this._buildSelect()}
                        <div className="flex">
                            <label
                                htmlFor="attributeName"
                                className="form-label"
                            >
                                Create new set:
                            </label>
                            <TextInput
                                onChange={this._attributeNameChange.bind(this)}
                                value={this.state.userInputString}
                                disabled={
                                    this.state.selectedDropdownOption !==
                                    this.defaultDropdownOption
                                }
                                invalid={Boolean(
                                    showError && !validationStatus.isValid
                                )}
                                onKeyDown={this._onKeyDown.bind(this)}
                                autoFocus={true}
                            />
                            {showError ? (
                                <div className="error-feedback">
                                    {validationStatus.message}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <Button
                        onClick={this._close.bind(this)}
                        type={Button.types.LARGE_GREY_SHADOW}
                        icon={''}
                        fixedWidth={false}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this._submitForm.bind(this)}
                        type={Button.types.LARGE_BLUE_SHADOW}
                        icon={''}
                        disabled={!validationStatus.isValid}
                        fixedWidth={false}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

SaveAttributeSelectionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    saveAttributeSet: PropTypes.func.isRequired,
    dataToSave: PropTypes.object,
};

const mapStateToProps = state => ({
    dataToSave: {
        selectedFilters: state.autobooking.selectedFilters,
        filterOrder: state.autobooking.filterOrder,
        disabledFilters: state.autobooking.disabledFilters,
    },
});

const mapDispatchToProps = {
    saveAttributeSet,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveAttributeSelectionDialog);
