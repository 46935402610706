import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import CheckBoxIcon from '../../icons/CheckBoxIcon/CheckBoxIcon.jsx';
import './multiSelectDropdown.scss';

const MultiSelectDropdown = props => {
    const [showOptions, setShowOptions] = useState(false);
    const focusRoot = useRef(null);

    const selectedOptions = props.selectedOptions || [];

    const _getOptionName = option => {
        const name = props.optionNames
            ? props.optionNames[option] || 'error' // would use undefined but that's actually an option for age groups
            : option;
        return name.toUpperCase();
    };

    const _onOptionClicked = option => {
        const optionStr = option.toString();
        const index = selectedOptions.findIndex(
            opt => opt.toString() === optionStr
        );
        if (index !== -1) {
            selectedOptions.splice(index, 1);
        } else {
            selectedOptions.push(option);
        }
        props.onChange(selectedOptions);
    };

    const _renderOption = option => {
        return (
            <li
                className="ui-400 text-gray-1100 text-left"
                key={option}
                onClick={() => _onOptionClicked(option)}
            >
                <CheckBoxIcon
                    selected={
                        selectedOptions.findIndex(
                            opt => opt.toString() === option.toString()
                        ) !== -1
                    }
                />
                {_getOptionName(option)}
            </li>
        );
    };

    const _getSelectedOptionsString = () => {
        if (!selectedOptions.length) {
            return props.placeholder || 'Select...';
        }
        return selectedOptions.map(option => _getOptionName(option)).join(', ');
    };

    return (
        <div
            className="multi-select-dropdown"
            ref={focusRoot}
            onBlur={event =>
                !focusRoot.current.contains(event.relatedTarget) &&
                setShowOptions(false)
            }
        >
            <button
                className={classnames('list-400', {
                    placeholder: !selectedOptions.length,
                    test: true,
                })}
                onClick={() => setShowOptions(!showOptions)}
            >
                {_getSelectedOptionsString()}
            </button>

            {showOptions && (
                <ul tabIndex="-1">
                    {props.options.map(option => _renderOption(option))}
                </ul>
            )}
        </div>
    );
};

MultiSelectDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    optionNames: PropTypes.object,
    selectedOptions: PropTypes.array,
    placeholder: PropTypes.string,
};
export default MultiSelectDropdown;
