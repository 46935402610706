import changelogConstants from './constants';
import moment from '../../utils/CalendarMoment';

const initialState = {
    currentlySavingEntryId: null,
    entries: [],
};

export default (state, action) => {
    switch (action.type) {
        case changelogConstants.FETCH:
            return {
                ...state,
                entries: action.payload,
            };
        case changelogConstants.START_CREATION:
            return {
                ...state,
                currentlySavingEntryId: 'new',
            };
        case changelogConstants.CREATE:
            return {
                currentlySavingEntryId: null,
                entries: [
                    {
                        id: action.payload.id,
                        dateCreated: action.payload.dateCreated,
                        description: action.payload.description,
                        releaseDate: action.payload.releaseDate,
                        releaseDateTime: action.payload.releaseDate
                            ? moment
                                  .fromTimestamp(action.payload.releaseDate)
                                  .toFormat('HH:mm')
                            : null,
                        featureId: parseInt(action.payload.featureId, 10),
                    },
                    ...state.entries,
                ],
            };
        case changelogConstants.START_UPDATE:
            return {
                ...state,
                currentlySavingEntryId: action.payload,
            };
        case changelogConstants.UPDATE:
            return {
                currentlySavingEntryId: null,
                entries: state.entries.map(entry =>
                    entry.id === action.payload.id ? action.payload : entry
                ),
            };
        case changelogConstants.DELETE:
            return {
                ...state,
                entries: state.entries.filter(
                    entry => entry.id !== action.payload
                ),
            };
        default:
            return state || initialState;
    }
};
