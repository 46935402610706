import React from 'react';
import PropTypes from 'prop-types';
import Component from './Component';
import RemoteData from '../../../RemoteData/RemoteData';
import settings from '../../../../utils/DefaultSettings';

const SaveFilterSelectionDialog = props => {
    return (
        <RemoteData
            key="formSaveFilterSelection"
            uri={settings.remoteData.filter.uri}
            caching={settings.remoteData.filter.caching}
            validatorFunction={() => true}
        >
            <Component onClose={props.onClose} />
        </RemoteData>
    );
};

SaveFilterSelectionDialog.propTypes = {
    onClose: PropTypes.func,
};

export default SaveFilterSelectionDialog;
