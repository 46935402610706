import {
    hasProduct,
    isConsideredBooked,
    tooLateToBook,
} from '../../utils/basketUtils';
import selectedAdapter from './selectedAdapter';
import matchesSorted from './matchesSorted';
import canBookWithCurrentAdapter from './canBookWithCurrentAdapter';

const hasUnbookedEventWithoutPrice = (
    eventsSorted,
    prices,
    selectedAdapterId
) => {
    for (const event of eventsSorted) {
        if (
            !prices.hasOwnProperty(event.uri) &&
            hasProduct(event.products, selectedAdapterId) &&
            !isConsideredBooked(event.products, selectedAdapterId) &&
            !tooLateToBook(event)
        ) {
            return true;
        }
    }
    return false;
};

const hasPriceForEventNotInBasket = (eventsSorted, prices) => {
    for (const eventUri in prices) {
        if (!prices.hasOwnProperty(eventUri)) {
            continue;
        }

        if (!eventsSorted.find(event => event.uri === eventUri)) {
            return true;
        }
    }

    return false;
};

export default shoppingBasketStoreState => {
    const adapter = selectedAdapter(shoppingBasketStoreState);
    if (adapter.isLoading || adapter.error) {
        return false;
    }

    if (!canBookWithCurrentAdapter(shoppingBasketStoreState)) {
        return false;
    }

    const prices =
            shoppingBasketStoreState.pricesByAdapterId[
                shoppingBasketStoreState.selectedAdapterId
            ],
        basketEventsSorted = matchesSorted(shoppingBasketStoreState);

    return (
        !prices ||
        hasUnbookedEventWithoutPrice(
            basketEventsSorted,
            prices,
            shoppingBasketStoreState.selectedAdapterId
        ) ||
        hasPriceForEventNotInBasket(basketEventsSorted, prices)
    );
};
