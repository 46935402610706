import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hasTextBanners from '../../../../selectors/viewport/hasTextBanners';
import textBannerIsForDay from '../../../../selectors/textBannerIsForDay';
import TextBanner, { bannerTypes } from '../../../TextBanner/TextBanner';
import './banners.scss';

const Banners = props => {
    let textBanners = [];
    const _buildTextBanners = props => {
        if (props.textBanners.length === 0) {
            return [];
        }

        return props.textBanners
            .filter(textBanner => textBanner.type !== bannerTypes.POPUP)
            .map((banner, itemIndex) => {
                if (textBannerIsForDay(props.selectedDayTimestamp, banner)) {
                    return (
                        <TextBanner
                            title={banner.title}
                            subTitle={banner.subTitle}
                            type={parseInt(banner.type, 10)}
                            key={banner.id}
                        />
                    );
                }
                return '';
            });
    };

    if (props.viewHasTextBanners) {
        textBanners = _buildTextBanners(props);
    }

    return textBanners.length ? (
        <div className="banners"> {textBanners} </div>
    ) : (
        ''
    );
};

Banners.propTypes = {
    textBanners: PropTypes.array.isRequired,
    viewHasTextBanners: PropTypes.bool,
    selectedDayTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
    textBanners: state.textBanner.textBanners,
    textBannerState: state.textBanner,
    viewHasTextBanners: hasTextBanners(state.viewport),
    selectedDayTimestamp: state.calendar.selectedDayTimestamp,
});

export default connect(mapStateToProps)(Banners);
