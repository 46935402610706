import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Word from './Word';
import './multiWordInput.scss';

class MultiWordInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWord: '',
            inputFocused: false,
        };
        this._onInputChange = this._onInputChange.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
    }

    _onInputChange(e) {
        this.setState({
            currentWord: e.target.value,
        });
    }

    _onAddWord() {
        const currentWord = this.state.currentWord.trim();
        if (currentWord !== '') {
            this.props.onChange([
                ...this.props.words,
                ...this._splitWords(currentWord),
            ]);
            this.setState({
                currentWord: '',
            });
        }
    }

    _splitWords(search) {
        const words = [];
        search = search
            // remove newlines
            .replace(/(\r\n|\n|\r)/gm, '')
            // remove double spaces
            .replace(/\s{2,}/g, ' ')
            // Capture strings encapsulated in quotes. Replace with empty in search.
            .replaceAll(/"(.*?)"/g, function (a, b) {
                words.push(b);
                return '';
            })
            // Remove all spaces adjacent to commas
            .replace(/\s*,\s*/g, ',')
            // Remove 2+ commas after capture/replace
            .replace(/,+/g, ',')
            // Remove comma at end of line
            .replace(/,$/g, '')
            // Remove comma at start of line
            .replace(/^,/g, '')
            .trim();

        if (search.length > 2) {
            // split remaining words
            search = search.split(',');
        } else {
            search = [];
        }

        // Join captured phrases with other searches
        return [...words, ...search];
    }

    _onKeyDown(e) {
        const KEY_ESC = 27;
        const KEY_ENTER = 13;
        const KEY_TAB = 9;
        const KEY_BACKSPACE = 8;

        switch (e.keyCode) {
            case KEY_ENTER:
            case KEY_TAB:
                this._onAddWord();
                e.preventDefault();
                e.stopPropagation();
                break;
            case KEY_ESC:
                e.target.blur();
                break;
            case KEY_BACKSPACE:
                if (
                    this.state.currentWord === '' &&
                    this.props.words.length !== 0
                ) {
                    const newWordsList = [...this.props.words];
                    newWordsList.splice(this.props.words.length - 1, 1);
                    this.props.onChange(newWordsList);
                }
                break;
            default:
                break;
        }
    }

    _buildWords() {
        return this.props.words.map((word, index) => {
            return (
                <Word
                    key={index}
                    text={word}
                    onClose={() => {
                        const newWordsList = [...this.props.words];
                        newWordsList.splice(index, 1);
                        this.props.onChange(newWordsList);
                    }}
                />
            );
        });
    }

    render() {
        return (
            <div className="multi-word-input">
                <div className="vertical-center">{this.props.labelText}</div>
                <div
                    className={classnames('input', {
                        outline: this.state.inputFocused,
                    })}
                >
                    {this._buildWords()}
                    <input
                        type="text"
                        value={this.state.currentWord}
                        onChange={this._onInputChange}
                        onKeyDown={this._onKeyDown}
                        autoFocus
                        onFocus={() => this.setState({ inputFocused: true })}
                        onBlur={() => this.setState({ inputFocused: false })}
                        ref={this.props.inputRef}
                    />
                </div>
            </div>
        );
    }
}

MultiWordInput.defaultProps = {
    labelText: null,
};

MultiWordInput.propTypes = {
    words: PropTypes.array.isRequired,
    labelText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.object,
};

export default MultiWordInput;
