import featureConstants from './constants';

const initialState = {
    currentlySavingFeatureId: null,
    features: [],
};

export default (state, action) => {
    switch (action.type) {
        case featureConstants.FETCH:
            return {
                ...state,
                features: action.payload,
            };
        case featureConstants.START_CREATION:
            return {
                ...state,
                currentlySavingFeatureId: 'new',
            };
        case featureConstants.CREATE:
            return {
                currentlySavingFeatureId: null,
                features: [
                    ...state.features,
                    {
                        id: action.payload.insertId,
                        dateCreated: 'Just now',
                        title: action.payload.title,
                        description: action.payload.description,
                        value: action.payload.value,
                        isEnabled: action.payload.isEnabled,
                    },
                ],
            };
        case featureConstants.START_UPDATE:
            return {
                ...state,
                currentlySavingFeatureId: action.payload,
            };
        case featureConstants.UPDATE:
            return {
                currentlySavingFeatureId: null,
                features: state.features.map(feature =>
                    feature.id === action.payload.id ? action.payload : feature
                ),
            };
        case featureConstants.DELETE:
            return {
                ...state,
                features: state.features.filter(
                    feature => feature.id !== action.payload
                ),
            };
        default:
            return state || initialState;
    }
};
