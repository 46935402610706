import types from './constants';
import moment from '../../utils/CalendarMoment';

const initialState = {
    data: {},
    meta: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.START:
            return {
                ...state,
                meta: {
                    [action.payload.uri]: {
                        loading: true,
                        error: false,
                        loadedAt: null,
                    },
                },
            };
        case types.SUCCESS:
            return {
                ...state,
                meta: {
                    [action.payload.uri]: {
                        loading: false,
                        error: false,
                        loadedAt: moment.now().toFormat('X'),
                    },
                },
                data: {
                    [action.payload.uri]: action.payload.response,
                },
            };
        case types.ERROR:
            return {
                ...state,
                meta: {
                    [action.payload.uri]: {
                        loading: false,
                        error: action.payload.error,
                        loadedAt: moment.now().toFormat('X'),
                    },
                },
            };
        default:
            return state || initialState;
    }
};
