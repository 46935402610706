import React from 'react';
import PropTypes from 'prop-types';
import './FontIcon.scss';

const FontIcon = ({
    title = '',
    icon = '�',
    size = 'sm',
    color,
    className = '',
    onClick = () => {},
}) => {
    const classes = ['font-icon', `size-${size}`, className];
    if (color) classes.push(color);

    return (
        <div
            title={title}
            className={classes.join(' ')}
            aria-hidden="true"
            onClick={onClick}
        >
            {icon}
        </div>
    );
};

FontIcon.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default FontIcon;
