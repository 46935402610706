import types from './constants';

const initialState = {
    isHovered: false,
    info: {},
    leftOffset: 0,
    gadgetEnabled: true,
};

export default (state, action) => {
    switch (action.type) {
        case types.UPDATE_INFO:
            return {
                ...state,
                info: action.payload.info,
                isHovered: true,
            };
        case types.MOUSE_ENTER:
            return {
                ...state,
                isHovered: true,
            };
        case types.MOUSE_LEAVE:
            return {
                ...state,
                isHovered: false,
            };
        case types.SET_LEFT_OFFSET:
            return {
                ...state,
                leftOffset: action.payload.leftOffset,
            };
        case types.SET_GADGET_ENABLED:
            return {
                ...state,
                gadgetEnabled: action.payload.gadgetEnabled,
            };
        default:
            return state || initialState;
    }
};
