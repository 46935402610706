import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../../Confirmation/Confirmation';

const DeleteAccessDialog = props => {
    return (
        <Confirmation onConfirm={props.onConfirm} onReject={props.onClose}>
            Are you sure you want to delete this access?
        </Confirmation>
    );
};

DeleteAccessDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default DeleteAccessDialog;
