import {
    PdsButton,
    PdsTextField,
} from '@podium-design-system/react-components';
import {
    PdsBookmarkIcon,
    PdsEditIcon,
} from '@podium-design-system/react-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Xhr from '../../../../../../../utils/Xhr';
import './PricingSetup.scss';

const PricingSetup = ({ access }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [linkText, setLinkText] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const hasWriteAccess =
        access.developer_write || access.admin_write || access.support_write;

    const isValidURL = value => {
        console.log('value', value);
        try {
            new URL(value);
            return true;
        } catch {
            return false;
        }
    };

    const handleEditClick = () => {
        if (isEditing) {
            if (isValidURL(inputValue)) {
                setLinkText(inputValue);
                setError('');
                Xhr.backendRequestLbcQuarkus(
                    '/support/package-setup-url',
                    null,
                    'POST',
                    { url: inputValue }
                );
            } else {
                setError('Please enter a valid URL.');
                return;
            }
        }

        setIsEditing(!isEditing);
    };

    useEffect(() => {
        Xhr.backendRequestLbcQuarkus('/support/package-setup-url').then(
            response => {
                setLinkText(response.url);
            }
        );
    }, []);

    return (
        <div className="pricing-setup-wrapper">
            <div
                className={`transition-wrapper ${
                    isEditing ? 'fade-out' : 'fade-in'
                }`}
            >
                {!isEditing && (
                    <PdsButton size="lg" className="pricing-setup-btn">
                        <a href={linkText} target="_blank" rel="noreferrer">
                            Pricing Setup
                        </a>
                    </PdsButton>
                )}
            </div>

            <div
                className={`transition-wrapper ${
                    isEditing ? 'fade-in' : 'fade-out'
                }`}
            >
                {isEditing && (
                    <>
                        <PdsTextField
                            placeholder="Enter new confluence link "
                            value={inputValue}
                            onPdsChange={e => setInputValue(e.target.value)}
                            type="url"
                        />
                        {error && <p className="error-message">{error}</p>}
                    </>
                )}
            </div>

            {hasWriteAccess && (
                <PdsButton
                    rank="ghost"
                    onClick={handleEditClick}
                    color="brand-secondary"
                    className="edit-button"
                >
                    <span>{isEditing ? 'Save' : 'Edit'}</span>
                    {isEditing ? (
                        <PdsBookmarkIcon style={{ verticalAlign: 'middle' }} />
                    ) : (
                        <PdsEditIcon style={{ verticalAlign: 'middle' }} />
                    )}
                </PdsButton>
            )}
        </div>
    );
};

PricingSetup.propTypes = {
    access: PropTypes.object,
};
export default PricingSetup;
