import {
    getSavedAttributeSets,
    getDefaultAttributeSetName,
    removeSavedAttributeSet,
    setDefaultAttributeSet,
    saveAttributeSet,
} from '../../utils/autobookingUtils';
import actionConstants from './constants';

const initialState = {
    savedAttributeSets: getSavedAttributeSets() || {},
    defaultAttributeSet: getDefaultAttributeSetName() || '',
};

export default (state, action) => {
    switch (action.type) {
        case actionConstants.SAVE_ATTRIBUTE_SET:
            const newSets = saveAttributeSet(
                action.payload.name,
                action.payload.attributeSet
            );
            return {
                ...state,
                savedAttributeSets: newSets,
            };

        case actionConstants.REMOVE_ATTRIBUTE_SET:
            const remainingSets = removeSavedAttributeSet(action.payload.name);
            return {
                ...state,
                savedAttributeSets: remainingSets,
            };

        case actionConstants.SET_DEFAULT_ATTRIBUTE_SET:
            setDefaultAttributeSet(action.payload.name);
            return {
                ...state,
                defaultAttributeSet: action.payload.name,
            };

        default:
            return state || initialState;
    }
};
