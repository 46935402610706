import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import AppWrapper from './AppWrapper';

export default class CalendarWebComponent extends HTMLElement {
    constructor() {
        super();
        this.observer = new MutationObserver(() => this.update());
        this.observer.observe(this, { attributes: true });
        this.root = null;
    }

    connectedCallback() {
        this.mount();
    }

    disconnectedCallback() {
        this.unmount();
        this.observer.disconnect();
    }

    update() {
        this.unmount();
        this.mount();
    }

    mount() {
        this.root = createRoot(this);
        this.root.render(this.getComponent());
    }

    unmount() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }

    getComponent() {
        return (
            <Suspense fallback="Loading...">
                <AppWrapper
                    isPiwikInitialized={
                        this.getAttribute('isPiwikInitialized') === 'true'
                    }
                />
                <Helmet>
                    <script
                        id="chat-script"
                        type="text/javascript"
                        src="https://wchat.eu.freshchat.com/js/widget.js"
                        async
                    ></script>
                </Helmet>
            </Suspense>
        );
    }
}
