import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { response, shouldFetch } from '../../selectors/remoteData/index';
import { fetchData } from '../../stores/remoteData/actions';
import GenericError from '../../utils/GenericError';
import Xhr from '../../utils/Xhr';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class RemoteData extends React.Component {
    lastFetchUri = '';
    lastValidatorFunction = null;
    lastData = null;
    reloadPossible = false;
    isComponentMounted = false;

    state = {
        error: false,
        isLoading: false,
        data: false,
    };

    _fetchData(props) {
        const uri = props.uri,
            validatorFunction = props.validatorFunction;

        if (!uri || !validatorFunction) {
            this._setState({
                error: new GenericError('Missing uri or validator function'),
            });
        } else if (!props.returnEmpty) {
            if (props.caching) {
                if (this.props.shouldFetch) {
                    this.props.fetchData(
                        uri,
                        validatorFunction,
                        this.props.autoErrorHandling
                    );
                }
            } else if (this.lastFetchUri !== uri || this.props.dataIsInvalid) {
                this.lastFetchUri = uri;
                this._setState({
                    isLoading: true,
                });

                const isQuarkusEnabled = props.uri.includes('find-events')
                    ? this.props.isQuarkusEnabledForFindEvents
                    : this.props.isQuarkusEnabledForDataGroup;

                const fetchReq = isQuarkusEnabled
                    ? Xhr.backendRequestLbcQuarkus(
                          uri,
                          validatorFunction,
                          'GET',
                          null,
                          this.props.autoErrorHandling
                      )
                    : Xhr.backendRequest(
                          uri,
                          validatorFunction,
                          'GET',
                          null,
                          this.props.autoErrorHandling
                      );

                fetchReq.then(
                    backendResponse => {
                        this._setState({
                            isLoading: false,
                            data: backendResponse,
                            error: false,
                        });
                    },
                    errorObj => {
                        this._setState({
                            isLoading: false,
                            data: false,
                            error: errorObj,
                        });
                    }
                );
            } else if (
                this.lastValidatorFunction !== validatorFunction &&
                this.state.data !== false
            ) {
                this.lastValidatorFunction = validatorFunction;
                this._setState({
                    isLoading: true,
                });
                if (!validatorFunction(this.state.data)) {
                    this._setState({
                        error: new GenericError(
                            'Response did not validate',
                            1000,
                            {
                                response: this.state.data,
                                validateFunction: validatorFunction,
                            }
                        ),
                        isLoading: false,
                        data: false,
                    });
                } else {
                    this._setState({
                        isLoading: false,
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            // when the method is not called manually
            prevProps &&
            (this.props.uri !== prevProps.uri ||
                this.props.parsingFunction !== prevProps.parsingFunction ||
                this.props.dataIsInvalid)
        ) {
            this._fetchData(this.props);
        }

        const data = this._getData();
        if (this.lastData !== data) {
            this.lastData = data;
            this._newData(data);
        }
        if (!this.props.returnEmpty && !data && this.reloadPossible) {
            this.reloadPossible = false;
            this._fetchData(this.props);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this._fetchData(this.props);
        this.componentDidUpdate();
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    _setState = stateArguments => {
        if (this.isComponentMounted) {
            this.setState(stateArguments);
        }
    };

    _newData = data => {
        if (_.isFunction(this.props.onNewData)) {
            this.props.onNewData(data);
        }
    };

    _getData = () => {
        if (this.props.returnEmpty) {
            return false;
        }
        const data = this.props.caching
            ? this.props.response.data
            : this.state.data;

        if (data) {
            this.reloadPossible = true;
        }

        return data;
    };

    render() {
        const childrenProps = {};
        let data, isLoading, error;

        if (!this.props.returnEmpty) {
            if (!this.props.caching) {
                isLoading = this.state.isLoading;
                error = this.state.error;
            } else {
                isLoading = this.props.response.meta.loading;
                error = this.props.response.meta.error;
            }
            data = this._getData();
        } else {
            data = false;
            isLoading = false;
            error = false;
        }

        if (!isLoading && !data && error) {
            childrenProps['error'] = error;
        } else {
            childrenProps['error'] = false;
        }

        data =
            data && this.props.parsingFunction
                ? this.props.parsingFunction(data)
                : data;
        data = !data ? this.props.emptyResponse : data;
        childrenProps[this.props.dataKey] = data;

        childrenProps['isLoading'] = isLoading;

        return (
            <div className="remote-data" style={this.props.style}>
                {React.Children.map(
                    this.props.children,
                    function (children, id) {
                        childrenProps['key'] = id;
                        return React.cloneElement(children, childrenProps);
                    }
                )}
            </div>
        );
    }
}

RemoteData.propTypes = {
    dataKey: PropTypes.string,
    emptyResponse: PropTypes.any,
    shouldFetch: PropTypes.bool.isRequired,
    response: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    autoErrorHandling: PropTypes.bool,
    dataIsInvalid: PropTypes.bool,
    uri: PropTypes.string,
    parsingFunction: PropTypes.func,
    returnEmpty: PropTypes.bool,
    onNewData: PropTypes.func,
    caching: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
    isQuarkusEnabledForDataGroup: PropTypes.bool.isRequired,
    isQuarkusEnabledForFindEvents: PropTypes.bool.isRequired,
};

RemoteData.defaultProps = {
    uri: '',
    validatorFunction: null,
    caching: false,
    parsingFunction: null,
    returnEmpty: false,
    emptyResponse: false,
    onNewData: null,
    autoReload: false,
    dataKey: 'remoteData',
    autoErrorHandling: true,
    dataIsInvalid: false,
    style: {},
};

const mapStateToProps = (state, ownProps) => ({
    shouldFetch: shouldFetch(state, ownProps.uri),
    response: response(state, ownProps.uri),
    isQuarkusEnabledForDataGroup:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend,
    isQuarkusEnabledForFindEvents:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_find_events && isMicrofrontend,
});

const mapDispatchToProps = {
    fetchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteData);
