import types from './constants';

const initialState = {
    textBanners: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.ADD:
            return {
                ...state,
                textBanners: [...state.textBanners, action.payload.banner],
            };
        case types.SET:
            const newTextBanners = [];

            action.payload.textBanners.forEach(newTextBanner => {
                newTextBanners.push(newTextBanner);
            });

            return {
                ...state,
                textBanners: newTextBanners,
            };
        case types.DELETE:
            return {
                ...state,
                textBanners: state.textBanners.filter(
                    textBanner => textBanner.id !== action.payload.id
                ),
            };
        case types.UPDATE:
            return {
                ...state,
                textBanners: state.textBanners.map(textBanner => {
                    if (textBanner.id === action.payload.banner.id) {
                        return action.payload.banner;
                    }
                    return textBanner;
                }),
            };
        case types.CONFIRM:
            return {
                ...state,
                textBanners: state.textBanners.map(textBanner => {
                    if (textBanner.id === action.payload.textBannerId) {
                        textBanner.userConfirmed = true;
                        return textBanner;
                    }
                    return textBanner;
                }),
            };
        default:
            return state || initialState;
    }
};
