import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import FontIcon from '../icons/FontIcon/FontIcon';
import './tabs.scss';

class Tabs extends React.Component {
    getCollapseExpandArrow = () => {
        const arrow =
            this.props.isOnRightSide ^ this.props.collapsed ? '' : '';

        return (
            <div
                className="collapse-expand-arrow"
                onClick={() =>
                    this.props.setCollapsedStatus(!this.props.collapsed)
                }
            >
                <FontIcon icon={arrow} />
            </div>
        );
    };

    render() {
        let tabs = '';

        tabs = this.props.data.map((tab, tabIndex) => {
            const classes = classnames('tab', 'ui-200', 'text-center', {
                'text-blue-br-700': !this.props.collapsed,
                'text-gray-1100': this.props.collapsed,
                active: tab.value === this.props.selectedTab,
            });
            return (
                <div
                    className={classes}
                    key={`tab_${tabIndex}`}
                    onClick={() => {
                        this.props.onTabClicked(tab.value);
                        this.props.setCollapsedStatus(false);
                    }}
                >
                    {tab.icon && <FontIcon icon={tab.icon} />}
                    <p className="tab-text">{tab.text}</p>
                </div>
            );
        });

        return (
            <div
                className={classnames('tabs', {
                    collapsed:
                        !this.props.onlyHorizontal && this.props.collapsed,
                    'on-right-side': this.props.isOnRightSide,
                })}
            >
                <div>{tabs}</div>
                {!this.props.onlyHorizontal && this.getCollapseExpandArrow()}
            </div>
        );
    }
}

Tabs.propTypes = {
    size: PropTypes.shape({ leftColumn: PropTypes.number }),
    data: PropTypes.array,
    selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onTabClicked: PropTypes.func,
    collapsable: PropTypes.bool,
    collapsed: PropTypes.bool,
    isOnRightSide: PropTypes.bool,
    setCollapsedStatus: PropTypes.func,
    onlyHorizontal: PropTypes.bool,
};

Tabs.defaultProps = {
    data: [],
    selectedTab: null,
    collapsable: false,
    collapsed: true,
    isOnRightSide: false,
    onlyHorizontal: false,
    setCollapsedStatus: status => {
        // intentionally empty function
        // to be overwritten by props if needed
    },
    onTabClicked: tabValue => {
        // intentionally empty function
        // to be overwritten by props if needed
    },
};

const mapStateToProps = state => ({
    size: state.calendar.size,
});

export default connect(mapStateToProps)(Tabs);
