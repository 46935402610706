import { bannerTypes } from '../components/TextBanner/TextBanner';
import moment from '../utils/CalendarMoment';

export default (timestamp, textBanner) => {
    const [start, end] = getStartAndEnd(timestamp, textBanner);
    return (
        (!textBanner.validFrom || textBanner.validFrom <= end) &&
        (!textBanner.validTo || textBanner.validTo >= start)
    );
};

export const textBannerIsPast = (timestamp, textBanner) => {
    const [start] = getStartAndEnd(timestamp, textBanner);
    return textBanner.validTo && textBanner.validTo < start;
};

export const textBannerIsFuture = (timestamp, textBanner) => {
    const [, end] = getStartAndEnd(timestamp, textBanner);
    return textBanner.validFrom && textBanner.validFrom > end;
};

const getStartAndEnd = (timestamp, textBanner) => {
    if (Number(textBanner.type) !== Number(bannerTypes.MAINTENANCE)) {
        const now = moment.now().toFormat('X');

        return [now, now];
    }

    const dateInMoment = moment.fromTimestamp(timestamp);

    const startOfDay = dateInMoment.startOf('day').toFormat('X');
    const endOfDay = dateInMoment.endOf('day').toFormat('X');

    return [startOfDay, endOfDay];
};
