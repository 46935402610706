import { navigate } from '@reach/router';
import _ from 'underscore';
import { bannerTypes } from '../../components/TextBanner/TextBanner';
import { reportTypes } from '../../components/dialog/dialogComponents/ExportDialog/components/TypeStep';
import constants from '../../constants/Constants';
import views from '../../constants/views';
import filteredEvents from '../../selectors/filter/filteredEvents';
import moment from '../../utils/CalendarMoment';
import settings from '../../utils/DefaultSettings';
import EnvUtils from '../../utils/EnvUtils';
import EventFetcher from '../../utils/EventFetcher';
import EventUtils from '../../utils/EventUtils';
import ExportUtils, { downloadFile } from '../../utils/ExportUtils';
import Scroller from '../../utils/Scroller';
import StorageLocal from '../../utils/StorageLocal';
import Xhr from '../../utils/Xhr';
import { setAutobookingFormInitialDates } from '../autobooking/actions';
import { addDialog, removeDialog } from '../dialog/actions';
import { dialogTypes } from '../dialog/constants';
import loggerConstants from '../logger/constants';
import {
    addMatchesFromLocalStorage,
    setBasketDefaults,
} from '../shoppingBasket/actions';
import { setTextBanners } from '../textBanner/actions';
import actionConstants, {
    matches as exportMatchesFilter,
    exportTypes as exportMatchesTypes,
} from './constants';

const storageLocal = new StorageLocal();

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

let delayTimer = null,
    reloadTimer = null;

export const switchToView = (view, error = null) => ({
    type: actionConstants.SWITCH_TO_VIEW,
    payload: {
        type: view,
        error,
    },
});

export const authenticate = consumerRoute => async dispatch => {
    dispatch({ type: actionConstants.GET_AUTH_STATUS_START });

    //remove the check below once standalone app is decomissioned
    const isMicrofrontend =
        window.location.href.includes('portal') ||
        window.location.href.includes('9090');

    const validateResponse = function (response) {
        return (
            _.isObject(response) &&
            _.isObject(response.auth) &&
            response.auth.loggedIn !== undefined
        );
    };
    const transformNullToEmptyString = obj => {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }
        for (const key in obj) {
            if (obj[key] === null) {
                obj[key] = '';
            } else {
                obj[key] = transformNullToEmptyString(obj[key]);
            }
        }
        return obj;
    };

    const startRequest = isMicrofrontend
        ? Xhr.backendRequestLbcQuarkus(settings.authUri, validateResponse)
        : Xhr.backendRequest(settings.authUri, validateResponse);

    startRequest.then(
        res => {
            const response = transformNullToEmptyString(res);
            const authenticated =
                response.auth.loggedIn && _.isObject(response.auth.user);

            if (authenticated) {
                moment.setTimezone(response.auth.user.timezone);
            }

            dispatch({
                type: actionConstants.GET_AUTH_STATUS_SUCCESS,
                payload: {
                    response,
                    authenticated,
                    consumerRoute,
                    enableQuarkusInPortal: isMicrofrontend,
                },
            });

            if (authenticated) {
                dispatch(setAutobookingFormInitialDates());

                dispatch(addMatchesFromLocalStorage());

                dispatch(
                    setBasketDefaults(
                        response.auth.user.bookmakerInfo.canBookLiveodds,
                        response.auth.user.bookmakerInfo.audioVisual.canBook,
                        response.auth.user.bookmakerInfo.canBookBetpal
                    )
                );

                if (import.meta.hot) {
                    setTimeout(() => {
                        window.hotReloaded = true;
                    }, 5000);
                }
            }
        },
        error => {
            dispatch({
                type: actionConstants.GET_AUTH_STATUS_ERROR,
                payload: {
                    error,
                },
            });
        }
    );
};

export const checkboxFilterToggle =
    (groupId, subGroupId, value) => dispatch => {
        dispatch({
            type: actionConstants.CHECKBOX_FILTER_TOGGLE,
            payload: {
                groupId,
                subGroupId,
                value,
            },
        });
    };

export const setProductFilter = products => dispatch => {
    dispatch({
        type: actionConstants.PRODUCTS_CHANGE,
        payload: { products },
    });
};

export const setNcaaFilter = filter => dispatch => {
    dispatch({
        type: actionConstants.NCAA_FILTER_CHANGE,
        payload: filter,
    });
};

export const setFreeTextSearchPhrase = phrase => dispatch => {
    dispatch({
        type: actionConstants.FREETEXT_SEARCH_PHRASE_CHANGE,
        payload: {
            phrase,
        },
    });
};

export const setRecommenderSettings = settings => dispatch => {
    dispatch({
        type: actionConstants.RECOMMENDER_SETTINGS_CHANGE,
        payload: {
            settings,
        },
    });
};

export const refetchEventsForDay =
    (viewUid, clearCache = false, delayMilliseconds = 0) =>
    (dispatch, getState) => {
        if (clearCache) {
            EventFetcher.clearCache();
        }

        dispatch(
            fetchEventsForDay(
                moment.fromTimestamp(getState().calendar.selectedDayTimestamp),
                viewUid,
                delayMilliseconds
            )
        );
    };

export const fetchEventsForDay =
    (mm, currentView, delayMilliseconds = 0) =>
    (dispatch, getState) => {
        const fetchFnc = () => {
            const calendarStoreState = getState().calendar;
            const currentCalendarView = getState().viewport.currentView;
            const consumerRoute = calendarStoreState.auth.consumerRoute;
            const isQuarkusEnabled =
                isMicrofrontend &&
                getState().calendar.auth.userInfo.bookmakerInfo.features
                    .enable_feed_quarkus;

            if (
                ![
                    views.VIEWPORT_TYPE_LIST_CONDENSED,
                    views.VIEWPORT_TYPE_TIMELINE_GADGET,
                ].includes(currentCalendarView)
            ) {
                return;
            }

            dispatch({
                type: actionConstants.FETCH_EVENTS_FOR_DAY_START,
                payload: {
                    mm,
                },
            });

            dispatch(setTextBanners([]));
            const route = consumerRoute === '/' ? '/' : `${consumerRoute}/`;
            navigate(`${route}` + mm.toISODate());

            EventFetcher.fetch(settings.feedUri, mm, isQuarkusEnabled).then(
                response => {
                    const uri = settings.feedUri;
                    const debug = response.debug;
                    let matches = response.matches;
                    const applyLOSportConfig =
                        calendarStoreState.selectedLOSportConfigSetting ===
                        constants.LO_SPORT_CONFIG_SETTINGS.MATCHES_HIDDEN;

                    if (applyLOSportConfig) {
                        matches = _.filter(matches, event => {
                            return (
                                event.isActiveInLiveoddsConfig === undefined ||
                                Boolean(event.isActiveInLiveoddsConfig)
                            );
                        });
                    }

                    dispatch({
                        type: loggerConstants.FETCH_LOGS_SUCCESS,
                        payload: {
                            debug,
                            uri,
                        },
                    });

                    dispatch(setTextBanners(response.textbanners));
                    response.textbanners
                        .filter(
                            textBanner => textBanner.type === bannerTypes.POPUP
                        )
                        .forEach(popup => {
                            if (popup.userConfirmed) {
                                return;
                            }
                            dispatch(
                                addDialog(dialogTypes.NOTIFICATION, {
                                    hideCornerX: true,
                                    inertDialogMask: true,
                                    message: popup.subTitle,
                                    textBannerId: popup.id,
                                    title: popup.title,
                                })
                            );
                        });

                    dispatch({
                        type: actionConstants.FETCH_EVENTS_FOR_DAY_SUCCESS,
                        payload: {
                            matches,
                        },
                    });

                    matches = filteredEvents(getState());
                    // process the response - group the matches in "time groups"
                    // and store those groups in state as well
                    EventUtils.groupEvents(
                        matches,
                        currentView,
                        calendarStoreState.sports,
                        mm
                    ).then(
                        data => {
                            dispatch({
                                type: actionConstants.FETCH_EVENTS_FOR_DAY_GROUP_SUCCESS,
                                payload: {
                                    currentView,
                                    data,
                                    matches,
                                },
                            });

                            Scroller.scrollToSavedPos(currentView);
                        },
                        err => {
                            // todo notify user that something went wrong
                        }
                    );
                },
                error => {
                    dispatch({
                        type: actionConstants.FETCH_EVENTS_FOR_DAY_ERROR,
                        payload: {
                            error,
                        },
                    });
                }
            );

            if (
                !EnvUtils.isLocal(calendarStoreState.backendSettings.env || '')
            ) {
                clearTimeout(reloadTimer);
                reloadTimer = setTimeout(
                    fetchFnc,
                    settings.feedAutoReload,
                    true
                );
            }
        };

        if (delayTimer) {
            clearTimeout(delayTimer);
        }

        if (delayMilliseconds) {
            delayTimer = setTimeout(fetchFnc, delayMilliseconds);
        } else {
            fetchFnc();
        }
    };

export const recalculateFilteredGroups =
    (currentView, sortOrder) => (dispatch, getState) => {
        const calendarStoreState = getState().calendar;
        const mm = moment.fromTimestamp(
            getState().calendar.selectedDayTimestamp
        );

        const matches = filteredEvents(getState());
        // process the response - group the matches in "time groups"
        // and store those groups in state as well
        EventUtils.groupEvents(
            matches,
            currentView,
            calendarStoreState.sports,
            mm,
            sortOrder
        ).then(
            data => {
                dispatch({
                    type: actionConstants.FETCH_EVENTS_FOR_DAY_GROUP_SUCCESS,
                    payload: {
                        currentView,
                        data,
                        matches,
                    },
                });

                Scroller.scrollToSavedPos(currentView);
            },
            err => {
                // todo notify user that something went wrong
            }
        );
    };

export const openUserSwitcher = () => (dispatch, getState) => {
    const calendarStoreState = getState().calendar;

    if (!calendarStoreState.auth.userSwitcher.enabled) {
        return;
    }

    dispatch(addDialog(dialogTypes.USER_SWITCHER));
};

export const toggleHelpBalloon = (
    visible,
    type = null,
    position = null,
    area = null
) => ({
    type: actionConstants.TOGGLE_HELP_BALLOON,
    payload: {
        visible,
        type,
        position,
        area,
    },
});

export const setLeftColumnStatus = (status, writeToLocalStorage = true) => ({
    type: actionConstants.SET_LEFT_COLUMN_STATUS,
    payload: {
        status,
        writeToLocalStorage,
    },
});

export const setRightColumnStatus = (status, writeToLocalStorage = true) => ({
    type: actionConstants.SET_RIGHT_COLUMN_STATUS,
    payload: {
        status,
        writeToLocalStorage,
    },
});

export const setFilterSelection = selection => dispatch => {
    dispatch({
        type: actionConstants.SET_FILTER_SELECTION,
        payload: {
            selection,
        },
    });
};

export const setDefaultFilterId = selectionId => dispatch => {
    dispatch({
        type: actionConstants.SET_DEFAULT_FILTER_ID,
        payload: {
            selectionId,
        },
    });
};

export const exportFeed =
    (
        type = 'pdf',
        dateFrom,
        dateTo,
        onExportDone,
        reportType = reportTypes.match,
        feedSettings = {}
    ) =>
    async (dispatch, getState) => {
        let url,
            filename,
            contentType,
            isQuarkusEnabledForExport = false,
            isCustomSelection = false;
        const data = {
            fromDate: dateFrom,
            toDate: dateTo,
            reportType: reportType,
            settings: feedSettings,
        };

        if (
            parseInt(feedSettings.myMatches, 10) ===
            exportMatchesFilter.FILTERED
        ) {
            data.eventUris = ExportUtils.getEventIds(
                ExportUtils.sortEvents(filteredEvents(getState()))
            );
            isCustomSelection = true;
        }

        data.settings.myMatches =
            data.settings.myMatches === exportMatchesFilter.MY_MATCHES;
        data.settings.isCustomSelection = isCustomSelection;

        switch (type) {
            case exportMatchesTypes.XML:
                url = '/export/xml';
                filename = 'Live-Calendar.xml';
                contentType = 'text/xml';
                isQuarkusEnabledForExport =
                    isMicrofrontend &&
                    !!getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_lbc_quarkus_export_csv;
                break;
            case exportMatchesTypes.CSV:
                url = '/export/csv';
                filename = 'Live-Calendar.csv';
                contentType = 'text/csv';
                isQuarkusEnabledForExport =
                    isMicrofrontend &&
                    !!getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_lbc_quarkus_export_csv;
                break;
            default:
                url = '/export/pdf';
                filename = 'Live-Calendar.pdf';
                contentType = 'application/pdf';
                isQuarkusEnabledForExport =
                    isMicrofrontend &&
                    !!getState().calendar.auth.userInfo.bookmakerInfo.features
                        .enable_lbc_quarkus_export_pdf;
                break;
        }

        try {
            const exportReqResponse = isQuarkusEnabledForExport
                ? await Xhr.exportFileRequest(url, data, 'POST', true)
                : await Xhr.exportFileRequest(url, data, 'POST', false);

            downloadFile(filename, exportReqResponse, contentType);
            onExportDone();
        } catch (error) {
            console.error(error);
            onExportDone(error);
        }
    };

export const toggleHiddenPackages = () => ({
    type: actionConstants.TOGGLE_HIDDEN_PACKAGES,
});

export const setShowBookedCountsType = uid => ({
    type: actionConstants.SET_SHOW_BOOKED_COUNTS_TYPE,
    payload: {
        uid,
    },
});

export const setShowAsBooked = uid => ({
    type: actionConstants.SET_SHOW_AS_BOOKED_PRODUCT,
    payload: {
        uid,
    },
});

export const setShowAsBookedPrimary = uid => ({
    type: actionConstants.SET_SHOW_AS_BOOKED_PRODUCT_PRIMARY,
    payload: {
        uid,
    },
});

export const setShowAsBookedSecondary = uid => ({
    type: actionConstants.SET_SHOW_AS_BOOKED_PRODUCT_SECONDARY,
    payload: {
        uid,
    },
});
export const setSelectedLOSportConfigSetting =
    (uid, currentView) => dispatch => {
        dispatch({
            type: actionConstants.SET_SELECTED_LO_SPORT_CONFIG_SETTING,
            payload: {
                uid,
            },
        });
        dispatch(refetchEventsForDay(currentView, true));
    };

export const hashMismatch = () => ({
    type: actionConstants.HASH_MISMATCH,
});

export const xhrError = error => switchToView(views.VIEWPORT_TYPE_ERROR, error);

export const clearXhrError = () => ({
    type: actionConstants.CLEAR_XHR_ERROR,
});

export const saveFilterSet = (uri, postData) => (dispatch, getState) => {
    const saveFilterSetReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(uri, null, 'POST', postData)
            : Xhr.backendRequest(uri, null, 'POST', postData);
    saveFilterSetReq.then(() => {
        dispatch(removeDialog(dialogTypes.SAVE_FILTER));
    });
};

export const deleteFilterSet = id => (dispatch, getState) => {
    const deleteFilterSetReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features.data_group &&
        isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(
                  settings.deleteFilterUri.replace('{id}', id),
                  null,
                  'DELETE'
              )
            : Xhr.backendRequest(
                  settings.deleteFilterUri.replace('{id}', id),
                  null,
                  'DELETE'
              );
    deleteFilterSetReq.then(() =>
        dispatch({
            type: actionConstants.DELETE_FILTER_SET,
            id,
        })
    );
};

export const sortRecomendations = sortOrder => (dispatch, getState) => {
    const events = getState().calendar.events;
    dispatch({
        type: actionConstants.SORT_RECOMMENDATIONS,
        payload: { events, sortOrder },
    });
};
