import PropTypes from 'prop-types';
import React from 'react';
import keyboard from 'keyboardjs';
import _ from 'underscore';
import {
    LazyTableData,
    Cell as LazyTableCell,
} from '../../models/LazyTableData';
import FontIcon from '../icons/FontIcon/FontIcon.jsx';
import Paginator from './Paginator/Paginator';
import StorageLocal from '../../utils/StorageLocal';
import './lazyTable.scss';

const storageLocal = new StorageLocal();

class LazyTable extends React.Component {
    state = {
        offset: 0,
        sortData: this.getInitialSortState(),
    };

    getInitialSortState() {
        const config = this.props.lazyTableData.getConfig().sorting;

        let sort = {
            column: config.defaultColumn,
            direction: config.defaultDirection,
        };
        const localStorageKey = this.getLocalStorageKey();

        if (localStorageKey) {
            const tableData = storageLocal.getItem(localStorageKey);
            if (tableData) {
                try {
                    sort = JSON.parse(tableData);
                } catch (e) {
                    console.error('invalid json in the local storage');
                }
            }
        }

        return sort;
    }

    getLocalStorageKey() {
        const id = this.props.lazyTableData.getConfig().id;
        if (id) {
            return `lazy_table_${id}`;
        }
        return null;
    }

    componentDidMount() {
        keyboard.on(`f${this._fKeyPressed}`);
    }

    componentWillUnmount() {
        keyboard.off(`f${this._fKeyPressed}`);
    }

    _createTable(tableData, rows = []) {
        let startIndex, maxIndex;

        if (tableData.pagination.enabled) {
            const maxPaginationIndex =
                this.state.offset + tableData.pagination.perPage;
            maxIndex =
                rows.length < maxPaginationIndex
                    ? rows.length
                    : maxPaginationIndex;
            startIndex = this.state.offset;
        } else {
            startIndex = 0;
            maxIndex = rows.length;
        }

        return this._buildRows(tableData, rows, startIndex, maxIndex);
    }

    _buildRows(tableData, rows, startIndex, maxIndex, isHeader = false) {
        const _rows = [],
            sorting = this.props.lazyTableData.getSorting();

        let sortedRows = rows;

        if (
            !isHeader &&
            sorting &&
            this.state.sortData.column !== null &&
            rows.length
        ) {
            const direction = this.state.sortData.direction === 'asc' ? -1 : 1;
            sortedRows = [...rows].sort((a, b) => {
                const newA = a.cells[this.state.sortData.column].sortingValue,
                    newB = b.cells[this.state.sortData.column].sortingValue;
                return sorting[this.state.sortData.column].sort(
                    newA,
                    newB,
                    direction
                );
            });
        }

        for (let rowIndex = startIndex; rowIndex < maxIndex; rowIndex++) {
            const row = sortedRows[rowIndex];

            if (row.hidden) {
                continue;
            }

            const cells = [];
            for (const cellIndex in row.cells) {
                if (!row.cells.hasOwnProperty(cellIndex)) {
                    continue;
                }

                let sortingCell = false;
                if (isHeader && sorting && sorting[cellIndex]) {
                    sortingCell = true;
                }

                const cell = row.cells[cellIndex];
                if (!_.isArray(cell.classes)) {
                    console.error('LazyTableCell classes should be an array!');
                    cell.classes = [];
                }
                const newCell = new LazyTableCell(cell.content),
                    classes = [...cell.classes];
                if (this.state.sortData.column === cellIndex) {
                    classes.push('sorted-column');
                }
                newCell.classes = classes;
                cells.push(
                    this._createCellComponent(
                        newCell,
                        tableData,
                        rowIndex,
                        cellIndex,
                        sortingCell,
                        cell.tooltip,
                        isHeader,
                        cell.colspan
                    )
                );
            }

            _rows.push(
                <tr
                    className={this._extractClasses(row, 'row').join(' ')}
                    key={`row_${rowIndex}`}
                    onClick={row.onClick || null}
                >
                    {cells}
                </tr>
            );

            if (row.additionalInfoRow) {
                // beware! magic number: 42
                // colspan 42 means it will span up to 42 columns, which is more than we need
                // there is no way to just tell HTML to span all columns, you need to give a number
                // if we ever need more than 42 columns we can change it
                _rows.push(
                    <tr
                        className="row child-row extra-info"
                        key={`row_${rowIndex}_info`}
                    >
                        <td className="cell full-width" colSpan="42">
                            {row.additionalInfoRow}
                        </td>
                    </tr>
                );
            }
        }

        return _rows;
    }

    _buildSortButton(cellIndex) {
        let icon = '';
        if (this.state.sortData.column === cellIndex) {
            icon = this.state.sortData.direction === 'asc' ? '' : '';
        }
        return <FontIcon icon={icon} style={{ padding: 5 }} />;
    }

    _getCellClickListener(cellIndex, cellContent) {
        return () => {
            const sortedColumnClicked =
                    this.state.sortData.column === cellIndex,
                shouldSort = !(
                    sortedColumnClicked &&
                    this.state.sortData.direction === 'desc'
                ),
                direction =
                    sortedColumnClicked &&
                    this.state.sortData.direction === 'asc'
                        ? 'desc'
                        : 'asc',
                data = {
                    column: shouldSort ? cellIndex : null,
                    direction: shouldSort ? direction : 'asc',
                };
            this.setState({ sortData: data });
            if (this.getLocalStorageKey()) {
                storageLocal.setItem(
                    this.getLocalStorageKey(),
                    JSON.stringify(data)
                );
            }
        };
    }

    _createCellComponent(
        cell,
        tableData,
        rowIndex,
        cellIndex,
        sortCell,
        tooltip = '',
        isHeader = false,
        colspan = null
    ) {
        const cellClasses = this._extractClasses(cell, 'cell');

        if (sortCell) {
            cellClasses.push('clickable');
        }

        let Tx = 'td';
        if (isHeader) {
            Tx = 'th';
        }

        return (
            <Tx
                className={cellClasses.join(' ')}
                key={['cell', rowIndex, cellIndex].join('_')}
                style={{ width: `${tableData.cellWidths[cellIndex]}%` }}
                title={
                    tooltip ||
                    (typeof cell.content === 'string' && cell.content) ||
                    ''
                }
                onClick={
                    sortCell
                        ? this._getCellClickListener(cellIndex, cell.content)
                        : () => {}
                }
                colSpan={colspan}
            >
                {sortCell ? this._buildSortButton(cellIndex) : ''}
                {cell.content}
            </Tx>
        );
    }

    _extractClasses(obj, base = '') {
        const classes = [base];
        if (_.isArray(obj.classes)) {
            _.each(obj.classes, className => {
                classes.push(className);
            });
        }
        return classes;
    }

    _addClass(row, className) {
        if (row.classes.indexOf(className) === -1) {
            row.classes.push(className);
        }
    }

    _getContent(tableData, pagination = false) {
        let header = '',
            footer = '';

        if (!_.isNull(tableData.headerRow)) {
            this._addClass(tableData.headerRow, 'table-header');

            header = (
                <thead
                    className="header-wrapper"
                    style={{
                        height: tableData.headerHeight,
                    }}
                >
                    {this._buildRows(
                        tableData,
                        [tableData.headerRow],
                        0,
                        1,
                        true
                    )}
                </thead>
            );
        }

        if (!_.isNull(tableData.footerRow)) {
            this._addClass(tableData.footerRow, 'footer');

            footer = (
                <tfoot
                    className="footer-wrapper"
                    style={{
                        height: tableData.footerHeight,
                    }}
                >
                    {this._buildRows(tableData, [tableData.footerRow], 0, 1)}
                </tfoot>
            );
        }

        return (
            <div className="table-wrapper">
                <table className="lazy-table">
                    {header}
                    <tbody className="content-wrapper">
                        {this._createTable(tableData, tableData.rows)}
                    </tbody>
                    {footer}
                </table>
            </div>
        );
    }

    _pageChangeListener = selectedPage => {
        const offset = Math.ceil(
            selectedPage *
                this.props.lazyTableData.getConfig().pagination.perPage
        );
        this.setState({ offset });
    };

    render() {
        const tableData = this.props.lazyTableData.getConfig();

        let Paginate = '';

        if (tableData.pagination.enabled) {
            if (tableData.numberOfPages > 1) {
                Paginate = (
                    <Paginator
                        numberOfPages={tableData.numberOfPages}
                        tableId={this.props.id}
                        pageChangeListener={this._pageChangeListener}
                    />
                );
            }
        }

        return (
            <div className="lazy-table-container">
                {this._getContent(tableData, Paginate)}
                {Paginate}
            </div>
        );
    }
}

LazyTable.propTypes = {
    lazyTableData: PropTypes.instanceOf(LazyTableData).isRequired,
    id: PropTypes.string.isRequired,
};

LazyTable.defaultProps = {
    lazyTableData: {},
};

export default LazyTable;
