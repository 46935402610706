import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScheduleOverviewDrawer from './ScheduleOverviewDrawer';
import moment from '../../../../../../utils/CalendarMoment';
import classnames from 'classnames';
import Settings from '../../../../../../utils/DefaultSettings';
import scheduleOverviewSelector from '../../../../../../selectors/scheduleOverview';
import {
    setColumnInfo,
    onMouseLeave,
    onMouseEnter,
} from '../../../../../../stores/scheduleOverview/actions';
import { setCurrentView } from '../../../../../../stores/viewport/actions';
import './scheduleOverview.scss';

class ScheduleOverview extends React.Component {
    highlightedColumn = -1;
    canvasRef = createRef();
    gridCanvasRef = createRef();
    highlightedColumnCanvasRef = createRef();
    scheduleOverviewRef = createRef();

    componentDidMount() {
        this._buildContent();
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)
        ) {
            this._buildContent();
        }
    }

    _mouseMove = e => {
        const x = e.nativeEvent.offsetX;
        const columnTimeZone = ScheduleOverviewDrawer.getColumnFromLeft(
            x,
            Settings.timeLine.blockWidth,
            this.props.data,
            this.props.columnSpace,
            this.props.leftOffset
        );

        if (this.highlightedColumn !== columnTimeZone) {
            const from = moment
                .now()
                .startOf('day')
                .plus({ seconds: columnTimeZone });
            const info = {
                time: {
                    from: from.toFormat('HH:mm'),
                    to: from
                        .plus({ seconds: this.props.data.interval })
                        .toFormat('HH:mm'),
                },
                column: this.props.data.columns[columnTimeZone],
            };

            this._newColumnInfo(info);

            this._updateHighlightedColumn(columnTimeZone);
        }
    };

    _newColumnInfo(info) {
        this.props.setColumnInfo(info);
    }

    _mouseLeave = () => {
        this._updateHighlightedColumn();
        this.props.onMouseLeave();
    };

    _buildContent() {
        if (!this.props.isVisible) {
            return;
        }

        const canvas = this.canvasRef.current,
            ctx = canvas.getContext('2d'),
            pxPerHour = Settings.timeLine.blockWidth;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ScheduleOverviewDrawer.drawColumns(
            ctx,
            pxPerHour,
            this.props.data,
            this.props.columnSpace,
            this.props.leftOffset,
            Settings.timeLine.scheduleOverview.expandedHeight
        );

        ScheduleOverviewDrawer.drawTotalLine(
            ctx,
            pxPerHour,
            this.props.data,
            this.props.columnSpace,
            Settings.timeLine.scheduleOverview.expandedHeight,
            this.props.leftOffset,
            this.props.lineWidth,
            'booked',
            '#3ee227'
        );
        this._buildGrid();
    }

    _buildGrid() {
        if (!this.props.isVisible) {
            return;
        }

        const canvas = this.gridCanvasRef.current,
            ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ScheduleOverviewDrawer.drawGrid(
            ctx,
            Settings.timeLine.scheduleOverview.expandedHeight,
            this._getCanvasWidth(),
            Settings.timeLine.scheduleOverview.canvasTopPadding
        );
    }

    _updateHighlightedColumn(highlightedColumn = null) {
        const canvas = this.highlightedColumnCanvasRef.current,
            ctx = canvas.getContext('2d');

        this.highlightedColumn = highlightedColumn;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (highlightedColumn !== null) {
            ScheduleOverviewDrawer.drawColumnHighlight(
                ctx,
                Settings.timeLine.blockWidth,
                highlightedColumn,
                this.props.data,
                this.props.columnSpace,
                Settings.timeLine.scheduleOverview.expandedHeight,
                this.props.leftOffset
            );
        }
    }

    _buildCanvas(style) {
        return [
            <canvas
                key="grid_canvas"
                ref={this.gridCanvasRef}
                style={style}
                width={this._getCanvasWidth() + 150}
                height={Settings.timeLine.scheduleOverview.expandedHeight}
            />,
            <canvas
                key="highlighted_column_canvas"
                ref={this.highlightedColumnCanvasRef}
                style={style}
                width={this._getCanvasWidth()}
                height={Settings.timeLine.scheduleOverview.expandedHeight}
            />,
            <canvas
                key="canvas"
                ref={this.canvasRef}
                style={style}
                width={this._getCanvasWidth()}
                height={Settings.timeLine.scheduleOverview.expandedHeight}
                onMouseMove={this._mouseMove}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this._mouseLeave}
            />,
        ];
    }

    _getCanvasWidth() {
        const hoursInDay = 24;
        return (
            Settings.timeLine.blockWidth * hoursInDay +
            this.props.leftOffset +
            100
        ); //100 so that the background lines goes some extra pixels to the right
    }

    _buildGridLabels() {
        const mostMatchInZone = this.props.data.mostMatchInZone;
        const actualCanvasHeight =
            Settings.timeLine.scheduleOverview.expandedHeight -
            Settings.timeLine.scheduleOverview.canvasTopPadding;
        const content = [];
        for (
            let x = 0;
            x < Settings.timeLine.scheduleOverview.gridLineCount;
            x++
        ) {
            const top =
                x *
                    (actualCanvasHeight /
                        Settings.timeLine.scheduleOverview.gridLineCount) +
                Settings.timeLine.scheduleOverview.canvasTopPadding;
            const count = Math.floor(
                mostMatchInZone -
                    x *
                        (mostMatchInZone /
                            Settings.timeLine.scheduleOverview.gridLineCount)
            );
            content.push(
                <div
                    key={top}
                    className="program-gadget-grid-label"
                    style={{
                        top: top,
                    }}
                >
                    {count}
                </div>
            );
        }
        return content;
    }

    render() {
        const canvasStyle = {
                left: -this.props.left,
            },
            style = {
                ...this.props.style,
                height: Settings.timeLine.scheduleOverview.expandedHeight,
                top: this.props.top,
            };

        return (
            <div
                className={classnames('schedule-overview', {
                    hidden: !this.props.isVisible,
                })}
            >
                <div
                    className="program-gadget"
                    style={style}
                    ref={this.scheduleOverviewRef}
                >
                    {this._buildCanvas(canvasStyle)}
                </div>
                {this._buildGridLabels()}
            </div>
        );
    }
}

ScheduleOverview.getHeight = isExpanded => {
    return isExpanded ? Settings.timeLine.scheduleOverview.height : 0;
};

ScheduleOverview.propTypes = {
    top: PropTypes.number,
    leftOffset: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    style: PropTypes.object,
    isVisible: PropTypes.bool.isRequired,
    setColumnInfo: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    columnSpace: PropTypes.number,
    lineWidth: PropTypes.number,
};

ScheduleOverview.defaultProps = {
    columnSpace: 4,
    lineWidth: 3,
    top: 0,
};

const mapStateToProps = state => ({
    left: state.scheduleOverview.leftOffset,
    currentView: state.viewport.currentView,
    data: scheduleOverviewSelector(state),
});

const mapDispatchToProps = {
    setColumnInfo,
    onMouseEnter,
    onMouseLeave,
    setCurrentView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleOverview);
