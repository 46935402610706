export default state => {
    const options = state.calendar.auth.userSwitcher.options;

    if (typeof options !== 'object') {
        return [];
    }

    const userInfo = state.calendar.auth.userInfo;

    return Object.values(options).map(option => {
        if (!option.timezone) {
            option.timezone = userInfo.timezone;
        }

        option.selected =
            userInfo.bookmakerId === option.bookmakerId &&
            userInfo.timezone === option.timezone &&
            userInfo.id === option.userId;

        return option;
    });
};
