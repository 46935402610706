import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../../../../Alert/Alert';
import Button from '../../../../formInput/Button/Button';
import OptionButtonGroup from '../../../../OptionButtonGroup/OptionButtonGroup';
import Switch from '../../../../Switch/Switch';
import { dateTypes } from './DateStep';
import { reportTypes } from './TypeStep';

export const exportTypes = {
    pdf: 0,
    xml: 1,
    csv: 2,
};

export const getInfoMessage = (dateType, dateFrom, dateTo) => {
    const _export = 'Export match list'; // can't use the name export because it's a keyword
    const dateFormat = 'DDD';
    switch (dateType) {
        case dateTypes.TODAY:
            return `${_export} for Today`;
        case dateTypes.MONTH:
            const month = dateFrom.toFormat('LLLL');
            const year = dateFrom.toFormat('yyyy');
            return `${_export} for ${month} ${year}`;
        case dateTypes.RANGE:
            const startDate = dateFrom.toFormat(dateFormat);
            const endDate = dateTo.toFormat(dateFormat);
            return `${_export} from ${startDate} to ${endDate}`;
        case dateTypes.CURRENT_DAY:
            const date = dateFrom.toFormat(dateFormat);
            return `${_export} for ${date}`;
        default:
            return _export;
    }
};

export const isFilterEnabled = (dateType, calendarSelectedDay, selectedDay) => {
    if (dateType === dateTypes.CURRENT_DAY) {
        return true;
    }
    return (
        dateType === dateTypes.TODAY &&
        calendarSelectedDay.hasSame(selectedDay, 'day')
    );
};

const getPdfSpecificOptions = props => {
    return (
        <fieldset>
            <legend className="label">Page orientation</legend>
            <OptionButtonGroup
                options={['landscape', 'portrait']}
                value={props.settings.isPortrait}
                onClick={value => {
                    props.onSettingsChange(
                        Object.assign({}, props.settings, {
                            isPortrait: value,
                        })
                    );
                }}
            />
        </fieldset>
    );
};

const getCsvSpecificOptions = props => {
    return (
        <fieldset key="sep">
            <legend>Separator</legend>
            <OptionButtonGroup
                options={[
                    {
                        name: 'Comma',
                        value: ',',
                    },
                    {
                        name: 'Semicolon',
                        value: ';',
                    },
                ]}
                value={props.settings.separator}
                onClick={value => {
                    props.onSettingsChange(
                        Object.assign({}, props.settings, {
                            separator: value,
                        })
                    );
                }}
            />
        </fieldset>
    );
};

const getExtraFieldsToggles = props => {
    return (
        <fieldset key="columns">
            <legend>Extra columns</legend>
            <div className="group horizontal compact">
                <div className="label">Covered from</div>
                <Switch
                    selected={props.settings.isCoveredFrom}
                    onChange={value => {
                        props.onSettingsChange(
                            Object.assign({}, props.settings, {
                                isCoveredFrom: value,
                            })
                        );
                    }}
                />
            </div>
            <div className="group horizontal compact">
                <div className="label">Covered with</div>
                <Switch
                    selected={props.settings.isCoveredWith}
                    onChange={value => {
                        props.onSettingsChange(
                            Object.assign({}, props.settings, {
                                isCoveredWith: value,
                            })
                        );
                    }}
                />
            </div>
        </fieldset>
    );
};

const getMatchOptions = props => {
    const options = [
        {
            name: 'all matches',
            value: 0,
            title: 'All matches (Booked, Available, Unavailable and Inactive matches) will be exported to the list.',
        },
        {
            name: 'my matches',
            value: 1,
            title: 'Only LiveOdds booked matches will appear in the exported list, with LiveChannel information.',
        },
    ];
    if (props.filterEnabled) {
        options.push({
            name: 'currently set filter',
            value: '2',
            title:
                'Filters selected and resulted in the list view, will appear in the exported list. For instance:\n' +
                'a) My booked LiveOdds matches with LiveChannel Online information: [X] My matches + [X] Online\n' +
                'b) All available LiveOdds matches with LiveChannel Online information: [X] Available matches + [X] Online',
        });
    }

    return (
        <fieldset>
            <legend>Matches</legend>
            <OptionButtonGroup
                options={options}
                value={props.settings.myMatches}
                onClick={value => {
                    props.onSettingsChange(
                        Object.assign({}, props.settings, {
                            myMatches: value,
                        })
                    );
                }}
            />
        </fieldset>
    );
};

const FileTypeOptions = reportType => {
    let types;

    if (reportType === reportTypes.match) {
        types = [
            { name: 'pdf', value: exportTypes.pdf },
            { name: 'xml', value: exportTypes.xml },
            { name: 'csv', value: exportTypes.csv },
        ];
    } else {
        types = [
            { name: 'csv', value: exportTypes.csv },
            { name: 'xml', value: exportTypes.xml },
        ];
    }

    return types;
};

const SettingStep = props => (
    <div
        className="export-wrapper export-step-setting"
        style={{ height: props.height }}
    >
        <div className="content">
            <Alert
                type={Alert.types.INFO_BOX}
                minWidth={false}
                icon={''}
                float={false}
            >
                {props.infoText}
            </Alert>
            {getMatchOptions(props)}
            <fieldset>
                <legend className="label">File type</legend>
                <OptionButtonGroup
                    options={FileTypeOptions(props.reportType)}
                    value={props.exportType}
                    onClick={props.onExportTypeChange}
                />
            </fieldset>
            {props.exportType === exportTypes.pdf
                ? getPdfSpecificOptions(props)
                : ''}
            {props.exportType === exportTypes.csv
                ? getCsvSpecificOptions(props)
                : ''}
            {props.reportType !== reportTypes.lcExtended &&
            props.exportType !== exportTypes.pdf
                ? getExtraFieldsToggles(props)
                : ''}
        </div>
        <div className="footer">
            <Button
                onClick={props.onPrevious}
                type={Button.types.LARGE_BLUE_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Previous
            </Button>
            <Button
                onClick={props.onClose}
                type={Button.types.LARGE_GREY_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Cancel
            </Button>
            <Button
                onClick={props.onDownload}
                type={Button.types.LARGE_BLUE_SHADOW}
                fixedWidth={false}
                icon={''}
            >
                Download File
            </Button>
        </div>
    </div>
);

getCsvSpecificOptions.propTypes =
    getExtraFieldsToggles.propTypes =
    getMatchOptions.propTypes =
    getPdfSpecificOptions.propTypes =
    SettingStep.propTypes =
        {
            height: PropTypes.number.isRequired,
            onClose: PropTypes.func.isRequired,
            onPrevious: PropTypes.func.isRequired,
            onDownload: PropTypes.func.isRequired,
            exportType: PropTypes.number.isRequired,
            reportType: PropTypes.number.isRequired,
            onExportTypeChange: PropTypes.func.isRequired,
            settings: PropTypes.object.isRequired,
            onSettingsChange: PropTypes.func.isRequired,
            infoText: PropTypes.string.isRequired,
            filterEnabled: PropTypes.bool.isRequired,
        };

export default SettingStep;
