import Xhr from '../../utils/Xhr';
import actionConstants from './constants';

export const setHoveredEvent = uri => ({
    type: actionConstants.SET_HOVERED_EVENT,
    payload: {
        uri: uri,
    },
});

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const fetchEventInfo = uri => (dispatch, getState) => {
    dispatch({
        type: actionConstants.FETCH_INFO,
        payload: {
            uri,
        },
    });
    const fetchEventInfoReq =
        isMicrofrontend &&
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_event_info
            ? Xhr.backendRequestLbcQuarkus(
                  '/event-info/' + uri,
                  null,
                  'GET',
                  null,
                  false
              )
            : Xhr.backendRequest(
                  '/event-info/' + uri,
                  null,
                  'GET',
                  null,
                  false
              );
    fetchEventInfoReq.then(
        response => {
            dispatch({
                type: actionConstants.FETCH_INFO_SUCCESS,
                payload: {
                    response,
                    uri,
                },
            });
        },
        () => {
            dispatch({
                type: actionConstants.FETCH_INFO_FAIL,
                payload: {
                    uri,
                },
            });
        }
    );
};
