import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { responseStatus } from '../../../../constants/shoppingBasket';
import Component from './Component';
import Alert from '../../../Alert/Alert';
import Xhr from '../../../../utils/Xhr';
import longestMatchDuration from '../../../../selectors/sport/longestMatchDuration';
import { create as createNotification } from '../../../../stores/notification/actions';
import { removeEvent } from '../../../../stores/shoppingBasket/actions';
import { refetchEventsForDay } from '../../../../stores/calendar/actions';

class ControlOddsDialog extends React.Component {
    mounted = true;

    state = {
        loadingStep: Component.loadingStep.DATA,
        traders: [],
        templates: [],
        value: {
            template: -1,
            trader: -1,
        },
        error: null,
        price: {},
        isLoadingPrice: true,
        changedPrice: false,
    };

    componentDidMount() {
        const promises = [
            this.fetchTraders(),
            this.fetchTemplates(this.props.event.id),
            this.fetchWillSwitch(this.props.event.id),
        ];
        Promise.all(promises).then(
            responses => {
                if (this.mounted) {
                    this.parseInitialResponsesToState(responses);
                }
            },
            () => {
                if (this.mounted) {
                    this.setState({
                        error: 'Problem while loading quick checkout',
                        loadingStep: null,
                    });
                }
            }
        );
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    parseInitialResponsesToState(responses) {
        const traderResponse = responses[0],
            templatesResponse = responses[1],
            willSwitchResponse = responses[2].response;

        let traderValue = traderResponse.defaultBetpalUserId,
            templateValue =
                templatesResponse.defaults[this.props.event.sportId];
        const templates = templatesResponse.templates[this.props.event.sportId];

        if (!traderValue) {
            traderValue = -1;
        }

        if (
            !templateValue &&
            Array.isArray(templates) &&
            templates.length > 0
        ) {
            templateValue = templates[0].id;
        }

        this.fetchPrice(this.props.event.id, templateValue);

        this.setState({
            traders: this.tradersToDropDown(traderResponse.traders),
            templates: this.templatesToDropDown(templates),
            loadingStep: null,
            willSwitch: willSwitchResponse.matches[this.props.event.id],
            value: Object.assign({}, this.state.value, {
                trader: traderValue,
                template: templateValue,
            }),
        });
    }

    _onSwitchMatchToBetpal = () => {
        this.setState({
            loadingStep: Component.loadingStep.SWITCHING_MATCH_TO_BETPAL,
        });
        const templateId = this.state.value.template;
        this.switchToBetpal(
            this.props.event.id,
            this.state.price[templateId].price,
            this.props.event.sportId,
            this.state.value.template,
            this.state.value.trader
        ).then(
            response => {
                switch (response.status) {
                    case responseStatus.SUCCESS:
                        this.props.removeMatch(this.props.event.uri);
                        this.props.refetchEventsForDay(
                            this.props.currentView,
                            true
                        );

                        const openedInAppMatchIds = Object.keys(
                            response.prices
                        ).filter(
                            matchId =>
                                parseInt(matchId, 10) === this.props.event.id &&
                                response.prices[matchId].openedInApp
                        );

                        this._displaySuccessNotification(
                            openedInAppMatchIds.length
                        );
                        this.props.onClose();
                        break;
                    case responseStatus.PRICE_CHANGE:
                        Object.keys(response.prices).forEach(matchId => {
                            if (
                                this.mounted &&
                                parseInt(matchId, 10) === this.props.event.id
                            ) {
                                this.setState({
                                    price: {
                                        ...this.state.price,
                                        [templateId]: {
                                            ...this.state.price[templateId],
                                            ...response.prices[matchId],
                                            priceChanged: true,
                                        },
                                    },
                                    loadingStep: null,
                                });
                            }
                        });
                        break;
                    default:
                        //any error
                        if (this.mounted) {
                            this.setState({
                                error: 'Could not import match to Betpal',
                                loadingStep: null,
                            });
                        }
                        break;
                }
            },
            failure => {
                this.setState({
                    error: failure,
                    loadingStep: null,
                });
            }
        );
    };

    fetchTraders() {
        return Xhr.backendRequest('/betpal/traders', null, 'GET', null, false);
    }

    fetchTemplates(matchId) {
        return Xhr.backendRequest(
            `/betpal/templates/${matchId}`,
            null,
            'GET',
            null,
            false
        );
    }

    fetchWillSwitch(matchId) {
        return Xhr.backendRequest(
            '/betpal/will-switch',
            null,
            'POST',
            {
                matchIds: [matchId],
            },
            false
        );
    }

    fetchPrice(guthMatchId, templateId) {
        this.setState({
            price: {
                ...this.state.price,
                [templateId]: {
                    ...this.state.price[templateId],
                    isLoading: true,
                },
            },
        });

        Xhr.backendRequest(
            '/betpal/price',
            null,
            'POST',
            {
                matchIds: [guthMatchId],
                templates: {
                    [this.props.event.sportId]: templateId,
                },
            },
            false
        ).then(
            pricesResponse => {
                if (!this.mounted) {
                    return;
                }

                if (pricesResponse.status === responseStatus.FAILURE) {
                    this.setState({
                        loadingStep: null,
                        error:
                            'Something happened while trying to fetch prices',
                        price: {
                            ...this.state.price,
                            [templateId]: {
                                ...this.state.price[templateId],
                                isLoading: false,
                            },
                        },
                    });
                } else {
                    this.setState({
                        price: {
                            ...this.state.price,
                            [templateId]: {
                                isLoading: false,
                                loaded: true,
                                ...pricesResponse.prices[this.props.event.id],
                            },
                        },
                    });
                }
            },
            () => {
                return this.setState({
                    loadingStep: null,
                    error: 'Something happened while trying to fetch prices',
                    price: {
                        ...this.state.price,
                        [templateId]: {
                            ...this.state.price[templateId],
                            isLoading: false,
                        },
                    },
                });
            }
        );
    }

    switchToBetpal(guthMatchId, price, sportId, templateId, traderId) {
        return Xhr.backendRequest(
            '/betpal/book',
            null,
            'POST',
            {
                matchIds: {
                    [guthMatchId]: {
                        trader: traderId,
                        price,
                    },
                },
                templates: {
                    [sportId]: templateId,
                },
                openInApp: true,
            },
            false
        );
    }

    tradersToDropDown(traders) {
        return traders.map(trader => {
            return {
                value: trader.userId,
                text: trader.alias,
            };
        });
    }

    _displaySuccessNotification(openedInApp = false) {
        let alertMessage = 'Successfully imported match to betpal';
        if (!openedInApp) {
            alertMessage = (
                <div>
                    {alertMessage}.<br />
                    <a href={`${this.props.betpalUrl}java/launch/launch`}>
                        Open application
                    </a>
                </div>
            );
        }
        this.props.createNotification(
            Alert.types.SUCCESS,
            alertMessage,
            ''
        );
    }

    getTraderOptions(options) {
        const optionsCopy = [...options];
        optionsCopy.unshift({
            value: -1,
            text: 'Unassigned',
        });
        return optionsCopy;
    }

    templatesToDropDown(templates) {
        const templatesObject = {};

        templates.forEach(template => {
            if (!templatesObject.hasOwnProperty(template.category)) {
                templatesObject[template.category] = [];
            }

            templatesObject[template.category].push({
                value: template.id,
                text: template.name,
            });
        });

        return templatesObject;
    }

    render() {
        return (
            <Component
                loadingStep={this.state.loadingStep}
                event={this.props.event}
                onClose={this.props.onClose}
                onConfirm={this._onSwitchMatchToBetpal}
                error={this.state.error}
                price={this.state.price[this.state.value.template] || {}}
                changedPrice={this.state.changedPrice}
                traderChange={value => {
                    this.setState({
                        value: Object.assign({}, this.state.value, {
                            trader: value,
                        }),
                    });
                }}
                traderValue={this.state.value.trader}
                traderOptions={this.getTraderOptions(this.state.traders)}
                templateChange={value => {
                    this.setState({
                        value: Object.assign({}, this.state.value, {
                            template: value,
                        }),
                    });
                    if (
                        !this.state.price[value] ||
                        (!this.state.price[value].isLoading &&
                            !this.state.price[value].loaded)
                    ) {
                        this.fetchPrice(this.props.event.id, value);
                    }
                }}
                templateValue={this.state.value.template}
                templateOptions={this.state.templates}
                willSwitch={this.state.willSwitch}
            />
        );
    }
}

ControlOddsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    betpalUrl: PropTypes.string.isRequired,
    selectedDayTimestamp: PropTypes.number.isRequired,
    longestMatchDuration: PropTypes.number.isRequired,
    currentView: PropTypes.string.isRequired,
    createNotification: PropTypes.func.isRequired,
    removeMatch: PropTypes.func.isRequired,
    refetchEventsForDay: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    let betpalUrl = '';
    if (
        state.calendar.backendSettings &&
        state.calendar.backendSettings.betpal &&
        state.calendar.backendSettings.betpal.uri
    ) {
        betpalUrl = state.calendar.backendSettings.betpal.uri;
    } else {
        console.error('Backend Settings is missing the betpal Url');
    }

    return {
        currentView: state.viewport.currentView,
        betpalUrl,
        selectedDayTimestamp: state.calendar.selectedDayTimestamp,
        longestMatchDuration: longestMatchDuration(state.calendar),
    };
};

const mapDispatchToProps = {
    createNotification,
    removeMatch: removeEvent,
    refetchEventsForDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlOddsDialog);
