import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import FontIcon from '../../../../../icons/FontIcon/FontIcon.jsx';
import Button from '../../../../../formInput/Button/Button';
import BusySpinner from '../../../../../BusySpinner/BusySpinner';
import {
    addDialog,
    removeDialog,
} from '../../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../../stores/dialog/constants';
import {
    fetchFeatures,
    createFeature,
    updateFeature,
    deleteFeature,
} from '../../../../../../stores/feature/actions';
import './featureConfig.scss';

class FeatureConfig extends React.Component {
    state = {
        features: [],
        unchangedFeatures: [],
        newTitle: '',
        newDescription: '',
        newValue: '',
        newEnabled: false,
    };

    componentDidMount() {
        this.props.fetchFeatures();
    }

    static getDerivedStateFromProps(props, state) {
        if (!isEqual(props.unchangedFeatures, state.unchangedFeatures)) {
            const nextState = {
                features: props.unchangedFeatures,
                unchangedFeatures: props.unchangedFeatures,
            };

            if (props.unchangedFeatures.length > state.features.length) {
                nextState.newTitle = '';
                nextState.newDescription = '';
                nextState.newValue = '';
                nextState.newEnabled = false;
            }
            return nextState;
        }

        return null;
    }

    onFeatureValueChange = (id, value) => {
        this.setState({
            features: this.state.features.map(f =>
                f.id === id ? { ...f, value } : f
            ),
        });
    };

    onFeatureEnabledChange = (id, isEnabled) => {
        this.setState({
            features: this.state.features.map(f =>
                f.id === id ? { ...f, isEnabled } : f
            ),
        });
    };

    updateFeature = id => {
        const feature = this.state.features.find(f => f.id === id);
        if (feature === undefined) {
            console.warn('Could not find feature with id: ' + id);
            return;
        }

        this.props.updateFeature(feature);
    };

    createNewFeature = () => {
        const feature = {
            title: this.state.newTitle,
            description: this.state.newDescription,
            value: this.state.newValue,
            isEnabled: this.state.newEnabled,
        };

        this.props.createFeature(feature);
    };

    deleteFeature = id => {
        this.props.addDialog(dialogTypes.DELETE_FEATURE, {
            onConfirm: () => {
                this.props.removeDialog(dialogTypes.DELETE_FEATURE);
                this.props.deleteFeature(id);
            },
        });
    };

    render() {
        let features = [];
        if (this.state.features.length > 0) {
            const isSaving = this.props.currentlySavingFeatureId !== null;

            features = [...this.state.features];

            features = features.map((feature, index) => {
                const isEnabledInDb = this.props.unchangedFeatures.find(
                    f => f.id === feature.id
                ).isEnabled;
                return (
                    <div className="feature" key={'feature_' + index}>
                        <div className="feature-id">{feature.id}</div>
                        <div className="feature-created-date">
                            {feature.dateCreated}
                        </div>
                        <div className="feature-title">{feature.title}</div>
                        <div className="feature-description">
                            {feature.description}
                        </div>
                        <div className="feature-value">
                            <input
                                type="text"
                                value={feature.value}
                                className="feature-value"
                                disabled={
                                    isSaving || !this.props.access.admin_write
                                }
                                onChange={e =>
                                    this.onFeatureValueChange(
                                        feature.id,
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <div className="feature-enabled">
                            <input
                                type="checkbox"
                                checked={feature.isEnabled}
                                disabled={
                                    isSaving || !this.props.access.admin_write
                                }
                                onChange={e =>
                                    this.onFeatureEnabledChange(
                                        feature.id,
                                        e.target.checked
                                    )
                                }
                            />
                        </div>
                        {this.props.access.admin_write && (
                            <Button
                                type={
                                    feature.value.length
                                        ? Button.types.LARGE_BLUE_SHADOW
                                        : Button.types.LARGE_RED_SOLID_NO_HOVER
                                }
                                fixedWidth={false}
                                disabled={isEqual(
                                    feature,
                                    this.props.unchangedFeatures.find(
                                        f => f.id === feature.id
                                    )
                                )}
                                className="save-button"
                                onClick={
                                    feature.value.length
                                        ? () => this.updateFeature(feature.id)
                                        : () => {
                                              //do nothing
                                          }
                                }
                                title={
                                    feature.value.length
                                        ? ''
                                        : 'Value cannot be empty'
                                }
                            >
                                {this.props.currentlySavingFeatureId ===
                                feature.id ? (
                                    <BusySpinner
                                        size={16}
                                        margin={'0'}
                                        color={'#FFF'}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        )}
                        {!isEnabledInDb && this.props.access.admin_write && (
                            <div
                                className="delete"
                                title="Delete feature"
                                onClick={() => this.deleteFeature(feature.id)}
                            >
                                <FontIcon icon="" />
                            </div>
                        )}
                    </div>
                );
            });

            // Add header row
            features.unshift(
                <div className="header feature" key={'feature_header'}>
                    <div className="feature-id">ID</div>
                    <div className="feature-created-date">Created Date</div>
                    <div className="feature-title">Title</div>
                    <div className="feature-description">Description</div>
                    <div className="feature-value">Value</div>
                    <div className="feature-enabled">Enabled</div>
                    <div className="save-button" />
                    <div className="delete" />
                </div>
            );

            features.unshift(
                <div className="new feature" key={'feature_new'}>
                    <div className="feature-id" />
                    <div className="feature-created-date">CREATE NEW</div>
                    <div className="feature-title">
                        <input
                            type="text"
                            placeholder="Title"
                            value={this.state.newTitle}
                            disabled={isSaving}
                            onChange={e =>
                                this.setState({ newTitle: e.target.value })
                            }
                        />
                    </div>
                    <div className="feature-description">
                        <input
                            type="text"
                            placeholder="Description"
                            value={this.state.newDescription}
                            disabled={isSaving}
                            onChange={e =>
                                this.setState({
                                    newDescription: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="feature-value">
                        <input
                            type="text"
                            placeholder="Value"
                            value={this.state.newValue}
                            disabled={isSaving}
                            onChange={e =>
                                this.setState({ newValue: e.target.value })
                            }
                        />
                    </div>
                    <div className="feature-enabled">
                        <input
                            type="checkbox"
                            checked={this.state.newEnabled}
                            disabled={isSaving}
                            onChange={e =>
                                this.setState({ newEnabled: e.target.checked })
                            }
                        />
                    </div>
                    {this.props.access.admin_write && (
                        <Button
                            type={Button.types.LARGE_BLUE_SHADOW}
                            fixedWidth={false}
                            disabled={
                                isSaving ||
                                !this.state.newTitle.length ||
                                !this.state.newDescription.length ||
                                !this.state.newValue.length
                            }
                            className="save-button"
                            onClick={this.createNewFeature}
                        >
                            {this.props.currentlySavingFeatureId === 'new' ? (
                                <BusySpinner
                                    size={16}
                                    margin={'0'}
                                    color={'#FFF'}
                                />
                            ) : (
                                'Create'
                            )}
                        </Button>
                    )}
                </div>
            );
        }

        return <div className="feature-config">{features}</div>;
    }
}

FeatureConfig.propTypes = {
    addDialog: PropTypes.func.isRequired,
    removeDialog: PropTypes.func.isRequired,
    unchangedFeatures: PropTypes.array.isRequired,
    currentlySavingFeatureId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    fetchFeatures: PropTypes.func.isRequired,
    createFeature: PropTypes.func.isRequired,
    updateFeature: PropTypes.func.isRequired,
    deleteFeature: PropTypes.func.isRequired,
    access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    access: state.calendar.access,
    unchangedFeatures: state.feature.features,
    currentlySavingFeatureId: state.feature.currentlySavingFeatureId,
});

const mapDispatchToProps = {
    addDialog,
    removeDialog,
    fetchFeatures,
    createFeature,
    updateFeature,
    deleteFeature,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureConfig);
