import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import App from './components/pages/App';
import createStore from './stores/createStore';

const AppWrapper = ({ isPiwikInitialized = false }) => {
    const store = createStore();

    return (
        <Provider store={store}>
            <App isPiwikInitialized={isPiwikInitialized} />
        </Provider>
    );
};

AppWrapper.propTypes = {
    consumerRoute: PropTypes.string,
    isPiwikInitialized: PropTypes.bool,
};

export default AppWrapper;
