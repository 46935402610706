import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import UiSwitcher from './UiSwitcher/UiSwitcher.jsx';
import UserInfo, { UserInfo as MockUserInfo } from './UserInfo/UserInfo.jsx';
import ViewSwitcher from './ViewSwitcher/ViewSwitcher.jsx';
import './subHeader.scss';

export class SubHeader extends React.Component {
    _buildUserInfoDiv() {
        const backendSettings = this.props.backendSettings;

        if (this.props.forceSize) {
            return <MockUserInfo userInfo={this.props.userInfo} />;
        }

        if (backendSettings && backendSettings.showUserInfo) {
            return <UserInfo />;
        }

        return '';
    }

    render() {
        return (
            <div className="top-sub-header" ref={this.subHeaderRef}>
                <ViewSwitcher />
                {this.props.isUiSwitcherEnabled && <UiSwitcher />}
                {this._buildUserInfoDiv()}
            </div>
        );
    }
}

SubHeader.propTypes = {
    backendSettings: PropTypes.object,
    forceSize: PropTypes.string,
    userInfo: PropTypes.object,
    isUiSwitcherEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    backendSettings: state.calendar.backendSettings,
    isUiSwitcherEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_ui_switcher,
});

export default connect(mapStateToProps)(SubHeader);
