import StorageLocal from './StorageLocal';

export default class DynamicStyles {
    constructor(isNewProductsUIEnabled) {
        this.isNewProductsUIEnabled = isNewProductsUIEnabled;
        this.colors = this.isNewProductsUIEnabled
            ? this.newUiColors
            : this.colors;
        this.storage = new StorageLocal();
        this.storagePrefix = 'dynamicStylesheet.';
        document.body.classList.add('dynamic');
        Object.keys(this.colors).forEach(attribute =>
            this.setDynamicColorClassname(attribute)
        );
    }

    setDynamicColors(attribute, value) {
        this.storageSet(attribute, value);
        this.setDynamicColorClassname(attribute);
    }

    setDynamicColorClassname(attribute) {
        this.colors[attribute].map(value =>
            document.body.classList.remove(attribute + value)
        );
        document.body.classList.add(attribute + this.storageGet(attribute));
    }

    getDynamicColor(attribute) {
        return this.storageGet(attribute);
    }

    storageGet(key) {
        return (
            this.storage.getItem(this.storagePrefix + key) ||
            (this.colors[key] && this.colors[key][0])
        );
    }

    storageSet(key, value) {
        return this.storage.setItem(this.storagePrefix + key, value);
    }

    colors = {
        booked: ['Default', 'Darker', 'Lighter'],
        selected: ['Default', 'Darker', 'Lighter'],
    };

    newUiColors = {
        'booked-primary': ['Default', 'Darker', 'Lighter'],
        'booked-secondary': ['Default', 'Darker', 'Lighter'],
        selected: ['Default', 'Darker', 'Lighter'],
    };
}
