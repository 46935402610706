import actionTypes from './constants';
import _ from 'underscore';

const initialState = {
    types: [],
    customPropsByType: {},
};

export default (state, action) => {
    switch (action.type) {
        case actionTypes.ADD_DIALOG:
            return {
                ...state,
                types: state.types.concat([action.dialogType]),
                customPropsByType: {
                    ...state.customPropsByType,
                    [action.dialogType]: action.customProps,
                },
            };

        case actionTypes.REMOVE_DIALOG:
            return {
                ...state,
                types: state.types.filter(type => type !== action.dialogType),
                customPropsByType: _.omit(
                    state.customPropsByType,
                    action.dialogType
                ),
            };

        case actionTypes.CLEAR_DIALOGS:
            return initialState;

        default:
            return state || initialState;
    }
};
