import { createSelector } from 'reselect';
import autobookingsByType from './autobookingsByType';

const hideAutobookingDueToFilter = (autobooking, filterName, filterValues) => {
    const compare = key => {
        if (filterValues[key] === -1) {
            return true; // -1 means All, so matches all autobooking values
        }
        return autobooking[key] === filterValues[key];
    };

    const compareBoolean = key => {
        return !!autobooking[key] === filterValues[key];
        // we get 0/1 values from the backend for booleans, so we need to cast them to boolean
    };

    const checkIfListsOverlap = (list1, list2) => {
        for (const item of list1) {
            if (list2.includes(item)) {
                return true;
            }
        }
        return false;
    };

    const compareLists = key => {
        if (!filterValues[key] || filterValues[key].length === 0) {
            if (key === 'products') {
                return checkIfListsOverlap(autobooking[key], ['lo']);
            }
            return true;
        }
        return checkIfListsOverlap(autobooking[key], filterValues[key]);
    };

    const compareRange = (fromKey, toKey) => {
        return (
            autobooking[fromKey] <= filterValues[toKey] &&
            filterValues[fromKey] <= autobooking[toKey]
        );
    };

    switch (filterName) {
        case 'sport':
            return !compare('sportId');
        case 'category':
            return !compare('realCategoryId');
        case 'tournament':
            return !compare('tournamentId');
        case 'matches':
            return !compare('homeTeamId') && !compare('awayTeamId');
        case 'products':
            return !compareLists('products');
        case 'types':
            return !compareLists('types');
        case 'tiers':
            return !compareLists('tierContent');
        case 'dates':
            return !compareRange('startDate', 'endDate');
        case 'times':
            return !compareRange('startTime', 'endTime');
        case 'weekdays':
            return !compareLists('daysOfWeek');
        case 'agegroups':
            return !compareLists('ageGroups');
        case 'surcharge':
            return !compareBoolean('withoutSurchargeOnly');
        case 'loconfig':
            return !compareBoolean('isMatchActiveInLiveoddsConfig');
        case 'single':
            return !compareBoolean('bookableWithSinglePackage');

        // to add a new autobooking filter/column, edit here and every other place this comment exists

        default:
            console.error('Invalid filter type: ' + filterName);
    }
    return false;
};

const shouldShowAutobooking = (autobooking, filters) => {
    const selectedFilterList = Object.keys(filters.selectedFilters).filter(
        filterName => filters.selectedFilters[filterName]
    );
    for (const filterName of selectedFilterList) {
        if (
            hideAutobookingDueToFilter(
                autobooking,
                filterName,
                filters.filterValues
            )
        ) {
            return false;
        }
    }
    return true;
};

export default createSelector(
    state => autobookingsByType(state),
    state => state.autobooking.activeFilters,
    (autobookings, activeFilters) => {
        return Object.values(autobookings).filter(autobooking =>
            shouldShowAutobooking(autobooking, activeFilters)
        );
    }
);
