export default calendarStoreState => {
    if (
        calendarStoreState &&
        calendarStoreState.auth &&
        calendarStoreState.auth.userInfo &&
        calendarStoreState.auth.userInfo.bookmakerInfo
    ) {
        return calendarStoreState.auth.userInfo.bookmakerInfo;
    } else {
        return {};
    }
};
