import types from './constants';

const initialState = {
    event: null,
    positionX: 0,
    positionY: 0,
    showContextMenu: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_CONTEXT_MENU:
        case types.CLOSE_CONTEXT_MENU:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state || initialState;
    }
};
