import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FilterConstants from '../../../../../../../constants/FilterConstants';
import selectedCheckboxes from '../../../../../../../selectors/filter/selectedCheckboxes';
import showOnlyMatchesInLOConfig from '../../../../../../../selectors/showOnlyMatchesInLOConfig';
import {
    checkboxFilterToggle,
    recalculateFilteredGroups,
    setRecommenderSettings,
} from '../../../../../../../stores/calendar/actions';
import { getIdentifier } from '../../../../../../../utils/FilterUtils';
import { recommenderSettingsFn } from '../../../../../../../utils/RecommenderSettings';
import CheckBoxIcon from '../../../../../../icons/CheckBoxIcon/CheckBoxIcon';
import FontIcon from '../../../../../../icons/FontIcon/FontIcon';
import Count from './Count/Count';
import './checkbox.scss';

const disabledSportTitle =
    'Sport disabled in LO Configuration. To view hidden matches, select settings tab and change your filter settings';

function Checkbox(props) {
    const {
        checkbox,
        selectedProducts,
        setRecommenderSettings,
        recommenderSettings,
    } = props;

    const isChecked = props.selectedCheckboxes.hasOwnProperty(
        getIdentifier(checkbox)
    );
    const isDisabledInLoSportConfig =
        props.showSportIcons &&
        checkbox.value &&
        props.showOnlyMatchesInLOConfig &&
        props.allSports[checkbox.value].disabledInLOConfig;

    const className = classnames('filter-checkbox', {
        disabled: checkbox.disabled,
        sport: props.showSportIcons,
        'checked-full': isChecked,
        'text-blue-br-700': isChecked,
        'disabled-lo-sport-config': isDisabledInLoSportConfig,
    });

    const showDisabledChildrenWarning =
        props.showOnlyMatchesInLOConfig &&
        props.showSportIcons &&
        !isDisabledInLoSportConfig &&
        props.allSports[checkbox.value].hasDisabledChildren;

    const countElement =
        !isDisabledInLoSportConfig &&
        buildCountElement(
            checkbox,
            props.showBookedCounts,
            isChecked,
            selectedProducts
        );

    const disabledChildrenWarning = showDisabledChildrenWarning && (
        <FontIcon
            icon=""
            size="xs"
            title={
                'Categories / Tournaments disabled within the sport in LO Configuration. ' +
                'To view hidden matches, select settings tab and change your Filter Settings'
            }
        />
    );

    const fullDisabledSportTitle =
        disabledSportTitle + (checkbox.title ? `. ${checkbox.title}` : '');

    const title = isDisabledInLoSportConfig
        ? fullDisabledSportTitle
        : checkbox.title || '';

    useEffect(() => {
        if (
            checkbox.value ===
            FilterConstants.availabilities.RECOMMENDED_MATCHES
        ) {
            const settings = recommenderSettingsFn(
                props.events,
                recommenderSettings.selectedNumber,
                recommenderSettings.selectedPriority === 'mts' ? true : false,
                recommenderSettings.showRecommended,
                isChecked
            );
            setRecommenderSettings(settings);
        }
    }, [
        setRecommenderSettings,
        checkbox,
        recommenderSettings.showRecommended,
        recommenderSettings.selectedPriority,
        recommenderSettings.selectedNumber,
        isChecked,
        props.events,
    ]);

    return (
        checkbox.name && (
            <div
                className={className}
                onClick={() => {
                    if (!isDisabledInLoSportConfig) {
                        props.checkboxFilterToggle(
                            checkbox.groupId,
                            checkbox.subGroupId,
                            checkbox.value
                        );

                        props.recalculateFilteredGroups(props.currentView);
                    }
                }}
                title={title}
            >
                {buildCheckboxElement(isDisabledInLoSportConfig, isChecked)}
                <div className="description ui-400">{checkbox.name}</div>
                {disabledChildrenWarning}
                {countElement}
            </div>
        )
    );
}

Checkbox.propTypes = {
    checkbox: PropTypes.object.isRequired,
    showSportIcons: PropTypes.bool.isRequired,
    showBookedCounts: PropTypes.bool.isRequired,
    selectedCheckboxes: PropTypes.object.isRequired,
    selectedProducts: PropTypes.array.isRequired,
    showOnlyMatchesInLOConfig: PropTypes.bool.isRequired,
    currentView: PropTypes.string.isRequired,
    allSports: PropTypes.object.isRequired,
    checkboxFilterToggle: PropTypes.func.isRequired,
    recalculateFilteredGroups: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    setRecommenderSettings: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    recommenderSettings: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        selectedCheckboxes: selectedCheckboxes(
            state,
            state.calendar.selectedCheckboxes
        ),
        selectedProducts: state.calendar.selectedProducts,
        showOnlyMatchesInLOConfig: showOnlyMatchesInLOConfig(state),
        currentView: state.viewport.currentView,
        allSports: state.calendar.sports,
        events: state.calendar.events,
        selectedTab: state.leftColumn.selectedTabs.event_views,
        recommenderSettings: state.calendar.recommenderSettings,
    };
};

const mapDispatchToProps = {
    checkboxFilterToggle,
    setRecommenderSettings,
    recalculateFilteredGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);

function buildCheckboxElement(disabled, checked) {
    const color = checked ? '' : 'color-black-45';
    return disabled ? (
        <FontIcon icon="" color={color} />
    ) : (
        <CheckBoxIcon selected={checked} color={color} />
    );
}

function buildCountElement(
    checkbox,
    showBookedCounts,
    checked,
    selectedProducts
) {
    return (
        <div className={checked ? 'text-blue-br-700' : 'text-gray-1100'}>
            <Count
                checkbox={checkbox}
                showBookedCounts={showBookedCounts}
                selectedProducts={selectedProducts}
            />
        </div>
    );
}
