import Settings from '../../../../../../utils/DefaultSettings';
import { colors } from '../../../../../../selectors/scheduleOverview';

const getLeft = (leftOffset, columnSpace, columnWidth, index) => {
    return (
        leftOffset + columnSpace / 2 + index * columnSpace + index * columnWidth
    );
};

const getColumnWidth = (pxPerHour, columnSpace) => {
    return (
        pxPerHour / Settings.timeLine.scheduleOverview.columnsPerHour -
        columnSpace
    );
};

const row = (ctx, top, height, color, width, left) => {
    ctx.fillStyle = color;
    ctx.fillRect(left, top, width, height);
};

const drawColumn = (
    ctx,
    left,
    columnWidth,
    rows,
    pxPerMatch,
    sportsSorted,
    maxHeight
) => {
    let topOffset = maxHeight;
    let smallDecimalLeft = 0.0;
    sportsSorted.forEach(sportData => {
        if (!rows[sportData.sportId]) {
            return;
        }
        const height =
            rows[sportData.sportId].all * pxPerMatch + smallDecimalLeft;
        if (height !== 0) {
            const roundedDownHeight = Math.floor(height);
            if (roundedDownHeight === 0) {
                smallDecimalLeft = height;
            } else {
                smallDecimalLeft = height % roundedDownHeight;
            }
            topOffset -= roundedDownHeight;
        }
        row(
            ctx,
            topOffset,
            height,
            sportData.isOthers
                ? Settings.timeLine.scheduleOverview.defaultColor
                : colors[sportData.sportId],
            columnWidth,
            left
        );
    });
};

const drawColumns = (
    ctx,
    pxPerHour,
    data,
    columnSpace,
    leftOffset,
    frameHeight
) => {
    const columnWidth =
        pxPerHour / Settings.timeLine.scheduleOverview.columnsPerHour -
        columnSpace;

    Object.keys(data.columns).forEach((key, index) => {
        const left = getLeft(leftOffset, columnSpace, columnWidth, index);

        drawColumn(
            ctx,
            left,
            columnWidth,
            data.columns[key].sports,
            data.pxPerMatch,
            data.sportsSorted,
            frameHeight
        );
    });
};

const drawLinePath = (
    ctx,
    points,
    color = 'black',
    width = 3,
    dashed = false
) => {
    ctx.lineWidth = width;

    points.forEach((point, index) => {
        if (index === 0) {
            ctx.beginPath();
            if (dashed) {
                ctx.setLineDash([10, 2]);
            }
            ctx.moveTo(point.x, point.y);
        } else {
            ctx.lineTo(point.x, point.y);
        }
    });

    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.setLineDash([]);
};

const drawTotalLine = (
    ctx,
    pxPerHour,
    data,
    columnSpace,
    frameHeight,
    leftOffset,
    lineWidth,
    totalName,
    color
) => {
    const columnWidth = getColumnWidth(pxPerHour, columnSpace);

    const points = Object.values(data.columns).map((column, index) => {
        return {
            x:
                getLeft(leftOffset, columnSpace, columnWidth, index) +
                columnWidth / 2,
            y: frameHeight - data.pxPerMatch * column.totals[totalName],
        };
    });

    drawLinePath(ctx, points, color, lineWidth);
};

const drawGrid = (ctx, frameHeight, frameWidth, canvasTopPadding) => {
    const lineWidth = 1;
    for (
        let x = 0;
        x <= Settings.timeLine.scheduleOverview.gridLineCount;
        x++
    ) {
        const y =
            x *
                ((frameHeight - canvasTopPadding) /
                    Settings.timeLine.scheduleOverview.gridLineCount) +
            canvasTopPadding;
        drawLinePath(
            ctx,
            [
                { x: 0, y },
                { x: frameWidth + 50, y },
            ],
            '#d7d7d7',
            lineWidth,
            true
        );
    }
};

const drawColumnHighlight = (
    ctx,
    pxPerHour,
    highlightedColumn,
    data,
    columnSpace,
    frameHeight,
    leftOffset,
    highlightWidth = 3
) => {
    const width = getColumnWidth(pxPerHour, columnSpace),
        height = data.columns[highlightedColumn].totals.all * data.pxPerMatch,
        left = getLeft(
            leftOffset,
            columnSpace,
            width,
            highlightedColumn / data.interval
        );

    const points = [
        {
            x: left - highlightWidth / 2,
            y: frameHeight,
        },
        {
            x: left - highlightWidth / 2,
            y: frameHeight - height - highlightWidth / 2,
        },
        {
            x: left + width + highlightWidth / 2,
            y: frameHeight - height - highlightWidth / 2,
        },
        {
            x: left + width + highlightWidth / 2,
            y: frameHeight,
        },
    ];

    drawLinePath(ctx, points, 'black', highlightWidth);
};

const getColumnFromLeft = (left, pxPerHour, data, columnSpace, leftOffset) => {
    const columnWidth = getColumnWidth(pxPerHour, columnSpace);
    let newLeft = left - leftOffset;

    if (newLeft < 0) {
        newLeft = 0;
    }

    if (newLeft >= pxPerHour * 24) {
        newLeft = pxPerHour * 24 - 1;
    }

    return Math.floor(newLeft / (columnSpace + columnWidth)) * data.interval;
};

export default {
    drawColumns,
    drawTotalLine,
    drawGrid,
    drawColumnHighlight,
    getColumnFromLeft,
};
