import PropTypes from 'prop-types';
import React from 'react';
import './BusySpinner.scss';

const BusySpinner = ({
    size = 32,
    color = '#F28185',
    margin = '1rem auto',
}) => (
    <div
        className="busy-spinner"
        style={{
            width: size,
            height: size,
            borderWidth: size / 8,
            borderColor: color,
            borderRightColor: 'transparent',
            margin: margin,
        }}
    />
);

BusySpinner.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    margin: PropTypes.string,
};

export default BusySpinner;
