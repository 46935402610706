import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './matchColorPicker.scss';
import DynamicStyles from '../../../utils/DynamicStyles';

class MatchColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.dynamicStyles = new DynamicStyles(
            this.props.isNewProductsUIEnabled
        );
    }

    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    renderSelectedOption() {
        const isExpanded = this.state.expanded;

        const option = this.dynamicStyles.getDynamicColor(this.props.attribute);
        const arrow = (
            <div className="arrow">
                <i
                    className={classnames('fa', {
                        'fa-caret-down': !isExpanded,
                        'fa-caret-up': isExpanded,
                    })}
                />
            </div>
        );

        return (
            <div
                key={'optionVisible'}
                className={classnames('option', this.props.attribute)}
            >
                {arrow}
                {option}
            </div>
        );
    }

    renderOption(option) {
        const classname = this.props.attribute + option,
            onClick = () => this.props.onChange(this.props.attribute, option);

        return (
            <div
                key={`option${option}`}
                onClick={onClick}
                className={classnames(
                    'option',
                    'dynamic',
                    classname,
                    this.props.attribute,
                    this.props.attribute === 'booked-secondary' &&
                        this.dynamicStyles.getDynamicColor('booked-primary') ===
                            option &&
                        'disabled'
                )}
            >
                {option}
            </div>
        );
    }

    renderSelectableOptions() {
        if (!this.state.expanded) {
            return '';
        }
        return (
            <div className="options">
                {this.props.options.map(option => {
                    return this.renderOption(option);
                })}
            </div>
        );
    }

    render() {
        return (
            <div
                onClick={this.toggleExpanded}
                className="match-color-picker"
                style={{ width: this.props.width }}
            >
                {this.renderSelectedOption()}
                {this.renderSelectableOptions()}
            </div>
        );
    }
}

MatchColorPicker.propTypes = {
    attribute: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.number,
    isNewProductsUIEnabled: PropTypes.bool,
};

export default MatchColorPicker;
