import PropTypes from 'prop-types';
import React from 'react';
import FontIcon from '../icons/FontIcon/FontIcon.jsx';
import classnames from 'classnames';
import './alert.scss';

const getCloseContent = props => {
    if (!props.onClose) {
        return '';
    }
    return (
        <div onClick={props.onClose} className="close-button">
            <FontIcon icon={''} />
        </div>
    );
};

getCloseContent.propTypes = {
    onClose: PropTypes.func,
};

const Alert = props => {
    const {
        constrained = false,
        minWidth = true,
        styleProp = {},
        icon = '',
        onMouseLeave = () => {},
        onMouseEnter = () => {},
        float = true,
    } = props;
    const className = classnames('alert', props.type, {
            'alert-small': !minWidth,
            'alert-constrained': constrained,
            'has-close': props.onClose,
        }),
        style = {
            ...styleProp,
            float: float ? 'left' : 'none',
        };

    return (
        <div
            className={className}
            style={style}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
        >
            <div className="left-container">
                <div className="icon-container">
                    {icon ? (
                        <FontIcon
                            className={icon === '' ? 'spinner' : ''}
                            icon={icon}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className="content-container">{props.children}</div>
            </div>
            <div className="close-container">{getCloseContent(props)}</div>
        </div>
    );
};

Alert.types = {
    INFO: 'alert-info',
    INFO_BOX: 'alert-info-box',
    ERROR: 'alert-error',
    DANGER: 'alert-danger',
    SUCCESS: 'alert-success',
    HINT: 'alert-hint',
    EXPLANATORY: 'alert-explanatory',
};

Alert.propTypes = {
    type: PropTypes.oneOf(Object.values(Alert.types)).isRequired,
    minWidth: PropTypes.bool,
    constrained: PropTypes.bool,
    float: PropTypes.bool,
    style: PropTypes.object,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onClose: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    children: PropTypes.node,
    styleProp: PropTypes.object,
};

export default Alert;
