let userClearTimer;
let chatOpen = false;
let chatOngoing = false; // we also sync this flag with localstorage to persist across reloads

class Chat {
    static init(userInfo, isLocal = false) {
        const isTest = Boolean(window && window.Cypress);
        if (isTest || isLocal) {
            return;
        }

        // if the script isn't loaded yet we set it to run init again when it is
        if (!window.fcWidget) {
            const chatScript = document.getElementById('chat-script');
            chatScript.addEventListener('load', this.init.bind(this, userInfo));
            return;
        }

        window.fcWidget.hide();

        window.fcWidget.init({
            token: 'fdd3b1f7-8647-4c84-90fe-f1f0486b7255',
            host: 'https://wchat.eu.freshchat.com',
            externalId: `${userInfo.bookmakerName} ${userInfo.bookmakerId}`,
        });

        window.fcWidget.on(
            'message:received',
            function (data) {
                if (
                    data.message.messageFragments[0].content ===
                    'Thank you for chatting with us.'
                ) {
                    userClearTimer = setTimeout(this.close, 300);
                }
            }.bind(this)
        );

        window.fcWidget.on('message:sent', this.markAsOngoing.bind(this));

        window.fcWidget.on(
            'csat:updated',
            function (data) {
                if (userClearTimer) {
                    clearTimeout(userClearTimer);
                }
                if (data.data.message.csatResponse.issueResolved) {
                    this.close();
                }
            }.bind(this)
        );

        chatOngoing = window.localStorage.getItem('chatOngoing');

        if (chatOngoing) {
            this.start(userInfo, null, true);
            this.markAsOngoing();
        } else {
            // clear any remaining chatlogs if there are any
            this.setProperties(userInfo);
            this.close();
        }

        window.addEventListener('beforeunload', event => {
            // on before tab close, if timer is running, then removed the ongoingChat flag in localStorage.
            if (userClearTimer) {
                window.localStorage.setItem('clearFreshchatUserOnOpen', false);
                window.localStorage.removeItem('chatOngoing');
                clearTimeout(userClearTimer);
            }
        });
    }

    static showChat() {
        window.fcWidget.show();
    }

    static setProperties(userInfo, event) {
        const names = userInfo.name.split(/\s+/);
        let lastName = null;
        if (names.length > 1) {
            lastName = names.pop();
        }
        const firstName = names.join(' ');

        let properties = {
            firstName,
            lastName,
            email: userInfo.bookmakerInfo.email,
            Chattype: 'LBC-Support', // meta property 1 (hardcoded)
            Username: firstName, // LBC username
            Useremail: userInfo.bookmakerInfo.email,
        };
        if (event) {
            properties = {
                ...properties,
                Match: `${event.homeTeam}:${event.awayTeam} (${event.id})`,
                MatchId: event.id,
            };
        }
        window.fcWidget.user.setProperties(properties);
    }

    static start(userInfo, event, force) {
        if (chatOpen && !force) {
            window.alert(
                'Please feel free to raise your concern in the already active chat'
            );
            return;
        }
        this.setProperties(userInfo, event);
        window.fcWidget.show();
        window.fcWidget.open();
        chatOpen = true;
    }

    static markAsOngoing() {
        if (!chatOngoing) {
            chatOngoing = true;
            window.localStorage.setItem('chatOngoing', true);
        }
    }

    static close() {
        window.fcWidget.user.clear();
        window.fcWidget.hide();
        chatOpen = false;
        chatOngoing = false;
        window.localStorage.removeItem('chatOngoing');
    }
}

export default Chat;
