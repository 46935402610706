import types from './constants';

let notificationCounter = 0;
const afterHoverTimeout = 2000,
    timers = {};

export const create = (
    type,
    message,
    icon = '',
    timeout = 5000
) => {
    return dispatch => {
        const id = notificationCounter++;
        if (timeout !== 0) {
            timers[id] = setTimeout(() => {
                dispatch(close(id));
            }, timeout);
        }

        dispatch({
            type: types.CREATE,
            payload: {
                id,
                type,
                message,
                icon,
            },
        });
    };
};

export const close = id => {
    return dispatch => {
        const notificationId = id !== undefined ? id : notificationCounter - 1;
        dispatch({
            type: types.CLOSE,
            payload: {
                id: notificationId,
            },
        });
    };
};

export const onMouseEnter = id => {
    return () => {
        if (timers[id]) {
            clearTimeout(timers[id]);
            delete timers[id];
        }
    };
};

export const onMouseLeave = id => {
    return dispatch => {
        timers[id] = setTimeout(() => {
            dispatch(close(id));
        }, afterHoverTimeout);
    };
};
