import actionTypes from './constants';

export const addDialog = (dialogType, customProps) => ({
    type: actionTypes.ADD_DIALOG,
    dialogType,
    customProps,
});

export const removeDialog = dialogType => ({
    type: actionTypes.REMOVE_DIALOG,
    dialogType,
});

export const clearDialogs = () => ({ type: actionTypes.CLEAR_DIALOGS });
