import views from '../../constants/views';

export default (
    history,
    defaultView = views.VIEWPORT_TYPE_LIST_CONDENSED,
    inGroup = []
) => {
    if (inGroup.length === 0) {
        if (history.length === 0) {
            return null;
        }

        return history[history.length - 1];
    }

    for (let x = history.length - 1; x > 0; x--) {
        if (inGroup.indexOf(history[x]) !== -1) {
            return history[x];
        }
    }

    return defaultView;
};
